<template>
  <div class="animated fadeIn">
    <b-container fluid class="order-list">
      <order-filters :onFiltered="onFiltered" />

      <b-row class="mt-3">
        <b-col class="col-md-auto">
          <b-pagination
            v-if="totalRows"
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            @change="this.onPaginationInput"
            class="mb-3"
          />
        </b-col>

        <b-col class="col-md-auto">
          <b-form-group
            id="perPage"
            horizontal
            :label="$t('Mostrar por página')"
          >
            <b-form-select :options="pageOptions" v-model="perPage" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col md="12">
          <b>{{
            $tc("{count} ordenes encontradas", totalRows, { count: totalRows })
          }}</b>
        </b-col>
      </b-row>

      <b-card
        header-bg-variant="primary"
        border-variant="primary"
        :header="$t('Listado de ordenes')"
      >
        <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
        <b-table
          v-show="!loading"
          id="mainTable"
          ref="mainTable"
          show-empty
          striped
          hover
          stacked="xl"
          :items="orderList"
          foot-clone
          :fields="isAdmin ? fields : fieldsNotAdmin"
          :current-page="1"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @row-clicked="rowClick"
          :empty-text="$t('No hay ordenes para mostrar')"
          :empty-filtered-text="$t('No hay ordenes para mostrar')"
        >
          <template slot="clientProfileData" slot-scope="data">
            {{ data.value ? data.value.firstName : "" }}
            {{ data.value ? data.value.lastName : "" }}
          </template>
          <template slot="status" slot-scope="status">
            <span :class="getStatusColorClass(status.value)"
              ><b>{{ $t(statusMapping[status.value]) }}</b></span
            >
          </template>
          <template slot="subStatus" slot-scope="subStatus">
            <p class="substatus-name" v-if="subStatus.item.subStatus">
              {{ subStatus.item.subStatus.name }}
            </p>
            <p v-else>-</p>
          </template>
          <template slot="creationDate" slot-scope="creationDate">
            {{ new Date(creationDate.value).toLocaleDateString(locale) }}
          </template>
          <template slot="value" slot-scope="value">
            {{ currency }} {{ value.value }}
          </template>
          <template slot="siteAndTradeAgreement" slot-scope="data">
            {{ getSiteAndTradeAgreementStr(data ? data.item : null) }}
          </template>
          <template slot="tags" slot-scope="row">
            <span
              v-for="tagElemKey in row.item.tags"
              v-b-tooltip.hover
              :title="$t(tagsMapping[tagElemKey].description)"
              :key="tagsMapping[tagElemKey].description"
              class="tag"
              :class="tagsMapping[tagElemKey].bg"
            >
              {{ tagElemKey }}
            </span>
          </template>
        </b-table>
      </b-card>
      <b-row>
        <b-col md="6" class="my-1">
          <b-pagination
            v-if="totalRows"
            :total-rows="totalRows"
            :per-page="perPage"
            v-model="currentPage"
            @change="this.onPaginationInput"
            class="mb-3"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// Date Picker
import Vue from "vue";
import datePicker from "vue-bootstrap-datetimepicker";
import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";
import { OrdersServiceMixim } from "@/mixims/OrdersServiceMixim";
import { SubStatusMixim } from "@/mixims/SubStatusMixim.js";
import appConfig from "@/config/applicationConfig.js";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import { ValidationsMixim } from "@/mixims/ValidationsMixim.js";
import { MarketplaceServiceMixim } from "@/mixims/MarketplaceServiceMixim.js";
import OrderFilters from "./components/Filters";
Vue.use(datePicker);

export default {
  name: "orders-view",
  mixins: [
    OrdersServiceMixim,
    ValidationsMixim,
    SubStatusMixim,
    MarketplaceServiceMixim
  ],
  components: { MoonLoader, OrderFilters },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: this.$t("Número"),
          class: "text-left",
          sortable: true
        },
        {
          key: "accountName",
          label: this.$t("Seller"),
          class: "text-left",
          sortable: true
        },
        {
          key: "clientProfileData",
          label: this.$t("Cliente"),
          class: "text-left",
          sortable: true
        },
        {
          key: "creationDate",
          label: this.$t("Fecha"),
          class: "text-left",
          sortable: true
        },
        {
          key: "value",
          label: this.$t("Total"),
          class: "text-left",
          sortable: true
        },
        {
          key: "siteAndTradeAgreement",
          label: this.$t("Sitio / Política comercial"),
          class: "text-left",
          sortable: true
        },
        {
          key: "status",
          label: this.$t("Estado"),
          class: "text-left",
          sortable: true
        },
        {
          key: "subStatus",
          label: this.$t("Sub estado"),
          class: "text-left",
          sortable: true
        },
        { key: "tags", label: this.$t("Etiquetas"), class: "text-left" }
      ],
      fieldsNotAdmin: [
        {
          key: "id",
          label: this.$t("Número"),
          class: "text-left",
          sortable: true
        },
        {
          key: "clientProfileData",
          label: this.$t("Cliente"),
          class: "text-left",
          sortable: true
        },
        {
          key: "creationDate",
          label: this.$t("Fecha"),
          class: "text-left",
          sortable: true
        },
        {
          key: "value",
          label: this.$t("Total"),
          class: "text-left",
          sortable: true
        },
        {
          key: "siteAndTradeAgreement",
          label: this.$t("Sitio / Política comercial"),
          class: "text-left",
          sortable: true
        },
        {
          key: "status",
          label: this.$t("Estado"),
          class: "text-left",
          sortable: true
        },
        {
          key: "subStatus",
          label: this.$t("Sub estado"),
          class: "text-left",
          sortable: true
        },
        {
          key: "tags",
          label: this.$t("Etiquetas"),
          class: "text-left",
          sortable: false
        }
      ],
      isAdmin: this.$store.getters.isAdmin,
      perPage: this.$route.query.size ?? 10,
      totalRows: 0,
      pageOptions: [10, 15, 25, 50, 100],
      sortBy: null,
      sortDesc: false,
      filter: null,
      loading: false,
      locale: appConfig.LOCALE,
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : "$",
      statusMapping: appConfig.STATUS_MAPPING,
      filterStatusMapping: appConfig.FILTER_STATUS_MAPPING,
      statusMappingColors: appConfig.STATUS_MAPPING_COLORS,
      tagsMapping: appConfig.TAGS_MAPPING,
      currentPage: this.$route.query.from
        ? parseInt(this.$route.query.from)
        : 1,
      orderList: []
    };
  },
  watch: {
    perPage(perPage) {
      this.$router.push({
        query: {
          ...this.$route.query,
          size: perPage,
          from: 1
        }
      });
    }
  },
  methods: {
    onPaginationInput(newPage) {
      this.$router.push({
        query: {
          ...this.$route.query,
          from: newPage
        }
      });
    },
    getStatusColorClass: function(status) {
      return "text-" + this.statusMappingColors[status] + " h6";
    },
    controversyTagClass: function(row) {
      let days = 0;
      if (row.controversies && row.controversies.length > 0) {
        let auxDays = 0;
        row.controversies.forEach(cont => {
          let timeDiff = new Date() - new Date(cont.updateDate);
          auxDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
          days = auxDays >= days ? auxDays : days;
        });
      }
      if (days < 1) return "green";
      if (days < 2) return "yellow";
      return "red";
    },
    showControversyTag(orderDesc) {
      if (
        orderDesc.controversies !== null &&
        orderDesc.controversies.length > 0
      ) {
        return (
          orderDesc.controversies.find(item => {
            return item.state !== "INVALID" && item.state !== "DONE";
          }) !== undefined
        );
      }
      return false;
    },
    rowClick(item) {
      this.$router.push(`/orders/orderDetail/${item.id}`);
    },
    onFiltered(filterOptions) {
      this.$router.push({
        query: {
          ...filterOptions
        }
      });
    },
    setOrders() {
      const routeParams = { ...this.$route.query };

      this.getOrders({
        ...routeParams,
        size: this.perPage,
        from: this.currentPage - 1
      }).then(({ body: orders, headers }) => {
        this.orderList = orders;
        this.totalRows = parseInt(headers.map["x-total-count"][0]);
      });
    },
    getSubStatus() {
      return this.getAllStatus(null).then(
        function(response) {
          this.substatus = response.body;
        },
        error => {
          this.errorText =
            error.body.message ||
            this.$t("Error obteniendo el listado de estados");
          return [];
        }
      );
    },
    getSiteAndTradeAgreementStr(data) {
      var marketPlaceName = null;
      if (data && data.marketplace && data.marketplace.name)
        marketPlaceName = data.marketplace.name;

      var tradeAgreementName = null;
      if (data && data.salesChannelName)
        tradeAgreementName = data.salesChannelName;

      return (
        (marketPlaceName ? marketPlaceName : "-") +
        " / " +
        (tradeAgreementName ? tradeAgreementName : "-")
      );
    },
    resetTradeAgreementFilter() {
      this.filterOptions.tradeAgreementFilter = null;
    }
  },
  mounted() {
    this.setOrders();

    setInterval(() => {
      if (this.$refs.mainTable) {
        this.$refs.mainTable.refresh();
      }
    }, appConfig.ORDER_LIST_TTL);
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.order-list {
  .substatus-name::first-letter {
    text-transform: uppercase;
  }

  .orderTable {
    table-layout: fixed;
  }

  .filter-row {
    .btn-primary {
      width: 80px;
    }

    button {
      i {
        margin-right: 5px;
      }
    }
  }

  .filters {
    border: 1px solid #e3e8ec;
    background-color: #fff;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */
 {
    opacity: 0;
  }

  .tag {
    width: 26px;
    display: inline-block;
    border-radius: 5px;
    line-height: 17px;
    margin-right: 4px;
    cursor: pointer;
    text-align: center;

    &.green {
      background: #4dbd74;
      color: #ffffff;
    }

    &.yellow {
      background: #e8f48b;
    }

    &.red {
      background: #f23c3c;
      color: #ffffff;
    }
  }

  tr:hover {
    cursor: pointer;
  }

  .col-multiselect {
    z-index: 3;
    position: relative;
  }
}

::v-deep .multiselect__tags {
  min-height: 35px;
  padding: 5px 40px 0 8px;
  border-radius: 0;
}

::v-deep .multiselect__tag {
  margin-bottom: 0;
}

::v-deep .multiselect__select {
  height: 35px;
}

::v-deep .multiselect__placeholder {
  margin-bottom: 5px;
}

::v-deep .multiselect__input {
  margin-bottom: 5px;
  margin-top: 3px;
}
</style>
