<template>
  <div>
    <roles-list/>
  </div>
</template>

<script>
import RolesList from '@/components/roles/RolesList'

export default {
  name: 'roles-view',
  components: {
    RolesList
  }
}
</script>