<template>
  <li class="tree-node" >
   <div class="toggle" @click="toggle">
    <span class="toggle-icon" :class="{ 'empty-toggle': !isFolder }" :key="open">
      <i v-if="isFolder" :class="{'fas fa-caret-down' : this.open, 'fas fa-caret-right' : !this.open}" ></i>
    </span>    
    <span  class="tree-icon" :class="{ 'empty-toggle': !icon }" :key="icon">
      <i v-if="icon" class="far" :class="icon" ></i>
    </span>
  </div>
    <div class="tree-text label" :for="model.id" @click="performSelect" key="label">{{model.text}}</div>    
    <div class="tree-children">
      <ul v-show="open" v-if="isFolder">
        <v-treeview-item v-for="child in model.children" :key="child.id" 
        :model="child" :treeTypes="treeTypes" :openAll="openAll" @addNode="addNode"
        @selected="selected">
        </v-treeview-item>      
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  name: 'v-treeview-item',
  props: ['model', 'treeTypes', 'openAll'],
  data () {
    return {
      open: false
    }
  },
  computed: {
    isFolder () {
      return this.model.children && this.model.children.length
    },
    icon () {
      return this.getTypeRule(this.model.type).icon
    }
  },
  methods: {
    getTypeRule (type) {
      var typeRule = this.treeTypes.filter(t => t.type === type)[0]
      return typeRule
    },
    selected (node) {
      this.$emit('selected', node)
    },
    addNode (newNode) {
      var typeRule = this.getTypeRule(this.model.type)

      if (typeRule.valid_children.indexOf(newNode.type) > -1) {
        this.model.children.push(newNode)
      }
    },
    performSelect () {
      if (this.model.id) {
        this.selected(this)
      }
    },
    toggle () {
      if (this.isFolder) {
        this.open = !this.open
      }
    }
  },
  created () {
    this.open = this.openAll
  },
  watch: {
    openAll (openAll) {
      this.open = openAll
    }
  }
}
</script>

<style scoped lang="scss">
ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}
ul .tree-node {
  display: block;
  padding: 5px;
  padding-left: 15px;
}
.toggle {
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background:  #DDD;
  }
  text-align: center;
}
.label {
  display: inline-block;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.toggle-icon {
  display: inline-block;
  width: 20px;
}

ul .tree-node :hover:before {
  background: rgba(190, 235, 255, 0.3);
}

ul .tree-node input[type="radio"] {
  display: none;
}
ul .tree-node input[type="radio"]:checked + label:before {
  background: rgba(83, 215, 220, 0.3);
}



</style>
