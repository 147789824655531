<template>
    <div class="animated fadeIn">
      <b-modal header-bg-variant="primary" size="lg" ref="confirmationCloseIncidentModal" id="confirmationCloseIncidentModal" centered :title="$t('Cerrar incidencia')"
        @ok="solveIncident(incidentToSolve)"
        :cancel-title="$t('Cancelar')">
        <span>{{$t('¿Confirma que desea marcar la incidencia como CERRADA?')}}</span>
      </b-modal>
      <b-row class="my-3">
        <b-col md="10">
          <b>{{totalRows}}</b> {{$t('incidencias encontradas')}}
        </b-col>
      </b-row>
      <b-card border-variant="primary">
        <incidentsComments :show.sync="showIncidentsComments" :incident="currentRow" @saved="saveComment"/>
        <moon-loader :loading="false" color="#000" size="30px"></moon-loader>
        <b-table id="mainTable"
          ref="mainTable"
          show-empty
          striped
          hover
          stacked="xl"
          :items="itemProvider"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @row-clicked="editThisRow"
          responsive=true
          :empty-text="$t('No hay incidencias para mostrar')"
          :empty-filtered-text="$t('No hay incidencias para mostrar')">
          <template slot="reference" slot-scope="row">
            <div v-if="addReferenceLink && (row.item.incidenceType === 'ORDER' || row.item.incidenceType === 'PRODUCT')">
              <router-link :to="{ path: routeTo(row.item)}">{{row.item.reference}}</router-link>
            </div>
            <div v-else-if="row.item.incidenceType === 'SKU'">
              <b-button class="primary p-0" variant="link" @click.stop="routeToSku(row.item)">{{row.item.reference}}</b-button>
            </div>
            <div v-else>
              {{row.item.reference}}
            </div>
          </template>
          <template slot="creationDate" slot-scope="row">
            <span>{{new Date(row.item.creationDate).toLocaleDateString(locale)}}</span>
          </template>
          <template slot="author" slot-scope="row">
            {{row.item.author.name}} {{row.item.author.lastName}}
          </template>
          <template slot="priority" slot-scope="row">
            <span :class="mapPriority(row.item.priority)">{{getPriorityText(row.item.priority)}}</span>
          </template>
          <template slot="accountName" slot-scope="row">
            <span v-if="!row.item.author.accountName">{{row.item.accountName}}</span>
            <span v-else class="tag Admin">Admin</span>
          </template>
          <template slot="status" slot-scope="row">
            {{$t(getStatusText(row.item.status))}}
          </template>
          <template slot="actions" slot-scope="row">
            <div class="d-flex justify-content-center">
              <a
                v-show="row.item.status !== 'CLOSE'"
                v-b-tooltip.hover :title="$t('Agregar comentario')"
                @click.stop="openModalComment(row.item)">
                <i class="fas fa-comment cursor-pointer"></i>
              </a>
              <a
                v-show="row.item.status !== 'CLOSE'"
                v-b-tooltip.hover :title="$t('Marcar como resuelto')"
                @click.stop="markAsSolved(row.item)">
                <i class="fas fa-check-circle cursor-pointer ml-2"></i>
              </a>
              <a
                v-show="showIncidenceDetail"
                v-b-tooltip.hover :title="$t('Ver incidencia')"
                @click.stop="ViewIncidence(row.item)">
                <i class="fas fa-eye cursor-pointer ml-2"></i>
              </a>
            </div>
          </template>
        </b-table>
      </b-card>
      <b-row>
        <b-col class="col-md-auto" cols="12" sm="6" md="6">
          <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="mb-3" />
        </b-col>

        <b-col class="col-md-auto per-page" cols="12" sm="6" md="6">
          <b-form-group id="perPage" horizontal class="form-page-custom">
            <label for="perPage">{{$t('Mostrar por página')}}</label>
            <b-form-select :options="pageOptions" v-model="perPage" for="perPage" :label-cols="12"/>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import appConfig from '@/config/applicationConfig.js'
import incidentsComments from '@/components/incidents/incidentsCommentModal'
import { IncidentsServices } from '@/mixims/IncidentsServices'
import { ProductServiceMixim } from '@/mixims/ProductServiceMixim.js'

export default {
  name: 'incidents-table',
  components: { MoonLoader, incidentsComments },
  mixins: [IncidentsServices, ProductServiceMixim],
  props: ['data', 'filters', 'showIncidenceDetail', 'addReferenceLink'],
  data () {
    return {
      currentRow: null,
      showIncidentsComments: false,
      fields: [
        { key: 'creationDate', label: this.$t('Fecha'), 'class': 'text-left' },
        { key: 'reference', label: this.$t('Referencia'), 'class': 'text-left' },
        { key: 'author', label: this.$t('Autor'), 'class': 'text-left' },
        { key: 'priority', label: this.$t('Prioridad'), 'class': 'text-left' },
        { key: 'accountName', label: this.$t('Asignado A'), 'class': 'text-left' },
        { key: 'status', label: this.$t('Estado'), 'class': 'text-left' },
        { key: 'title', label: this.$t('Título'), 'class': 'text-left' },
        { key: 'actions', label: this.$t('Acciones'), 'class': 'text-left' }
      ],
      isAdmin: this.$store.getters.isAdmin,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [ 10, 15, 25, 50, 100 ],
      sortBy: null,
      sortDesc: false,
      salesRows: [],
      disableFilterButton: false,
      tableFilterInfo: null,
      loading: true,
      locale: appConfig.LOCALE,
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : '$',
      incidentToSolve: null
    }
  },
  computed: {
    sortOptions () {
      /* Create an options list from our fields */
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    }
  },
  methods: {
    userType (accountName) {
      let organizationsKeys = Object.keys(this.$store.getters.organizations)
      if (organizationsKeys.indexOf(accountName) > 0) {
        return 'Seller'
      }

      return 'Admin'
    },
    mapPriority (priority) {
      switch (priority) {
        case 'HIGH': return 'text-danger'
        case 'HALF': return 'text-warning'
        case 'LOW': return 'text-success'
      }
    },
    saveComment () {
      this.showIncidentsComments = false
      this.refresh()
    },
    openModalComment (incident) {
      this.currentRow = incident
      this.showIncidentsComments = true
    },
    editThisRow (item) {
      this.$emit('selected', item)
    },
    itemProvider (tableInfo) {
      this.showModal = false
      this.loading = true
      return this.getIncidents(tableInfo, this.filters).then(
        response => {
          this.totalRows = parseInt(response.headers.get('X-Total-Count'))
          this.$emit('incidentsNumber', this.totalRows)
          this.loading = false
          return response.body
        }
      ).catch(
        error => {
          this.errorText = error.body.message || this.$t('Error obteniendo el listado de incidencias')
          this.loading = false
          return []
        }
      )
    },
    refresh () {
      this.$refs.mainTable.refresh()
    },
    markAsSolved (incident) {
      this.incidentToSolve = incident
      this.$refs.confirmationCloseIncidentModal.show()
    },
    solveIncident (incident) {
      incident.status = 'CLOSE'
      this.updateIncident(incident.id, incident).then(
        response => {
          this.refresh()
        }
      )
    },
    ViewIncidence (incidence) {
      this.$router.push({path: '/incidents/list', query: { ref: incidence.reference }})
    },
    routeTo (incidence) {
      let route = ''
      if (incidence.incidenceType === 'ORDER') route = `/orders/orderDetail/${incidence.reference}`
      if (incidence.incidenceType === 'PRODUCT') route = `/catalog/details/${incidence.reference}`
      return route
    },
    routeToSku (incidence) {
      if (incidence.incidenceType === 'SKU') {
        let filter = {
          sku: {desde: null, hasta: null},
          upc: {desde: null, hasta: null},
          fecha: {desde: null, hasta: null},
          category: {id: null, text: null},
          fromQuantity: null,
          toQuantity: null,
          account: null,
          text: incidence.reference
        }
        let tableFilterInfo = {currentPage: 1, perPage: 2}
        this.getProducts(tableFilterInfo, filter).then(
          (response) => {
            this.$router.push({path: `/catalog/details/${response.body[0].id}`, query: { sku: incidence.reference }})
          },
          (error) => {
            console.log(error)
            return []
          }
        )
      }
    },
    getPriorityText (priority) {
      switch (priority) {
        case 'HIGH': return this.$t('Alta')
        case 'HALF': return this.$t('Media')
        case 'LOW': return this.$t('Baja')
      }
    },
    getStatusText (priority) {
      switch (priority) {
        case 'CLOSE': return this.$t('Cerrada')
        case 'INPROGRESS': return this.$t('En progreso')
        case 'OPEN': return this.$t('Abierta')
      }
    }
  }
}
</script>

<style lang="scss">
  .tag{
    font-size: 0.8rem;
    display: inline-block;
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: bold;
    margin-right: 5px;
    &.Seller{
      background-color: #f8cb00;
      color: #fff;
    }
    &.Admin{
      background-color: #0084f8;
      color: #fff;
    }
  }
  .form-page-custom{
    .form-row{
      > div[role='group']{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        label{
          margin-top: 10px;
          margin-right: 10px !important;
        }
      }
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>
