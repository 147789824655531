import appConfig from '@/config/applicationConfig.js'

export const TaxesServiceMixin = {
  data () {
    return {
      TAXES_ENDPOINT: `${appConfig.API_END_POINT}/taxes`,
      HEADER: {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }
    }
  },
  methods: {
    getTaxes (tableInfo, filters, showFilters) {
      let params = {}
      let url = new URL(this.TAXES_ENDPOINT)

      params.from = tableInfo.currentPage - 1
      params.size = tableInfo.perPage

      if (tableInfo.sortBy) {
        params.sortBy = tableInfo.sortBy
        params.sortOrder = tableInfo.sortDesc ? 'desc' : 'asc'
      }

      if (showFilters || !filters.isActive) {
        params.isActive = filters.isActive
        if (filters.text !== '') params.text = filters.text
        if (filters.zipCodeStart !== '') params.zipCodeStart = filters.zipCodeStart
        if (filters.zipCodeEnd !== '') params.zipCodeEnd = filters.zipCodeEnd
      }

      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null) {
            url.searchParams.append(key, params[key])
          }
        }
      )

      return this.$http.get(url.href, this.HEADER)
    },
    createTax (tax) {
      return this.$http.post(this.TAXES_ENDPOINT, tax, this.HEADER)
    },
    updateTax (tax) {
      return this.$http.put(`${this.TAXES_ENDPOINT}/${tax.id}`, tax, this.HEADER)
    },
    deleteTax (id) {
      return this.$http.delete(`${this.TAXES_ENDPOINT}/${id}`, this.HEADER)
    }
  }
}
