<template>
  <div class="">
    <b-container fluid class="product-list">

      <b-modal header-bg-variant="primary" size="lg" ref="confirmationStateModal" id="confirmationStateModal" centered :title="$t(confirmationModalData.confirmationStateTitle)"
        @ok="confirmationModalData.confirmationStateOkHandler"
        @cancel="confirmationModalData.confirmationStateCancelHandler"
        :cancel-title="$t('Cancelar')">
        <moon-loader class="mb-2" :loading="loading" color="#000" size="30px"></moon-loader>
        <span v-show="!loading">{{confirmationModalData.confirmationStateText}}</span>
      </b-modal>

      <div v-show="errorText !== ''" class="alert alert-danger" role="alert">
        {{errorText}}
      </div>
      <div v-show="successText !== ''" class="alert alert-success" role="alert">
        {{successText}}
      </div>
      <sku-modal @needReload="reloadInformation" ref="skuModal"></sku-modal>

      <b-row class="filter-row">
        <b-col md="4" class="mb-3">
          <b-form-group id="nombre"
            horizontal
            :label-cols="4"
            breakpoint="md"
            class="mb-0"
            :label="$t('Buscar')"
            :invalid-feedback="!isValidText(filter.text, false)? $t('No es un valor válido') : ''"
            :state="isValidText(filter.text, false)"
            :description="$t('Este campo de filtro realizará la busqueda en los nombres, id, UPC y SKU de los productos')"
            label-for="nameFormatter">
            <b-input-group>
              <b-form-input :state="isValidText(filter.text, false)" id="nameFormatter" v-model="filter.text" :placeholder="$t('Texto a buscar')" />
              <b-input-group-button>
                <b-btn :disabled="!isValidText(filter.text, false)" @click="filterTable">{{$t('Buscar')}}</b-btn>
              </b-input-group-button>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md-=8>
          <button type="button" class="btn btn-primary float-right"  @click.stop="showFilters = !showFilters">
            <i class="fa" :class="{'fa-times': showFilters, 'fa-filter': !showFilters}" /> {{$t('Filtros')}}
          </button>
          <button v-show="canCreateProduct" :disabled="isAdmin" type="button" class="btn btn-primary float-right mr-2"  @click="goToCreateProduct">
            <i class="fa fa-plus"/> {{$t('Crear Producto')}}
          </button>
        </b-col>
      </b-row>

      <transition name="fade">
        <div class="filters mt-3 p-3" v-show="showFilters">
          <b-row>
            <b-col lg="4" class="px-4 py-2">
              <b-form-group id="estadoInput"
                horizontal
                :label-cols="4"
                breakpoint="md"
                :label="$t('Estado')"
                label-for="estadoFormatter">
                <b-form-select id="categoryFormatter" v-model="filter.state">
                  <option slot="first" :value="null">{{$t('Todos')}}</option>
                  <option v-for="item in estados" 
                    :key="item.value"
                    :value="item.value">{{$t(item.text)}}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="4" class="px-4 py-2">
              <b-form-group id="categoryInput"
                horizontal
                :label-cols="4"
                breakpoint="md"
                :label="$t('Categoría')"
                label-for="categoryFormatter">
                <category-tree id='selectCategory'
                  :categorySelected="filter.category"
                  @onCategorySelected="selectCategory">
                </category-tree>
              </b-form-group>
            </b-col>
            <b-col lg="4" class="px-4 py-2" v-if="isAdmin">
              <b-form-group id="sellerInput"
                horizontal
                :label-cols="4"
                breakpoint="md"
                :label="$t('Seller_label')"
                label-for="sellerFormatter">
                <b-form-select :options="organizations" v-model="filter.seller">
                  <option slot="first" :value="null">{{$t('Todos')}}</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="4" class="px-4 py-2">
              <b-form-group id="marketplaceInput"
                horizontal
                :label-cols="4"
                breakpoint="md"
                :label="$t('Sitio')">
                <b-form-select :options="optionSites" v-model="filter.site">
                  <option slot="first" :value="null">{{$t('Todos')}}</option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" class="px-4 py-2">
              <label class="float-left"><strong>{{$t('Fecha creación')}}</strong></label>
            </b-col>
            <b-col lg="4" class="px-4 py-2">
              <b-form-group
                id="desdeInput"
                horizontal
                :label-cols="4"
                breakpoint="md"
                :label="$t('Desde')"
                label-for="desdeInputFormatter"
                :state="invalidDateFrom === '' ? true : false"
                :invalid-feedback="invalidDateFrom">
                <date-picker v-on:contextmenu.native.stop.prevent v-on:keydown.native.stop.prevent id="desdeInputFormatter" v-model="filter.fromDate" :config="config"></date-picker>
              </b-form-group>
            </b-col>
            <b-col lg="4" class="px-4 py-2">
              <b-form-group
                id="hastaInput"
                horizontal
                :label-cols="4"
                breakpoint="md"
                :label="$t('Hasta')"
                label-for="hastaInputFormatter"
                :state="invalidDateTo === '' ?  true : false"
                :invalid-feedback="invalidDateTo">
                <date-picker v-on:contextmenu.native.stop.prevent v-on:keydown.native.stop.prevent id="hastaInputFormatter" v-model="filter.toDate" :config="config"></date-picker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-input-group-button class="float-right mt-3">
                <b-button :disabled="loading || disableFilterButton" :block="true" variant="primary" @click.stop="filterTable" >{{$t('Filtrar')}}</b-button>
              </b-input-group-button>
              <b-input-group-button class="float-right mt-3 mr-3">
                <b-button :disabled="loading" :block="true" variant="primary" @click.stop="cleanFilters" >{{$t('Mostrar Todos')}}</b-button>
              </b-input-group-button>
            </b-col>
          </b-row>
        </div>
      </transition>

      <b-row class="mt-3">
        <b-col class="col-md-auto">
          <b-pagination v-if="totalRows" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" @change="onPaginationChange" class="mb-3" />
          <b-pagination v-else :total-rows="totalRows" class="mb-3" />
        </b-col>
        <b-col class="col-md-auto per-page">
          <b-form-group id="perPage" horizontal :label="$t('Mostrar por página')">
            <b-form-select :options="pageOptions" v-model="perPage" @change="onSizeChange"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <button type="button" @click="showActionModal('Activar')" v-show="isAdmin" :disabled="productsSelected.length === 0" class="btn btn-primary float-right">{{$t('Activar')}}</button>
          <button type="button" @click="showActionModal('Rechazar')" v-show="isAdmin" :disabled="productsSelected.length === 0" class="btn btn-primary float-right mr-2">{{$t('Rechazar')}}</button>
          <button type="button" @click="showActionModal('Eliminar')" v-show="!isAdmin" :disabled="productsSelected.length === 0 || !canDeleteProduct" class="btn btn-primary float-right mr-2">{{$t('Eliminar')}}</button>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-modal header-bg-variant="primary" size="lg" ref="confirmationModal" id="confirmationModal" centered :title="$t('Confirmación')"
          @ok="confirmationModalOkHandler"
          @cancel="confirmationModalCancelHandler"
          :cancel-title="$t('Cancelar')">
            <template v-if="productsSelected.length > 0">
              <p class="my-4" v-if="productsSelected.length > 1">{{$t(groupAction)}} {{$t('los')}} {{productsSelected.length}} {{$t('productos seleccionados?')}}</p>
              <p class="my-4" v-if="productsSelected.length == 1">{{$t(groupAction)}} {{$t('el producto seleccionado?')}}</p>
            </template>
          </b-modal>
        <b-col class="my-1" align-self="start">
          <b>{{totalRows}}</b> {{$t('productos encontrados')}} {{productsSelected.length != 0 ? `(${productsSelected.length} ` + $t('seleccionados)') : ""}}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p v-show="Boolean(productsSelected.length === 0)" class="alert alert-warning"><i class="fa fa-exclamation-triangle"></i>{{$t('No hay productos de la lista seleccionados para ejecutar acción')}}</p>
        </b-col>
      </b-row>
      <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Lista de productos')">
        <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
        <b-col md="3" class="my-2 pl-0">
          <b-form-select
            class="action-form-select"
            id="selectProductGroup"
            :options="productGroupSelection.options"
            required
            v-model="productGroupSelection.current"
            @click.native.stop
            @change="productGroupSelectionHandler">
          </b-form-select>
        </b-col>

        <b-table id="productTable"
          ref="productTable"
          show-empty
          :busy="isFetchingProducts"
          hover
          :items="products"
          foot-clone
          :fields="isAdmin ? fieldsProductAdmin : fieldsProduct"
          responsive=true
          stacked="xl"
          :empty-text="isFetchingProducts ?  $t('Cargando productos...') : $t('No hay productos para mostrar')"
          :empty-filtered-text="isFetchingProducts ?  $t('Cargando productos...') : $t('No hay productos para mostrar')"
          @row-dblclicked="productDbClicked"
          >
          <!-- hide if user is admin and product has not skus -->
          <!-- <template slot="selected" slot-scope="row">
            <b-form-checkbox class="ml-4" v-if="!isAdmin || (isAdmin && row.item.skus && row.item.skus.length > 0)" @click.native.stop @change="handleRowCheckbox" :value="row.item.id" v-model="productsSelected"></b-form-checkbox>
          </template> -->
          <template slot="selected" slot-scope="row">
            <b-form-checkbox class="ml-4" v-if="showCheckbox(row.item.skus)" @click.native.stop @change="handleRowCheckbox" :value="row.item.id" v-model="productsSelected"></b-form-checkbox>
          </template>
          <template slot="category" slot-scope="row">
            {{row.item.category.name}}
          </template>
          <template slot="brand" slot-scope="row">
            {{row.item.brand.name}}
          </template>
          <template slot="marketplace" slot-scope="row">
            <b-badge class="mr-1" pill v-for="site in row.item.marketplaces" :key="site.id"> {{site.name}} </b-badge>
          </template>
          <template slot="action" slot-scope="row">
            <b-button v-if="row.item.skus.length > 0" size="sm" variant="link" @click.stop="row.toggleDetails">
              <span v-show="!row.detailsShowing"><i class="fa fa-plus"></i></span>
              <span v-show="row.detailsShowing"><i class="fa fa-minus"></i></span>
              {{ row.detailsShowing ? $t('Ocultar') : $t('Ver')}} {{$t('Variantes')}} ({{row.item.skus.length}})
            </b-button>
            <span v-else><b>{{$t('No contiene variantes')}}</b></span>
          </template>
          <template slot="row-details" slot-scope="row">
            <b-card
              :header="$t('Listado de SKUs')"
              border-variant="primary"
              header-bg-variant="primary"
              header-text-variant="white"
            >
              <b-table v-show="!loading" id="productTable"
                ref="variantTable"
                show-empty
                hover
                :items="row.item.skus"
                :fields="fieldsVariant"
                responsive=true
                @row-dblclicked="variantDbClicked"
                stacked="xl"
                :empty-text="$t('No hay variantes para mostrar')"
                :empty-filtered-text="$t('No hay variantes para mostrar')">
                <template slot="listPrice" slot-scope="row">
                  {{currency}} {{row.item.price.listPrice}}
                </template>
                <template slot="inventory" slot-scope="row">
                  <div class="inventoryContainer" v-b-tooltip.hover :title="$t('La cantidad entre parentesis es stock reservado')">
                    <span class="inventoryTotal">{{row.item.inventory.totalQuantity}}</span>
                    <span class="inventoryReserved">({{row.item.inventory.reservedQuantity ? row.item.inventory.reservedQuantity : 0}})</span>
                  </div>
                </template>

                <template slot="price" slot-scope="row">
                  {{currency}} {{row.item.price.listPrice}}<br>
                  <span v-b-tooltip.hover :title="$t('Precio con descuento')" class="discount">({{currency}} {{row.item.price.price}})</span><br>
                  {{row.item.price.priceValidUntil}}
                </template>

                <template slot="status"  slot-scope="row">
                  <b-button size="sm" variant="link" @click.stop="row.toggleDetails">
                    <span v-show="!row.detailsShowing"><i class="fa fa-plus"></i></span>
                    <span v-show="row.detailsShowing"><i class="fa fa-minus"></i></span>
                    {{ row.detailsShowing ? $t('Ocultar') : $t('Ver')}} {{$t('Estados')}}
                  </b-button>
                  <!-- <b v-if="data.value === 'APPROVED'" class="text-success">
                    {{$t(estados.find(p => p.value === data.value).text)}}
                  </b>
                  <b v-if="data.value === 'REJECTED'" class="text-danger">
                    {{$t(estados.find(p => p.value === data.value).text)}}
                  </b>
                  <b v-if="data.value === 'PENDING_APPROVAL'" class="text-warning">
                    {{$t(estados.find(p => p.value === data.value).text)}}
                  </b> -->
                </template>

                <template slot="row-details" slot-scope="row">
                  <b-card
                    :header="$t('Estados por sitios')"
                    border-variant="primary"
                    header-bg-variant="primary"
                    header-text-variant="white"
                  >
                    <b-table v-show="!loading" id="productTable"
                      ref="variantTable"
                      show-empty
                      hover
                      :items="row.item.marketplaces"
                      :fields="fieldsStatusByMarketplaces"
                      responsive=true
                      stacked="xl"
                      :empty-text="$t('No hay variantes para mostrar')"
                      :empty-filtered-text="$t('No hay variantes para mostrar')">
                      <template slot="status" slot-scope="item">
                        <b v-if="item.value === 'APPROVED'" class="text-success">
                          {{$t(estados.find(p => p.value === item.value).text)}}
                        </b>
                        <b v-if="item.value === 'REJECTED'" class="text-danger">
                          {{$t(estados.find(p => p.value === item.value).text)}}
                        </b>
                        <b v-if="item.value === 'PENDING_APPROVAL'" class="text-warning">
                          {{$t(estados.find(p => p.value === item.value).text)}}
                        </b>
                      </template>
                      <template slot="action" slot-scope="item">
                        <!-- <p>{{ row.item.status }}</p> -->
                        <!-- :label-cols="2" -->
                        <button type="button" @click="changeStatusModal(row.item, item.item, 'REJECTED')" v-show="isAdmin" 
                          :disabled="item.item.status === 'APPROVED' || item.item.status === 'REJECTED'" class="btn btn-primary float-right">
                          {{$t('Rechazar')}}
                        </button>
                        <button type="button" @click="changeStatusModal(row.item, item.item, 'APPROVED')" v-show="isAdmin" 
                          :disabled="item.item.status === 'APPROVED' || item.item.status === 'REJECTED'" class="btn btn-primary float-right mr-2">
                          {{$t('Activar')}}
                        </button>
                      </template>
                    </b-table>
                  </b-card>
                </template>
              </b-table>
            </b-card>
          </template>
        </b-table>
      </b-card>
      <b-row>
        <b-col md="6" class="my-1">
          <b-pagination v-if="totalRows" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" @change="onPaginationChange" class="mb-3" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue'
import datePicker from 'vue-bootstrap-datetimepicker'
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'
import { ProductServiceMixim, PRODUCT_STATES } from '@/mixims/ProductServiceMixim.js'
import { ValidationsMixim } from '@/mixims/ValidationsMixim.js'
import CategoryTree from './CategoryTree'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import appConfig from '@/config/applicationConfig.js'
import SkuModal from './SkuModal'

Vue.use(datePicker)

export default {
  name: 'activable-product-list',
  components: { MoonLoader, CategoryTree, SkuModal },
  mixins: [ProductServiceMixim, ValidationsMixim],
  data () {
    return {
      isFetchingProducts: false,
      confirmationModalData: {
        confirmationStateOkHandler: '',
        confirmationStateCancelHandler: '',
        confirmationStateText: '',
        confirmationStateTitle: ''
      },
      locale: appConfig.LOCALE,
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : '$',
      fieldsProduct: [
        { key: 'selected', label: this.$t('Selección') },
        { key: 'id', label: this.$t('Id'), 'class': 'text-left', sortable: true },
        { key: 'name', label: this.$t('Nombre'), 'class': 'text-left', sortable: true },
        { key: 'category', label: this.$t('Categoría'), 'class': 'text-left', sortable: true },
        { key: 'brand', label: this.$t('Marca'), 'class': 'text-left', sortable: true },
        { key: 'marketplace', label: this.$t('Sitios'), 'class': 'text-left', sortable: false },
        { key: 'action', label: ' ', 'class': 'text-right', sortable: false }
      ],
      fieldsProductAdmin: [
        { key: 'selected', label: this.$t('Selección') },
        { key: 'id', label: 'Id', 'class': 'text-left', sortable: true },
        { key: 'accountName', label: this.$t('Seller'), 'class': 'text-left' },
        { key: 'name', label: this.$t('Nombre'), 'class': 'text-left', sortable: true },
        { key: 'category', label: this.$t('Categoría'), 'class': 'text-left', sortable: true },
        { key: 'brand', label: this.$t('Marca'), 'class': 'text-left', sortable: true },
        { key: 'marketplace', label: this.$t('Sitios'), 'class': 'text-left', sortable: false },
        { key: 'action', label: ' ', 'class': 'text-right', sortable: false }
      ],
      fieldsVariant: [
        { key: 'skuName', label: this.$t('Nombre'), 'class': 'text-left' },
        { key: 'upc', label: this.$t('UPC'), 'class': 'text-left' },
        { key: 'id', label: this.$t('SKU'), 'class': 'text-left' },
        { key: 'refId', label: this.$t('Identificador'), 'class': 'text-left' },
        { key: 'price', label: this.$t('Precio'), 'class': 'priceCol' },
        { key: 'inventory', label: this.$t('Inventario'), 'class': 'text-left' },
        { key: 'status', label: this.$t('Estado'), 'class': 'text-left' }
      ],
      fieldsStatusByMarketplaces: [
        { key: 'skuIdRef', label: this.$t('SKU'), 'class': 'text-left' },
        { key: 'name', label: this.$t('Sitio'), 'class': 'text-left' },
        { key: 'status', label: this.$t('Estado'), 'class': 'text-left' },
        { key: 'action', label: ' ', 'class': 'text-right', sortable: false }
      ],
      estados: PRODUCT_STATES,
      isAdmin: this.$store.getters.isAdmin,
      errorText: '',
      successText: '',
      pageOptions: [ 10, 15, 25, 50, 100 ],
      account: null,
      sortBy: 'id',
      sortDesc: true,
      filter: {
        text: this.$route.query.text,
        state: this.$route.query.state,
        category: this.getCategory(),
        seller: this.$route.query.seller,
        site: this.$route.query.site,
        fromDate: this.$route.query.fromDate,
        toDate: this.$route.query.toDate
      },
      perPage: parseInt(this.$route.query.size),
      currentPage: parseInt(this.$route.query.page) + 1,
      totalRows: null,
      showFilters: false,
      allSelected: false,
      productsSelected: [],
      currentPageElement: [],
      groupAction: null,
      productGroupSelection: {
        options: [
          { text: this.$t('Seleccione una opción'), value: null, disabled: true },
          { text: this.$t('Ninguno'), value: 'Ninguno' },
          { text: this.$t('Todos en esta pagina'), value: 'Todos en esta pagina' },
          { text: this.$t('Todos'), value: 'Todos' }
        ],
        current: null
      },
      loading: false,
      config: {
        format: 'YYYY-MM-DD',
        useCurrent: false,
        icons: {
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right'
        }
      },
      productList: [],
      products: [],
      canCreateProduct: this.$store.getters.signedRoles.includes('PRODUCT_WRITE'),
      optionSites: this.$store.getters.marketplacesCombo
    }
  },
  computed: {
    canDeleteProduct () {
      let productsSelectedAux = this.productList.filter((product) => {
        return this.productsSelected.some((id) => { return id == product.id })
      })
      return !productsSelectedAux.some((product) => { return product.skus.some((sku) => { return sku.status == "APPROVED" }) })
    },
    disableFilterButton () {
      if (!this.isValidText(this.filter.text, false)) {
        return true
      }

      if (this.invalidDateTo !== '' || this.invalidDateFrom !== '') {
        return true
      }

      return false
    },
    sortOptions () {
      /* Create an options list from our fields */
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    },
    organizations () {
      return Object.values(this.$store.getters.organizations).map(value => ({value:value, text: value}))
    },
    invalidDateTo () {
      if (this.filter.toDate) {
        let hDate = new Date(this.filter.toDate)
        if (hDate > new Date()) {
          return this.$t('Fecha seleccionada es mayor al día de hoy')
        }
        if (this.filter.fromDate) {
          let dDate = new Date(this.filter.fromDate)
          return dDate > hDate ? this.$t('La fecha final es menor a la fecha inicial') : ''
        }
      }
      return ''
    },
    invalidDateFrom () {
      if (this.filter.fromDate) {
        let dDate = new Date(this.filter.fromDate)
        if (dDate > new Date()) {
          return this.$t('Fecha seleccionada es mayor al día de hoy')
        }
      }
      return ''
    }
  },
  methods: {
    getCategory () {
      console.log(this.$store)
      return this.$store.getters._categoriesPlain.find(category => category.id === this.$route.query.category)
    },
    onSizeChange (size) {
      this.$router.push({
        query: {
          ...this.$route.query,
          size: size,
          page: 0
        }
      })
    },
    onPaginationChange (page) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: page - 1
        }
      })
    },
    changeStatusModal (sku, skuByMarktplace, newStatus) {
      let self = this
      this.confirmationModalData.confirmationStateTitle = `${this.$t('Cambio de estado')}`
      this.confirmationModalData.confirmationStateText = `${this.$t('¿Cambiar el estado a')} ${newStatus === 'REJECTED' ? this.$t('rechazado') : this.$t('activado')}?`
      this.confirmationModalData.confirmationStateOkHandler = () => {
        self.execChangeStatus(sku, skuByMarktplace, newStatus)
      }
      this.confirmationModalData.confirmationStateCancelHandler = () => {
        // self.execChangeStatus(sku, skuByMarktplace, newStatus)
      }
      // this.currentStatus = status
      this.$refs.confirmationStateModal.show()
    },
    execChangeStatus (sku, skuByMarktplace, newStatus) {
      if (newStatus === 'APPROVED') {
        this.loading = true
        this.activateSku(sku.id, skuByMarktplace)
        .then(
            (response) => {
              let res = response.body
              if (res.status && res.status.code) {
                if (res.status.code !== '200') {
                  this.errorText = res.errors[0].description || this.$t('Error') + ` ${this.$t('aprobando')} ` + this.$t('los productos')
                } else {
                  this.$refs.productTable.refresh()
                  this.successText = this.$t('Se completó la tarea con éxito')
                }
              }
              this.loading = false
            },
            (error) => {
              this.errorText = error.body.message || this.$t('Error') + ` ${this.$t('aprobando')} ` + this.$t('los productos')
              this.loading = false
            }
        )
      }
      if (newStatus === 'REJECTED') {
        this.rejectSku(sku.id, skuByMarktplace)
        .then(
            (response) => {
              let res = response.body
              if (res.status && res.status.code) {
                if (res.status.code !== '200') {
                  this.errorText = res.errors[0].description || this.$t('Error') + ` ${this.$t('rechazando')} ` + this.$t('sku')
                } else {
                  this.$refs.productTable.refresh()
                  this.successText = this.$t('Se completó la tarea con éxito')
                }
              }
              this.loading = false
            },
            (error) => {
              this.errorText = error.body.message || this.$t('Error') + ` ${this.$t('rechazando')} ` + this.$t('sku')
              this.loading = false
            }

        )
      }
    },
    haveAllPending (item) {
      if (item.skus.length === 0) return false
      return !item.skus.some(
        (sku) => {
          return sku.status === 'APPROVED' || sku.status === 'REJECTED'
        })
    },
    reloadInformation (response) {
      this.$refs.productTable.refresh()
      if (response) {
        if (response.status) {
          this.successText = response.text
        } else {
          this.errorText = response.text
        }
      }
    },
    variantDbClicked (item) {
      this.errorText = ''
      this.successText = ''
      let product = this.currentPageElement.find((productItem) => {
        return productItem.skus.some((skuItem) => {
          return skuItem.id === item.id
        })
      })
      this.$router.push(`/catalog/details/${product.id}/sku/${item.id}`)
    },
    productDbClicked (item) {
      this.$router.push(`/catalog/details/${item.id}`)
    },
    goToCreateProduct () {
      this.$router.push('/catalog/new')
    },
    cleanFilters () {
      this.$router.push({
        query: {
          page: 0,
          size: 10
        }
      })
    },
    selectCategory (res) {
      this.filter.category = res
    },
    confirmationModalCancelHandler () {
      this.currentActionGroupSelection = null
    },
    showActionModal (action) {
      this.groupAction = action
      this.$refs.confirmationModal.show()
    },
    confirmationModalOkHandler () {
      this.successText = ''
      this.errorText = ''
      this.loading = true
      switch (this.groupAction) {
        case 'Eliminar' :
          this.deleteSelectedItems()
          break
        case 'Activar':
          this.activateSelectedItems()
          break
        case 'Rechazar':
          this.rejectSelectedItems()
          break
        default:
          break
      }
      this.currentActionGroupSelection = null
    },
    productGroupSelectionHandler (selectionType) {
      this.productsSelected.splice(0, this.productsSelected.length)
      let _this = this
      switch (selectionType) {
        case 'Ninguno':
          break
        case 'Todos en esta pagina':
          this.currentPageElement.forEach(item => {
            this.productsSelected.push(item.id)
          })
          break
        case 'Todos':
          this.loading = true
          this.getProducts(null, this.filter).then((response) => {
            response.body.forEach(item => {
              _this.productsSelected.push(item.id)
            })
            this.loading = false
          })
          break
        default:
          break
      }
    },
    _execRejectApprove (action) {
      let actionFunction = null
      let errorVerv = ''
      switch (action) {
        case 'aprobar':
          actionFunction = this.activateProducts
          errorVerv = this.$t('aprobando')
          break
        case 'rechazar':
          actionFunction = this.rejectProducts
          errorVerv = this.$t('rechazando')
          break
        case 'eliminar':
          actionFunction = this.deleteProducts
          errorVerv = this.$t('eliminando')
          break
      }
      actionFunction(this.productsSelected, false).then(
        (response) => {
          this.loading = false
          this.productsSelected.splice(0, this.productsSelected.length)
          this.$refs.productTable.refresh()
          this.successText = this.$t('Se completó la tarea con éxito')
        },
        (error) => {
          this.errorText = error.body.message || this.$t('Error') + ` ${errorVerv} ` + this.$t('los productos')
          this.loading = false
        }
      )
    },
    rejectSelectedItems () {
      this._execRejectApprove('rechazar')
    },
    activateSelectedItems () {
      this._execRejectApprove('aprobar')
    },
    deleteSelectedItems () {
      this._execRejectApprove('eliminar')
    },
    handleRowCheckbox () {
      this.productGroupSelection.current = null
    },
    onFiltered (filteredItems) {
      this.productGroupSelection.current = null
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    sortClick () {
      this.productsSelected.splice(0, this.productsSelected.length)
      this.productGroupSelection.current = null
    },
    filterTable () {
      const query = {}

      query.text = this.filter.text
      query.state = this.filter.state
      if (this.filter.category) query.category = this.filter.category.id
      query.seller = this.filter.seller
      query.site = this.filter.site
      query.fromDate = this.filter.fromDate
      query.toDate = this.filter.toDate
      
      this.$router.push({
        query: {
          ...this.$route.query,
          ...query,
          page: 0
        }
      })
    },
    showCheckbox (skus) {
      if (!this.isAdmin || (this.isAdmin && skus && skus.length > 0)) {
        return this.isSkuPending(skus)
      }
      return false
    },
    isSkuPending (skus) {
      return skus
        ?.filter(sku => sku
          ?.marketplaces
          ?.some(place => place?.status === 'PENDING_APPROVAL')
        )
        ?.length > 0
    }
  },
  mounted () {
    this.isFetchingProducts = true

    const params = {}
    params.from = this.$route.query.page
    params.size = this.$route.query.size

    params.fromDate = this.$route.query.fromDate
    params.toDate = this.$route.query.toDate
    params.status = this.$route.query.status
    params.categoryId = this.$route.query.category
    params.marketplaces = this.$route.query.site
    params.accountName = this.$route.query.seller
    params.text = this.$route.query.text

    this._getProducts(params).then(({body: products, headers}) => {
      this.products = products
      this.isFetchingProducts = false

      this.totalRows = parseInt(headers.get('X-Total-Count'))
    }).catch(() => {
      this.errorText = this.$t('Ocurrió un error al cargar los productos')
    })
  }
}
</script>

<style lang="scss" scoped>

  .product-list {
    .btn-link {
      color: #666666;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    .filters {
      border: 1px solid #e3e8ec;
      background-color: #FFF
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }

    tr:hover {
      cursor: pointer
    }

    .fa-exclamation-triangle {
      color: #f8cb00;
      font-size: 20px;
      float: left;
      margin-right: 5px;
      display: inline-block;
    }

    .inventoryContainer {
      display: inline-block;
    
      .inventoryTotal {
        font-weight: bolder;
        font-size: 16px;
      }

      .inventoryReserved {
        color: #777;
      }
    }

    .priceCol {
      .discount {
       font-size: 12px;
       font-weight: bolder;
      }
    }
  }
</style>
