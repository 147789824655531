<template>
  <div>
    <order-details-board :orderId="orderId"/>
  </div>
</template>

<script>
import OrderDetailsBoard from '@/components/common/OrderDetailsBoard'

export default {
  name: 'order-details-view',
  props: ['orderId'],
  components: {
    OrderDetailsBoard
  }
}
</script>
