<template>
  <div>
    <carrier-list/>
  </div>
</template>

<script>
import CarrierList from '@/components/carrier/CarrierList'

export default {
  name: 'carriers',
  components: {
    CarrierList
  }
}
</script>
