<template>
  <b-container fluid class="promotions">
    <h5>{{$t('Gestión de promociones')}}</h5>
    <b-alert :show="errorText !== ''" fade variant="danger" v-html="errorText" dismissible>{{errorText}}</b-alert>  
    <b-alert :show="successText !== ''" variant="success" v-html="successText" dismissible>{{successText}}</b-alert>

    <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
  
    <!-- PROMO LIST TABLE -->

    <!-- FILTERS -->

    <b-row class="filter-row">
      <b-col md-=12>
        <button type="button" class="btn btn-primary float-right ml-2"  @click.stop="showFilters = !showFilters">
          <i class="fa" :class="{'fa-times': showFilters, 'fa-filter': !showFilters}" /> {{$t('Filtros')}}
        </button>
        <b-col>
          <button :disabled="loading" type="button" class="btn btn-primary float-right mr-3"  @click="showModal">
            {{$t('Crear Promoción')}}
          </button>
        </b-col>
      </b-col>
    </b-row>


    <transition name="fade">
      <div class="filters mt-3 p-3 mb-3" v-show="showFilters">
        <b-row>
          <b-col class="col-md-auto">
            <b-form-group
              id="dateFilterFromGroup"
              breakpoint="md"
              :label="$t('Filtro fecha')"
              label-for="filterBeginDate">
              <date-picker 
                v-on:contextmenu.native.stop.prevent 
                v-on:keydown.native.stop.prevent 
                id="filterBeginDate"
                ref="filterBeginDate"
                v-model="filter.range" 
                format="YYYY-MM-DD"
                valueType="YYYY-MM-DD"
                :confirm="true"
                :lang="lang"
                @input="filterTable"
                :confirm-text="$t('Seleccione rango')"  
                :clearable="true"
                range>
              </date-picker>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
             <b-form-group id="nombre"
              :label-cols="4"
              breakpoint="md"
              class="mb-0"
              :label="$t('Buscar')"
              :invalid-feedback="!isValidText(filter.text, false)? $t('No es un texto válido') : ''"
              :state="isValidText(filter.text, false)"
              :description="$t('PROMO_SEARCH_DESCRIPTION')"
              label-for="nameFormatter">
              <b-input-group>
                <b-form-input id="nameFormatter" v-model="filter.text" :placeholder="$t('Texto a buscar')" />
                <b-input-group-button>
                  <b-btn :disabled="!isValidText(filter.text, false)" @click="filterTable">{{$t('Buscar')}}</b-btn>
                </b-input-group-button>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="md-auto">
            <b-form-group
                :label-cols="4"
                :description="$t('Filtrar por el estado de las promociones')"
                :label="$t('Seleccione el estado de la promoción')"
              >
              <b-form-radio-group
                v-model="filter.isActive"
                :options="[
                  { text: $t('Activas'), value: true },
                  { text: $t('Inactivas'), value: false },
                  { text: $t('Todas'), value: null }
                ]">
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group 
              id="inputBrandGroup"
              :label-cols="2"
              breakpoint="md"
              :label="$t('Marcas')"
              label-for="inputBrand">
              <autocomplete
                id="inputBrand"
                name="inputBrand"
                :maxElements="100"
                :provider="getSearchBrands"
                :disabled="loading"
                v-model="filter.brands">
              </autocomplete>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              id="inputProductosGroup"
              :label-cols="2"
              breakpoint="md"
              :label="$t('Productos')"
              label-for="inputProducts">
              <autocomplete
                id="inputProducts"
                name="inputProducts"
                :maxElements="100"
                :provider="getSearchProducts"
                :disabled="loading"
                :searchText="$t('Enter para agregar Producto')"
                v-model="filter.products">
              </autocomplete>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              id="inputCategoryGroup"
              :label-cols="2"
              breakpoint="md"
              :label="$t('Categorías')"
              label-for="inputCategory">
              <autocomplete
                id="inputCategory"
                name="inputCategory"
                :maxElements="100"
                :provider="getSearchCategories"
                :disabled="loading"
                :searchText="$t('Enter para agregar Categoría')"
                v-model="filter.categories">
              </autocomplete>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="isAdmin">
            <b-form-group 
               id="inputSellersGroup"
              :label-cols="2"
              breakpoint="md"
              :label="$t('Sellers')"
              label-for="inputSellers">
              <autocomplete
                id="inputSellers"
                name="inputSellers"
                :maxElements="100"
                :provider="getSearchSeller"
                :disabled="loading"
                :searchText="$t('Enter para agregar Seller')"
                v-model="filter.sellers">
              </autocomplete>
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group 
               id="inputMarketGroup"
              :label-cols="2"
              breakpoint="md"
              label="marketplaces"
              label-for="inputMarkets">
              <autocomplete
                id="inputMarkets"
                name="inputMarkets"
                :maxElements="100"
                :searcheable="false"
                :provider="getSearchMarketplaces"
                :disabled="loading"
                v-model="filter.marketplaces">
              </autocomplete>
            </b-form-group>
          </b-col> -->
        </b-row>

        <b-row>
          <b-col>
            <b-input-group-button class="float-right mt-3">
              <b-button :disabled="loading" :block="true" variant="primary" @click.stop="filterTable" >{{$t('Filtrar')}}</b-button>
            </b-input-group-button>
            <b-input-group-button class="float-right mt-3 mr-3">
              <b-button :disabled="loading" :block="true" variant="primary" @click.stop="cleanFilters" >{{$t('Mostrar Todo')}}</b-button>
            </b-input-group-button>
          </b-col>
        </b-row>
      </div>>
    </transition>

    <!-- CONTROLS -->
    <hr/>
    <b-row class="mt-3">
      <b-col class="col-md-auto">
        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="mb-3" />
      </b-col>
      <b-col class="col-md-auto per-page">
        <b-form-group id="perPage" horizontal :label="$t('Mostrar por página')">
          <b-form-select id="perPage" :options="pageOptions" v-model="perPage" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-md-auto" align-self="start">
        {{totalRows > 0 ? $t('Promociones encontradas:') + totalRows : $t('No se encontraron promociones')}}
      </b-col>
    </b-row>
    
    <!-- TABLE -->
    <b-table class="mt-3" v-show="!loading" id="mainTable"
      ref="mainTable"
      show-empty
      striped
      hover
      stacked="md"
      :items="itemProvider"
      foot-clone
      :fields="fields"
       small
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @row-dblclicked="promoClicked"
      @filtered="onFiltered"
      responsive=true
      :empty-text="$t('No hay promociones para mostrar')"
      :empty-filtered-text="$t('No hay promociones para mostrar')">
      <template slot="beginDate" slot-scope="beginDate">
        {{new Date(beginDate.value).toLocaleDateString(locale)}}<br>
        {{new Date(beginDate.value).toLocaleTimeString(locale)}}
      </template>
      <template slot="endDate" slot-scope="endDate">
        {{new Date(endDate.value).toLocaleDateString(locale)}}<br>
        {{new Date(endDate.value).toLocaleTimeString(locale)}}
      </template>
      <template slot="lastModified" slot-scope="lastModified">
        {{new Date(lastModified.value).toLocaleDateString(locale)}}<br>
        {{new Date(lastModified.value).toLocaleTimeString(locale)}}
      </template>
      <template slot="nominalDiscountValue" slot-scope="nominalDiscountValue">
        {{currency}} {{nominalDiscountValue.value}}
      </template>
      <template slot="percentualDiscountValue" slot-scope="nominalDiscountValue">
        {{nominalDiscountValue.value}}%
      </template>
      <template slot="isActive" slot-scope="isActive">
        {{isActive.value ? $t('Activa') : $t('No Activa')}}
      </template>
      <template slot="name" slot-scope="name">
        <i class="fas fa-tag mr-2"></i>{{name.value}}
      </template>
      <template slot="userType" slot-scope="userType">
        <span 
          :class="userType.value !== 'ADMIN' ? 'bg-primary' : 'bg-warning'" 
          v-b-tooltip.hover 
          :title="userType.value === 'ADMIN' ? $t('Promoción creada por un Administrador') : $t('Promoción creada por un Venderor')"
          class="userType">{{userType.value === 'ADMIN' ? $t('Administrador') : $t('Seller')}}</span>
      </template>
      <template slot="actions" slot-scope="row">
        <b-button :disabled="row.item.userType === 'ADMIN' && !isAdmin" variant="primary" size="sm" @click="showDeleteConfirm(row.item)">{{$t('Eliminar')}}</b-button>
      </template>
    </b-table>
    <!-- END PROMO LIST TABLE -->
  
    <!-- DELETE CONFIRM -->
    <b-modal header-bg-variant="primary" size="lg" ref="confirmationdDeleteModal" id="confirmationDeleteModal" centered :title="cancelation.title"
      @ok="cancelation.confirm"
      :cancel-title="$t('Cancelar')">
        <span v-html="cancelation.text">{{cancelation.text}}</span>
     </b-modal>

     <promo-details @done="refreshTable" :promo="promo" ref="promoDetails"></promo-details>

  </b-container>
</template>

<script>
import PromoDetails from '@/components/promotion/PromoDetails.vue'
import { ProductServiceMixim } from '@/mixims/ProductServiceMixim'
import { OrganizationService } from '@/mixims/AdminServiceMixin'
import { PromotionsServiceMixim } from '@/mixims/PromotionsServiceMixim'
import { ValidationsMixim } from '@/mixims/ValidationsMixim.js'
// import { MarketplaceService } from '@/mixims/MarketplaceServiceMixim'
import appConfig from '../../config/applicationConfig.js'
import autocomplete from '@/components/common/vue-autocomplete'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import 'vue2-datepicker/index.css'
import DatePicker from 'vue2-datepicker'

export default {
  name: 'PromoList',
  mixins: [
    ProductServiceMixim, ValidationsMixim, OrganizationService, PromotionsServiceMixim
  // MarketplaceService
  ],
  components: {
    autocomplete,
    MoonLoader,
    DatePicker,
    PromoDetails
  },
  props: [],
  data () {
    return {
      lang: {
        formatLocale: this.$i18n.locale === 'en-gr' ? {} : {
          // MMMM
          months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          // MMM
          monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          // dddd
          weekdays: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
          // ddd
          weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vier', 'Sab'],
          // dd
          weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']
        }
      },
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : '$',
      locale: appConfig.LOCALE,
      isAdmin: this.$store.getters.isAdmin,
      loading: false,
      modalTitle: '',
      errorText: '',
      showFilters: false,
      successText: '',
      cancelation: {
        text: '',
        title: '',
        confirm: ''
      },
      promo: {
        id: null,
        name: null,
        description: null,
        isActive: false,
        beginDate: null,
        endDate: null,
        promoType: 'Nominal',
        promoValue: null,
        nominalDiscountValue: 0,
        percentualDiscountValue: 0,
        sellers: [],
        marketplaces: [],
        brands: [],
        products: [],
        categories: [],
        userType: null
      },
      filter: {
        text: null,
        isActive: null,
        range: [],
        brands: [],
        products: [],
        categories: [],
        sellers: [],
        marketplaces: []
      },
      fields: [
        { key: 'name', label: this.$t('Nombre'), 'class': 'text-left', sortable: true },
        { key: 'nominalDiscountValue', label: this.$t('Nominal'), 'class': 'text-left', sortable: true },
        { key: 'description', label: this.$t('Descripción'), 'class': 'text-left', sortable: false },
        { key: 'percentualDiscountValue', label: this.$t('Descuento %'), 'class': 'text-left', sortable: true },
        { key: 'name', label: this.$t('Nombre'), 'class': 'text-left', sortable: true },
        { key: 'beginDate', label: this.$t('Inicio'), 'class': 'text-left', sortable: true },
        { key: 'endDate', label: this.$t('Fin'), 'class': 'text-left', sortable: true },
        { key: 'userType', label: this.$t('Usuario'), 'class': 'text-left', sortable: true },
        { key: 'isActive', label: this.$t('Activa'), 'class': 'text-left', sortable: true },
        { key: 'actions', label: ' ', 'class': 'text-right' }
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [ 10, 15, 25, 50, 100 ],
      sortBy: 'id',
      sortDesc: true
    }
  },
  methods: {
    refreshTable (data) {
      this.$refs.mainTable.refresh()
      if (data.key === 'successText') {
        this.successText = data.value
      } else {
        this.errorText = data.value
      }
    },
    showDeleteConfirm (promo) {
      this.cancelation.text = `${this.$t('¿Está seguro de eliminar la promoción')} <b>${promo.name}</b>?`
      this.cancelation.title = `${this.$t('Eliminar')} ${promo.name}`
      this.errorText = ''
      this.successText = ''
      this.cancelation.confirm = () => {
        this.loading = true
        this.deletePromotion(promo.id).then(
          () => {
            this.successText = `${this.$t('La promoción')}  <b>${promo.name}</b> ${this.$t('fue eliminada con éxito')}`
            this.loading = false
            this.$refs.mainTable.refresh()
          },
          () => {
            this.errorText = `${this.$t('No se pudo eliminar')} <b>${promo.name}</b>?`
            this.loading = false
          }
        )
      }
      this.$refs.confirmationdDeleteModal.show()
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.disableEditionlength
      this.currentPage = 1
    },
    itemProvider (tableFilterInfo) {
      this.successText = ''
      this.errorText = ''
      this.tableFilterInfo = tableFilterInfo
      this.loading = true
      return this.getPromotionsCatalog(tableFilterInfo, this.filter).then(
        (response) => {
          this.loading = false
          this.totalRows = parseInt(response.headers.get('X-Total-Count'))
          // //TODO: Return  only the response.body if the backend takes the responsibility for 
          // changing the active state taking into consideration the endDate
          return response.body.map((promotion) => ({
            ...promotion,
            isActive: promotion.isActive && new Date() < new Date(promotion.endDate)
          }))
        },
        (error) => {
          this.loading = false
          this.errorText = error.body.message || this.$t('Error obteniendo las promociones')
          return []
        }
      )
    },
    filterTable () {
      this.$refs.mainTable.refresh()
    },
    cleanFilters () {
      this.filter.text = ''
      this.filter.isActive = null
      this.filter.range.splice(0, this.filter.range.length)
      this.filter.brands.splice(0, this.filter.brands.length)
      this.filter.products.splice(0, this.filter.products.length)
      this.filter.categories.splice(0, this.filter.categories.length)
      this.filter.sellers.splice(0, this.filter.sellers.length)
      this.filter.marketplaces.splice(0, this.filter.marketplaces.length)
      this.filterTable()
    },
    promoClicked (item) {
      this.errorText = ''
      this.successText = ''
      this.showModal(JSON.parse(JSON.stringify(item)))
    },
    showModal (item) {
      let _this = this
      if (item && item.id) {
        Object.entries(item).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            _this.promo[key] = item[key].slice(0, item[key].length)
          } else {
            if (['brands', 'categories', 'products', 'sellers', 'marketplaces'].includes(key)) {
              _this.promo[key].splice(0, _this.promo[key])
            } else {
              _this.promo[key] = value
            }
          }
        })
        if (item.nominalDiscountValue > 0) {
          this.promo.promoValue = item.nominalDiscountValue
          this.promo.promoType = 'Nominal'
        } else {
          this.promo.promoValue = item.percentualDiscountValue
          this.promo.promoType = 'Porcentual'
        }
      } else {
        this.promo.id = null
        this.promo.name = null
        this.promo.isActive = false
        this.promo.beginDate = null
        this.promo.endDate = null
        this.promo.description = null
        this.promo.promoType = 'Nominal'
        this.promo.nominalDiscountValue = 0
        this.promo.percentualDiscountValue = 0
        this.promo.promoValue = null
        this.promo.userType = null
        this.promo.brands.splice(0, this.promo.brands.length)
        this.promo.products.splice(0, this.promo.products.length)
        this.promo.categories.splice(0, this.promo.categories.length)
        this.promo.sellers.splice(0, this.promo.sellers.length)
        this.promo.marketplaces.splice(0, this.promo.marketplaces.length)
      }
      this.$refs.promoDetails.showModal()
    }
  },
  created () {
    if (this.$route.query.promo) {
      this.showFilters = true
      this.filter.text = this.$route.query.promo
    }
  }
}
</script>

<style lang="scss" scoped>
  .promotions {
    .filters {
      border: 1px solid #e3e8ec;
      background-color: #FFF
    }
    .userType {
      border-radius: 5px;
      padding: 3px;
    }
  }
</style>
<style lang="scss">
  .multiselect {
    &.multiselect--active {
      z-index: 3 !important;
    }
  }
</style>