import appConfig from '@/config/applicationConfig.js'
import Vue from 'vue'

export const PromotionsServiceMixim = {
  methods: {
    /**
    * Obtains the commercial conditions
    */
    getPromotions (from = 0, size = 10, text = '') {
      return this.$http.get(
        `${appConfig.API_END_POINT}/commercialconditions?from=${from}&size=${size}&text=${text}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
    updatePromotionsAdmin (payload) {
      return this.$http.post(
        `${appConfig.API_END_POINT}/commercialconditions`, payload,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
    updatePromotionSeller (commercialConditionId) {
      return this.$http.post(
        `${appConfig.API_END_POINT}/commercialconditions/${commercialConditionId}/activate`, '',
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
    saveBulkDiscount (discount, priceValidUntil) {
      let payload = { 'discount': discount, 'priceValidUntil': priceValidUntil }

      return this.$http.post(
        `${appConfig.API_END_POINT}/sku/prices/bulkdiscount`, payload,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
    downloadCSV () {
      Vue.http.options.emulateHTTP = true

      return this.$http.get(
        `${appConfig.API_END_POINT}/commercialconditions/downloadcsv`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`,
            Accept: 'application/octet-stream; charset=ISO-8859-1'
          },
          responseType: 'arraybuffer'
        }
      )
    },
    uploadCSV (delimiter, formData) {
      return this.$http.post(`${appConfig.API_END_POINT}/commercialconditions/importcsv?delimiter=${delimiter}`, formData,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        })
    },
    uploadPDF (formData) {
      return this.$http.post(`${appConfig.API_END_POINT}/commercialconditions/commission/import`, formData,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
    },
    downloadPDF () {
      return this.$http.get(
        `${appConfig.API_END_POINT}/commercialconditions/commission/download`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          },
          responseType: 'arraybuffer'
        }
      )
    },
    /**
     * Promotions in catalog
     */
    getPromotionsCatalog (tableInfo, filter) {
      let url = new URL(`${appConfig.API_END_POINT}/promotions`)
      let params = {}
      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }

      params.text = this.filter.text
      if (this.filter.isActive !== null) params.isActive = this.filter.isActive
      params.beginDate = this.filter.range[0]
      params.endDate = this.filter.range[1]
      params.brandId = filter.brands.map((elem) => elem.id).join(',')
      params.productId = filter.products.map((elem) => elem.id).join(',')
      params.categoryId = filter.categories.map((elem) => elem.id).join(',')
      params.accountName = filter.sellers.map((elem) => elem.id).join(',')
      params.marketplace = filter.marketplaces.map((elem) => elem.id).join(',')
      params.from = tableInfo.currentPage - 1
      params.size = tableInfo.perPage
      params.sortOrder = tableInfo.sortDesc ? 'desc' : 'asc'

      if (tableInfo.sortBy) {
        params.sortBy = tableInfo.sortBy
      } else {
        params.sortBy = 'lastModified'
        params.sortOrder = 'desc'
      }

      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null && params[key] !== undefined && params[key] !== '') {
            url.searchParams.append(key, params[key])
          }
        }
      )

      return this.$http.get(
        url.href,
        config
      )
    },
    getPromotionByName (name) {
      let url = new URL(`${appConfig.API_END_POINT}/promotions`)
      let params = {
        text: name
      }
      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }

      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null && params[key] !== undefined && params[key] !== '') {
            url.searchParams.append(key, params[key])
          }
        }
      )

      return this.$http.get(
        url.href,
        config
      )
    },
    getPromotion (promoId) {
      let url = new URL(`${appConfig.API_END_POINT}/promotions/${promoId}`)
      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }
      return this.$http.get(
        url.href,
        config
      )
    },
    savePromotion (payload) {
      if (payload.id !== null) {
        return this.$http.put(
          `${appConfig.API_END_POINT}/promotions/${payload.id}`
          , payload,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.access_token}`
            }
          }
        )
      } else {
        return this.$http.post(
          `${appConfig.API_END_POINT}/promotions`
          , payload,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.access_token}`
            }
          }
        )
      }
    },
    deletePromotion (promoId) {
      return this.$http.delete(
        `${appConfig.API_END_POINT}/promotions/${promoId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    }

  }
}
