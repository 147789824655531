<template>
  <div class="mb-3">
    <b-row class="mb-3">
      <b-col>
        <h5 v-if="product.name">{{product.name}}<span v-if="product.category"> ({{product.category.name}}) - {{product.id}}</span></h5>
      </b-col>
      <b-col>
        <button v-if="!isAdmin" :disabled="!product.id || loading || isProductActive" type="button" class="btn btn-primary float-right mr-3"  @click="deleteProduct">
          <i class="far fa-trash-alt"></i> {{$t('Eliminar Producto')}}
        </button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p v-show="product.id && product.skus.length === 0" class="alert alert-warning"><i class="fa fa-exclamation-triangle"></i> {{$t('Este producto no contiene SKUs creados')}}</p>
      </b-col>
    </b-row>
    <div>
      <b-alert :show="errorText !== ''" fade dismissible variant="danger">
        <h4 class="alert-heading">{{$t('Se ha producido un error!')}}</h4>
        <hr>
        <p v-html="errorText">{{errorText}}</p>
      </b-alert>
      <b-alert :show="successText !== ''" variant="success" dismissible>
        {{successText}}
       </b-alert>
    </div>

    <b-modal header-bg-variant="primary" size="lg" ref="confirmationModal" id="confirmationModal" centered :title="$t(confirmationModalData.confirmationModalTitle)"
      @ok="confirmationModalData.confirmationModalOkHandler"
      :cancel-title="$t('Cancelar')">
      {{confirmationModalData.confirmationModalText}}
    </b-modal>

    <b-tabs v-model="tabIndex">
      <moon-loader class="mt-3" :loading="loading" color="#000" size="30px"></moon-loader>
      <b-tab :title="$t('Datos del producto')" active>
        <b-row>
          <b-col>
            <b-card v-show="!loading" :header="$t('Datos generales')" class="my-3" header-bg-variant="primary">
              <b-col>
                  <b-form-group
                    id="idInput"
                    :label-cols="2"
                    breakpoint="md"
                    :label="$t('ID')"
                    label-for="idInput">
                    <b-form-input
                      id="idInṕut"
                      v-model="product.id"
                      :disabled="true">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="nombreInput"
                  :label-cols="2"
                  breakpoint="md"
                  :label="$t('Nombre')"
                  :description="$t('Máximo 100 caracteres')"
                  :invalid-feedback="!isValidText(product.name, true) ? $t('El nombre no es válido') : ''"
                  :state="isValidText(product.name, true)"
                  label-for="nombreFormatterHelp">
                  <b-form-input
                    id="nombreFormatterHelp"
                    v-model="product.name"
                    :disabled="editionDisabled"
                    maxlength="100">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                    id="categoriaInput"
                    :label-cols="2"
                    breakpoint="md"
                    :label="$t('Categoría')"
                    :invalid-feedback="!Boolean(product.category) ? $t('Seleccione una categoría') : ''"
                    :state="Boolean(product.category)"
                    label-for="categoryFormatterHelp">
                    <category-tree
                      :editable="!editionDisabled"
                      id='categoryFormatterHelp'
                      :categorySelected="product.category"
                      @onCategorySelected="selectCategory">
                    </category-tree>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group id="marcaInput"
                    :label-cols="2"
                    breakpoint="md"
                    :label="$t('Marca')"
                    :invalid-feedback="productBrand.length == 0 ? $t('Seleccione una marca') : ''"
                    :state="productBrand.length > 0"
                    label-for="marcaFormatterHelp">
                    <autocomplete
                      :v-if="product.brand"
                      id="inputBrand"
                      class="input_class optional"
                      name="inputBrand"
                      :maxElements="1"
                      :placeholder="$t('Ingrese una marca...')"
                      :provider="getSearchBrands"
                      :disabled="editionDisabled"
                      v-model="productBrand">
                    </autocomplete>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                   id="textLinkInput"
                   :label-cols="2"
                    breakpoint="md"
                    :label="$t('Descripción')"
                    :invalid-feedback="!isValidTextArea(product.description, true) ? $t('La descripción no es un texto / html válido') : ''"
                    :state="isValidTextArea(product.description, true)"
                    label-for="textlinkFormatterHelp">
                    <vue-editor :editorToolbar="customToolbar" :disabled="editionDisabled" name="Description" v-model="product.description" />
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    :label-cols="2"
                    breakpoint="md"
                    :label="$t('Sitios')"
                    :invalid-feedback="!selectedSites ? $t('Seleccione al menos un sitio') : ''"
                    :state="selectedSites">
                    <b-form-checkbox-group
                      v-model="sites"
                      :options="marketplacesOptions"
                      @change="siteChange"
                      :disabled="editionDisabled"
                      class="mb-3"
                    ></b-form-checkbox-group>
                  </b-form-group>
                </b-col>

            </b-card>
          </b-col>
          <b-col>
            <b-card v-show="!loading" :header="$t('Especificaciones')" class="my-3" header-bg-variant="primary">
              <product-attributes :disabled="isAdmin || isProductActive" :mergedInfo="specificationsComputedMap"></product-attributes>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="`SKUs (${this.product.skus.length})`">
        <b-row class="row m-0 mb-2">
          <b-col class="p-0">
            <button type="button" class="btn btn-primary float-right"  @click.stop="showFilters = !showFilters">
              <i class="fa" :class="{'fa-times': showFilters, 'fa-filter': !showFilters}" /> {{$t('Filtros')}}
            </button>
            <button v-if="!isAdmin" :disabled="!product.id || loading" type="button" class="btn btn-primary float-right mr-2"  @click="createSku">
              <i class="fa fa-plus"/> {{$t('Crear Variante')}}
            </button>
          </b-col>
          <b-col class="col-md-auto">
            <button type="button" @click="showActionModal('Aprobar')" v-show="isAdmin" :disabled="variantsSelected.length === 0" class="btn btn-primary float-right ml-2">{{$t('Activar sku')}}</button>
            <button type="button" @click="showActionModal('Rechazar')" v-show="isAdmin" :disabled="variantsSelected.length === 0" class="btn btn-primary float-right ml-2">{{$t('Rechazar sku')}}</button>
            <button type="button" @click="showActionModal('Eliminar')" v-show="!isAdmin" :disabled="variantsSelected.length === 0 || !canDeleteSkus" class="btn btn-primary float-right ml-2">{{$t('Eliminar sku')}}</button>
          </b-col>
        </b-row>
        <transition name="fade">
          <div class="filters p-3" v-show="showFilters">
            <b-row>
              <b-col>
                <b-form-group id="filterSku"
                  horizontal
                  :label-cols="4"
                  breakpoint="md"
                  :label="$t('SKU')"
                  :invalid-feedback="!isValidInteger(filter.sku, false)? $t('No es un valor válido') : ''"
                  :state="isValidInteger(filter.sku, false)"
                  label-for="skuDesdeFormatter">
                  <b-input-group>
                    <b-form-input id="filterSkuInput" v-model="filter.sku"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="filterUpc"
                  horizontal
                  :label-cols="4"
                  breakpoint="md"
                  :label="$t('UPC')"
                  :invalid-feedback="!isValidInteger(filter.upc, false)? $t('No es un valor válido') : ''"
                  :state="isValidInteger(filter.upc, false)"
                  label-for="skuDesdeFormatter">
                  <b-input-group>
                    <b-form-input id="filterUpcInput" v-model="filter.upc"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="stateInput"
                  horizontal
                  :label-cols="4"
                  breakpoint="md"
                  :label="$t('Estado')"
                  label-for="stateFormatter">
                  <b-form-select id="stateFormatter" v-model="filter.estado">
                    <option slot="first" :value="null">{{$t('Todos')}}</option>
                    <option v-for="item in estados"
                      :key="item.value"
                      :value="item.value">{{$t(item.text)}}
                    </option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-input-group-button class="float-right mt-3 mr-3">
                  <b-button :disabled="loading" :block="true" variant="primary" @click.stop="cleanFilters" >{{$t('Limpiar')}}</b-button>
                </b-input-group-button>
              </b-col>
            </b-row>
          </div>
        </transition>
        <b-row v-if="product.skus.length > 0">
          <b-col md="3" class="my-2">
            <b-form-select
              class="action-form-select"
              id="selectProductGroup"
              :options="productGroupSelection.options"
              required
              v-model="productGroupSelection.current"
              @click.native.stop
              @change="productGroupSelectionHandler">
            </b-form-select>
          </b-col>
        </b-row>
        <b-table id="mainTable"
          ref="variantTable"
          show-empty
          hover
          :current-page="variantCurrentPage"
          :per-page="variantPerPage"
          :items="variantItemProvider"
          :fields="fieldsVariant"
          responsive=true
          @row-dblclicked="variantDbClicked"
          stacked="xl"
          :empty-text="$t('No hay variantes para mostrar')"
          :empty-filtered-text="$t('No hay variantes para mostrar')">
          <template slot="selected" slot-scope="row">
            <b-form-checkbox v-if="!isAdmin || (isAdmin && canActiveOrRejectSku(row.item))" class="ml-4" @click.native.stop @change="handleRowCheckbox" :value="row.item.id" v-model="variantsSelected">
            </b-form-checkbox>
          </template>
          <template slot="price" slot-scope="row">
            {{currency}} {{row.item.price.listPrice}}<br>
            <span v-b-tooltip.hover :title="$t('Precio con descuento')" class="discount">({{currency}} {{row.item.price.price}})</span><br>
            {{row.item.price.priceValidUntil}}
          </template>
            <template slot="inventory" slot-scope="row">
              <div class="inventoryContainer" v-b-tooltip.hover :title="$t('La cantidad entre parentesis es stock reservado')">
                <span class="inventoryTotal">{{row.item.inventory.totalQuantity}}</span>
                <span class="inventoryReserved">({{row.item.inventory.reservedQuantity ? row.item.inventory.reservedQuantity : 0}})</span>
              </div>
          </template>
          <template slot="status"  slot-scope="data">
            <b :class="getClassByStatus(data)">
              {{ getStatus(data) }}
            </b>
          </template>
          <template slot="actions"  slot-scope="row">
            <span class="mr-2" v-show="!isAdmin && canDeleteSku(row.item)" @click="deleteVariant(row.item)"><i class="fas fa-trash cursor-pointer"></i></span>
            <b-button class="primary" size="sm" variant="link" @click.stop="row.toggleDetails">
              <span v-show="!row.detailsShowing"><i class="fa fa-plus"></i></span>
              <span v-show="row.detailsShowing"><i class="fa fa-minus"></i></span>
               {{$t('Incidencias')}}
            </b-button>
          </template>
          <template slot="row-details" slot-scope="row">
            <div>
              <div class="row position-relative"><button @click.stop="createSkuIncidence(row.item.id)" type="button" class="add-controversy-sku btn btn-primary mr-1 mb-3 float-right"><i class="fa fa-plus mr-2"></i>{{$t('Crear incidencia')}}</button></div>
              <incidents-table
                :filters="{
                  fromDate: null,
                  toDate: null,
                  incidenceType: 'SKU',
                  accountName: null,
                  priority: null,
                  status: null,
                  text: row.item.id
                }"
                :showIncidenceDetail="true"
              >
              </incidents-table>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col class="col-md-auto" cols="12" sm="6" md="6">
            <b-pagination :total-rows="variantTotalRows" :per-page="variantPerPage" v-model="variantCurrentPage" class="mb-3" />
          </b-col>
          <b-col class="col-md-auto per-page" cols="12" sm="6" md="6">
            <b-form-group id="perPage" horizontal class="form-page-custom">
              <label for="perPage">{{$t('Mostrar por página')}}</label>
              <b-form-select :options="variantPageOptions" for="perPage" v-model="variantPerPage" label-cols="12"/>
            </b-form-group>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="`Incidencias (${incidentsNumber})`">
        <b-row>
          <div class="w-100 text-right px-3 mb-3" :class="!isAdmin ? 'mr-3' : ''">
            <router-link v-if="false" :to="{ path: '/incidents/list', query: { product: product.id }}">{{$t('Crear incidencia')}}</router-link>
          </div>
        </b-row>
        <b-row>
          <!-- Incidence table -->
          <b-col md="12">
            <b-card class="controversy-card" :title="$t('Incidencias')" v-if="product.id" ref="IncidenceContainer">
              <button @click.stop="createIncidence()" type="button" class="add-controversy btn btn-primary float-right mr-2"><i class="fa fa-plus mr-2"></i>{{$t('Crear incidencia')}}</button>
              <b-col>
                <incidents-table
                  ref="indicentsTable"
                  :filters="incidenceFilters"
                  :showIncidenceDetail="true"
                  @incidentsNumber="setIncidentsNumber"
                >
                </incidents-table>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <b-row v-if="!isAdmin && tabIndex === 0" class="mt-4">
      <b-col>
        <div class="d-flex justify-content-end">
          <b-button :disabled="loading" class="mr-4" @click="$router.back()">{{$t('Cancelar')}}</b-button>
          <b-button :disabled="saveButtonDisabled || loading" @click="handleSave">{{$t('Guardar')}}</b-button>
        </div>
      </b-col>
    </b-row>
    <sku-modal @needReload="reloadInformation" ref="skuModal"></sku-modal>
  </div>
</template>

<script>

// Components
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import CategoryTree from './CategoryTree'
import ProductAttributes from './ProductAttributes'
import appConfig from '@/config/applicationConfig.js'
import SkuModal from './SkuModal'
import incidentsTable from '@/components/incidents/IncidentsTable'
import autocomplete from '@/components/common/vue-autocomplete'
import { VueEditor } from 'vue2-editor'

// Mixims
import { ProductServiceMixim, PRODUCT_STATES } from '@/mixims/ProductServiceMixim'
import {ValidationsMixim} from '@/mixims/ValidationsMixim.js'

export default {
  name: 'productDetail',
  mixins: [ProductServiceMixim, ValidationsMixim],
  components: {
    MoonLoader,
    CategoryTree,
    ProductAttributes,
    SkuModal,
    autocomplete,
    VueEditor,
    incidentsTable
  },
  props: ['productId'],
  data () {
    return {
      marketplacesOptions: [],
      sites: [],
      selectedSites: false,
      productGroupSelection: {
        options: [
          { text: this.$t('Seleccione una opción'), value: null, disabled: true },
          { text: this.$t('Ninguno'), value: 'Ninguno' },
          { text: this.$t('Todos'), value: 'Todos' }
        ],
        current: null
      },
      showFilters: false,
      loading: false,
      variantGroupAction: null,
      saveResult: {},
      isAdmin: this.$store.getters.isAdmin,
      product: {
        id: null,
        skus: [],
        category: null,
        brand: null,
        productSpecifications: []
      },
      customToolbar: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        [
          { list: 'ordered' },
          { list: 'bullet' }
        ],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        ['link'],
        [{ color: [] }, { background: [] }],
        [{ header: 1 }, { header: 2 }],
        ['clean']
      ],
      productBrand: [],
      tabIndex: 0,
      specifications: [],
      successText: '',
      errorText: '',
      confirmationModalData: {
        confirmationModalOkHandler: '',
        confirmationModalCancelHandler: '',
        confirmationModalText: '',
        confirmationModalTitle: ''
      },
      locale: appConfig.LOCALE,
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : '$',
      variantCurrentPage: 1,
      variantPerPage: 10,
      variantTotalRows: 0,
      variantPageOptions: [ 10, 15, 25, 50, 100 ],
      // brandOptions: this.$store.getters.brands.map(b => { return { text: b.name, value: b } }),
      variantsSelected: [],
      filter: {
        sku: null,
        upc: null,
        estado: null
      },
      fieldsVariant: [
        { key: 'selected', label: this.$t('Selección') },
        { key: 'upc', label: this.$t('UPC'), 'class': 'text-left' },
        { key: 'id', label: this.$t('SKU'), 'class': 'text-left' },
        { key: 'skuName', label: this.$t('Nombre'), 'class': 'text-left' },
        { key: 'refId', label: this.$t('Identificador'), 'class': 'text-left' },
        { key: 'price', label: this.$t('Precio con descuento'), class: 'priceCol' },
        { key: 'inventory', label: this.$t('Inventario'), 'class': 'text-left' },
        { key: 'status', label: this.$t('Estado'), 'class': 'text-left' },
        { key: 'actions', label: this.$t('Acciones'), 'class': 'text-right' }
      ],
      estados: PRODUCT_STATES,
      incidenceFilters: {
        fromDate: null,
        toDate: null,
        incidenceType: null,
        accountName: null,
        priority: null,
        status: null
      },
      incidentsNumber: 0
    }
  },
  methods: {
    canDeleteSku (sku) {
      const statuses = sku?.marketplaces?.map(x=>x.status)
      if (statuses && statuses.length > 0) {
        if(statuses.includes('APPROVED'))
          return false
        return true
      }
      return false
    },
    canActiveOrRejectSku (sku) {
      const statuses = sku?.marketplaces?.map(x=>x.status)
      if (statuses && statuses.length > 0) {
        return statuses.includes('PENDING_APPROVAL')
      }
      return false
    },
    getStatus (data) {
      const statuses = data?.item?.marketplaces?.map(x=>x.status)
      if (statuses && statuses.length > 0) {
        if(statuses.includes('APPROVED'))
          return this.estados.find(p => p.value === 'APPROVED').text
        else if(statuses.includes('REJECTED'))
          return this.estados.find(p => p.value === 'REJECTED').text
        else if(statuses.includes('PENDING_APPROVAL'))
          return this.estados.find(p => p.value === 'PENDING_APPROVAL').text
      }
      return ''
    },
    getClassByStatus (data) {
      const statuses = data?.item?.marketplaces?.map(x => x.status)
      if (statuses && statuses.length > 0) {
        if(statuses.includes('APPROVED'))
          return 'text-success'
        else if(statuses.includes('REJECTED'))
          return 'text-danger'
        else if(statuses.includes('PENDING_APPROVAL'))
          return 'text-warning'
      }
      return ''
    },
    siteChange (val) {
      let marketplaces = this.$store.getters.marketplaces
      this.product.marketplaces = []

      marketplaces.forEach(marketplace => {
        let isMarketplace = val.some(site => { return site === marketplace.id })
        if (isMarketplace) {
          this.product.marketplaces.push(marketplace)
        }
      })
      if (this.product.marketplaces.length > 0) {
        this.selectedSites = true
      } else {
        this.selectedSites = false
      }
    },
    cleanFilters () {
      this.filter.sku = null
      this.filter.upc = null
      this.filter.estado = null
    },
    productGroupSelectionHandler (selectionType) {
      this.variantsSelected.splice(0, this.variantsSelected.length)
      switch (selectionType) {
        case 'Ninguno':
          break
        case 'Todos':
          this.product.skus.forEach(sku => {
            this.variantsSelected.push(sku.id)
          })
          break
        default:
          break
      }
    },
    showActionModal (action) {
      let actionFunction = null
      let errorVerv = ''
      this.successText = ''
      this.errorText = ''
      switch (action) {
        case 'Aprobar':
          actionFunction = this.activateProducts
          errorVerv = this.$t('aprobando')
          break
        case 'Rechazar':
          actionFunction = this.rejectProducts
          errorVerv = this.$t('rechazando')
          break
        case 'Eliminar':
          actionFunction = this.deleteProducts
          errorVerv = this.$t('eliminando')
          break
      }
      this.confirmationModalData.confirmationModalTitle = `${this.$t('Confirmar')} ${this.$t(action)}`
      this.confirmationModalData.confirmationModalText = this.variantsSelected.length > 1 ? `${this.$t('Se procedera a')} ${this.$t(action)} ${this.$t('las')} ${this.variantsSelected.length} ${this.$t('variantes')}` : `${this.$t('Se procedera a')} ${this.$t(action)} ${this.$tc('la variante', 1)}`
      this.confirmationModalData.confirmationModalOkHandler = () => {
        this.loading = ''
        actionFunction(this.variantsSelected, true).then(
          () => {
            this.variantsSelected.splice(0, this.variantsSelected.length)
            this.successText = this.$t('Se completó la tarea con éxito')
            this.loading = false
            this.reloadInformation()
          },
          (error) => {
            this.errorText = error.body.message || `${this.$t('Error')} ${errorVerv} ${this.$tc('la variante', 2)}`
            this.loading = false
          }
        )
      }
      this.$refs.confirmationModal.show()
    },
    handleRowCheckbox () {
      // this.productGroupSelection.current = null
    },
    deleteVariant (variant) {
      this.confirmationModalData.confirmationModalTitle = `${this.$t('Eliminar SKU')}`
      this.confirmationModalData.confirmationModalText = `${this.$t('Se procederá a eliminar la variante')} ${variant.skuName}`
      this.confirmationModalData.confirmationModalOkHandler = () => {
        this.loading = true
        this.errorText = ''
        this.successText = ''
        this.deleteProducts([variant.id], true).then(
          () => {
            this.$refs.confirmationModal.hide()
            this.successText = this.$t('La variante fue eliminada con éxito')
            this.handleMount()
          },
          (error) => {
            this.loading = false
            this.errorText = error.body.message || this.$t('Error eliminando la variante')
          }
        )
      }
      this.$refs.confirmationModal.show()
    },
    deleteProduct () {
      this.confirmationModalData.confirmationModalTitle = `${this.$t('Eliminar Producto')}`
      this.confirmationModalData.confirmationModalText = `${this.$t('Se procederá a eliminar el producto')} ${this.product.id}`
      this.confirmationModalData.confirmationModalOkHandler = () => {
        this.loading = true
        this.errorText = ''
        this.successText = ''
        this.deleteProducts([this.product.id], false).then(
          () => {
            this.loading = false
            this.successText = this.$t('El producto fue eliminado con éxito')
            this.$refs.confirmationModal.hide()
            this.product = {
              id: null,
              skus: [],
              productSpecifications: []
            }
          },
          (error) => {
            this.loading = false
            this.errorText = error.body.message || this.$t('Error eliminando el producto')
          }
        )
      }
      this.$refs.confirmationModal.show()
    },
    reloadInformation (response) {
      if (response) {
        if (response.status) {
          this.successText = response.text
        } else {
          this.errorText = response.text
        }
      }
      this.handleMount()
      this.tabIndex = 1
    },
    createSku () {
      this.errorText = ''
      this.successText = ''
      this.$router.push(`/catalog/details/${this.product.id}/sku`)
    },
    variantDbClicked (item) {
      this.errorText = ''
      this.successText = ''
      this.$router.push(`/catalog/details/${this.product.id}/sku/${item.id}`)
    },
    _pendingProps () {
      return this.specificationsComputedMap.some(
        (prop) => {
          if (prop.value.isRequired) {
            return Object.keys(prop.value.specificationFieldValues[0]).length === 0 || !prop.value.specificationFieldValues[0].value
          }
        }
      )
    },
    selectCategory (category) {
      this.loading = true
      this.errorText = ''
      this.product.category = category
      return this.getProductAtributes(category.id).then(
        (res) => {
          this.product.productSpecifications.splice(0, this.product.productSpecifications.length)
          this.specifications = res.body
          this.loading = false
        },
        (error) => {
          this.loading = false
          this.errorText = error.body.message || this.$t('Error obteniendo los atributos del producto')
        }
      )
    },
    loadMarketplaces () {
      this.product.marketplaces.forEach(marketplace => {
        let isSites = this.sites.some(site => { return site === marketplace.id })
        if (!isSites) {
          this.sites.push(marketplace.id)
        }
      })
      this.marketplacesOptions = []
      this.$store.getters.marketplacesCombo.forEach(option => {
        let aux = {
          value: option.value, 
          text: option.text,
          disabled: false
        }
        let isMarket = this.product.marketplaces.some(item => { return item.id === option.value })
        if (isMarket) {
          aux.disabled = true
        }
        this.marketplacesOptions.push(aux)
      })
      if (this.product.marketplaces.length > 0) {
        this.selectedSites = true
      }
    },
    handleMount () {
      if (this.productId) {
        this.loading = true
        return this.getProduct().then(
          (res) => {
            this.product = res.body
            this.loadMarketplaces()
            this.incidenceFilters.text = this.product.id
            if (this.product.productSpecifications === null) {
              this.product.productSpecifications = []
            }
            this.variantTotalRows = this.product.skus.length

            if (this.product.brand.name) {
              this.productBrand.push(this.product.brand)
            }
            this.$refs.variantTable.refresh()
            return this.getProductAtributes(this.product.category.id).then(
              (specifications) => {
                this.loading = false
                this.specifications = specifications.body
              },
              (error) => {
                this.loading = false
                this.errorText = error.body.message || this.$t('Error obteniendo las especificaciones del producto')
              }
            )
          },
          (error) => {
            this.loading = false
            this.errorText = error.body.message || this.$t('Error obteniendo el producto')
          }
        )
      }
    },
    handleSave (evt) {
      window.scrollTo(0, 0)
      evt.preventDefault()

      this.errorText = this.successText = ''
      if (!this.product.id) {
        this.confirmationModalData.confirmationModalTitle = `${this.$t('Procesar alta de producto:')} ${this.product.name}`
      } else {
        this.confirmationModalData.confirmationModalTitle = `${this.$t('Procesar modificacion de Producto:')} ${this.product.name}`
      }
      this.confirmationModalData.confirmationModalText = this.$t('¿Desea continuar?')
      this.confirmationModalData.confirmationModalOkHandler = () => {
        this.loading = true
        this.product.brand = this.productBrand[0]
        if (this.product.id) {
          return this.updateProduct(this.product).then(
            (res) => {
              this.successText = this.$t('El producto fue actualizado con exito')
              this.loading = false
            },
            (error) => {
              this.errorText = error.body.message || this.$t('Error actualizando el producto')
              this.loading = false
            }
          )
        }

        return this.createProduct(this.product).then(
          (res) => {
            this.product.id = res.id
            this.successText = this.$t('El producto fue creado con exito')
            this.loading = false
            this.createSku()
          },
          (error) => {
            this.errorText = error.body.message || this.$t('Error creando el producto')
            this.loading = false
          }
        )
      }
      this.$refs.confirmationModal.show()
    },
    createIncidence () {
      this.$router.push({path: '/incidents/list', query: { product: this.productId }})
    },
    createSkuIncidence (sku) {
      this.$router.push({path: '/incidents/list', query: { sku: sku }})
    },
    scrollToIncidence () {
      const el = this.$refs.IncidenceContainer
      if (el) {
        el.scrollIntoView()
      }
    },
    setIncidentsNumber (data) {
      this.incidentsNumber = data
    }
  },
  mounted () {
    if (this.$route.query.sku) {
      this.tabIndex = 1
      this.showFilters = true
      this.filter.sku = this.$route.query.sku
    }
    this.handleMount()
  },
  computed: {
    canDeleteSkus () {
      let skusSelected = this.product.skus.filter((item) => { 
        return this.variantsSelected.some((id) => { return id == item.id }) 
      })
      //return !skusSelected.some((sku) => { return sku.status == "APPROVED" })
      const approvedSkus = skusSelected?.filter(sku=> sku?.marketplaces?.some(site=> site?.status == "APPROVED"))
      return approvedSkus?.length == 0 
    },
    isProductActive () {
      //return this.product.skus.some((sku) => { return sku.status == "APPROVED" })
      const approvedSkus = this.product?.skus?.filter(sku=> sku?.marketplaces?.some(site=> site?.status == "APPROVED"))
      return approvedSkus?.length > 0 
    },
    disableFilterButton () {
      if (!this.isValidInteger(this.filter.sku, false) || !this.isValidInteger(this.filter.upc, false)) {
        return true
      }
      return false
    },
    variantItemProvider () {
      let items = this.product.skus.filter((sku) => {
        let value = true
        if (this.filter.sku && value) {
          if (!String(sku.id).includes(this.filter.sku)) {
            value = false
          }
        }
        if (this.filter.upc && value) {
          if (!String(sku.upc).includes(this.filter.upc)) {
            value = false
          }
        }
        if (this.filter.estado && value) {
          if (sku.status !== this.filter.estado) {
            value = false
          }
        }
        return value
      })
      let start = (this.variantCurrentPage - 1) * this.variantPerPage
      let end = this.variantPerPage * this.variantCurrentPage
      return items.slice(start, end)
    },
    editionDisabled: function () {
      if (this.isAdmin) {
        return true
      }
      
      const pendingSkus = this.product?.skus?.filter(sku=> sku?.marketplaces?.some(site=> site?.status !== "PENDING_APPROVAL"))
      return pendingSkus?.length > 0 
      
      // return this.product.skus.some((sku) => {
      //   return sku.status !== 'PENDING_APPROVAL'
      // })
    },
    specificationsComputedMap: function () {
      let attributes = this.product.productSpecifications
      return this.specifications.map(spec => {
        let newAttr
        let foundAttr = attributes.find(attr => attr && attr.name === spec.name)
        if (!foundAttr) {
          newAttr = {
            ...spec,
            specificationFieldValues: [{}]
          }
          this.product.productSpecifications.push(newAttr)
        }
        return {
          spec,
          value: foundAttr !== undefined ? foundAttr : newAttr}
      }).sort((a,b) => Number(b.spec.isRequired) - Number(a.spec.isRequired))
    },
    saveButtonDisabled: function () {
      if (!this.isValidText(this.product.name, true) || this.isEmptyData(this.product.category) || this.isEmptyData(this.product.description) || !this.productBrand.length > 0) {
        return true
      }
      if (this._pendingProps()) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
  .filters {
    border: 1px solid #e3e8ec;
    margin: 10px 0 15px;
  }
  .priceCol {
    .discount {
     font-size: 12px;
     font-weight: bolder;
    }

  }

  .inventoryContainer {
    display: inline-block;
  
    .inventoryTotal {
      font-weight: bolder;
      font-size: 16px;
    }

    .inventoryReserved {
      color: #777;
    }
  }

  .add-controversy {
   position: absolute;
   top: 14px;
   right: 12px;
  }

  .add-controversy-sku {
   position: absolute;
   top: 0;
   right: 12px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

</style>
