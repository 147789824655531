export const Utils = {
  methods: {
    _exportCSV (csvFile, filename) {
      let headers = csvFile.headers
      var blob = new Blob([csvFile.body], {
        type: { type: headers.map['content-type'] }
      })
      const blobURL = window.URL.createObjectURL(blob)
      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = blobURL
      tempLink.setAttribute('download', filename)
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
    }
  }
}
