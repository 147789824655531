<template>
    <b-container fluid>
      <h4 class="mb-4">{{title}}</h4>
      <b-row class="mb-3">
        <b-col cols="12" lg="6">
         <span class="mr-1">Tipo de usuario</span>
          <el-select :disabled="id !== null || $store.getters.role.userType == 'ADMIN' || $store.getters.role.userType == 'SELLER' || !adminWrite" @change="reset" v-model="userType" placeholder="Selecciona un tipo de usuario">
            <el-option
            v-for="item in userTypeOptions"
            :key="item.userType"
            :label="item.label"
            :value="item.userType">
            </el-option>
          </el-select>
        </b-col>
        <b-col cols="12" lg="6">
          <el-alert
            v-if="!userType"
            class="bg-gray"
            title="Tipo de usuario"
            type="info"
            description="Para comenzar debes seleccionar el tipo de usuario que deseas crear"
            show-icon
            :closable="false">
          </el-alert>

        </b-col>
        </b-row>
        <b-row>
        <b-col cols="12" lg="6">
          <span class="mr-2">Nombre de rol</span>
          <el-input
            style="width:200px"
            placeholder="Ingresa un nombre"
            v-model="roleName"
            :clearable="adminWrite"
            :disabled="!userType || !adminWrite"
            >
          </el-input>
        </b-col>
        <b-col cols="12" lg="6">
          <el-alert
            v-if="!roleName && userType"
            class="bg-gray"
            title="Nombre de rol"
            type="info"
            description="Ingresa un nombre para el rol"
            show-icon
            :closable="false">
          </el-alert>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col class="mb-3" cols="12">Permisos</b-col>
        <b-col cols="12">
          <el-tabs tab-position="left">
            <el-tab-pane :disabled="!userType || !roleName" v-for="(permission, i) in filteredPermissions" :key="i" :label="permission.label">
              <b-row>
                <b-col cols="12" xl="4">
                  <div class="permission-label">
                    <span style="font-size: 12px">Permisos: {{permission.label}}</span>
                  </div>
                  <el-card>
                  <b-col>
                  <el-radio
                    v-model="permission.value"
                    :label="permission.idRead"
                    :disabled="!userType || !roleName || !adminWrite || disabledReadByLabel(permission)">
                    Lectura
                  </el-radio>
                  </b-col>
                  <b-col>
                  <el-radio
                    class="mt-3"
                    v-model="permission.value"
                    :label="permission.idRead + ',' + permission.idWrite"
                    :disabled="!userType || !roleName || !adminWrite || (permission.label == 'Alta de productos') && userType == 'ADMIN' || permission.label == 'Reportes' || (permission.label == 'Actualizaciones') && userType == 'SELLER'">
                    Escritura
                  </el-radio>
                  </b-col>
                  <b-col>
                  <el-radio
                    :disabled="!adminWrite"
                    class="mt-3"
                    v-model="permission.value"
                    :label="''">
                    Ninguno
                  </el-radio>
                  </b-col>
                  </el-card>
                </b-col>
                <b-col cols="8">
                  <el-alert
                    v-if="userType && roleName && ((userType === 'ADMIN' && permission.adminRead !== undefined) || (userType === 'SELLER' && permission.sellerRead !== undefined))"
                    class="mb-3 bg-gray"
                    title="Lectura"
                    type="info"
                    :description="userType == 'SELLER' ? permission.sellerRead : permission.adminRead"
                    show-icon
                    :closable="false">
                  </el-alert>
                  <el-alert
                    v-if="userType && roleName && ((userType === 'ADMIN' && permission.adminWrite !== undefined) || (userType === 'SELLER' && permission.sellerWrite !== undefined))"
                    class="bg-gray"
                    title="Escritura"
                    type="info"
                    :description="userType == 'SELLER' ? permission.sellerWrite : permission.adminWrite"
                    show-icon
                    :closable="false">
                  </el-alert>
                </b-col>
              </b-row>
            </el-tab-pane>
          </el-tabs>
        </b-col>
        <b-col cols="12" class="text-center mt-3">
        <div v-show="success" class="alert alert-success" role="alert" dismissible @dismised="success=false">
          La acción fue completada con éxito
        </div>
        <div v-show="errorText !== ''" class="alert alert-danger" role="alert" dismissible @dismised="errorText = ''">
        {{errorText}}
        </div>
        </b-col>
        <b-col class="text-right mb-3">
          <el-button v-if="!success && adminWrite" :disabled="!userType || !roleName || !atLeastOnePerm" @click="createNewRole"><span v-if="!id">Crear rol</span><span v-else>Editar rol</span></el-button>
          <el-button v-if="!adminWrite" @click="routeList">Atras</el-button>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import {RoleService} from '@/mixims/AdminServiceMixin'

export default {
  name: 'role-form',
  props: ['id'],
  mixins: [RoleService],
  data () {
    return {
      title: 'Crear rol',
      userType: '',
      roleName: '',
      errorText: '',
      success: false,
      loading: false,
      role: {
        'id': '',
        'roleName': '',
        'description': '',
        'permissions': [],
        'userType': '',
        'roleStatus': '',
        'creationDate': '',
        'updateDate': ''
      },
      adminWrite: this.$store.getters.signedRoles.includes('ADMIN_WRITE'),
      permissions:
      [
        {
          idRead: 'ORDER_READ',
          idWrite: 'ORDER_WRITE',
          readValue: '',
          writeValue: '',
          label: 'Pedidos',
          value: '',
          adminRead: 'Visualizar listado de pedidos, filtrar pedidos, ver detalle de los pedidos',
          adminWrite: 'Botones de acción sobre los pedidos (iniciar manejo, facturar, cancelar, etc.',
          sellerRead: 'Visualizar listado de pedidos, filtrar pedidos, ver detalle de los pedidos',
          sellerWrite: 'Botones de acción sobre los pedidos (iniciar manejo, facturar, cancelar, etc.'
        },
        {
          idRead: 'INVOICE_READ',
          idWrite: 'INVOICE_WRITE',
          readValue: '',
          writeValue: '',
          label: 'Facturar pedidos',
          value: '',
          adminWrite: 'Facturar pedidos'
        },
        {
          idRead: 'PRODUCT_READ',
          idWrite: 'PRODUCT_WRITE',
          readValue: '',
          writeValue: '',
          value: '',
          label: 'Administrar Productos',
          adminRead: 'Visualizar listado de productos, filtrar productos, ver detalle de productos, ver detalle de los skus',
          adminWrite: 'Aprobar productos, aprobar skus, rechazar productos, rechazar sku, eliminar producto, eliminar variante',
          sellerRead: 'Visualizar listado de productos, filtrar productos, ver detalle de productos, ver detalle de los skus',
          sellerWrite: 'Eliminar producto, eliminar variante'
        },
        {
          idRead: 'PRODUCT_READ',
          idWrite: 'PRODUCT_WRITE',
          readValue: '',
          writeValue: '',
          value: '',
          label: 'Alta de productos',
          sellerRead: 'Exportar CSV de productos, exportar CSV de atributos, Exportar CSV de atributos de variantes',
          sellerWrite: 'Crear producto, crear variante, importar CSV de productos, importar CSV de atributos'
        },
        {
          idRead: 'INVENTORY_READ',
          idWrite: 'INVENTORY_WRITE',
          readValue: '',
          writeValue: '',
          value: '',
          label: 'Administración de inventario',
          adminRead: 'Visualizar listado de inventarios, exportar CSV inventarios',
          adminWrite: 'Importar CSV de inventarios, editar inventarios, actualizar el inventario en el alta manual de productos, actualizar el inventario en el alta masiva de productos',
          sellerRead: 'Visualizar listado de inventarios, exportar CSV inventarios',
          sellerWrite: 'Importar CSV de inventarios, editar inventarios, actualizar el inventario en el alta manual de productos, actualizar el inventario en el alta masiva de productos'
        },
        {
          idRead: 'PRICE_READ',
          idWrite: 'PRICE_WRITE',
          readValue: '',
          writeValue: '',
          value: '',
          label: 'Administración de precios',
          adminRead: 'Visualizar listado de precios, exportar CSV de precios',
          adminWrite: 'Importar CSV de precios, actualizar el precio en el alta manual de productos, actualizar el precio en el alta masiva de productos',
          sellerRead: 'Visualizar listado de precios, exportar CSV de precios',
          sellerWrite: 'Importar CSV de precios, actualizar el precio en el alta manual de productos, actualizar el precio en el alta masiva de producto'
        },
        {
          idRead: 'PROMOTION_READ',
          idWrite: 'PROMOTION_WRITE',
          readValue: '',
          writeValue: '',
          value: '',
          label: 'Promociones',
          adminRead: 'Ver listado de promociones, ver detalle de promociones',
          adminWrite: 'Crear nueva promoción, editar promoción',
          sellerRead: 'Ver listado de promociones, ver detalle de promociones',
          sellerWrite: 'Crear nueva promoción, editar promoción'
        },
        {
          idRead: 'CARRIER_READ',
          idWrite: 'CARRIER_WRITE',
          readValue: '',
          writeValue: '',
          value: '',
          label: 'Transportadoras',
          adminRead: 'Ver listado de transportadoras, ver detalle de una transportadora, ver listado de precios de transportadoras, ver detalle de un precio, ver los sellers asignados a una transportadora, ver puntos de retiros, ver punto de retiro en específico',
          adminWrite: 'Crear/editar una transportadora, asignar sellers a una transportadora, crear/editar un precio, ver puntos de retiros, ver punto de retiro en específico, crear/editar puntos de retiro',
          sellerRead: 'Ver listado de transportadoras, ver detalle de una transportadora, ver listado de precios de transportadoras, ver detalle de un precio, ver puntos de retiros, ver punto de retiro en específico',
          sellerWrite: 'Crear/editar una transportadora, asignar sellers a una transportadora, crear/editar un precio, ver puntos de retiros, ver punto de retiro en específico, crear/editar puntos de retiro'
        },
        {
          idRead: 'REPORT_READ',
          idWrite: 'REPORT_WRITE',
          readValue: '',
          writeValue: '',
          value: '',
          adminRead: 'Visualizar reportes de ventas, visualizar reportes de estado de pedidos, visualizar reporte de inventario de productos, visualizar reporte de cátalogo de productos, exportar reportes de ventas, exportar reportes de estado de pedidos, exportar reporte de inventario de productos, exportar reporte de cátalogo de productos',
          sellerRead: 'Visualizar reportes de ventas, visualizar reportes de estado de pedidos, visualizar reporte de inventario de productos, visualizar reporte de cátalogo de productos, exportar reportes de ventas, exportar reportes de estado de pedidos, exportar reporte de inventario de productos, exportar reporte de cátalogo de productos',
          label: 'Reportes'
        },
        {
          idRead: 'INCIDENCE_READ',
          idWrite: 'INCIDENCE_WRITE',
          readValue: '',
          writeValue: '',
          value: '',
          adminRead: 'Ver listado de incidencias, ver detalle de incidencias, filtros de incidencia',
          adminWrite: 'Crear incidencia, agregar comentario a una incidencia, marcar como resuelta una incidencia',
          sellerRead: 'Ver listado de incidencias, ver detalle de incidencias, filtros de incidencia',
          sellerWrite: 'Crear incidencia, agregar comentario a una incidencia, marcar como resuelta una incidencia',
          label: 'Incidencias'
        },
        {
          idRead: 'ADMIN_READ',
          idWrite: 'ADMIN_WRITE',
          readValue: '',
          writeValue: '',
          value: '',
          label: 'Administración',
          adminRead: 'Ver listado de usuarios, ver detalle de usuarios, ver listado de sellers, ver detalle de sellers, ver listado de notificaciones, ver detalle de notificaciones',
          adminWrite: 'Crear nuevo usuario, editar un usuario, crear nuevo seller, editar un seller, crear un nueva notificación, editar una notificación',
          sellerWrite: 'Crear nuevo usuario, editar un usuario'
        },
        {
          idRead: 'UPDATE_READ',
          idWrite: 'UPDATE_WRITE',
          readValue: '',
          writeValue: '',
          value: '',
          label: 'Actualizaciones',
          adminWrite: 'Actualizar catalogo y marcas, actualizar atributos',
          adminRead: 'Visualizar actualizaciones'
        }
      ]
    }
  },
  mounted () {
    this.handleMount()
  },
  computed: {
    filteredPermissions () {
      if (this.$store.getters.userData.userType === 'SELLER' && this.$store.getters.signedRoles.indexOf('ADMIN_WRITE') !== -1) {
        return this.permissions.filter(permission => this.$store.getters.signedRoles.includes(permission.idRead) || this.$store.getters.signedRoles.includes(permission.idWrite))
      } else {
        if (this.userType === 'ADMIN') {
          return this.permissions.filter(permission => permission.label !== 'Alta de productos')
        } else {
          let permissions = this.permissions.filter(permission => permission.label !== 'Administrar Productos')
          return permissions.filter(permission => permission.label !== 'Facturar pedidos')
        }
      }
    },
    atLeastOnePerm () {
      let onePerm = false
      this.filteredPermissions.map(perm => {
        if (perm.value) {
          onePerm = true
        }
      })
      return onePerm
    },
    userTypeOptions () {
      let userTypes = [
        {
          userType: 'SELLER',
          label: 'Seller'
        }
      ]
      if (this.$store.getters.userData.userType === 'ADMIN') {
        userTypes.unshift({
          userType: 'ADMIN',
          label: 'Administrador'
        })
      }
      return userTypes
    }
  },
  methods: {
    reset () {
      for (let i = 0; i < this.permissions.length; i++) {
        this.permissions[i].readValue = ''
        this.permissions[i].writeValue = ''
        this.permissions[i].value = ''
      }
    },
    createNewRole () {
      this.role = {
        'userType': this.userType,
        'roleName': this.roleName,
        'permissions': [],
        'roleStatus': 'ENABLED'
      }
      this.permissions = [...this.filteredPermissions]
      for (let i = 0; i < this.permissions.length; i++) {
        if (this.permissions[i].value !== '' && this.permissions[i].value.split(',').length === 1) {
          if (this.role['permissions'].findIndex(perm => perm.id === this.permissions[i].value.split(',')[0]) === -1) this.role['permissions'].push({'id': this.permissions[i].value.split(',')[0], 'name': this.permissions[i].value.split(',')[0], 'description': this.permissions[i].value.split(',')[0]})
        }
        if (this.permissions[i].value !== '' && this.permissions[i].value.split(',').length === 2) {
          if (this.role['permissions'].findIndex(perm => perm.id === this.permissions[i].value.split(',')[0]) === -1) this.role['permissions'].push({'id': this.permissions[i].value.split(',')[0], 'name': this.permissions[i].value.split(',')[0], 'description': this.permissions[i].value.split(',')[0]})
          if (this.role['permissions'].findIndex(perm => perm.id === this.permissions[i].value.split(',')[1]) === -1) this.role['permissions'].push({'id': this.permissions[i].value.split(',')[1], 'name': this.permissions[i].value.split(',')[1], 'description': this.permissions[i].value.split(',')[1]})
        }
      }
      if (this.id) {
        try {
          this.updateRole(this.role, this.id).then(() => {
            this.success = true
            this.errorText = ''
            setTimeout(() => {
              this.$router.push('/admin/roles')
            }, 1000)
          }).catch((er) => {
            console.log(er)
            this.errorText = 'Ocurrió un error, el rol no fue editado'
          })
        } catch (e) {
          console.log(e.message)
          this.errorText = 'Ha ocurrido un error en el módulo de Roles'
        }
      } else {
        try {
          this.createRole(this.role).then(() => {
            this.success = true
            this.errorText = ''
            setTimeout(() => {
              this.$router.push('/admin/roles')
            }, 1000)
          }).catch((er) => {
            console.log(er)
            this.errorText = 'Ocurrió un error, el rol no fue creado'
          })
        } catch (e) {
          console.log(e.message)
          this.errorText = 'Ha ocurrido un error en el módulo de Roles'
        }
      }
    },
    handleMount () {
      if (this.id) {
        if (!this.adminWrite) {
          this.title = 'Detalle de rol'
        } else {
          this.title = 'Editar rol'
        }
        try {
          this.getRole(this.id).then((response) => {
            let items = response.body
            let rolePermission = items.permissions.map(permission => permission)
            this.userType = items.userType
            this.roleName = items.roleName
            for (let i = 0; i < this.permissions.length; i++) {
              for (let j = 0; j < rolePermission.length; j++) {
                if (this.permissions[i].idRead === rolePermission[j].id) {
                  this.permissions[i].value = rolePermission[j].id
                }
                if (this.permissions[i].idWrite === rolePermission[j].id) {
                  this.permissions[i].value = this.permissions[i].idRead + ',' + rolePermission[j].id
                }
              }
            }
          }).catch((er) => {
            console.log(er)
          })
        } catch (e) {
          console.log(e.message)
          this.errorText = 'Ha ocurrido un error en el módulo de Roles'
        }
      } else if (this.$store.getters.role !== '') {
        let rolePermission = this.$store.getters.role.permissions.map(permission => permission)
        for (let i = 0; i < this.permissions.length; i++) {
          for (let j = 0; j < rolePermission.length; j++) {
            if (this.permissions[i].idRead === rolePermission[j].id) {
              this.permissions[i].value = rolePermission[j].id
            }
            if (this.permissions[i].idWrite === rolePermission[j].id) {
              this.permissions[i].value = this.permissions[i].idRead + ',' + rolePermission[j].id
            }
          }
        }
        this.userType = this.$store.getters.role.userType
      }
    },
    routeList () {
      this.$router.push('/admin/roles')
    },
    disabledReadByLabel (permission) {
      if (this.userType === 'ADMIN') {
        return permission.label === 'Alta de productos' || permission.label === 'Facturar pedidos'
      } else {
        return permission.label === 'Actualizaciones' || permission.label === 'Administración'
      }
    }
  }
}
</script>

<style scoped>
  .permission-label {
    background-color:#E4E7ED; 
    height:35px; 
    display:flex;
    justify-content:center; 
    align-items:center
  }

  .bg-gray {
    background-color:#E4E7ED
  }
</style>
