<template>
  <div>
    <users-list/>
  </div>
</template>

<script>
import UsersList from '@/components/user/UsersList'

export default {
  name: 'users-view',
  components: {
    UsersList
  }
}
</script>
