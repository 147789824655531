// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import { router } from './router/index'
import VueResource from 'vue-resource'
import { store } from './store/store'
import VueSession from 'vue-session'
import i18n from './locales/Lang'
import appConfig from '@/config/applicationConfig.js'
import TitleMixin from './mixims/TitleMixin'
import ElementUI from 'element-ui'

Vue.use(BootstrapVue)
Vue.use(VueResource)
Vue.use(VueSession)
Vue.mixin(TitleMixin)
Vue.use(ElementUI)

/* eslint-disable no-new */
new Vue({
  i18n,
  store: store,
  el: '#app',
  router,
  template: '<App/>',
  components: {
    App
  }
})

Vue.http.interceptors.push(function (request, next) {
  next(function (response) {
    if (response.status === 401) {
      return router.push('/pages/401')
    }
  })
})

console.log('Version: ', appConfig.VERSION)
