import appConfig from '@/config/applicationConfig.js'

export const InventoryServiceMixim = {
  methods: {

    /**
    * Update the given sku inventory
    * @param sku
    * @param newInventory
    */
    updateInventory (skuId, totalQuantity, reservedQuantity) {
      let payload = {
        'totalQuantity': totalQuantity,
        'reservedQuantity': reservedQuantity
      }
      return this.$http.put(
        `${appConfig.API_END_POINT}/sku/inventories/${skuId}`, payload,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    /**
    * Gte the inventory csv content file
    */
    getInventoryCSV (userFilterSelections) {
      let url = new URL(`${appConfig.API_END_POINT}/sku/inventories/downloadcsv`)
      let params = {}
      if (userFilterSelections) {
        params.accountName = userFilterSelections.account
        params.fromSkuId = userFilterSelections.sku.desde
        params.toSkuId = userFilterSelections.sku.hasta
        params.fromUpc = userFilterSelections.upc.desde
        params.toUpc = userFilterSelections.upc.hasta
        params.categoryId = userFilterSelections.category.id
        params.fromQuantity = userFilterSelections.fromQuantity
        params.toQuantity = userFilterSelections.toQuantity
        params.fromDate = userFilterSelections.fecha.desde
        params.toDate = userFilterSelections.fecha.hasta
      }

      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null) {
            url.searchParams.append(key, params[key])
          }
        }
      )

      return this.$http.get(
        url.href,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`,
            Accept: 'application/octet-stream; charset=ISO-8859-1'
          },
          responseType: 'arraybuffer'
        }
      )
    },

    /**
    * Add inventory
    * @param inventory
    */
    addInventoryCSV (csvInventoryFile, delimiter) {
      let formData = new FormData()
      formData.append('file', csvInventoryFile)
      return this.$http.post(`${appConfig.API_END_POINT}/sku/inventories/importcsv?delimiter=${delimiter}`, formData,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
    }
  }
}
