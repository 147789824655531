<script>
import { Doughnut, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: ['height'],
  data () {
    return {
      graphOptions: {
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: true,
          text: this.$t('Estado de ordenes')
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: 'rgb(0, 0, 0)'
          }
        }
      }
    }
  },
  methods: {
    updateGraph () {
      this.renderChart(this.chartData, this.graphOptions)
    }
  }
}
</script>
