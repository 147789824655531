<template>
  <div class="animated fadeIn">
    <b-container fluid class="px-0 product-list">
      <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Filtros')">
        <b-row>
          <b-col lg="4" class="px-4 py-2">
            <label><strong>{{$t('SKU ID')}}</strong></label>
          </b-col>
          <b-col lg="4" class="px-4 py-2">
            <label><strong>{{$t('UPC')}}</strong></label>
          </b-col>
          <b-col lg="4" class="px-4 py-2">
            <label><strong>{{$t('Cantidad')}}</strong></label>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" class="px-4 py-2">
            <b-form-group id="skuDesdeInputForm"
              horizontal
              :label-cols="4"
              breakpoint="md"
              :invalid-feedback="!isValidInteger(filter.sku.desde, false)? $t('No es un valor válido') : ''"
              :state="isValidInteger(filter.sku.desde, false)"
              :label="$t('Desde')"
              label-for="skuDesdeFormatter">
              <b-input-group>
                <b-form-input id="skuDesdeInputFormatter" v-model="filter.sku.desde"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="4" class="px-4 py-2">
            <b-form-group id="upcDesdeInput"
              horizontal
              :label-cols="4"
              breakpoint="md"
              :invalid-feedback="!isValidInteger(filter.upc.desde, false)? $t('No es un valor válido') : ''"
              :state="isValidInteger(filter.upc.desde, false)"
              :label="$t('Desde')"
              label-for="upcDesdeFormatter">
              <b-input-group>
                <b-form-input id="upcDesdeInputFormatter" v-model="filter.upc.desde"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="4" class="px-4 py-2">
            <b-form-group id="inventoryFromQuantityInput"
              horizontal
              :label-cols="4"
              breakpoint="md"
              :invalid-feedback="!isValidInteger(filter.fromQuantity, false)? $t('No es un valor válido') : ''"
              :state="isValidInteger(filter.fromQuantity, false)"
              :label="$t('Desde')"
              label-for="inventoryFromQuantity">
              <b-input-group>
                <b-form-input id="inventoryFromQuantity" v-model="filter.fromQuantity"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" class="px-4 py-2">
            <b-form-group id="skuHastaInput"
              horizontal
              :label-cols="4"
              breakpoint="md"
              :label="$t('Hasta')"
              :invalid-feedback="$t(validIntegerCompare(filter.sku.hasta, filter.sku.desde, false))"
              :state="validIntegerCompare(filter.sku.hasta, filter.sku.desde, false) === ''"
              label-for="skuHastaFormatter">
              <b-input-group>
                <b-form-input id="skuHastaInputFormatter" v-model="filter.sku.hasta"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="4" class="px-4 py-2">
            <b-form-group id="upcHastaInput"
                horizontal
                :label-cols="4"
                breakpoint="md"
                :invalid-feedback="$t(validIntegerCompare(filter.upc.hasta, filter.upc.desde, false))"
                :state="validIntegerCompare(filter.upc.hasta, filter.upc.desde, false) === ''"
                :label="$t('Hasta')"
                label-for="upcHastaFormatter">
              <b-input-group>
                <b-form-input id="upcHastaInputFormatter" v-model="filter.upc.hasta"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="4" class="px-4 py-2">
            <b-form-group id="inventoryToQuantityInput"
              horizontal
              :label-cols="4"
              breakpoint="md"
              :label="$t('Hasta')"
              :invalid-feedback="$t(validIntegerCompare(filter.toQuantity, filter.fromQuantity, false))"
              :state="validIntegerCompare(filter.toQuantity, filter.fromQuantity, false) === ''"
              label-for="inventoryToQuantity">
              <b-input-group>
                <b-form-input id="inventoryToQuantity" v-model="filter.toQuantity"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
          <b-row>
            <b-col lg="4" class="px-4 py-2">
              <b-form-group id="categoryInput"
                horizontal
                :label-cols="4"
                breakpoint="md"
                :label="$t('Categoria')"
                label-for="categoryFormatter">
                <category-tree id='selectCategory'
                  :categorySelected="filter.category"
                  @onCategorySelected="selectCategory">
                </category-tree>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-button class="float-right mr-2" :disabled="loading || disableFilter" variant="primary" @click.stop="filterTable" >{{$t('Filtrar')}}</b-button>
              <b-button class="float-right mr-2" :disabled="loading" variant="primary" @click.stop="cleanFilters" >{{$t('Mostrar Todos')}}</b-button>
              <b-button class="float-right mr-2" :disabled="loading || disableFilter" variant="primary" @click.stop="exportCSV" >{{$t('Exportar CSV')}}</b-button>
            </b-col>
          </b-row>
      </b-card>
      <b-card id="mainCard" class="mt-3" header-bg-variant="primary" border-variant="primary" :header="$t('Listado de inventario actual')">
        <b-row class="mt-3">
          <b-col class="col-md-auto">
            <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="mb-3" />
          </b-col>
          <b-col class="col-md-auto per-page">
            <b-form-group id="perPage" horizontal :label="$t('Mostrar por página')">
              <b-form-select :options="pageOptions" v-model="perPage" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-md-auto" align-self="start">
            {{$t('Elementos de inventario encontrados')}} <b>{{totalRows}}</b>
          </b-col>
        </b-row>
        <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
        <b-table class="mt-3" v-show="!loading" id="productTable"
          ref="productTable"
          show-empty
          striped
          hover
          small
          stacked="lg"
          :items="itemProvider"
          foot-clone
          :fields="fieldsProduct"
          :current-page="currentPage"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @filtered="onFiltered"
          responsive=true
          :empty-text="$t('No hay inventario para mostrar')"
          :empty-filtered-text="$t('No hay invetario para mostrar')">
          <template slot="category" slot-scope="row">
            {{row.item.category.name}}
          </template>
          <template slot="brand" slot-scope="row">
            {{row.item.brand.name}}
          </template>
          <template slot="action" slot-scope="row">
            <b-button v-if="row.item.skus.length > 0" size="sm" variant="link" @click.stop="row.toggleDetails">
              <span v-show="!row.detailsShowing"><i class="fa fa-plus"></i></span>
              <span v-show="row.detailsShowing"><i class="fa fa-minus"></i></span>
              {{ row.detailsShowing ? $t('Ocultar') : $t('Ver')}} {{$t('Variantes')}} ({{row.item.skus.length}})
            </b-button>
            <span v-else><b>{{$t('No contiene variantes')}}</b></span>
          </template>
          <template slot="row-details" slot-scope="row">
            <b-card>
              <b-table v-show="!loading" id="productTable"
                ref="variantTable"
                show-empty
                hover
                :items="row.item.skus"
                :fields="fieldsVariant"
                responsive=true
                stacked="xl"
                :empty-text="$t('No hay variantes para mostrar')"
                :empty-filtered-text="$t('No hay variantes para mostrar')">
                <template  slot="inventory" slot-scope="row">
                  <div class="inventoryContainer" v-b-tooltip.hover :title="$t('La cantidad entre parentesis es stock reservado')">
                    <span class="inventoryTotal">{{row.item.inventory.totalQuantity}}</span>
                    <span class="inventoryReserved">({{row.item.inventory.reservedQuantity ? row.item.inventory.reservedQuantity : 0}})</span>
                  </div>
                </template>
                <template slot="price" slot-scope="row">
                  {{currency}} {{row.item.price.listPrice}}<br>
                  <span class="discount">({{currency}} {{row.item.price.price}})</span><br>
                  {{row.item.price.priceValidUntil}}
                </template>
                <template slot="status" slot-scope="data">
                  <div v-for="smks in data" :key="smks.id">
                    <div v-for="smk in smks.marketplaces" :key="smk.id" style="display: flex; flex-direction: column; margin-bottom: 6px;">
                      <b class="text-center" style="font-size: 12px;">{{ smk.name }}</b>
                      <b v-if="smk.status === 'APPROVED'" class="text-success text-center" style="margin-top: -4px;">
                        {{ $t(estados.find(p => p.value === smk.status).text) }}
                      </b>
                      <b v-if="smk.status === 'REJECTED'" class="text-danger text-center" style="margin-top: -4px;">
                        {{ $t(estados.find(p => p.value === smk.status).text) }}
                      </b>
                      <b v-if="smk.status === 'PENDING_APPROVAL'" class="text-warning text-center" style="margin-top: -4px;">
                        {{ $t(estados.find(p => p.value === smk.status).text) }}
                      </b>
                    </div>
                  </div>
                </template>
              </b-table>
            </b-card>
          </template>
        </b-table>
        <b-row class="mb-3">
          <b-col md="6" class="my-1">
            <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import appConfig from '@/config/applicationConfig.js'
import CategoryTree from '../catalog/CategoryTree'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import {InventoryServiceMixim} from '@/mixims/InventoryServiceMixim.js'
import {ProductServiceMixim, PRODUCT_STATES} from '@/mixims/ProductServiceMixim.js'
import { Utils } from '@/mixims/Utils'
import {ValidationsMixim} from '@/mixims/ValidationsMixim.js'

export default {
  name: 'inventory-report',
  components: { MoonLoader, CategoryTree },
  mixins: [InventoryServiceMixim, ValidationsMixim, ProductServiceMixim, Utils],
  props: ['rangeDate', 'account', 'marketplace'],
  watch: {
    rangeDate: function (val, oldVal) {
      this.filter.fecha.desde = val[0]
      this.filter.fecha.hasta = val[1]
      this.$refs.productTable.refresh()
    },
    account: function (val, oldVal) {
      this.filter.seller = val
      this.$refs.productTable.refresh()
    },
    marketplace: function (val, oldVal) {
      this.filter.marketplaces = val
      this.$refs.productTable.refresh()
    }
  },
  data () {
    return {
      fieldsVariant: [
        { key: 'skuName', label: this.$t('Nombre'), 'class': 'text-left' },
        { key: 'upc', label: this.$t('UPC'), 'class': 'text-left' },
        { key: 'id', label: this.$t('SKU'), 'class': 'text-left' },
        { key: 'price', label: this.$t('Precio'), 'class': 'text-left' },
        { key: 'inventory', label: this.$t('Inventario'), 'class': 'text-left' },
        { key: 'status', label: this.$t('Estado'), 'class': 'text-center' }
      ],
      fieldsProduct: [
        { key: 'id', label: this.$t('Id'), 'class': 'text-left', sortable: true },
        { key: 'name', label: this.$t('Nombre'), 'class': 'text-left', sortable: true },
        { key: 'category', label: this.$t('Categoría'), 'class': 'text-left', sortable: true },
        { key: 'brand', label: this.$t('Marca'), 'class': 'text-left', sortable: true },
        { key: 'skus', label: this.$t('Estado'), 'class': 'text-left', sortable: true, formatter: this.productTableState },
        { key: 'action', label: ' ', 'class': 'text-right', sortable: false }
      ],
      isAdmin: this.$store.getters.isAdmin,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [ 10, 15, 25, 50, 100 ],
      sortBy: null,
      locale: appConfig.LOCALE,
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : '$',
      sortDesc: false,
      filter: {
        sku: {desde: null, hasta: null},
        upc: {desde: null, hasta: null},
        fecha: {desde: null, hasta: null},
        category: {id: null, text: null},
        fromQuantity: null,
        toQuantity: null,
        account: null,
        marketplaces: null
      },
      tableFilterInfo: null,
      loading: false,
      estados: PRODUCT_STATES
    }
  },
  computed: {
    disableFilter () {
      if (
        !this.isValidInteger(this.filter.sku.desde, false) ||
        this.validIntegerCompare(this.filter.sku.hasta, this.filter.sku.desde, false) !== '' ||
        !this.isValidInteger(this.filter.upc.desde, false) ||
        this.validIntegerCompare(this.filter.upc.hasta, this.filter.upc.desde, false) !== '' ||
        !this.isValidInteger(this.filter.fromQuantity, false) ||
        this.validIntegerCompare(this.filter.toQuantity, this.filter.fromQuantity, false) !== '') {
        return true
      }
      return false
    },
    sortOptions () {
      /* Create an options list from our fields */
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    }
  },
  methods: {
    productTableState (value, key, item) {
      let skus = item.skus
      for (let sku in skus) {
        if (skus[sku].status !== PRODUCT_STATES[0].value) {
          return this.$t('Ver SKUs')
        }
      }

      return this.$t('Activo')
    },
    cleanFilters () {
      this.filter = {
        account: null,
        name: null,
        estado: null,
        sku: {desde: null, hasta: null},
        upc: {desde: null, hasta: null},
        fecha: {desde: null, hasta: null},
        category: {id: null, text: null}
      }
      this.$refs.productTable.refresh()
    },
    selectCategory (res) {
      this.filter.category = res
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filterTable () {
      this.$refs.productTable.refresh()
    },
    exportCSV () {
      this.loading = true
      this.$emit('error', '')
      return this.getInventoryCSV(this.filter).then(
        (csvFile) => {
          this.loading = false
          this._exportCSV(csvFile, 'inventoryReportCSV.csv')
        },
        (error) => {
          this.loading = false
          let errorText = error.body.message || this.$t('Error obteniendo el reporte de pedidos')
          this.$emit('error', errorText)
        }
      )
    },
    itemProvider (tableFilterInfo) {
      this.tableFilterInfo = tableFilterInfo
      this.loading = true
      return this.getProducts(tableFilterInfo, this.filter).then(
        (response) => {
          this.loading = false
          this.totalRows = parseInt(response.headers.get('X-Total-Count'))
          console.log(response.body)
          return response.body
        },
        (error) => {
          this.loading = false
          let errorText = error.body.message || this.$t('Error obteniendo el inventario')
          this.$emit('error', errorText)
          return []
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
  .product-list {
    .btn-link {
      color: #666666;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }

    tr:hover {
      cursor: pointer
    }

    .inventoryContainer {
      display: inline-block;

      .inventoryTotal {
        font-weight: bolder;
        font-size: 16px;
      }

      .inventoryReserved {
        color: #777;
      }
    }

    .priceCol {
      .discount {
        font-size: 12px;
        font-weight: bolder;
      }
    }
  }
</style>
