<template>
  <b-container fluid>
    <div>
      <b-modal header-bg-variant="primary" centered size="lg" @hide="onHide" ref="controversyModal" id="controversyModal" :title="$t('Detalles Controversia')" o-close-on-backdrop="true" :ok-title="$t('Cerrar')" ok-only>
        <moon-loader :loading="controversyData.actionInProgress" color="#000" size="30px"></moon-loader>
        <b-row class="alert alert-danger" v-show="errorText !== ''"><i class="fa fa-exclamation-circle"></i>{{errorText}}</b-row>
            <!-- Controversy notes -->
        <b-card :title="$t('Notas')">
          <ul v-if="controversySelected">
            <li class="controversy-note" v-for="(comment, index) in controversySelected.comments" :key="index">
              <b>{{new Date(comment.date).toLocaleDateString(locale)}}</b> - {{comment.text}}
            </li>
          </ul>
        </b-card>
      </b-modal>
    </div>
  </b-container>
</template>

<script>

import {ControversyServiceMixim} from '../../mixims/ControversyServiceMixim.js'
import {OrdersServiceMixim} from '../../mixims/OrdersServiceMixim'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import appConfig from '../../config/applicationConfig.js'
export default {
  name: 'controversy-detail-modal',
  components: {
    MoonLoader
  },
  mixins: [ControversyServiceMixim, OrdersServiceMixim],
  props: ['visible', 'controversySelected', 'order'],
  data () {
    return {
      title: null,
      errorText: '',
      controversyData: {
        text: null,
        actionInProgress: false
      },
      fileNotSupported: null,
      imageData: null,
      image: null,
      locale: appConfig.LOCALE
    }
  },
  watch: {
    visible (newVal, oldVal) {
      if (this.visible === true) {
        this.$refs.controversyModal.show()
      }
    }
  },
  methods: {
    onHide (evt) {
      this.$emit('onControversyModalHide')
    }
  }
}
</script>

<style lang="scss" scoped>
  p {
    margin-bottom: 2px;
  }

 .fa-exclamation-circle {
   margin-top: 3px;
   margin-right: 3px;
 }

  img.preview {
    width: 180px;
    background-color: white;
    border: 1px solid #DDDDDD;
    padding: 5px;
    cursor: pointer;
  }
</style>
