<script>
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import localeArg from './es-ar.json'
import localeEn from './en-gr.json'
import localePt from './pt-br.json'

import config from '../config/applicationConfig'

Vue.use(VueI18n)

let url = document.location.href
url = url.replace('#', '')
url = new URL(url)
let urlLocale = new URLSearchParams(url.search).get('locale')
const browserLang = navigator.language || navigator.userLanguage
let userLang = ''
switch (browserLang) {
  case browserLang.toLowerCase().indexOf('es-') > -1:
    userLang = 'es-ar'
    break
  case browserLang.toLowerCase().indexOf('en-') > -1:
    userLang = 'en-gr'
    break
  case browserLang.toLowerCase().indexOf('pt-') > -1:
    userLang = 'pt-br'
    break
  default:
    userLang = 'es-ar'
    break
}
if (urlLocale) {
  localStorage.setItem('locale', urlLocale)
}
const localeLocalStg = localStorage.getItem('locale')
urlLocale = localeLocalStg
// prioridades lenguajes: preferencia por url, browser lang, applicationConfig
export default new VueI18n({
  locale: urlLocale !== null ? urlLocale : userLang || config.LOCALE,
  messages: {
    'es-ar': localeArg,
    'es-CO': localeArg,
    'es-MX': localeArg,
    'en-gr': localeEn,
    'pt-br': localePt
  }
})
</script>