<template>
<div>
  <div>
    <div v-show="errorText !== ''" v-html="errorText" class="alert alert-danger" role="alert">
      {{errorText}}
    </div>
    <div v-show="successText !== ''" class="alert alert-success" role="alert">
      {{successText}}
    </div>
  </div>
  <moon-loader :loading="inProgress" color="#666" size="30px"></moon-loader>
  <b-row>
    <b-col>
      <p>{{$t('Especificaciones productos')}}</p>
      <b-button :disabled="inProgress || disableProducts" class="float-left ml-5 pr-5 pl-5" @click="migrate('products')" variant="primary">{{$t('Actualizar')}}</b-button>
    </b-col>
  </b-row>
  <b-row>
    <b-col  class="mt-5">
      <p>{{$t('Especificaciones variantes')}}</p>
      <b-button :disabled="inProgress || disableVariants" class="float-left ml-5 pr-5 pl-5" @click="migrate('variants')" variant="primary">{{$t('Actualizar')}}</b-button>
    </b-col>
  </b-row>
</div>
</template>

<script>
import appConfig from '@/config/applicationConfig.js'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

export default {
  name: 'attributes-updater',
  components: {
    MoonLoader
  },
  mixins: [],
  methods: {
    migrate (item) {
      this.inProgress = true
      this.errorText = ''
      this.successText = ''
      let endpoint = `${appConfig.API_END_POINT}/specifications/migrate?isStockKeepingUnit=${item === 'products' ? 'false' : 'true'}`
      console.log(this.$store.state.access_token)
      return this.$http.get(endpoint,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      ).then(
        () => {
          this.inProgress = false
          this.successText = `${this.$t('La actualización de')} ${item === 'products' ? this.$t('productos (atributos)') : this.$t('variantes (atributos)')} ${this.$t('ha comenzado, validar en 15 minutos (aprox.)')}`
          if (item === 'product') {
            this.disableProduct = true
          } else {
            this.disableVariants = true
          }
        },
        (error) => {
          this.inProgress = false
          this.errorText = error.body.message || `${this.$t('Error migrando los datos para')} ${item === 'products' ? this.$t('Productos') : this.$t('Variantes')} ${this.$t('a Seller Center')}`
        }
      )
    }
  },
  data () {
    return {
      inProgress: false,
      successText: '',
      errorText: '',
      disableProducts: false,
      disableVariants: false
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
