<template>
  <div class='animated fadeIn'>
    <div v-show="errorText !== ''" class="alert alert-danger" role="alert">
      {{$t(errorText)}}
    </div>
    <b-row>
      <b-col><strong>{{$t('Fecha')}}</strong></b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group :label="$t('Desde')" class="mb-0" :description="$t('Fecha de inicio para filtrar datos')" :state="invalidDateFrom === '' ? true : false" :invalid-feedback="invalidDateFrom">
          <b-input-group>
            <date-picker v-on:contextmenu.native.stop.prevent v-on:keydown.native.stop.prevent v-model="fromDate" :config="datePickerConfig1"></date-picker>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col  md="3">
        <b-form-group :label="$t('Hasta')" class="mb-0" :description="$t('Fecha final para filtrar datos')" :state="invalidDateTo === '' ? true : false" :invalid-feedback="invalidDateTo">
          <b-input-group>
            <date-picker v-on:contextmenu.native.stop.prevent v-on:keydown.native.stop.prevent v-model="toDate" :config="datePickerConfig2"></date-picker>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group :label="$t('Sitio')" class="mb-3" :description="$t('Seleccione el sitio para filtrar datos')">
          <b-form-select :options="optionSites" v-model="siteName">
            <option slot="first" :value="null">{{$t('Todos')}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="4" v-if="isAdmin">
        <b-form-group :label="$t('Seller_label')" class="mb-3">
          <b-form-select :options="organizations" v-model="account">
            <option slot="first" :value="null">{{$t('Todos')}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col xl="12">
        <h4 class="my-5">{{$t('Dashboard de ordenes')}} [{{totalPedidos !== null ? totalPedidos : $t('espere..')}}]</h4>
        <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
      </b-col>
      <b-col xl="12">
        <b-card-group deck>
          <b-card v-bind:class="{active: ordeStatus === 'ready-for-handling'}" class="mt-3 cardCliqueable" @click="changeOrderStatus('ready-for-handling')" header-bg-variant="primary" border-variant="primary" align="center" :header="$t('Para manejo')">
            <div v-b-tooltip.hover.bottom :title="$t('Click para filtrar por pedidos pagos')" class="number">{{cardStatistics.readyForHandling}}</div>
          </b-card>
          <b-card v-bind:class="{active: ordeStatus === 'payment-pending'}" class="mt-3 cardCliqueable" @click="changeOrderStatus('payment-pending')" align="center" header-bg-variant="primary" border-variant="primary" :header="$t('No pagada')">
            <div v-b-tooltip.hover.bottom :title="$t('Click para filtrar por pedidos no pagados')" class="number">{{cardStatistics.paymentPending}}</div>
          </b-card>
          <b-card v-bind:class="{active: ordeStatus === 'handling'}" class="mt-3  cardCliqueable" @click="changeOrderStatus('handling')" align="center" header-bg-variant="primary" border-variant="primary" :header="$t('Para envío')">
            <div v-b-tooltip.hover.bottom :title="$t('Click para filtrar por pedidos listos para envio')" class="number">{{cardStatistics.handling}}</div>
          </b-card>
          <b-card v-bind:class="{active: ordeStatus === 'invoiced'}" xl="3" class="mt-3 cardCliqueable" @click="changeOrderStatus('invoiced')"  align="center" header-bg-variant="primary" border-variant="primary" :header="$t('Enviada')">
            <div v-b-tooltip.hover.bottom :title="$t('Click para filtrar por pedidos enviados')" class="number">{{cardStatistics.invoiced}}</div>
          </b-card>
          <b-card v-bind:class="{active: ordeStatus === 'delivered'}" class="mt-3 cardCliqueable" @click="changeOrderStatus('delivered')" align="center" header-bg-variant="primary" border-variant="primary" :header="$t('Entregada')">
            <div v-b-tooltip.hover.bottom :title="$t('Click para filtrar por pedidos entregados')" class="number">{{cardStatistics.delivered}}</div>
          </b-card>
          <b-card v-bind:class="{active: ordeStatus === 'canceled'}" class="mt-3 cardCliqueable" @click="changeOrderStatus('canceled')" align="center" header-bg-variant="primary" border-variant="primary" :header="$t('Cancelada')">
            <div v-b-tooltip.hover.bottom :title="$t('Click para filtrar por pedidos cancelados')" class="number">{{cardStatistics.canceled}}</div>
          </b-card>
          <b-card  v-bind:class="{active: ordeStatus === 'return'}" class="mt-3 cardCliqueable" @click="changeOrderStatus('return')" align="center" header-bg-variant="primary" border-variant="primary" :header="$t('Devolución')">
            <div v-b-tooltip.hover.bottom :title="$t('Click para filtrar por pedidos con devolución')" class="number">{{cardStatistics.return}}</div>
          </b-card>
          <b-card  v-bind:class="{active: ordeStatus === 'incidence'}" class="mt-3 cardCliqueable" @click="changeOrderStatus('controversy')" align="center" header-bg-variant="primary" border-variant="primary" :header="$t('Incidencias')">
            <div v-b-tooltip.hover.bottom :title="$t('Click para filtrar por pedidos con incidencias')" class="number">{{cardStatistics.incidence}}</div>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
    <b-row v-show="!loading && (!totalPedidos || totalPedidos === 0)">
      <b-col md="12" class="mt-3">
        <p class="alert alert-warning">{{$t('No hay informacion sobre ordenes disponible para graficar')}}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="7" >
        <main-line-chart ref="graphLine" :chart-data="lines" class='chart-wrapper' style='height:400px;margin-top:40px;' height='400'></main-line-chart>
      </b-col>
      <b-col md="5" >
        <main-pie-chart  ref="graphPie" :chart-data="pie" class='chart-wrapper' style='height:400px;margin-top:40px;' height='400'></main-pie-chart>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" >
        <h4 class="mt-3">{{$t('Listado de ordenes')}}</h4>
        <h6 class="mb-3">{{$t('Se muestran las últimas 10 ordenes dentro del rango seleccionado.')}}</h6>
        <ordersReport ref="ordersReport" @total="setTotal" :account="account" :fromDate="fromDate" :toDate="toDate" @error="showError"></ordersReport>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import datePicker from 'vue-bootstrap-datetimepicker'
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'
import OrdersReport from '@/components/dashboard/OrdersReport'
import MainLineChart from '@/components/dashboard/MainLineChart'
import MainPieChart from '@/components/dashboard/MainPieChart'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import {OrdersServiceMixim} from '@/mixims/OrdersServiceMixim'
import {UserServiceMixim} from '@/mixims/UserServiceMixim.js'
import appConfig from '@/config/applicationConfig.js'
import { MarketplaceServiceMixim } from '@/mixims/MarketplaceServiceMixim.js'

Vue.use(datePicker)

export default {
  name: 'dashboard-view',
  mixins: [OrdersServiceMixim, UserServiceMixim, MarketplaceServiceMixim],
  components: {
    OrdersReport,
    MainLineChart,
    MainPieChart,
    MoonLoader
  },
  watch: {
    fromDate: function (val, oldVal) {
      if (this.muted || this.invalidDateFrom !== '' || this.invalidDateTo !== '') return
      this._getGraphData()
      this._getStatusstatistics()
    },
    toDate: function (val, oldVal) {
      if (this.muted || this.invalidDateTo !== '') return
      this._getGraphData()
      this._getStatusstatistics()
    },
    account: function (val, oldVal) {
      this._getGraphData()
      this._getStatusstatistics()
    },
    siteName: function (val, oldVal) {
      this._getGraphData()
      this._getStatusstatistics()
    }
  },
  data () {
    return {
      isAdmin: this.$store.getters.isAdmin,
      props: ['graph'],
      datePickerConfig1: {
        format: 'YYYY-MM-DD',
        useCurrent: false,
        icons: {
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right'
        }
      },
      datePickerConfig2: {
        format: 'YYYY-MM-DD',
        useCurrent: false,
        icons: {
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right'
        }
      },
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).toISOString().split('T')[0],
      toDate: new Date().toISOString().split('T')[0],
      account: null,
      locale: appConfig.LOCALE,
      inactivityTime: appConfig.INACTIVITY_PERMITED_TIME,
      errorText: '',
      totalPedidos: null,
      loading: false,
      muted: true,
      ordeStatus: '',
      cardStatistics: {
        readyForHandling: 0,
        paymentPending: 0,
        invoiced: 0,
        delivered: 0,
        canceled: 0,
        controversy: 0,
        incidence: 0,
        handling: 0,
        return: 0
      },
      lines: {
        labels: [],
        datasets: [{
          label: this.$t('Ordenes creadas'),
          data: [],
          backgroundColor: 'rgba(0, 255, 17, 0.2)',
          pointBackgroundColor: 'white',
          borderWidth: 2,
          borderColor: 'rgba(0, 255, 17, 1)',
          pointBorderColor: 'rgba(0, 255, 17, 0)'
        }]
      },
      pie: {
        labels: [],
        datasets: [{
          label: this.$t('Estado de ordenes'),
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
          borderColor: 'rgba(255, 255, 255, 1)',
          hoverBorderColor: 'rgba(255, 255, 255, 1)',
          borderWidth: 2
        }]
      },
      optionSites: [],
      siteName: null
    }
  },
  computed: {
    organizations () {
      return this.$store.getters.organizations
    },
    invalidDateTo () {
      if (this.toDate) {
        let tDate = new Date(this.toDate)
        if (tDate > new Date()) {
          return this.$t('La fecha seleccionada es mayor al día de hoy')
        }
        if (this.fromDate) {
          let fDate = new Date(this.fromDate)
          return fDate > tDate ? this.$t('La fecha final es menor a la fecha inicial') : ''
        }
      }
      return ''
    },
    invalidDateFrom () {
      if (this.fromDate) {
        let fDate = new Date(this.fromDate)
        if (fDate > new Date()) {
          return this.$t('La fecha seleccionada es mayor al día de hoy')
        }
      }
      return ''
    }
  },
  methods: {
    startTimer () {
      window.timeoutId = window.setTimeout(this.doInactive, this.inactivityTime)
    },
    resetTimer () {
      window.clearTimeout(window.timeoutId)
      this.startTimer()
    },
    doInactive () {
      this.logout()
    },
    setupTimers () {
      document.addEventListener('mousemove', this.resetTimer, false)
      document.addEventListener('mousedown', this.resetTimer, false)
      document.addEventListener('keypress', this.resetTimer, false)
      document.addEventListener('touchmove', this.resetTimer, false)
      this.startTimer()
    },
    changeOrderStatus (ordeStatus) {
      this.ordeStatus = ordeStatus
      this.$refs.ordersReport.changeStatusExternally(ordeStatus)
    },
    setTotal (total) {
      this.totalPedidos = total
    },
    showError (errorText) {
      this.errorText = errorText
    },
    _getStatusstatistics () {
      // this.loading = true
      this.getStatusstatistics(this.account, this.fromDate, this.toDate, this.siteName).then(
        (data) => {
          this.loading = false
          this.pie.datasets[0].data.splice(0, this.pie.datasets[0].data.length)
          this.pie.labels = []

          this.cardStatistics.readyForHandling = 0
          this.cardStatistics.paymentPending = 0
          this.cardStatistics.invoiced = 0
          this.cardStatistics.delivered = 0
          this.cardStatistics.canceled = 0
          this.cardStatistics.controversy = 0
          this.cardStatistics.incidence = 0
          this.cardStatistics.handling = 0
          this.cardStatistics.return = 0

          data.body.forEach((item) => {
            this.cardStatistics[this._camelize(item.key)] = item.count
          })
          this.pie.labels.push(this.$t('Entregada'))
          this.pie.datasets[0].data.push(this.cardStatistics['delivered'])
          this.pie.datasets[0].backgroundColor.push('rgba(254, 208, 22, 0.2)')
          this.pie.datasets[0].hoverBackgroundColor.push('rgba(254, 208, 22, 0.6)')

          this.pie.labels.push(this.$t('Cancelada'))
          this.pie.datasets[0].data.push(this.cardStatistics['canceled'])
          this.pie.datasets[0].backgroundColor.push('rgba(252, 45, 22, 0.2)')
          this.pie.datasets[0].hoverBackgroundColor.push('rgba(252, 45, 22, 0.6)')

          this.pie.labels.push(this.$t('Para envío'))
          this.pie.datasets[0].data.push(this.cardStatistics['handling'])
          this.pie.datasets[0].backgroundColor.push('rgba(0, 255, 17, 0.2)')
          this.pie.datasets[0].hoverBackgroundColor.push('rgba(0, 255, 17, 0.6)')

          this.pie.labels.push(this.$t('Enviada'))
          this.pie.datasets[0].data.push(this.cardStatistics['invoiced'])
          this.pie.datasets[0].backgroundColor.push('rgba(149, 249, 191, 0.2)')
          this.pie.datasets[0].hoverBackgroundColor.push('rgba(149, 249, 191, 0.6)')

          this.pie.labels.push(this.$t('No pagada'))
          this.pie.datasets[0].data.push(this.cardStatistics['paymentPending'])
          this.pie.datasets[0].backgroundColor.push('rgba(22, 137, 252, 0.2)')
          this.pie.datasets[0].hoverBackgroundColor.push('rgba(22, 137, 252, 0.6)')

          this.pie.labels.push(this.$t('Para manejo'))
          this.pie.datasets[0].data.push(this.cardStatistics['readyForHandling'])
          this.pie.datasets[0].backgroundColor.push('rgba(225, 22, 252, 0.2)')
          this.pie.datasets[0].hoverBackgroundColor.push('rgba(225, 22, 252, 0.6)')

          this.$refs.graphPie.updateGraph()
        },
        (error) => {
          this.loading = false
          this.errorText = error.body.message || this.$t('Error obteniendo las estadisticas')
        }
      )
    },
    _getGraphData () {
      this.loading = true
      this.intervalstatistics(this.account, this.fromDate, this.toDate, this.siteName).then(
        (data) => {
          this.lines.datasets[0].data.splice(0, this.lines.datasets[0].data.length)
          this.lines.labels = []
          data.body.forEach((item) => {
            this.lines.labels.push(new Date(item.key).toLocaleDateString(this.locale))
            this.lines.datasets[0].data.push(item.count)
          })
          this.$refs.graphLine.updateGraph()
        },
        (error) => {
          this.errorText = error.body.message || this.$t('Error obteniendo las estadisticas')
        }
      )
    },
    _camelize (str) {
      return str.replace(/\W+(.)/g,
        (match, chr) => {
          return chr.toUpperCase()
        }
      )
    },
    _getMarketplaces () {
      let self = this
      this.getMarketplaces(0, 100).then(
        (res) => {
          self.$store.dispatch('setMarketplaces', res.body)
          for (let i = 0; i < res.body.length; i++) {
            self.optionSites.push({'value': res.body[i].id, 'text': res.body[i].name})
          }
        },
        (error) => {
          this.errorText = error.body.message || 'Ocurrió un error el guardar el seller'
        }
      )
    }
  },
  mounted () {
    this._getMarketplaces()
    this._getStatusstatistics()
    this._getGraphData()
    // this.startTimer()
    this.muted = false
  }
}
</script>

<style lang="scss" scoped>
  .number {
    font-weight: bold;
    font-size: 40px;
  }

  .cardCliqueable {
    cursor: pointer;
   }

  .card-deck {
      .card {
        min-width: 145px !important;
      }
  }

</style>
