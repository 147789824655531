import { render, staticRenderFns } from "./ShippingGratesList.vue?vue&type=template&id=4818bc06&scoped=true&"
import script from "./ShippingGratesList.vue?vue&type=script&lang=js&"
export * from "./ShippingGratesList.vue?vue&type=script&lang=js&"
import style0 from "./ShippingGratesList.vue?vue&type=style&index=0&id=4818bc06&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4818bc06",
  null
  
)

export default component.exports