<template>
  <div class="animated fadeIn">
    <b-container fluid>
      <b-card id="mainCard" class="mt-3" header-bg-variant="primary" border-variant="primary" :header="$t('Listado de inventario actual')">
        <b-row class="mt-3">
          <b-col class="col-md-auto">
            <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="mb-3" />
          </b-col>
          <b-col class="col-md-auto per-page">
            <b-form-group id="perPage" horizontal :label="$t('Mostrar por página')">
              <b-form-select :options="pageOptions" v-model="perPage" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-button class="float-right mr-2" variant="primary" @click.stop="exportCSV" >{{$t('Exportar CSV')}}</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-md-auto" align-self="start">
            {{$t('Ventas encontrados')}} <b>{{totalRows}}</b>
          </b-col>
        </b-row>
        <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
        <b-table class="mt-3" v-show="!loading" id="mainTable"
          ref="mainTable"
          show-empty
          striped
          hover
          :items="itemProvider"
          foot-clone
          stacked="xl"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @filtered="onFiltered"
          responsive=true
          :empty-text="$t('No hay ventas para mostrar')"
          :empty-filtered-text="$t('No hay ventas para mostrar')">
          <template slot="date" slot-scope="row">
            {{new Date(row.item.date).toLocaleDateString(locale)}}
          </template>
          <template slot="totalCanceled" slot-scope="row">
            {{currency}} {{row.item.totalCanceled}}
          </template>
          <template slot="totalSales" slot-scope="row">
            {{currency}} {{row.item.totalSales}}
          </template>
          <template slot="totalShipping" slot-scope="row">
            {{currency}} {{row.item.totalShipping}}
          </template>
        </b-table>
        <b-row class="mb-3">
          <b-col md="6" class="my-1">
            <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>

import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import {OrdersServiceMixim} from '../../mixims/OrdersServiceMixim'
import {CsvReportExportMixim} from './CsvReportExportMixim.js'
import appConfig from '../../config/applicationConfig.js'

export default {
  name: 'sales-report',
  components: { MoonLoader },
  mixins: [OrdersServiceMixim, CsvReportExportMixim],
  props: ['rangeDate', 'account', 'marketplace'],
  watch: {
    rangeDate: function (val, oldVal) {
      this.filter.fecha.desde = val[0]
      this.filter.fecha.hasta = val[1]
      this.getSalesRows()
    },
    account: function (val, oldVal) {
      this.filter.account = val
      this.getSalesRows()
    },
    marketplace: function (val, oldVal) {
      this.filter.marketplace = val
      this.getSalesRows()
    }
  },
  data () {
    return {
      fields: [
        { key: 'date', label: this.$t('Fecha'), 'class': 'text-left' },
        { key: 'ordersQuantity', label: this.$t('Ordenes'), 'class': 'text-left' },
        { key: 'itemsQuantity', label: this.$t('Items'), 'class': 'text-left' },
        { key: 'totalCanceled', label: this.$t('Total Canceladas'), 'class': 'text-left' },
        { key: 'totalSales', label: this.$t('Total Ventas'), 'class': 'text-left' },
        { key: 'totalShipping', label: this.$t('Total Envíos'), 'class': 'text-left' }
      ],
      isAdmin: this.$store.getters.isAdmin,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [ 10, 15, 25, 50, 100 ],
      sortBy: null,
      sortDesc: false,
      salesRows: [],
      filter: {
        fecha: {
          desde: null,
          hasta: null
        },
        account: null
      },
      disableFilterButton: false,
      tableFilterInfo: null,
      loading: false,
      locale: appConfig.LOCALE,
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : '$'
    }
  },
  computed: {
    sortOptions () {
      /* Create an options list from our fields */
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    }
  },
  methods: {
    itemProvider (tableComponentInfoCSV) {
      let start = (this.currentPage - 1) * this.perPage
      let end = this.perPage * this.currentPage
      return this.salesRows.slice(start, end)
    },
    exportCSV () {
      this.loading = true
      this.$emit('error', '')
      return this.getSalesReport(this.filter, true).then(
        (csvFile) => {
          this.loading = false
          this._exportCSV(csvFile, 'salesReportCSV.csv')
        },
        (error) => {
          this.loading = false
          let errorText = error.body.message || this.$t('Error obteniendo el reporte de ventas')
          this.$emit('error', errorText)
        }
      )
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filterTable () {
      this.$refs.mainTable.refresh()
    },
    getSalesRows () {
      this.disableFilterButton = true
      this.loading = true
      return this.getSalesReport(this.filter, false).then(
        (response) => {
          this.loading = false
          this.totalRows = response.body.length
          this.disableFilterButton = false
          this.salesRows = response.body
          this.currentPage = 1
          this.$refs.mainTable.refresh()
        },
        (error) => {
          this.loading = false
          let errorText = error.body.message || this.$t('Error obteniendo reporte de ventas')
          this.$emit('error', errorText)
          this.salesRows = []
        }
      )
    }
  },
  mounted () {
    this.getSalesRows()
  }
}
</script>
