<template>
  <b-container fluid class="animated fadeIn">
    <div v-show="errorText !== ''" class="alert alert-danger" role="alert" dismissible @dismised="errorText = ''">
      {{errorText}}
    </div>

    <b-row class="mb-3">
      <b-col md="12">
        <button  @click="showCreateModal" type="button" class="btn btn-primary float-right">{{$t('Crear nuevo precio')}}</button>
        <button type="button" class="btn btn-primary float-right mr-2"  @click.stop="showFilters = !showFilters">
          <i class="fa" :class="{'fa-times': showFilters, 'fa-filter': !showFilters}" /> {{$t('Filtros')}}
        </button>
      </b-col>
    </b-row>

    <transition name="fade">
      <div class="filters mt-3 p-3 mb-3" v-show="showFilters">
        <b-row>
          <b-col md="4" v-if="isAdmin">
            <b-form-group label="Seller" class="mb-3">
              <b-form-select :options="organizations" v-model="filters.accountName">
                <option slot="first" :value="null">{{$t('Todos')}}</option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" class="px-4 py-2">
            <b-form-group id="zipCodeStartGroup"
              :label-cols="4"
              breakpoint="md"
              :description="$t('Se buscaran precios que sean aplicables al código postal ingresado')"
              :invalid-feedback="!isValidInteger(filters.zipCode, false)? $t('No es un valor válido') : ''"
              :state="isValidInteger(filters.zipCode, false)"
              :label="$t('Código Postal')"
              label-for="zipCodeStartInput">
              <b-input-group>
                <b-form-input id="zipCodeStartInput" v-model="filters.zipCode"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="4" class="px-4 py-2">
            <b-form-group id="weightStartGroup"
              :label-cols="4"
              breakpoint="md"
              :label="$t('Peso')"
              :description="$t('Se buscaran precios que sean aplicables al peso ingresado')"
              :invalid-feedback="!isValidInteger(filters.weight, false)? $t('No es un valor válido') : ''"
              :state="isValidInteger(filters.weight, false)"
              label-for="weightStartInput">
              <b-input-group>
                <b-form-input id="weightStartInput" v-model="filters.weight"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="4" class="px-4 py-2">
            <b-form-group id="maxVolumeGroup"
              :label-cols="4"
              breakpoint="md"
              :description="$t('Se buscaran precios que sean aplicables al volumen ingresado')"
              :invalid-feedback="!isValidInteger(filters.maxVolume, false)? $t('No es un valor válido') : ''"
              :state="isValidInteger(filters.maxVolume, false)"
              :label="$t('Volumen del paquete')"
              label-for="maxVolumeInput">
              <b-input-group>
                <b-form-input id="maxVolumeInput" v-model="filters.maxVolume"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-input-group-button class="float-right mt-3">
              <b-button :disabled="loading || filterDisabled" :block="true" variant="primary" @click.stop="filterTable" >{{$t('Filtrar')}}</b-button>
            </b-input-group-button>
            <b-input-group-button class="float-right mt-3 mr-3">
              <b-button :disabled="loading" :block="true" variant="primary" @click.stop="cleanFilters" >{{$t('Mostrar Todos')}}</b-button>
            </b-input-group-button>
          </b-col>
        </b-row>
      </div>
    </transition>

    <b-row class="mt-3">
      <b-col class="col-md-auto">
        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="mb-3" />
      </b-col>
      <b-col class="col-md-auto">
        <b-form-group
          id="perPage"
          horizontal
          :label="$t('Mostrar por página')"
          >
          <b-form-select :options="pageOptions" v-model="perPage" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col md="10">
        <b>{{totalRows}}</b> {{$t('precios encontrados')}}  {{selected.length != 0 ? `(${selected.length} ${$t('seleccionados')})` : ""}}
      </b-col>
      <b-col>
        <button type="button" @click="showActionModal('Eliminar')" :disabled="selected.length === 0" class="btn btn-primary float-right mr-2">{{$t('Eliminar')}}</button>
      </b-col>
    </b-row>
    <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Listado de precios para envíos')">
      <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
        <b-col md="3" class="my-2 pl-0">
          <b-form-select
            class="action-form-select"
            id="selectProductGroup"
            :options="productGroupSelection.options"
            required
            v-model="productGroupSelection.current"
            @click.native.stop
            @change="productGroupSelectionHandler">
          </b-form-select>
        </b-col>
      <b-table v-show="!loading" id="mainTable"
         ref="mainTable"
         show-empty
         striped
         hover
         stacked="xl"
         :items="itemProvider"
         foot-clone
         :fields="isAdmin ? fieldsAdmin: fieldsNotAdmin"
         :current-page="currentPage"
         :per-page="perPage"
         :sort-by.sync="sortBy"
         :sort-desc.sync="sortDesc"
         @row-clicked="rowClick"
         @row-dblclicked="rowDbClick"
         @filtered="onFiltered"
         :empty-text="$t('No hay precios para mostrar')"
         :empty-filtered-text="$t('No hay precios para mostrar')">
         <template slot="selected" slot-scope="row">
            <b-form-checkbox class="ml-4" @click.native.stop @change="handleRowCheckbox" v-if="row.item.type !== 'ADMIN' || isAdmin" :value="row.item.id" v-model="selected"></b-form-checkbox>
         </template>
         <template slot="accountName" slot-scope="row">
            {{row.item.accountName? row.item.accountName : $t('No definido')}}
         </template>
         <template slot="carrier" slot-scope="row">
           {{row.item.carrier.name}} - {{row.item.carrier.slaType}}
         </template>
         <template slot="zipCodeStart" slot-scope="row" >
           <span v-if="row.item.zipCodeStart">{{row.item.zipCodeStart}} - {{row.item.zipCodeEnd}}</span>
           <span v-else>{{row.item.zipCodes.join(' ')}}</span>
         </template>
         <template slot="weightStart" slot-scope="row">
           {{row.item.weightStart}} - {{row.item.weightEnd}}
         </template>
         <template slot="absoluteMoneyCost" slot-scope="row">
           {{currency}} {{row.item.absoluteMoneyCost}}
         </template>
         <template slot="timeCost" slot-scope="row">
           {{row.item.timeCost}} {{$t('días')}}
         </template>
        <template slot="actions" slot-scope="row" >
          <b-button size="sm" @click.stop="showDeteleModal(row)" v-if="row.item.type !== 'ADMIN' || isAdmin">{{$t('Eliminar')}}</b-button>
        </template>
      </b-table>
    </b-card>

    <b-modal :ok-title="this.newPriceModel.id ? $t('Guardar') : $t('Crear')" :ok-disabled="saveDisabled || (newPriceModel.carrier.type === 'ADMIN' && !isAdmin)" header-bg-variant="primary" @ok="createPrice" size="lg" ref="newPriceModal" id="newPriceModal" centered :title="this.newPriceModel.id ? $t('Editar precio') : $t('Nuevo precio')">
      <b-row>
        <b-col lg="6">
           <b-form-group
            id="carrier"
            :label="$t('Transportadora')"
            >
            <b-form-select oncontextmenu="return false;" :options="carrierList" v-model="newPriceModel.carrier" :disabled="newPriceModel.carrier.type === 'ADMIN' && !isAdmin"/>
          </b-form-group>
        </b-col>
        <!-- <b-col lg="6">
          <b-form-group
            id="country"
            :label="$t('País')"
            >
            <b-form-select oncontextmenu="return false;" :options="countries" v-model="newPriceModel.country" :disabled="newPriceModel.carrier.type === 'ADMIN' && !isAdmin"/>
          </b-form-group>
        </b-col> -->
      </b-row>
      <b-row>
        <b-col class="my-3" lg="12"><b>{{$t('Costo de envío')}}</b></b-col>
        <b-col lg="6">
          <b-form-group
            id="absoluteMoneyCost"
            :label-cols="2"
            breakpoint="md"
            :description="$t('Tasa de envío según el rango y el peso del código postal')"
            :invalid-feedback="!isValidFloat(newPriceModel.absoluteMoneyCost, true)? $t('No es un valor válido') : ''"
            :state="isValidFloat(newPriceModel.absoluteMoneyCost, true)"
            :label="$t('Precio')"
            label-for="zipCodeStartInput">
            <b-form-input
              id="absoluteMoneyCostInput"
              v-model="newPriceModel.absoluteMoneyCost"
              :disabled="newPriceModel.carrier.type === 'ADMIN' && !isAdmin"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group
            id="timeCost"
            :label-cols="2"
            breakpoint="md"
            :label="rangeLabel"
            :invalid-feedback="!newPriceModel.timeCost? $t('El plazo de entrega es obligatorio') : ''"
            :state="newPriceModel.timeCost? true : false"
            label-for="timeCostInput">
            <b-form-input
              id="timeCostInput"
              type="range"
              v-model="newPriceModel.timeCost"
              :disabled="newPriceModel.carrier.type === 'ADMIN' && !isAdmin"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group
            id="maxVolume"
            :label-cols="2"
            breakpoint="md"
            :invalid-feedback="!isValidInteger(newPriceModel.maxVolume, true)? $t('No es un valor válido') : ''"
            :state="isValidInteger(newPriceModel.maxVolume, true)"
            :label="$t('Volumen máximo (Alto x Ancho x Largo - cm3)')"
            label-for="maxVolumeInput">
            <b-form-input
              id="timeCostInput"
              v-model="newPriceModel.maxVolume"
              :disabled="newPriceModel.carrier.type === 'ADMIN' && !isAdmin"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group
            id="priceByExtraWeight"
            :label-cols="2"
            breakpoint="md"
            :label="$t('Valor adicional por peso (cobro extra por gramo)')"
            :description="$t('Se agregará un cobro adicional por gramo al producto. Por ejemplo se agrega $1 adicional por peso y el producto pesa 10 gramos, se agregara $10 al total.')"
            :invalid-feedback="!this.isValidFloat(newPriceModel.priceByExtraWeight, true)? $t('No es un valor válido') : ''"
            :state="this.isValidFloat(newPriceModel.priceByExtraWeight, true)"
            label-for="priceByExtraWeightInput">
            <b-form-input
              id="timeCostInput"
              v-model="newPriceModel.priceByExtraWeight"
              :disabled="newPriceModel.carrier.type === 'ADMIN' && !isAdmin"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group
            id="pricePercent"
            :label-cols="2"
            breakpoint="md"
            :label="$t('Valor adicional (% del valor del producto)')"
            :description="$t('Un porcentaje del valor del producto que se agregará al valor de flete. Ejemplo: si el valor del producto es $100 y el 1% adicional, se agregará $1 al valor de flete.')"
            :invalid-feedback="!this.isValidFloat(newPriceModel.pricePercent, true)? $t('No es un valor válido') : ''"
            :state="this.isValidFloat(newPriceModel.pricePercent, true)"
            label-for="pricePercentInput">
            <b-form-input
              id="timeCostInput"
              v-model="newPriceModel.pricePercent"
              :disabled="newPriceModel.carrier.type === 'ADMIN' && !isAdmin"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="my-3 separator pt-3" lg="6"><b>{{$t('Código postal')}}</b></b-col>
        <b-col class="my-3 separator pt-3" lg="6">
        <b-form-group
          v-if="false" 
          id="commaModeGroup"
          :label-cols="2"
          breakpoint="md"
          :label="$t('¿Codigos postales separados por coma?')">
        <c-switch 
          inputType="checkbox" 
          type="text" 
          variant="success" 
          on="Si" 
          off="No" 
          :pill="true" 
          v-model="comaMode" 
          size="m" 
          id="s0"></c-switch>
        </b-form-group>
        </b-col>
        <b-col lg="6" v-if="!comaMode || true">
          <b-form-group
            id="zipCodeStart"
            :label-cols="2"
            breakpoint="md"
            :label="$t('Desde')"
            :invalid-feedback="!isEmptyData(newPriceModel.zipCodeStart) && !isValidCP(newPriceModel.zipCodeStart, true)? $t('No es un valor válido') : ''"
            :state="!isEmptyData(newPriceModel.zipCodeStart) && isValidCP(newPriceModel.zipCodeStart, true)"
            label-for="zipCodeStartInput">
            <b-form-input
              id="zipStartEndInput"
              maxlength="6"
              size="6"
              v-model="newPriceModel.zipCodeStart"
              :disabled="newPriceModel.carrier.type === 'ADMIN' && !isAdmin"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6" v-if="!comaMode || true">
          <b-form-group
            id="zipCodeEnd"
            :label-cols="2"
            breakpoint="md"
            :label="$t('Hasta')"
            :invalid-feedback="!isEmptyData(newPriceModel.zipCodeEnd) ? $t(validCPCompare(newPriceModel.zipCodeEnd, newPriceModel.zipCodeStart, true)) : ''"
            :state="!isEmptyData(newPriceModel.zipCodeEnd) && validCPCompare(newPriceModel.zipCodeEnd, newPriceModel.zipCodeStart, true) === ''"
            label-for="zipCodeEndInput">
            <b-form-input
              id="zipCodeEndInput"
              v-model="newPriceModel.zipCodeEnd"
              maxlength="6"
              size="6"
              :disabled="newPriceModel.carrier.type === 'ADMIN' && !isAdmin"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="12" v-if="comaMode || true">
          <b-form-group
            id="zipCodeCommas"
            :label-cols="2"
            breakpoint="md"
            :invalid-feedback="!validZipCommas ? $t('Revisar los valores de los CP, uno a varios son incorrectos') : ''"
            :state="validZipCommas"
            label-for="zipCodeCommaInput">
            <b-form-input
              v-show="false"
              id="zipCodeCommaInput"
              size="6"
              v-model="newPriceModel.zipCodeCommas"
              :disabled="newPriceModel.carrier.type === 'ADMIN' && !isAdmin"
            >
            </b-form-input>
            <div v-if="(isEmptyData(newPriceModel.zipCodeStart) && !isEmptyData(newPriceModel.zipCodeEnd)) || (!isEmptyData(newPriceModel.zipCodeStart) && isEmptyData(newPriceModel.zipCodeEnd))" role="alert" aria-live="assertive" aria-atomic="true" class="invalid-feedback d-block mb-3 mt-0">{{$t('El rango desde/hasta no debe contener valores vacíos')}}</div>
            <div>
              <p class="mb-0 mt-2" style="line-height:16px;">{{$t('Códigos individuales')}}</p>
              <small class="text-muted">{{$t('Escribir el código y presionar enter, coma o tab para agregarlo')}}</small>
              <input-tag class="w-100" style="min-height: 30px;" :placeholder="$t('Agregar CP')" v-model="tags" validate="digits" :add-tag-on-keys="[13,188,9]" :read-only="newPriceModel.carrier.type === 'ADMIN' && !isAdmin" @input="updateZipCodeComma"></input-tag>
            </div>
            <div v-if="isEmptyData(newPriceModel.zipCodeCommas) && isEmptyData(newPriceModel.zipCodeStart) && isEmptyData(newPriceModel.zipCodeEnd)" role="alert" aria-live="assertive" aria-atomic="true" class="invalid-feedback d-block mt-3">{{$t('Ingresar rango o códigos postales individuales')}}</div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="my-3 separator pt-3" lg="12"><b>{{$t('Rango de peso (gramos)')}}</b></b-col>
        <b-col lg="6">
          <b-form-group
            id="weightStart"
            :label-cols="2"
            breakpoint="md"
            :label="$t('Desde')"
            :invalid-feedback="!isValidInteger(newPriceModel.weightStart, true)? $t('No es un valor válido') : ''"
            :state="isValidInteger(newPriceModel.weightStart, true)"
            label-for="weightStartInput">
            <b-form-input
              id="weightStartInput"
              v-model="newPriceModel.weightStart"
              :disabled="newPriceModel.carrier.type === 'ADMIN' && !isAdmin"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group
            id="weightEnd"
            :label-cols="2"
            breakpoint="md"
            :label="$t('Hasta')"
            :invalid-feedback="$t(validIntegerCompare(newPriceModel.weightEnd, newPriceModel.weightStart, true))"
            :state="validIntegerCompare(newPriceModel.weightEnd, newPriceModel.weightStart, true) === ''"
            label-for="weightEndInput">
            <b-form-input
              id="weightEndInput"
              v-model="newPriceModel.weightEnd"
              :disabled="newPriceModel.carrier.type === 'ADMIN' && !isAdmin"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-show="newPriceModel.carrier.type === 'ADMIN' && !isAdmin ? true : false">
        <b-col md="12">
          {{ $t('Transportadora gestionada por el administrador, los valores no se pueden modificar.') }}
        </b-col>
      </b-row>
    </b-modal>

    <!-- <b-modal centered headerBgVariant="warning" ref="deleteModal" id="deleteModal" size="lg" title="Confirmar eliminar precio" o-close-on-backdrop="true" cancel-title="Cancelar" ok-title="Eliminar" @ok="_execDelete" > -->
    <b-modal centered ref="deleteModal" header-bg-variant="primary" id="deleteModal" size="lg" :title="$t('Confirmar eliminar precio')" o-close-on-backdrop="true" :cancel-title="$t('Cancelar')" :ok-title="$t('Eliminar')" @ok="_execDelete" >
      {{$t('Confirma que desea eliminar este precio?')}}
    </b-modal>
        <b-modal header-bg-variant="primary" size="lg" ref="confirmationModal" id="confirmationModal" centered :title="$t('Confirmación')"
          @ok="confirmationModalOkHandler"
          @cancel="confirmationModalCancelHandler"
          :cancel-title="$t('Cancelar')">
            <template v-if="selected.length > 0">
              <p class="my-4" v-if="selected.length > 1">{{$t(groupAction)}} {{$t('los')}} {{selected.length}} {{$t('precios seleccionados?')}}</p>
              <p class="my-4" v-if="selected.length == 1">{{$t(groupAction)}} {{$t('el precio seleccionado?')}}</p>
            </template>
          </b-modal>
  </b-container>
</template>
<script>

import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import { CarrierServiceMixin } from '@/mixims/CarrierServiceMixin'
import appConfig from '@/config/applicationConfig.js'
import {ValidationsMixim} from '@/mixims/ValidationsMixim.js'
import cSwitch from '@/components/common/Switch'
import InputTag from 'vue-input-tag'

export default {
  name: 'shipping-grates-list',
  components: { MoonLoader, cSwitch, InputTag },
  mixins: [CarrierServiceMixin, ValidationsMixim],
  computed: {
    validZipCommas () {
      let ret = true
      if (!this.isEmptyData(this.newPriceModel.zipCodeCommas)) {
        ret = true
        let zips = []
        if (this.newPriceModel.zipCodeCommas) zips = this.newPriceModel.zipCodeCommas.split(',')
        let self = this
        zips.forEach(function (zip) {
          ret = ret && self.isValidCP(zip)
        })
      }
      return ret
    },
    rangeLabel () {
      return `${this.$t('Plazo de entrega')} <b>${this.newPriceModel.timeCost} ${this.$t('días')} </b>`
    },
    organizations () {
      return this.$store.getters.organizations
    },
    filterDisabled () {
      if (this.isAdmin) {
        return false
      }
      if (
        this.isEmptyData(this.filters.weight) &&
        this.isEmptyData(this.filters.maxVolume) &&
        this.isEmptyData(this.filters.zipCode)
      ) {
        return true
      }

      if (
        !this.isValidInteger(this.filters.weight, false) ||
        !this.isValidInteger(this.filters.maxVolume, false) ||
        !this.isValidCP(this.filters.zipCode, false)) {
        return true
      }

      return false
    },
    saveDisabled () {
      if (this.isEmptyData(this.newPriceModel.carrier)) {
        return true
      }

      if (!this.isValidFloat(this.newPriceModel.absoluteMoneyCost, true) ||
        !this.isValidInteger(this.newPriceModel.maxVolume, true) ||
        !this.isValidFloat(this.newPriceModel.priceByExtraWeight, true) ||
        !this.isValidFloat(this.newPriceModel.pricePercent, true) ||
        !this.isValidInteger(this.newPriceModel.timeCost, true) ||
        !this.isValidInteger(this.newPriceModel.weightEnd, true) ||
        !this.isValidInteger(this.newPriceModel.weightStart, true)
        // ||
        // !this.isValidCP(this.newPriceModel.zipCodeEnd, true) ||
        // !this.isValidCP(this.newPriceModel.zipCodeStart, true)
      ) {
        return true
      }

      if (this.validIntegerCompare(this.newPriceModel.weightEnd, this.newPriceModel.weightStart, true) !== '') {
        return true
      }

      if (!this.isEmptyData(this.newPriceModel.zipCodeEnd) && !this.isEmptyData(this.newPriceModel.zipCodeStart) && this.validCPCompare(this.newPriceModel.zipCodeEnd, this.newPriceModel.zipCodeStart, true) !== '') {
        return true
      }

      if (!this.isEmptyData(this.newPriceModel.zipCodeEnd) && !this.isValidCP(this.newPriceModel.zipCodeEnd, true)) {
        return true
      }

      if (!this.isEmptyData(this.newPriceModel.zipCodeStart) && !this.isValidCP(this.newPriceModel.zipCodeStart, true)) {
        return true
      }

      if (!this.isEmptyData(this.newPriceModel.zipCodeStart) && this.isEmptyData(this.newPriceModel.zipCodeEnd)) {
        return true
      }

      if (this.isEmptyData(this.newPriceModel.zipCodeStart) && !this.isEmptyData(this.newPriceModel.zipCodeEnd)) {
        return true
      }

      if (!this.isEmptyData(this.newPriceModel.zipCodeCommas) && !this.validZipCommas) {
        return true
      }

      if (this.isEmptyData(this.newPriceModel.zipCodeEnd) && this.isEmptyData(this.newPriceModel.zipCodeStart) && this.isEmptyData(this.newPriceModel.zipCodeCommas)) {
        return true
      }

      return false
    }
  },
  data () {
    return {
      comaMode: false,
      locale: appConfig.LOCALE,
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : '$',
      countries: appConfig.COUNTRY_CODES,
      isAdmin: this.$store.getters.isAdmin,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [ 10, 15, 25, 50, 100 ],
      success: null,
      errorText: '',
      loading: true,
      toDelete: null,
      disableFilterButton: false,
      showFilters: false,
      carrierList: null,
      tags: [],
      newPriceModel: {
        id: null,
        absoluteMoneyCost: null,
        accountName: null,
        carrier: {
          id: null,
          name: null,
          slaType: null,
          accountName: null
        },
        country: null,
        maxVolume: null,
        minimumValueInsurance: 0,
        priceByExtraWeight: null,
        pricePercent: null,
        timeCost: 0,
        weightEnd: null,
        weightStart: null,
        zipCodeEnd: null,
        zipCodeStart: null,
        zipCodeCommas: null,
        zipCodes: []
      },
      filters: {
        accountName: null,
        zipCode: null,
        weight: null,
        maxVolume: null
      },
      sortBy: null,
      sortDesc: true,
      selected: [],
      currentPageElement: [],
      fieldsNotAdmin: [
        { key: 'selected', label: ' ', 'class': 'text-left', sortable: false },
        { key: 'carrier', label: this.$t('Transportadora'), 'class': 'text-left', sortable: false },
        { key: 'zipCodeStart', label: this.$t('Cód. Postal'), 'class': 'text-left', sortable: false, thClass: 'zipCodesTh' },
        { key: 'weightStart', label: this.$t('Rango Peso'), 'class': 'text-left', sortable: false },
        { key: 'country', label: this.$t('País'), 'class': 'text-left', sortable: true },
        { key: 'absoluteMoneyCost', label: this.$t('Costo'), 'class': 'text-left', sortable: true },
        { key: 'timeCost', label: this.$t('Plazo de entrega'), 'class': 'text-left', sortable: true },
        { key: 'actions', label: this.$t('Acciones'), _showDetails: false, sortable: false }
      ],
      fieldsAdmin: [
        { key: 'selected', label: ' ', 'class': 'text-left', sortable: false },
        // { key: 'accountName', label: 'Seller', 'class': 'text-left', sortable: true },
        { key: 'carrier', label: this.$t('Transportadora'), 'class': 'text-left', sortable: false },
        { key: 'zipCodeStart', label: this.$t('Cód. Postal'), 'class': 'text-left', sortable: false, thClass: 'zipCodesTh' },
        { key: 'weightStart', label: this.$t('Rango Peso'), 'class': 'text-left', sortable: false },
        { key: 'country', label: this.$t('País'), 'class': 'text-left', sortable: true },
        { key: 'absoluteMoneyCost', label: this.$t('Costo'), 'class': 'text-left', sortable: true },
        { key: 'timeCost', label: this.$t('Plazo de entrega'), 'class': 'text-left', sortable: true }
      ],
      productGroupSelection: {
        options: [
          { text: this.$t('Seleccione una opción'), value: null, disabled: true },
          { text: this.$t('Ninguno'), value: 'Ninguno' },
          { text: this.$t('Todos en esta pagina'), value: 'Todos en esta pagina' },
          { text: this.$t('Todos'), value: 'Todos' }
        ],
        current: null
      },
      currentActionGroupSelection: null,
      groupAction: null
    }
  },
  methods: {
    confirmationModalCancelHandler () {
      this.currentActionGroupSelection = null
    },
    showActionModal (action) {
      this.groupAction = action
      this.$refs.confirmationModal.show()
    },
    confirmationModalOkHandler () {
      this.successText = ''
      this.errorText = ''
      this.loading = true
      switch (this.groupAction) {
        case 'Eliminar' :
          this.deleteSelectedItems()
          break
        case 'Activar':
          this.activateSelectedItems()
          break
        case 'Rechazar':
          this.rejectSelectedItems()
          break
        default:
          break
      }
      this.currentActionGroupSelection = null
    },
    handleRowCheckbox () {
      this.productGroupSelection.current = null
    },
    _execDelete () {
      this.loading = true
      this.deleteShippingrates(this.toDelete).then(
        () => {
          this.loading = false
          this.$refs.mainTable.refresh()
        },
        (error) => {
          this.errorText = error.body.message || this.$t('Error eliminando el precio')
          this.loading = false
        }
      )
    },
    showDeteleModal (context) {
      this.errorText = ''
      this.toDelete = context.item.id
      this.$refs.deleteModal.show()
    },
    showCreateModal () {
      this.comaMode = false
      this.newPriceModel.id = null
      this.newPriceModel.absoluteMoneyCost = null
      this.newPriceModel.carrier = {}
      this.newPriceModel.country = appConfig.DEFAULT_COUNTRY
      this.newPriceModel.maxVolume = null
      this.newPriceModel.minimumValueInsurance = 0
      this.newPriceModel.priceByExtraWeight = null
      this.newPriceModel.pricePercent = null
      this.newPriceModel.timeCost = 0
      this.newPriceModel.weightEnd = null
      this.newPriceModel.weightStart = null
      this.newPriceModel.zipCodeEnd = null
      this.newPriceModel.zipCodeStart = null
      this.newPriceModel.zipCodeCommas = null
      this.newPriceModel.zipCodes = null
      this.tags = []
      this.$refs.newPriceModal.show()
    },
    createPrice () {
      this.loading = true
      this.newPriceModel.accountName = this.newPriceModel.carrier.accountName
      if (this.newPriceModel.zipCodeCommas) {
        this.newPriceModel.zipCodes = this.newPriceModel.zipCodeCommas.split(',')
      }
      this.postShippingrates(this.newPriceModel).then(
        () => {
          this.loading = false
          this.$refs.mainTable.refresh()
        },
        (error) => {
          this.errorText = error.body.message || this.$t('Error obteniendo el listado de precios')
          this.loading = false
        }
      )
    },
    rowDbClick (item) {
      this.comaMode = false
      this.newPriceModel.id = item.id
      this.newPriceModel.absoluteMoneyCost = item.absoluteMoneyCost
      this.newPriceModel.accountName = item.accountName
      this.newPriceModel.country = item.country
      this.newPriceModel.maxVolume = item.maxVolume
      this.newPriceModel.minimumValueInsurance = item.minimumValueInsurance
      this.newPriceModel.priceByExtraWeight = item.priceByExtraWeight
      this.newPriceModel.pricePercent = item.pricePercent
      this.newPriceModel.timeCost = item.timeCost
      this.newPriceModel.weightEnd = item.weightEnd
      this.newPriceModel.weightStart = item.weightStart
      this.newPriceModel.zipCodeEnd = item.zipCodeEnd
      this.newPriceModel.zipCodeStart = item.zipCodeStart
      this.newPriceModel.zipCodeCommas = null
      this.newPriceModel.zipCodes = null
      if (item.zipCodes) {
        this.newPriceModel.zipCodeCommas = item.zipCodes.toString()
        this.tags = this.newPriceModel.zipCodeCommas.split(',')
      } else {
        this.tags = []
      }
      if (item.carrier) {
        this.newPriceModel.carrier = {
          'id': item.carrier.id,
          'name': item.carrier.name,
          'accountName': item.carrier.accountName,
          'slaType': item.carrier.slaType,
          'factorCubicWeight': item.carrier.factorCubicWeight,
          'type': item.carrier.type,
          'isActive': item.carrier.isActive
        }
      } else {
        this.newPriceModel.carrier = {}
      }
      this.$refs.newPriceModal.show()
    },
    rowClick (item) {
      console.log(item)
    },
    productGroupSelectionHandler (selectionType) {
      this.selected.splice(0, this.selected.length)
      let _this = this
      let tableinfo = null
      switch (selectionType) {
        case 'Ninguno':
          break
        case 'Todos en esta pagina':
          this.currentPageElement.forEach(item => {
            if (item.type !== 'ADMIN' || this.isAdmin) this.selected.push(item.id)
          })
          break
        case 'Todos':
          this.loading = true
          tableinfo = {currentPage: 1, perPage: 100}
          this.getShippinGrates(tableinfo, this.filters).then((response) => {
            response.body.forEach(item => {
              if (item.type !== 'ADMIN' || this.isAdmin) _this.selected.push(item.id)
            })
            this.loading = false
          })
          break
        default:
          break
      }
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filterTable () {
      this.$refs.mainTable.refresh()
      this.errorText = ''
    },
    cleanFilters () {
      this.filters.accountName = null
      this.filters.zipCodeStart = null
      this.filters.zipCodeEnd = null
      this.filters.weightStart = null
      this.filters.weightEnd = null
      this.filters.maxVolume = null
      this.filters.carrierId = null
      this.$refs.mainTable.refresh()
    },
    itemProvider (ctx) {
      this.loading = true
      return this.getShippinGrates(ctx, this.filters).then(
        response => {
          this.loading = false
          this.totalRows = parseInt(response.headers.get('X-Total-Count'))
          this.currentPageElement = response.body
          return response.body
        },
        error => {
          this.errorText = error.body.message || this.$t('Error obteniendo el listado de precios volumetricos')
          this.loading = false
          return []
        }
      )
    },
    deleteSelectedItems () {
      this.loading = true
      this.deleteMultipleShippingrates(this.selected).then(
        response => {
          this.loading = false
          this.selected = []
          this.$refs.mainTable.refresh()
          return response.body
        },
        error => {
          this.errorText = error.body.message || 'Error obteniendo el listado de precios volumetricos'
          this.loading = false
          return []
        }
      )
    },
    updateZipCodeComma () {
      this.newPriceModel.zipCodeCommas = this.tags.join()
    }
  },
  mounted () {
    this.loading = true
    let filter = {}
    if (!this.isAdmin) {
      filter.type = 'SELLER'
    }
    this.getCarriers(filter).then(
      (carriers) => {
        this.loading = false
        this.carrierList = [{ text: this.$t('Seleccione una transportadora'), value: null, disabled: true }]
        this.carrierList = this.carrierList.concat(carriers.body.map(
          (item) => {
            return {
              'text': item.name,
              'value': {
                'id': item.id,
                'name': item.name,
                'accountName': item.accountName,
                'slaType': item.slaType,
                'factorCubicWeight': item.factorCubicWeight,
                'type': item.type,
                'isActive': item.isActive
              }
            }
          }).filter(a => a.value.isActive)
        )
      },
      (error) => {
        this.errorText = error.body.message || this.$t('Error obteniendo el listado de precios volumetricos')
        this.loading = false
      }
    )
  }
}
</script>

<style lang="scss" scoped>
  .filters {
    border: 1px solid #e3e8ec;
    background-color: #FFF
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .separator {
    border-top: 1px solid #CCCCCC;
  }

  #timeCostInput {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .vue-input-tag-wrapper {
    border: 1px solid #e3e8ec !important;
  }

</style>
