<template>
  <b-container fluid class="profile-info animated fadeIn">
    <div v-show="errorText !== ''" class="alert alert-danger" role="alert" dismissible @dismised="errorText = ''">
      {{errorText}}
    </div>
    <div v-show="success" class="alert alert-success" role="alert" dismissible @dismised="success=false">
      {{successText}}
    </div>
    <b-card>
      <user-form
        ref="userForm"
        :user="user"
        :roleOptions="roleOptions"
        :allOrganizations="organizationOptions"
      />
      <hr>
      <b-row>
        <b-col cols="12" class="mb-2">
          <h6>{{$t('Notificaciones')}}</h6>
        </b-col>
        <b-col cols="12">
          <b-form-group
            horizontal
            :label="$t('Quiero recibir notificaciones por email')">
            <c-switch
              inputType="checkbox"
              type="text"
              variant="success"
              :on="$t('Si')"
              :off="$t('No')"
              :pill="true"
              v-model="user.activeSendEmail"
              size="m"
              id="s0">
            </c-switch>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="px-4">
          <b-button class="mb-2 float-right" @click="resetUser">{{$t('Reestablecer')}}</b-button>
          <b-button
            class="mb-2 mr-2 float-right" @click="handleUpdateUser"
            :disabled="this.$refs.userForm ? this.$refs.userForm.disableSave : true"
          >{{$t('Actualizar')}}</b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col>
          <h4>{{$t('Contraseña')}}</h4>
          <br />
          <b-col md="4" offset-md="4">
            <b-btn class="w-100" v-b-modal.pass-modal>{{$t('Cambiar clave de acceso')}}</b-btn>
          </b-col>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col class="mb-3">
          <h4>{{$t('Idioma')}}</h4>
          <br />
          <b-col md="4" offset-md="4">
            <b-form-select id="categoryFormatter" v-model="lang" @change="changeLang">
              <option v-for="item in languages" 
                :key="item.value"
                :value="item.value"
                :disabled="item.disabled ? item.disabled : false">{{$t(item.text)}}
              </option>
            </b-form-select>
          </b-col>
        </b-col>
      </b-row>
    </b-card>

    <b-modal id="pass-modal"
      centered
      :title="$t('Cambiar clave')"
      :ok-title="$t('Cambiar')"
      :cancel-title="$t('Cancelar')"
      :ok-disabled="!(Boolean(password.old) && Boolean(password.newVal) && password.newVal.length > 5 && password.newVal === password.repeated)"
      @hidden="password={}"
      @ok="handleChangePassword">
      <b-container fluid class="mt-3 pw-info animated fadeIn">
        <b-col md="12">
          <b-form-group
            horizontal
            :label-cols="4"
            :label="$t('Clave actual')"
          >
            <b-form-input type="password" v-model="password.old"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            horizontal
            :label-cols="4"
            :label="$t('Clave nueva')"
            :invalid-feedback="$t('Debe contener al menos 6 caracteres')"
            :state="!Boolean(password.newVal) || password.newVal.length > 5"
          >
            <b-form-input type="password" v-model="password.newVal"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            horizontal
            :label-cols="4"
            :label="$t('Repetir clave nueva')"
            :invalid-feedback="$t('Las claves no coinciden')"
            :state="!Boolean(password.repeated) || password.newVal === password.repeated"
          >
            <b-form-input type="password" v-model="password.repeated"></b-form-input>
          </b-form-group>
        </b-col>
      </b-container>
    </b-modal>
  </b-container>
</template>
<script>
import UserForm from '@/components/user/UserForm'
import cSwitch from '@/components/common/Switch'
import { UserServiceMixim } from '@/mixims/UserServiceMixim.js'
import { AdminServiceMixin, RoleService, OrganizationService } from '@/mixims/AdminServiceMixin'

export default {
  name: 'profile-view',
  mixins: [AdminServiceMixin, RoleService, OrganizationService, UserServiceMixim],
  components: {
    UserForm,
    cSwitch
  },
  data () {
    return {
      successText: '',
      errorText: '',
      success: false,
      password: {
        old: null,
        newVal: null,
        repeated: null
      },
      user: {...this.$store.state.user_data},
      roleOptions: [],
      organizationOptions: [],
      lang: localStorage.getItem('locale') || null,
      languages: [{value: null, text: 'Seleccionar idioma', disabled: true}, {value: 'es-ar', text: 'Español'}, {value: 'en-gr', text: 'Inglés'}, {value: 'pt-br', text: 'Portugués'}]
    }
  },
  mounted () {
    this.user = {...this.$store.state.user_data}
    this.user.organizations = this.$store.state.user_data.organizations
    if (this.$store.getters.isAdmin) {
      this.getRoles().then(rs => {
        this.roleOptions = rs.body
      })
      this.getOrganizations().then(os => {
        this.organizationOptions = os.body
      })
    } else {
      this.roleOptions = this.$store.state.user_data.roles
      this.organizationOptions = [...this.$store.state.user_data.organizations]
    }
  },
  methods: {
    successed (text) {
      window.scrollTo(0, 0)
      this.successText = text || this.$t('La acción fue completada con éxito')
      this.success = true
    },
    catchError (defaultMessage) {
      return er => {
        window.scrollTo(0, 0)
        this.errorText = this.$t(er.body.message) || defaultMessage
      }
    },
    handleUpdateUser () {
      let self = this
      this.updateUser(this.user).then(() => {
        self.$store.dispatch('setUserData', self.user)
        self.successed()
      }).catch((er) => {
        console.log(er)
        this.errorText = this.$t('Ocurrio un error, el usuario no se fue guardado')
      })
    },
    handleChangePassword () {
      this.changePassword(this.user.id, {
        code: this.password.old,
        password: this.password.newVal
      }).then(() => {
        this.successed(this.$t('La acción fue completada con éxito. En 10 segundos se cerrará la sesión y deberá utilizar la nueva contraseña para ingresar.'))
        setTimeout(() => {
          this.logout()
        }, 10000)
      }).catch(
        this.catchError(this.$t('Ocurrio un error, el usuario no se fue guardado'))
      )
    },
    resetUser () {
      this.user = {...this.$store.state.user_data}
    },
    changeLang (e) {
      console.log(e)
      localStorage.setItem('locale', e)
      this.$i18n.locale = e
    }
  }
}
</script>
