import appConfig from '@/config/applicationConfig.js'

export const IntegrationServiceMixin = {
  data () {
    return {
      INTEGRATIONS_ENDPOINT: `${appConfig.API_END_POINT}/integrations`,
      HEADER: {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }
    }
  },
  methods: {
    getIntegrationsData (tableInfo, filters) {
      let params = {}
      let url = new URL(`${this.INTEGRATIONS_ENDPOINT}/data`)

      params.from = tableInfo.currentPage - 1
      params.size = tableInfo.perPage

      if (tableInfo.sortBy) {
        params.sortBy = tableInfo.sortBy
        params.sortOrder = tableInfo.sortDesc ? 'desc' : 'asc'
      }
      params.reference = filters.reference
      params.text = filters.text

      Object.keys(params).forEach(
        (key) => {
          if (params[key]) {
            url.searchParams.append(key, params[key])
          }
        }
      )

      return this.$http.get(url.href, this.HEADER)
    },
    getIntegrations (tableInfo, filters) {
      let params = {}
      let url = new URL(this.INTEGRATIONS_ENDPOINT)

      params.from = tableInfo.currentPage - 1
      params.size = tableInfo.perPage

      if (tableInfo.sortBy) {
        params.sortBy = tableInfo.sortBy
        params.sortOrder = tableInfo.sortDesc ? 'desc' : 'asc'
      }
      params.accountName = filters.accountName
      params.integrationDataId = filters.integrationDataId

      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null) {
            url.searchParams.append(key, params[key])
          }
        }
      )

      return this.$http.get(url.href, this.HEADER)
    },
    getIntegrationsDataById (integrationId) {
      return this.$http.get(`${this.INTEGRATIONS_ENDPOINT}/data/${integrationId}`, this.HEADER)
    },
    postIntegrations (integrationData) {
      return this.$http.post(this.INTEGRATIONS_ENDPOINT, integrationData, this.HEADER)
    },
    editIntegrations (integrationData, integrationId) {
      return this.$http.put(`${this.INTEGRATIONS_ENDPOINT}/${integrationId}`, integrationData, this.HEADER)
    },
    deleteIntegrations (integrationId) {
      return this.$http.delete(`${this.INTEGRATIONS_ENDPOINT}/${integrationId}`, this.HEADER)
    },
    deleteMultipleIntegrations (ids) {
      let integrationData = {'delete': ids}
      return this.$http.post(`${this.INTEGRATIONS_ENDPOINT}/batch`, integrationData, this.HEADER)
    }
  }
}
