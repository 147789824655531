<template>
  <b-container fluid>
    <b-alert :show="showAlert" fade dismissible :variant="variant">{{$t(message)}}</b-alert>
    <b-row>
      <b-container>
        <b-btn class="float-right mb-4" @click="$router.back()">Volver</b-btn>
      </b-container>
    </b-row>
    <FormPickupPoints @showMessage="handleShowMessage"/>
  </b-container>
</template>

<script>
import FormPickupPoints from '../components/pickupPoints/FormPickupPoints.vue'
export default {
  components: {
    FormPickupPoints
  },
  data () {
    return {
      message: '',
      variant: 'success',
      showAlert: 0,
      dismissSecs: 3
    }
  },
  methods: {
    handleShowMessage (data) {
      this.showAlert = 0
      this.message = data.message
      this.variant = data.variant
      this.showAlert = this.dismissSecs
    }
  }
}
</script>

<style>

</style>