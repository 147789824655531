import appConfig from '@/config/applicationConfig.js'
import { getOrders } from './getOrders'
export const OrdersServiceMixim = {
  methods: {
    getOrders,
    /**
    * Get all the orders
    * @param tableInfo data to filter the query
    */
    getAllOrders (tableInfo, isCSVExport) {
      let url = null
      let params = {}
      if (!isCSVExport) {
        url = new URL(`${appConfig.API_END_POINT}/orders`)
        params.from = tableInfo.currentPage - 1
        params.size = tableInfo.perPage
      } else {
        url = new URL(`${appConfig.API_END_POINT}/orders/downloadcsv`)
      }

      if (tableInfo.sortBy) {
        params.sortBy = tableInfo.sortBy === 'clientProfileData' ? 'clientProfileData.lastName.keyword' : tableInfo.sortBy
        params.sortOrder = tableInfo.sortDesc ? 'desc' : 'asc'
      } else {
        params.sortBy = 'creationDate'
        params.sortOrder = 'desc'
      }

      if (this.substatusFilter) params.subStatus = this.substatusFilter
      if (this.filter) params.text = this.filter
      if (this.showFilters) {
        if (this.account) params.accountName = this.account
        if (this.statusName) {
          params.status = this.statusName
        }

        if (this.siteNameFilter) {
          params.marketplaces = this.siteNameFilter
        }

        if (this.tradeAgreementFilter) {
          params.salesChannel = this.tradeAgreementFilter
        }

        if (this.liquidationReport) {
          if (this.fromDate && typeof this.fromDate !== 'object') params.fromLiquidationDate = this.fromDate
          if (this.toDate && typeof this.toDate !== 'object') params.toLiquidationDate = this.toDate
        } else {
          if (this.fromDate && typeof this.fromDate !== 'object') params.fromDate = this.fromDate
          if (this.toDate && typeof this.toDate !== 'object') params.toDate = this.toDate
        }

        if (this.filterTags) {
          if (this.filterTags[0] && this.filterTags[0].key) {
            params.tags = this.filterTags.map(a => a.key)
          } else {
            params.tags = this.filterTags.join(',')
          }
        }
      }

      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null) {
            url.searchParams.append(key, params[key])
          }
        }
      )

      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }

      if (isCSVExport) {
        config.responseType = 'arraybuffer'
        config.headers.Accept = 'application/octet-stream; charset=ISO-8859-1'
      }
      return this.$http.get(
        url.href,
        config
      )
    },

    /**
    * Get the order details give the id
    * @param orderId
    */
    getOrderDetails (orderId) {
      return this.$http.get(
        `${appConfig.API_END_POINT}/orders/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    /**
    * Invoice the order
    * @param orderId
    * @param courier
    * @param trackingUrl
    * @param trackingNumber
    * @param packagesCount to define de invoice number
    */
    invoiceOrder (orderId, courier, trackingUrl, trackingNumber, items, invoiceNumber) {
      /* Invoice the Order */
      return this.$http.post(
        `${appConfig.API_END_POINT}/orders/${orderId}/invoice`,
        {courier, trackingUrl, trackingNumber, items, invoiceNumber},
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    addTrackingInformation (orderId, invoiceNumber, courier, trackingUrl, trackingNumber) {
      return this.$http.put(
        `${appConfig.API_END_POINT}/orders/${orderId}/invoice/${invoiceNumber}`,
        {courier, trackingUrl, trackingNumber},
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
    /**
    * Cancel the order given the id
    * @param orderId
    */
    cancelOrder (orderId) {
      /* Cancel Order */
      return this.$http.post(
        `${appConfig.API_END_POINT}/orders/${orderId}/canceled`, '',
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    /**
    * Start the handling of the given order
    * @param orderId
    */
    startHandling (orderId) {
      /* Start Handling Order */
      return this.$http.post(
        `${appConfig.API_END_POINT}/orders/${orderId}/start-handling`, '',
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    deliverOrders (orderId, invoiceList) {
      let _this = this

      function syncMagicPromisseCall () {
        let invoiceNumber = invoiceList.pop()
        return _this.deliverOrder(orderId, invoiceNumber).then(
          () => {
            if (invoiceList.length !== 0) {
              return syncMagicPromisseCall()
            }
          }
        )
      }
      return syncMagicPromisseCall()
    },
    /**
    * Deliver the given order
    * @param orderId
    */
    deliverOrder (orderId, invoiceNumber) {
      /* Deliver an order */
      return this.$http.put(
        `${appConfig.API_END_POINT}/orders/${orderId}/invoice/${invoiceNumber}/tracking`, '',
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    getStatusstatistics (accountName, dateFrom, dateTo, siteName) {
      let url = new URL(`${appConfig.API_END_POINT}/orders/statusstatistics`)
      let params = {}

      params.accountName = accountName
      params.fromDate = dateFrom
      params.toDate = dateTo
      params.marketplaces = siteName

      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null) {
            url.searchParams.append(key, params[key])
          }
        }
      )

      return this.$http.get(
        url.href,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    downloadSalesReport (accountName, dateFrom, dateTo) {
      let url = new URL(`${appConfig.API_END_POINT}/orders/downloadsalesreportcsv`)
      let params = {}

      params.accountName = accountName
      params.fromDate = dateFrom
      params.toDate = dateTo

      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null) {
            url.searchParams.append(key, params[key])
          }
        }
      )

      return this.$http.get(
        url.href,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    getSalesReport (filter, isCSVExport) {
      let url = null
      if (!isCSVExport) {
        url = new URL(`${appConfig.API_END_POINT}/orders/salesreport`)
      } else {
        url = new URL(`${appConfig.API_END_POINT}/orders/downloadsalesreportcsv`)
      }
      let params = {}

      params.accountName = filter.account
      params.fromDate = filter.fecha.desde
      params.toDate = filter.fecha.hasta
      params.marketplaces = filter.marketplace

      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null) {
            url.searchParams.append(key, params[key])
          }
        }
      )

      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }

      if (isCSVExport) {
        config.responseType = 'arraybuffer'
        config.headers.Accept = 'application/octet-stream; charset=ISO-8859-1'
      }

      return this.$http.get(
        url.href,
        config
      )
    },

    intervalstatistics (accountName, dateFrom, dateTo, siteName) {
      let url = new URL(`${appConfig.API_END_POINT}/orders/intervalstatistics`)
      let params = {}

      params.accountName = accountName
      params.fromDate = dateFrom
      params.toDate = dateTo
      params.marketplaces = siteName

      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null) {
            url.searchParams.append(key, params[key])
          }
        }
      )

      return this.$http.get(
        url.href,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
    getImputabilityCauses () {
      return this.$http.get(
        `${appConfig.API_END_POINT}/orders/imputabilitycauses`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
    cancelCDESeller (orderId, payload) {
      return this.$http.post(
        `${appConfig.API_END_POINT}/orders/${orderId}/cancelcde`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    cancelCAESeller (orderId, payload) {
      return this.$http.post(
        `${appConfig.API_END_POINT}/orders/${orderId}/cancelcae`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    getSearchOrders (text) {
      return this.$http.get(
        `${appConfig.API_END_POINT}/orders?sortBy=creationDate&sortOrder=desc&text=${text}`,
        {headers: {Authorization: `Bearer ${this.$store.state.access_token}`}}
      )
    },

    putSubstatus (substatusId, orderId) {
      return this.$http.put(
        `${appConfig.API_END_POINT}/orders/${orderId}/substatus/${substatusId}`,'',
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
    setAsReturn (orderId, items) {
      return this.$http.put(
        `${appConfig.API_END_POINT}/orders/${orderId}/return`, { items },
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    }
  }
}
