<template>
  <b-container fluid class="animated fadeIn">
    <div v-show="errorText !== ''" class="alert alert-danger" role="alert" dismissible @dismised="errorText = ''">
      {{errorText}}
    </div>
    <div v-show="success" class="alert alert-success" role="alert" dismissible @dismised="success=false">
      {{$t('La acción fue completada con éxito')}}
    </div>

    <b-row>
      <b-col>
        <button class="btn btn-primary float-right mb-3 mr-2" @click="openCarrierModal(selectedCarrier)">{{$t('Crear transportadora')}}</button>
        <button class="btn btn-primary float-right mb-3 mr-2" v-b-toggle.collapse2 @click="showFilters = !showFilters">
          <i class="fa" :class="{'fa-times': showFilters, 'fa-filter': !showFilters}" />{{$t('Filtros')}}
        </button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
          <b-collapse id="collapse2">
            <b-card class="filters mt-3 p-3">
              <b-form-group horizontal
                :label-cols="2"
                breakpoint="md"
                :label="$t('Nombre')"
                label-for="nameLabelFormater">
                <b-form-input
                  type="text"
                  id="nameLabelFormater"
                  v-model="carrierFilterRules.name"
                  @keydown.native="textInputValidation">
                </b-form-input>
              </b-form-group>
              <b-form-group horizontal
                :label-cols="2"
                breakpoint="md"
                :label="$t('Tipo SLA')"
                label-for="slaLabelFormater">
                <b-form-select v-model="carrierFilterRules.slaType">
                  <option v-for="(sla, index) in slaFilterOptions" 
                      :key="index"
                      :value="sla.value">{{$t(sla.text)}}</option>
                </b-form-select>
              </b-form-group>
              <b-row>
                <b-col>
                  <button class="btn btn-primary float-right" :disabled='loading' @click="$refs.mainTable.refresh()">{{$t('Filtrar')}}</button>
                  <button class="btn btn-primary float-right mr-2" :disabled='loading' @click="resetFilters">{{$t('Mostrar Todos')}}</button>
                </b-col>
              </b-row>
            </b-card>
          </b-collapse>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Lista de transportadoras')">
          <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
          <b-table
            v-show="!loading"
            id="mainTable"
            ref="mainTable"
            responsive=true
            stacked="xl"
            :empty-text="$t('No hay transportadoras para mostrar')"
            :empty-filtered-text="$t('No hay transportadoras para mostrar')"
            show-empty
            striped
            hover
            foot-clone
            :items="itemProvider"
            :fields="isAdmin ? fieldsAdmin : fields"
            :filter="filter"
            @row-clicked="openCarrierModal">
              <template slot="slaType"  slot-scope="row" >
                {{$t(row.item.slaType)}}
              </template>
              <template slot="actions" slot-scope="row">
                <b-button v-show="isAdmin && row.item.slaType === 'Retiro en tienda'" class="mr-2" variant="primary" size="sm" @click.stop="showPickupPointsModal(row)">{{$t('Puntos de retiro')}}</b-button>
                <b-button v-show="isAdmin" class="mr-2" variant="primary" size="sm" @click.stop="showSellerModal(row)">{{$t('Editar Sellers')}}</b-button>
                <b-button :disabled="disableHideButton(row.item.type)" variant="primary" size="sm" @click.stop="showConfirmModal(row.item)">{{$t('Eliminar')}}</b-button>
              </template>
              <template slot="isActive" slot-scope="row">
                {{row.item.isActive ? $t('Activa') : $t('Inactiva')}}
              </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      header-bg-variant="primary"
      ref="carrierInfo"
      centered
      :title="selectedCarrier.id ? $t('Guardar transportadora') : $t('Crear transportadora')"
      size="lg"
      @hide="resetModal"
      >
      <b-container fluid>
        <b-row>
          <b-col md="12">
            <carrier-form :value="selectedCarrier" :organizationOptions="organizationOptions"/>
          </b-col>
        </b-row>
      </b-container>
      <div slot="modal-footer" class="w-100">
        <b-row>
          <b-col class="px-4">
            <b-button class="mb-2 mx-1 float-right" @click="$refs.carrierInfo.hide()">{{$t('Cancelar')}}</b-button>
            <b-button class="mb-2 mx-1 float-right"
              :disabled="disableSave"
              @click="handleSave">{{selectedCarrier.id ? $t('Guardar') : $t('Crear')}}</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      ref="sellerModal"
      id="sellerModal"
      header-bg-variant="primary"
      centered
      :title="$t('Editar Sellers')"
      size="lg"
      :ok-disabled="false"
      @hide="resetModal"
      >
      <b-row>
        <b-col>
          <b-alert :show="errorTextModal !== ''" fade dismissible variant="danger">{{errorTextModal}}</b-alert>
          <b-alert :show="successTextModal !== ''" variant="success" dismissible>{{successTextModal}}</b-alert>
          <moon-loader :loading="loadingModal" color="#000" size="30px"></moon-loader>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col md="8">
          <b-form-group
            id="inputSellersGroup"
            horizontal
            :label-cols="2"
            breakpoint="md"
            :label="$t('Sellers_label')"
            label-for="inputSellers">
            <autocomplete
              id="inputSellers"
              name="inputSellers"
              :maxElements="1"
              :provider="searchOrganization"
              :disabled="loading"
              v-model="selSeller"
              @input="onChangeSelected">
            </autocomplete>
          </b-form-group>
        </b-col>
        <b-col v-if="selectedEditCarrier.item">
          <b-button class="ml-auto addSpec primary" @click="addSeller()" :disabled="selSeller.length === 0">{{ $t('Agregar Seller') }}</b-button>
        </b-col>
      </b-row>
      <b-row v-if="selSeller.length > 0 && carrierIntegration && preloadIntegration">
        <hr class="w-100" v-if="integrationData.length">
        <h6 class="w-100 px-3 pb-4 pt-2 font-weight-bold" v-if="integrationData.length">{{$t('Datos de integración:')}}</h6>
        <b-col md="8" v-for="(data, index) in integrationData" :key="index">
          <b-form-group
            horizontal
            :label-cols="2"
            :label="data.type === 'RADIO' && data.name === 'integrationType' ? $t('Guía') : data.name"
            :invalid-feedback="!stateIntegrationData(selSeller[0][data.name]) ? `${$t('Campo')} ${data.type === 'RADIO' && data.name === 'integrationType' ? $t('Guía') : data.name}${$t('inválido')}` : ''"
            :state="stateIntegrationData(selSeller[0][data.name])"
          >
            <b-form-input v-model="selSeller[0][data.name]" :type="'text'" v-if="data.type === 'STRING'"></b-form-input>
            <b-form-input v-model="selSeller[0][data.name]" :type="'number'" v-if="data.type === 'NUMBER'"></b-form-input>
            <b-form-radio-group
              class="custom-carrier-form pt-1"
              v-if="data.type === 'RADIO' && data.name !== 'integrationType'"
              v-model="selSeller[0][data.name]"
              :options="data.values">
            </b-form-radio-group>
            <b-form-radio-group
              class="custom-carrier-form pt-1"
              v-if="data.type === 'RADIO' && data.name === 'integrationType'"
              v-model="selSeller[0][data.name]"
              :options="[{ text: $t('Manual'), value: 'MANUAL' }, { text: $t('Automática'), value: 'AUTOMATIC' }]">
            </b-form-radio-group>
            <b-form-select
              v-if="data.type === 'COMBO'"
              v-model="selSeller[0][data.name]"
              :options="data.values">
            </b-form-select>
            <b-form-radio-group
              class="custom-carrier-form pt-1"
              v-if="data.type === 'BOOLEAN'"
              v-model="selSeller[0][data.name]"
              :options="[{value: true, text: $t('Si')}, {value: false, text: $t('No')}]">
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <!-- <b-col v-if="selectedEditCarrier.item && Object.keys(shippingsExcluded).find(key => shippingsExcluded[key] === selectedEditCarrier.item.name)">
          <b-button class="ml-auto addSpec primary" @click="addSeller()" :disabled="selSeller.length === 0 || addButtonDisabled">Agregar Seller</b-button>
        </b-col> -->
      </b-row>
      <b-row class="mt-3">
        <b-col class="col-md-auto">
          <b-pagination :total-rows="totalRowsSeller" :per-page="filterRules.size" v-model="filterRules.from" class="mb-3" />
        </b-col>
        <b-col class="col-md-auto per-page">
          <b-form-group id="perPage" horizontal >
            <b-form-select :options="pageOptions" v-model="filterRules.size" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        id="sellersTable"
        ref="sellersTable"
        show-empty
        striped
        hover
        :sort-by.sync="filterRules.sortBy"
        :sort-desc.sync="filterRules.sortOrder"
        :per-page="filterRules.size"
        :current-page="filterRules.from"
        :items="sellerProvider"
        :fields="fieldsSeller"
        :filter="filter"
        :fixed=true
        small
        responsive=true
        stacked="xl"
        :empty-text="$t('No hay sellers para mostrar')"
        :empty-filtered-text="$t('No hay sellers para mostrar')">
          <template slot="accountName" slot-scope="row">
            <router-link v-if="carrierIntegration" :to="{ path: '/admin/integrations', query: { accountName: row.item.accountName, carrierId: selectedEditCarrier.item.id }}">{{row.item.accountName}}</router-link>
            <span v-else>{{row.item.accountName}}</span>
          </template>
          <template slot="guide"  slot-scope="row" v-if="false">
            <b-form-group
                class="my-1 radio-guia"
                v-if="carrierIntegration"
                :label-cols="6"
                :invalid-feedback="!row.item.integrationType ? $t('Ingrese un tipo de guía') : ''"
                :state="row.item.integrationType"
              >
              <b-form-radio-group
                v-model="row.item.integrationType"
                :value="row.item.integrationType"
                :options="guiaOptions"
                @change="onChangeSeller(row.item)"
                >
              </b-form-radio-group>
            </b-form-group>
          </template>
          <template slot="actions"  slot-scope="row" >
            <b-button @click="removeSeller(row.item)"><i class="fa fa-trash" aria-hidden="true"></i></b-button>
          </template>
      </b-table>
    </b-modal>
    <b-modal ref="confirmModal" header-bg-variant="primary" centered :title="$t('Confirmar')" size="sm" :ok-title="$t('Aceptar')" :cancel-title="$t('Cancelar')" @ok="handleDeleteCarrier">
      <h5>{{$t('¿Está seguro que desea eliminar la transportadora seleccionada?')}}</h5>
    </b-modal>
     <PickupPoints ref="pickupPoints"/>
  </b-container>
</template>
<script>

import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import { CarrierServiceMixin } from '@/mixims/CarrierServiceMixin'
import { OrganizationService } from '@/mixims/AdminServiceMixin'
import { ValidationsMixim } from '@/mixims/ValidationsMixim'
import { IntegrationServiceMixin } from '@/mixims/IntegrationServiceMixin'
import CarrierForm from './CarrierForm'
import PickupPoints from './PickupPoints.vue'
import appConfig from '@/config/applicationConfig.js'
import autocomplete from '@/components/common/vue-autocomplete'

export default {
  name: 'carrier-list',
  components: { MoonLoader, CarrierForm, autocomplete, PickupPoints},
  mixins: [CarrierServiceMixin, OrganizationService, ValidationsMixim, IntegrationServiceMixin],
  data () {
    return {
      success: null,
      errorText: '',
      errorTextModal: '',
      successTextModal: '',
      loading: true,
      loadingModal: false,
      showFilters: false,
      isAdmin: this.$store.getters.isAdmin,
      integrationData: [],
      integration: {},
      fields: [
        { key: 'slaType', label: this.$t('Tipo SLA'), 'class': 'text-left', sortable: false },
        { key: 'name', label: this.$t('Nombre'), 'class': 'text-left', sortable: false },
        { key: 'isActive', label: this.$t('Estado'), 'class': 'text-left', sortable: false },
        { key: 'actions', label: this.$t('Acciones'), 'class': 'text-left', sortable: false }
      ],
      fieldsAdmin: [
        { key: 'slaType', label: this.$t('Tipo SLA'), 'class': 'text-left', sortable: false },
        { key: 'name', label: this.$t('Nombre'), 'class': 'text-left', sortable: false },
        { key: 'isActive', label: this.$t('Estado'), 'class': 'text-left', sortable: false },
        { key: 'actions', label: this.$t('Acciones'), 'class': 'text-left', sortable: false }
      ],
      selectedCarrier: {
        name: '',
        slaType: null,
        selectedCarrierBase: null,
        isActive: false,
        factorCubicWeight: null

      },
      carrierFilterRules: {
        slaType: null
      },
      slaFilterOptions: [{ value: null, text: 'Todos los tipos de SLA' }, ...appConfig.SLA_LIST.map(trn => { return {value: trn, text: trn} })],
      organizationOptions: this.$store.state.user_data.sellers,
      selSeller: [],
      filterRules: {
        sortBy: 'name',
        sortOrder: false,
        size: 10,
        from: 0,
        text: ''
      },
      pageOptions: [ 10, 15, 25, 50, 100 ],
      fieldsSeller: [
        { key: 'accountName', label: this.$t('Cuenta'), 'class': 'text-left', sortable: true },
        { key: 'actions', label: ' ', 'class': 'text-right' }
      ],
      totalRowsSeller: 0,
      selectedEditCarrier: {},
      guiaOptions: [
        { text: this.$t('Guía manual'), value: 'MANUAL' },
        { text: this.$t('Guía automática'), value: 'AUTOMATIC', disabled: appConfig.AUTOMATIC_GUIDE_DISABLED }
      ],
      shippingsExcluded: appConfig.SHIPPING_EXCLUDED,
      sellersItems: null,
      carrierIntegration: false,
      carrierSelected: {},
      preloadIntegration: false
    }
  },
  methods: {
    resetFilters () {
      this.carrierFilterRules = {
        slaType: null
      }
      this.$refs.mainTable.refresh()
    },
    filter () {
      return this.carrierFilterRules
    },
    addIfNotPresent (list, item) {
      if (!list.includes(item)) list.push(item)
    },
    handleSave () {
      if (this.selectedCarrier.id) {
        this.updateCarrier(this.selectedCarrier).then(() => {
          this.success = true
          this.$refs.mainTable.refresh()
          this.$refs.carrierInfo.hide()
        }).catch(er => {
          this.errorText = er.body.message || this.$t('Ocurrió un error al modificar la transportadora')
        })
      } else {
        this.createCarrier(this.selectedCarrier).then(() => {
          this.success = true
          this.$refs.mainTable.refresh()
          this.$refs.carrierInfo.hide()
        }).catch(er => {
          this.errorText = er.body.message || this.$t('Ocurrió un error al crear la transportadora')
        })
      }
    },
    resetModal () {
      this.selectedCarrier = {
        name: '',
        slaType: null,
        selectedCarrierBase: null
      }
    },
    openCarrierModal (carrier) {
      this.selectedCarrier = {...carrier}
      /* Get the carrier base name from the carrier name */
      this.selectedCarrier.selectedCarrierBase = this.selectedCarrier.name.split('_')[1] ? this.selectedCarrier.name.split('_')[1] : null

      if (this.$store.state.user_data.organizations.length === 1) this.selectedCarrier.accountName = this.$store.state.user_data.organizations[0].accountName
      this.$refs.carrierInfo.show()
    },
    itemProvider (ctx) {
      this.loading = true
      let filter = ctx.filter()
      return this.getCarriers(filter).then(
        response => {
          this.loading = false
          this.$store.dispatch('setCarrierData', response.body)
          return response.body
        },
        error => {
          this.errorText = error.body.message || this.$t('Error obteniendo el listado de transportadoras')
          this.loading = false
          return []
        }
      )
    },
    showPickupPointsModal (item) {
      this.$refs.pickupPoints.openModal(item)
    },
    showSellerModal (item) {
      this.loadingModal = false
      this.errorTextModal = ''
      this.successTextModal = ''
      this.selectedEditCarrier = item
      this.carrierIntegration = false
      this.integrationData = []
      this.integration = {}
      this.preloadIntegration = false
      this._getIntegrationsData(item)
      this.$refs.sellersTable.refresh()
      this.selSeller.splice(0, this.selSeller.length)
      this.$refs.sellerModal.show()
    },
    showConfirmModal (item) {
      this.carrierSelected = item
      this.$refs.confirmModal.show()
    },
    disableHideButton (carrierType) {
      if (carrierType === 'ADMIN') {
        return !this.isAdmin
      } else {
        return this.isAdmin
      }
    },
    handleDeleteCarrier () {
      this.deleteCarrier(this.carrierSelected.id).then(
        () => {
          this.$refs.mainTable.refresh()
          this.success = true
        },
        (error) => {
          this.errorText = error
        }
      )
    },
    selectedSeller (seller) {
      this.selSeller = seller
    },
    addSeller () {
      this.loadingModal = true
      this.successTextModal = ''
      this.errorTextModal = ''
      let carrierSeller = {
        'accountName': this.selSeller[0].accountName,
        'carrierId': this.selectedEditCarrier.item.id,
        'isActive': true
      }
      if (this.carrierIntegration) {
        this.integration.accountName = this.selSeller[0].accountName
        this.integration.values = {
        }
        for (let i = 0; i < this.integrationData.length; i++) {
          this.integration.values[this.integrationData[i].name] = this.selSeller[0][this.integrationData[i].name]
        }
        this.postIntegrations(this.integration)
      }
      this.addSellerToCarrier(this.selectedEditCarrier.item.id, carrierSeller).then(
        response => {
          this.loadingModal = false
          this.successTextModal = this.$t('El seller fue agregado con exito')
          this.selSeller.splice(0, this.selSeller.length)
          this.$refs.sellersTable.refresh()
        },
        () => {
          this.selSeller.splice(0, this.selSeller.length)
          this.loadingModal = false
          this.errorTextModal = this.$t('Error agregando el seller')
        }
      )
    },
    onChangeSeller (item) {
      this.loadingModal = true
      this.successTextModal = ''
      this.errorTextModal = ''
      let carrierSeller = {
        'accountName': item.accountName,
        'carrierId': this.selectedEditCarrier.item.id,
        'isActive': true
      }
      this.addSellerToCarrier(this.selectedEditCarrier.item.id, carrierSeller).then(
        response => {
          this.loadingModal = false
          this.successTextModal = this.$t('El seller fue modificado con exito')
          this.$refs.sellersTable.refresh()
        },
        () => {
          this.loadingModal = false
          this.errorTextModal = this.$t('Error al modificar el seller')
        }
      )
    },
    onChangeSelected (item) {
      this.preloadIntegration = false
      if (item && item[0] && item[0].accountName && this.integration && this.integration.integrationDataId) {
        this.getIntegrations(
          {
            currentPage: 1,
            perPage: 1
          },
          {
            accountName: item[0].accountName,
            integrationDataId: this.integration.integrationDataId
          }
        ).then(result => {
          if (result.data && result.data[0] && result.data[0].values) {
            const keys = Object.keys(result.data[0].values)
            keys.forEach((key, index) => {
                this.selSeller[0][key] = result.data[0].values[key]
            })
          }
          this.preloadIntegration = true
        },
        error => {
          console.log('error obtaining integration')
          this.preloadIntegration = true
        })
      }
      
    },
    sellerProvider (tableFilter) {
      this.loadingModal = true
      if (!this.selectedEditCarrier.item) return []
      return this.getSellersFromCarrier(this.selectedEditCarrier.item.id, tableFilter).then(
        response => {
          this.loadingModal = false
          this.sellersItems = response.body
          return response.body
        },
        () => {
          this.loadingModal = false
          this.errorTextModal = this.$t('Error obteniendo el listado de sellers')
          return []
        }
      )
    },
    removeSeller (carrierSeller) {
      this.loadingModal = true
      this.successTextModal = ''
      this.errorTextModal = ''
      this.delSellerToCarrier(this.selectedEditCarrier.item.id, carrierSeller.id).then(
        response => {
          this.loadingModal = false
          this.selSeller.splice(0, this.selSeller.length)
          this.successTextModal = this.$t('El seller fue eliminado con exito')
          this.$refs.sellersTable.refresh()
        },
        () => {
          this.loadingModal = false
          this.selSeller.splice(0, this.selSeller.length)
          this.errorTextModal = this.$t('Error eliminando el seller')
        }
      )
    },
    stateIntegrationData (field) {
      return this.isValidText(field, true)
    },
    _getIntegrationsData (item) {
      this.getIntegrationsData({currentPage: 1, perPage: 10}, {reference: item.item.id || ' '}).then(result => {
        if (result.body && result.body.length) {
          if (result.body[0].reference.findIndex(ref => ref === item.item.id) > -1 && result.body[0].type === 'CARRIER') {
            this.fieldsSeller = [
              { key: 'accountName', label: this.$t('Cuenta'), 'class': 'text-left', sortable: true },
              { key: 'actions', label: ' ', 'class': 'text-right' }
            ]
            this.carrierIntegration = true
            this.integration.integrationDataId = result.body[0].id
            this.integrationData = result.body[0].values || []
          }
        }
      },
      () => {
        console.log('error obtiendo datos')
      })
    }
  },
  computed: {
    filterState () {
      return {
        name: Boolean(this.carrierFilterRules.name),
        slaType: Boolean(this.carrierFilterRules.slaType)
      }
    },
    disableSave () {
      return Object.values(this.state).includes(false)
    },
    state () {
      return {
        name: this.isValidText(this.selectedCarrier.name, true),
        slaType: this.isValidText(this.selectedCarrier.slaType, true),
        factorCubicWeight: this.isValidFloat(this.selectedCarrier.factorCubicWeight, false, true)
      }
    },
    addButtonDisabled () {
      if (this.selSeller.length) {
        let disabled = false
        this.integrationData.map((a) => {
          if (!this.selSeller[0][a.name]) {
            disabled = true
          }
        })
        return disabled
      }
      return true
    }
  }
}
</script>
<style lang="scss">
  .multiselect {
    &.multiselect--active {
      z-index: 3 !important;
    }
  }
  .guide-width {
    width: 278px !important;
  }
  .radio-guia {
    .invalid-feedback {
        display: block;
    }
  }
  .custom-carrier-form {
    .custom-radio {
      margin-bottom: 0;
    }
  }
  legend {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
</style>
