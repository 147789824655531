<template>
  <PickupPoints/>
</template>

<script>
import PickupPoints from '../components/pickupPoints/PickupPoints.vue'
export default {
  components: {
    PickupPoints
  }
}
</script>

<style>

</style>