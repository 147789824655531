<template>
  <b-container fluid class="animated fadeIn">
    <b-alert
      :show="dismissSec"
      fade
      dismissible
      :variant="alertVariant"
    >
      {{alertText}}
    </b-alert>

    <b-row class="mb-3">
      <b-col md="12">
        <button  @click="showCreateModal" type="button" class="btn btn-primary float-right">{{$t('Crear nuevo impuesto')}}</button>
        <button type="button" class="btn btn-primary float-right mr-2"  @click.stop="showFilters = !showFilters">
          <i class="fa" :class="{'fa-times': showFilters, 'fa-filter': !showFilters}" /> {{$t('Filtros')}}
        </button>
      </b-col>
    </b-row>

    <transition name="fade">
      <div class="filters my-3 p-3" v-show="showFilters">
        <b-row>
          <b-col lg="4" class="px-4 py-2">
            <b-form-group id="wordFilterGroup"
              :label-cols="4"
              breakpoint="md"
              :description="$t('Se buscan los impuestos por nombre')"
              :invalid-feedback="!isValidText(filters.text, false)? $t('No es un valor válido') : ''"
              :state="isValidText(filters.text, false)"
              :label="$t('Buscar')"
              label-for="textInput">
              <b-input-group>
                <b-form-input id="textInput" v-model="filters.text"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
           <b-col lg="4" class="px-4 py-2">
            <b-form-group id="zipCodeStartGroup"
              :label-cols="4"
              breakpoint="md"
              :description="$t('Se buscan impuestos que se aplican desde el código postal ingresado')"
              :invalid-feedback="!isValidInteger(filters.zipCodeStart, false)? $t('No es un valor válido') : ''"
              :state="isValidInteger(filters.zipCodeStart, false)"
              :label="$t('Código Postal Desde')"
              label-for="zipCodeStartInput">
              <b-input-group>
                <b-form-input id="zipCodeStartInput" v-model="filters.zipCodeStart"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="4" class="px-4 py-2">
            <b-form-group id="zipCodeEndGroup"
              :label-cols="4"
              breakpoint="md"
              :description="$t('Se buscan impuestos que se aplican hasta el código postal ingresado')"
              :invalid-feedback="!isValidInteger(filters.zipCodeEnd, false)? $t('No es un valor válido') : ''"
              :state="isValidInteger(filters.zipCodeEnd, false)"
              :label="$t('Código Postal Hasta')"
              label-for="zipCodeEndInput">
              <b-input-group>
                <b-form-input id="zipCodeEndInput" v-model="filters.zipCodeEnd"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4">
            <b-form-group
              id="activeInputGroup"
              :label-cols="2"
              breakpoint="md"
              :label="$t('Impuestos habilitados')">
                <c-switch
                  inputType="checkbox"
                  type="text"
                  variant="success"
                  :on="$t('Si')"
                  :off="$t('No')"
                  :pill="true"
                  v-model="filters.isActive"
                  size="m"
                  id="s0"></c-switch>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-input-group-button class="float-right mt-3">
              <b-button :disabled="loading" :block="true" variant="primary" @click.stop="filterTable" >{{$t('Filtrar')}}</b-button>
            </b-input-group-button>
            <b-input-group-button class="float-right mt-3 mr-3">
              <b-button :disabled="loading" :block="true" variant="primary" @click.stop="cleanFilters" >{{$t('Mostrar Todos')}}</b-button>
            </b-input-group-button>
          </b-col>
        </b-row>
      </div>
    </transition>

    <b-row class="mt-3">
      <b-col class="col-md-auto">
        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="mb-3" />
      </b-col>
      <b-col class="col-md-auto">
        <b-form-group
          id="perPage"
          horizontal
          :label="$t('Mostrar por página')"
          >
          <b-form-select :options="pageOptions" v-model="perPage" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col md="10">
        <b>{{totalRows}}</b> {{$t('impuestos encontrados')}}  {{selected.length != 0 ? `(${selected.length} ${$t('seleccionados')})` : ""}}
      </b-col>
    </b-row>
    <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Listado de impuestos')">
      <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
        <b-col md="3" class="my-2 pl-0">
          <b-form-select
            class="action-form-select"
            id="selectProductGroup"
            :options="productGroupSelection.options"
            required
            v-model="productGroupSelection.current"
            @click.native.stop
            @change="productGroupSelectionHandler">
          </b-form-select>
        </b-col>
      <b-table v-show="!loading" id="mainTable"
         ref="mainTable"
         show-empty
         striped
         hover
         stacked="xl"
         :items="itemProvider"
         foot-clone
         :fields="fields"
         :current-page="currentPage"
         :per-page="perPage"
         :sort-by.sync="sortBy"
         :sort-desc.sync="sortDesc"
         @filtered="onFiltered"
         :empty-text="$t('No hay impuestos para mostrar')"
         :empty-filtered-text="$t('No hay impuestos para mostrar')">
         <template slot="percentage" slot-scope="row">
           {{row.item.percentage}}%
         </template>
         <template slot="isActive" slot-scope="row">
           {{row.item.isActive ? 'Si' : 'No'}}
         </template>
        <template slot="actions" slot-scope="row" >
          <b-button size="sm" variant="warning" class="mr-2" @click.stop="showEditModal(row)">{{$t('Editar')}}</b-button>
          <b-button size="sm" variant="danger" @click.stop="showDeleteModal(row)">{{$t('Eliminar')}}</b-button>
        </template>
      </b-table>
    </b-card>
    <b-modal
      hide-footer
      header-bg-variant="primary"
      size="lg"
      ref="taxModal"
      id="taxModal"
      centered
      :title="this.tax.id ? $t('Editar impuesto') : $t('Nuevo impuesto')"
    >
      <tax-form :refreshPage="refreshTable" :closeModal="closeCreateModal" :taxSelected="tax" @showMessage="showMessage"/>
    </b-modal>

    <b-modal centered ref="deleteModal" header-bg-variant="primary" id="deleteModal" size="lg" :title="$t('Confirmar eliminar impuesto')" o-close-on-backdrop="true" :cancel-title="$t('Cancelar')" :ok-title="$t('Eliminar')" @ok="_execDelete" >
      {{$t('Confirma que desea eliminar este impuesto?')}}
    </b-modal>

  </b-container>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import cSwitch from '@/components/common/Switch.vue'
import TaxForm from '@/components/taxes/TaxForm.vue'
import { ValidationsMixim } from '@/mixims/ValidationsMixim.js'
import { TaxesServiceMixin } from '@/mixims/TaxesServiceMixin.js'
export default {
  components: {
    cSwitch,
    MoonLoader,
    TaxForm
  },
  mixins: [ValidationsMixim, TaxesServiceMixin],
  data () {
    return {
      alertText: '',
      alertVariant: 'success',
      dismissSec: 0,
      dismissCountDown: 3,
      filters: {
        isActive: true,
        text: '',
        zipCodeStart: '',
        zipCodeEnd: ''
      },
      loading: false,
      tax: {
        id: null,
        name: '',
        description: '',
        isActive: true,
        percentage: false,
        zipCodeStart: '',
        zipCodeEnd: ''
      },
      showFilters: false,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [ 10, 15, 25, 50, 100 ],
      selected: [],
      sortBy: null,
      sortDesc: true,
      currentPageElement: [],
      fields: [
        { key: 'name', label: this.$t('Nombre'), 'class': 'text-left', sortable: false },
        { key: 'zipCodeStart', label: this.$t('Cód. Postal Desde'), 'class': 'text-left', sortable: false, thClass: 'zipCodesTh' },
        { key: 'zipCodeEnd', label: this.$t('Cód. Postal Hasta'), 'class': 'text-left', sortable: false, thClass: 'zipCodesTh' },
        { key: 'percentage', label: this.$t('Porcentaje'), 'class': 'text-left', sortable: true },
        { key: 'isActive', label: this.$t('Activo'), 'class': 'text-left', sortable: true },
        { key: 'actions', label: this.$t('Acciones'), 'class': 'text-left', sortable: false }
      ],
      productGroupSelection: {
        options: [
          { text: this.$t('Seleccione una opción'), value: null, disabled: true },
          { text: this.$t('Ninguno'), value: 'Ninguno' },
          { text: this.$t('Todos en esta pagina'), value: 'Todos en esta pagina' },
          { text: this.$t('Todos'), value: 'Todos' }
        ],
        current: null
      },
      currentActionGroupSelection: null,
      groupAction: null,
      toDelete: null
    }
  },
  methods: {
    showCreateModal () {
      this.tax = {
        id: null,
        name: '',
        description: '',
        isActive: true,
        percentage: false,
        zipCodeStart: '',
        zipCodeEnd: ''
      }
      this.alertVariant = 'success'
      this.alertText = ''
      this.dismissSec = 0
      this.$refs.taxModal.show()
    },
    closeCreateModal () {
      this.$refs.taxModal.hide()
    },
    refreshTable () {
      this.$refs.mainTable.refresh()
    },
    filterTable () {
      this.$refs.mainTable.refresh()
      this.alertText = ''
    },
    cleanFilters () {
      this.filters = {
        isActive: true,
        text: '',
        zipCodeStart: '',
        zipCodeEnd: ''
      }
      this.showFilters = false
      this.$refs.mainTable.refresh()
    },
    productGroupSelectionHandler (selectionType) {
      this.selected.splice(0, this.selected.length)
      let _this = this
      let tableinfo = null
      switch (selectionType) {
        case 'Ninguno':
          break
        case 'Todos en esta pagina':
          this.currentPageElement.forEach(item => {
            this.selected.push(item.id)
          })
          break
        case 'Todos':
          this.loading = true
          tableinfo = {currentPage: 1, perPage: 100}
          this.getTaxes(tableinfo, this.filters, this.showFilters).then((response) => {
            response.body.forEach(item => {
              _this.selected.push(item.id)
            })
            this.loading = false
          })
          break
        default:
          break
      }
    },
     onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    itemProvider (ctx) {
      this.loading = true
      return this.getTaxes(ctx, this.filters, this.showFilters).then(
        response => {
          this.loading = false
          this.totalRows = parseInt(response.headers.get('X-Total-Count'))
          this.currentPageElement = response.body
          return response.body
        },
        error => {
          this.alertText = error.body.message || this.$t('Error obteniendo el listado de impuestos')
          this.loading = false
          return []
        }
      )
    },
    handleRowCheckbox () {
      this.productGroupSelection.current = null
    },
    showDeleteModal (row) {
      this.alertVariant = 'success'
      this.alertText = ''
      this.dismissSec = 0
      this.toDelete = row.item.id
      this.$refs.deleteModal.show()
    },
    _execDelete () {
      this.loading = true
      this.deleteTax(this.toDelete).then(
        () => {
          this.loading = false
          this.$refs.mainTable.refresh()
          this.alertVariant = 'success'
          this.alertText = this.$t('Impuesto eliminado exitosamente')
          this.dismissSec = this.dismissCountDown
        },
        (error) => {
          this.alertVariant = 'danger'
          this.alertText = error.body.message || this.$t('Error eliminando el impuesto')
          this.dismissSec = this.dismissCountDown
          this.loading = false
        }
      )
    },
    showEditModal (row) {
      this.alertVariant = 'success'
      this.alertText = ''
      this.dismissSec = 0
      this.tax = row.item
      this.$refs.taxModal.show()
    },
    showMessage (messageBody) {
      this.alertVariant = messageBody.variant
      this.alertText = messageBody.text
      this.dismissSec = this.dismissCountDown
    }
  }
}
</script>

<style lang="scss" scoped>
  .filters {
    border: 1px solid #e3e8ec;
    background-color: #FFF
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .separator {
    border-top: 1px solid #CCCCCC;
  }


  .vue-input-tag-wrapper {
    border: 1px solid #e3e8ec !important;
  }

</style>