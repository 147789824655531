<template>
  <b-row class="mb-3">
    <b-col cols="12">
      <b-btn class="float-right" variant="primary" @click="goToCreatePickupPoint">{{$t('Crear Punto de Retiro')}}</b-btn>
      <b-btn class="float-right mr-3" variant="secondary" @click="showFilters = !showFilters">
        <i class="fa" :class="{'fa-times': showFilters, 'fa-filter': !showFilters}" />{{$t('Filtros')}}
      </b-btn>
    </b-col>
    <b-col cols="12">
      <b-collapse id="collapseFilters" v-model="showFilters">
        <b-card class="mt-3 p-3">
          <b-form-group
            horizontal
            :label-cols="2"
            breakpoint="md"
            :label="$t('Buscar')"
            label-for="searchFilter"
          >
            <b-form-input
              type="text"
              id="searchFilter"
              v-model="filters.text">
            </b-form-input>
          </b-form-group>
          <b-form-group
            horizontal
            :label-cols="2"
            breakpoint="md"
            :label="$t('Activo')"
            label-for="isActiveFilter"
          >
            <b-form-radio-group
              type="text"
              id="isActiveFilter"
              v-model="filters.isActive">
              <b-form-radio :value="true">{{$t('Si')}}</b-form-radio>
              <b-form-radio :value="false">{{$t('No')}}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <b-row>
            <b-col>
              <button class="btn btn-primary float-right" :disabled='loading' @click="handleFilter">{{$t('Filtrar')}}</button>
              <button class="btn btn-primary float-right mr-2" :disabled='loading' @click="resetFilters">{{$t('Mostrar Todos')}}</button>
            </b-col>
          </b-row>
        </b-card>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data () {
    return {
      showFilters: false,
      filters: {
        text: '',
        isActive: true
      },
      loading: false
    }
  },
  methods: {
    goToCreatePickupPoint () {
      this.$router.push({name: 'Crear Punto de retiro'})
    },
    handleFilter () {
      this.$emit('filterTable', this.filters)
    },
    resetFilters () {
      this.filters = {
        text: '',
        isActive: true
      }
      this.showFilters = false
      this.$emit('showAll', {})
    }
  }
}
</script>

<style>

</style>