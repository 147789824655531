<template>
<div>

  <div v-show="errorText !== ''" v-html="errorText" class="alert alert-danger" role="alert">
    {{errorText}}
  </div>
  <div v-show="success" class="alert alert-success" role="alert">
    {{$t('Los precios fueron cambiados con éxito')}}
  </div>

  <b-modal header-bg-variant="primary" centered ref="processCSVModal" id="processCSVModal" size="lg" :title="$t('Guardar los cambios de precio')" o-close-on-backdrop="true" @ok="processAltaPrecios" :cancel-title="$t('Cancelar')">
    {{$t('¿Desea guardar los cambios de precios seleccionados?')}}
  </b-modal>
  <h4>{{$t('Actualización masiva de precios')}} </h4>
  <b-row class="my-3">
    <b-col md="12">
      <b-button
        :disabled="!Boolean(csvPricesFile) || errorText !== ''"
        v-on:click="showProcessConfirm"
        variant="primary"
        class="float-right mr-2">{{$t('Procesar')}}</b-button>
      <b-button
        @click="downloadPriceFile"
        variant="primary"
        class="float-right mr-2">{{$t('Obtener listado actual de precios CSV')}}</b-button>
    </b-col>
  </b-row>
  <moon-loader :loading="inProgress" color="#000" size="30px"></moon-loader>
  <b-tabs v-model="tabIndex">
    <b-tab :title="$t('Seleccione un archivo')" active>
      <h5>{{$t('Subir archivo CSV a procesar')}}</h5>
      <p v-show="!Boolean(csvPricesFile)" class="alert alert-warning"><i class="fa fa-exclamation-triangle"></i>{{$t('Se debe seleccionar un CSV para continuar.')}}</p>
      <p v-show="Boolean(csvPricesFile)">{{$t('Archivo CSV seleccionado:')}} <strong>{{csvPricesFile && csvPricesFile.name}}</strong></p>
      <b-row class="mb-2">
        <b-col md="6">
          <b-form-file id="csvPricesFile"
            ref="filePricestInput"
            :no-drop="true"
            @click.native="$refs.filePricestInput.reset()"
            accept=".csv"
            v-on:change="onFileChanged"
            v-model="csvPricesFile"
            :class="$i18n.locale"
            :placeholder="$t('Lista Precios CSV')"></b-form-file>
        </b-col>
      </b-row>
    </b-tab>
    <b-tab :title="$t('Previsualización de datos')" :disabled="!Boolean(csvPricesFile)">
      <b-row class="mt-3">
        <b-col class="col-md-auto">
          <b-pagination :total-rows="productsCount" :per-page="perPage" v-model="currentPage" class="mb-3" />
        </b-col>
        <b-col class="col-md-auto per-page">
          <b-form-group id="perPage" horizontal :label="$t('Mostrar por página')">
            <b-form-select :options="pageOptions" v-model="perPage" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Previsualizacion')">
            <h5>{{$t('Previsualización de precios a actualizar')}}</h5>
            <p>{{$t('Cantidad de precios:')}} <strong>{{productsCount}}</strong></p>
            <div class="w-100 overflow-x-auto">
              <b-table
                ref="productsTable"
                :items="itemProvider"
                :current-page="currentPage"
                :per-page="perPage"
                hover
                :fields="csvFields"
                :small="true"
                show-empty
                striped
                :empty-text="$t('No hay precios para mostrar')"
                stacked="lg"
                foot-clone>
              </b-table>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-tab>
  </b-tabs>
</div>
</template>

<script>
import appConfig from '@/config/applicationConfig.js'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import {ProductServiceMixim} from '@/mixims/ProductServiceMixim.js'
import {ValidationsMixim} from '@/mixims/ValidationsMixim.js'
import Papa from 'papaparse'

export default {
  components: {
    MoonLoader
  },
  mixins: [ProductServiceMixim, ValidationsMixim],
  methods: {
    resetCSVPricesFile () {
      this.$refs.filePricestInput.reset()
    },
    itemProvider (tableComponentInfo) {
      let start = (tableComponentInfo.currentPage - 1) * tableComponentInfo.perPage
      let end = tableComponentInfo.perPage * tableComponentInfo.currentPage
      return this.csvElements.slice(start, end)
    },
    showProcessConfirm () {
      this.$refs.processCSVModal.show()
    },
    processAltaPrecios () {
      this.uploadPricesFile()
    },
    downloadPriceFile () {
      this.errorText = ''
      this.getShippingRatesPriceList().then(
        (csvFile) => {
          let headers = csvFile.headers
          var blob = new Blob([csvFile.body], {
            type: { type: headers.map['content-type'] }
          })
          const blobURL = window.URL.createObjectURL(blob)
          const tempLink = document.createElement('a')
          tempLink.style.display = 'none'
          tempLink.href = blobURL
          tempLink.setAttribute('download', 'shippingRatesPriceList.csv')
          document.body.appendChild(tempLink)
          tempLink.click()
          document.body.removeChild(tempLink)
        },
        () => {
          this.errorText = this.$t('Error procesando la descarga')
        }
      )
    },
    uploadPricesFile () {
      this.inProgress = true
      this.success = false
      this.errorText = ''
      let formData = new FormData()
      formData.append('file', this.csvPricesFile)
      this.uploadCsvShippingRatesPriceList(formData, this.delimiter).then(
        () => {
          this.inProgress = false
          this.success = true
        },
        (error) => {
          this.inProgress = false
          this.errorText = error.body.message || `${this.$t('Error procesando el archivo CSV -')} ${this.csvPricesFile.name}`
          /* Mark every row as error */
          this.csvElements.forEach((elem, index) => {
            elem._rowVariant = 'danger'
          })
          this.$refs.productsTable.refresh()
        }
      )
    },
    onFileAttrChanged (evt) {
      this.errorText = ''
      this.success = false
    },
    /* On price csv file changed */
    onFileChanged (evt) {
      const self = this
      this.errorText = ''
      this.success = false
      this.csvElements = []
      self.$refs.productsTable.refresh()
      var file = evt.target.files[0]
      if (!file) {
        return
      }

      var reader = new FileReader()

      /* Parse the file */
      reader.onload = (evt) => {
        try {
          self.tabIndex = 1
          let rowError = false
          let parsedData = Papa.parse(
            evt.target.result.trim(),
            {
              delimiter: '',
              skipEmptyLines: true,
              encoding: 'ISO-8859-1'
            }
          )

          if (parsedData.errors.length > 0) {
            self.errorText = `${this.$t('El archivo tiene un formato que no es soportado')}`
            self.$refs.fileProductInput.reset()
            return
          }

          self.delimiter = parsedData.meta.delimiter

          let header = parsedData.data.shift()
          if (header.length !== 12) {
            self.errorText += this.$t('La cabecera del archivo CSV debe tener 12 columnas.')
            self.errorText += '<ul>'
            self.errorText += '<li>' + this.$t("Verificar que la cabecera es 'Id','Código postal desde', 'Código postal hasta', 'Peso desde', 'Peso hasta', 'Precio','Valor adicional (% del valor del producto)', 'Valor adicional por peso', 'Volumen máximo', 'Plazo de entrega', 'Transportadora', 'Códigos Postales'") + '</li>'
            self.errorText += '</ul>'

            return
          }

          this.productsCount = parsedData.data.length
          parsedData.data.forEach((elem, index) => {
            let newRowElem = {
              'id': elem[0],
              'zcFrom': elem[1],
              'zcTO': elem[2],
              'weightFrom': elem[3],
              'weightTo': elem[4],
              'price': elem[5],
              'addicionalValue': elem[6],
              'weightaddicionalValue': elem[7],
              'maxVolume': elem[8],
              'deliveryWindow': elem[9],
              //'country': elem[10],
              'carrier': elem[10],
              'zcCommas': elem[11],
              '_cellVariants': null
            }
            if (elem.length !== 12) {
              rowError = true
              newRowElem = {'_cellVariants': null}
              newRowElem.sku = `Faltan ${12 - elem.length} campos`
            } else {
              newRowElem._cellVariants = {}
              rowError = this.testZipCodes(elem[1], elem[2], elem[11], newRowElem) || this.testPrices(elem[5], elem[6], elem[7], newRowElem) ||
              this.testWeight(elem[3], elem[4], newRowElem) || !this.isValidInteger(elem[8]) || !this.isValidInteger(elem[9]) ||
              this.testCarrier(elem[10], newRowElem)
            }
            if (rowError) {
              self.errorText = this.$t('Hay filas con errores en el archivo CSV')
              self.errorText += '<ul>'
              self.errorText += '<li>' + this.$t('Verifique que las filas tengan 13 items igual que la cabecera.') + '</li>'
              self.errorText += '<li>' + this.$t('Verifique que las fechas tengan formato YYYY-MM-DD.') + '</li>'
              self.errorText += '<li>' + this.$t('Verifique que los precios sean números válidos.') + '</li>'
              if (this.testCarrier(elem[10], newRowElem)) {
                self.errorText += '<li>' + this.$t('Verifique que las transportadoras sean válidas.') + '</li>'
              }
              self.errorText += '</ul>'

              if (newRowElem._cellVariants === null) {
                newRowElem._rowVariant = 'danger'
              }
            } else {
              newRowElem._rowVariant = 'success'
            }
            self.csvElements.push(newRowElem)
          })
          this.$refs.productsTable.refresh()
        } catch (err) {
          console.log(err)
          this.errorText = this.$t('El archivo CSV de precios tiene fallas')
        }
      }
      reader.readAsText(file, 'ISO-8859-1')
    },
    testCarrier (carrier, newRowElem) {
      let ret = true
      const isAdmin = this.$store.getters.isAdmin
      this.$store.getters.carriers.forEach((item, index) => {
        if (item.name === carrier && item.isActive) {
          ret = false
          if (item.isActive && item.type === 'ADMIN' && !isAdmin) {
            ret = true
          } else return false
        }
      })
      if (ret) {
        newRowElem._cellVariants.carrier = 'danger'
        return true
      }
      return false
    },
    testCountry (country, newRowElem) {
      let ret = true
      appConfig.COUNTRY_CODES.forEach((elem, index) => {
        if (elem.value === country) {
          ret = false
          return false
        }
      })
      if (ret) {
        newRowElem._cellVariants.country = 'danger'
        return true
      }
      return false
    },
    testZipCodes (zcFrom, zcTO, zcCommas, newRowElem) {
      let ret = false
      if (this.isValidCP(zcFrom) && this.isValidCP(zcTO)) {
        return false
      } else {
        let zips = zcCommas.split('|')
        zips.forEach((elem, index) => {
          if (!this.isValidCP(elem)) {
            ret = true
            return true
          }
        })
      }
      if (ret) {
        newRowElem._cellVariants.zcFrom = 'danger'
        newRowElem._cellVariants.zcTO = 'danger'
        newRowElem._cellVariants.zcCommas = 'danger'
        return true
      }
    },
    testWeight (weightFrom, weightTo, newRowElem) {
      if (!this.isValidFloat(weightFrom, true, false)) {
        newRowElem._cellVariants.weightTo = 'danger'
        return true
      }
      if (!this.isValidFloat(weightTo, true, false)) {
        newRowElem._cellVariants.weightTo = 'danger'
        return true
      }
      if (Number(weightTo) < Number(weightFrom)) {
        newRowElem._cellVariants.weightTo = 'danger'
        return true
      }
      return false
    },
    testPrices (price, addicionalValue, weightaddicionalValue, newRowElem) {
      /* Precio de lista (Obligatorio) */
      if (isNaN(price) || Number(price) < 0) {
        newRowElem._cellVariants.price = 'danger'
        return true
      }

      /* Valor adicional es un % y es obligatiorio */
      if (isNaN(addicionalValue) || Number(addicionalValue) < 0 || Number(addicionalValue) > 100) {
        newRowElem._cellVariants.addicionalValue = 'danger'
        return true
      }

      /* Precio por peso adicional (Obligatorio) */
      if (isNaN(weightaddicionalValue) || Number(weightaddicionalValue) < 0) {
        newRowElem._cellVariants.weightaddicionalValue = 'danger'
        return true
      }

      return false
    }
  },
  data () {
    return {
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 50, 70, 100, 1000],
      tabIndex: 0,
      success: false,
      inProgress: false,
      productsCount: 0,
      errorText: '',
      debugFileExtencion: '',
      delimiter: null,
      csvPricesFile: null,
      csvElements: [],
      csvFields: [
        { key: 'id', label: this.$t('Id') },
        { key: 'zcFrom', label: this.$t('CP desde') },
        { key: 'zcTO', label: this.$t('CP hasta') },
        { key: 'weightFrom', label: this.$t('Peso desde') },
        { key: 'weightTo', label: this.$t('Peso hasta') },
        { key: 'price', label: this.$t('Precio') },
        { key: 'addicionalValue', label: this.$t('Valor adicional (%)') },
        { key: 'weightaddicionalValue', label: this.$t('Valor adicional por peso') },
        { key: 'maxVolume', label: this.$t('Volumen Máximo') },
        { key: 'deliveryWindow', label: this.$t('Plazo de entrega') },
        // { key: 'country', label: this.$t('País') },
        { key: 'carrier', label: this.$t('Transportadora') },
        { key: 'zcCommas', label: this.$t('CP commas') }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>

  .overflow-x-auto {
    overflow-x: auto;
  }

  .b-form-file.custom-file {
    .custom-file-control[data-choose] {
      &::before {
          content: "Seleccionar" !important;
      }
    }
  }

  .fa-exclamation-triangle {
    color: #f8cb00;
    font-size: 20px;
    float: left;
    margin-right: 5px;
    display: inline-block;
  }

</style>
