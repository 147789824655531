<template>
  <div>
    <product-detail :productId="productId"/>
  </div>
</template>

<script>
import ProductDetail from '@/components/catalog/ProductDetail'

export default {
  name: 'product-details-view',
  props: ['productId'],
  components: {
    ProductDetail
  }
}
</script>
