<template>
  <div class="animated fadeIn">
    <b-container fluid class="product-list">
      <div v-show="errorText !== ''" v-html="errorText" class="alert alert-danger" role="alert">
        {{$t(errorText)}}
      </div>
      <div v-show="successText" class="alert alert-success" role="alert">
        {{$t(successText)}}
      </div>

      <b-row class="filter-row">
        <b-col md-=12>
          <button type="button" class="btn btn-primary float-right ml-2"  @click.stop="showFilters = !showFilters">
            <i class="fa" :class="{'fa-times': showFilters, 'fa-filter': !showFilters}" /> {{$t('Filtros')}}
          </button>
          <button type="button" class="btn btn-primary float-right ml-2"  @click.stop="showDownloadCSVFileModal">
            {{$t('Exportar CSV')}}
          </button>
          <button type="button" class="btn btn-primary float-right ml-2"  @click.stop="goToImportFilePage">
            {{$t('Importar CSV')}}
          </button>
        </b-col>
      </b-row>

      <transition name="fade">
        <div class="filters mt-3 p-3 mb-3" v-show="showFilters">
          <b-row>
            <b-col lg="3" class="px-4 py-2">
               <b-form-group id="nombre"
                :label-cols="4"
                breakpoint="md"
                class="mb-0"
                :label="$t('Buscar')"
                :invalid-feedback="!isValidText(filter.text, false)? $t('No es un valor válido') : ''"
                :state="isValidText(filter.text, false)"
                :description="$t('Este campo de filtro realizará la busqueda en los nombres, id, UPC y SKU del inventario')"
                label-for="nameFormatter">
                <b-input-group>
                  <b-form-input :state="isValidText(filter.text, false)" id="nameFormatter" v-model="filter.text" :placeholder="$t('Texto a buscar')" />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="3" class="px-4 py-2">
              <b-form-group 
                id="statusInput"
                :label-cols="4"
                breakpoint="md"
                :label="$t('Estado')"
                label-for="statusInput">
                <b-form-select id="statusInput" v-model="filter.estado">
                  <option slot="first" :value="null">{{$t('Todos')}}</option>
                  <option v-for="item in estados" 
                    :key="item.value"
                    :value="item.value">{{$t(item.text)}}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="3" class="px-4 py-2">
              <b-form-group 
                id="categoryInput"
                :label-cols="4"
                breakpoint="md"
                :label="$t('Categoría')"
                label-for="categoryFormatter">
                <category-tree id='selectCategory'
                  :categorySelected="filter.category"
                  @onCategorySelected="selectCategory">
                </category-tree>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-input-group-button class="float-right mt-3">
                <b-button :disabled="inventoryListLoading" :block="true" variant="primary" @click.stop="filterTable" >{{$t('Filtrar')}}</b-button>
              </b-input-group-button>
              <b-input-group-button class="float-right mt-3 mr-3">
                <b-button :disabled="inventoryListLoading" :block="true" variant="primary" @click.stop="cleanFilters" >{{$t('Mostrar Todo')}}</b-button>
              </b-input-group-button>
            </b-col>
          </b-row>
        </div>
      </transition>

      <b-tabs v-model="tabIndex">
        <b-tab :title="$t('Inventario')" active>
          <b-card id="mainCard" class="mt-3" header-bg-variant="primary" border-variant="primary" :header="$t('Listado de inventario actual (Click en la fila para editar manualmente)')">
            <b-row class="mt-3">
              <b-col class="col-md-auto">
                <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="mb-3" />
              </b-col>
              <b-col class="col-md-auto per-page">
                <b-form-group id="perPage" horizontal :label="$t('Mostrar por página')">
                  <b-form-select id="perPage" :options="pageOptions" v-model="perPage" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-md-auto" align-self="start">
                {{$t('Elementos de inventario encontrados')}} <b>{{totalRows}}</b>
              </b-col>
            </b-row>
            <moon-loader :loading="inventoryListLoading" color="#000" size="30px"></moon-loader>
            <b-table class="mt-3" v-show="!inventoryListLoading" id="mainTable"
              ref="mainTable"
              show-empty
              striped
              hover
              stacked="md"
              :items="itemProvider"
              foot-clone
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              @filtered="onFiltered"
              responsive=true
              :empty-text="$t('No hay inventario para mostrar')"
              :empty-filtered-text="$t('No hay inventario para mostrar')">
              <template slot="category" slot-scope="row">
                {{row.item.category.name}}
              </template>
              <template slot="brand" slot-scope="row">
                {{row.item.brand.name}}
              </template>
              <template slot="action" slot-scope="row">
                <b-button v-if="row.item.skus.length > 0" size="sm" variant="link" @click.stop="row.toggleDetails">
                  <span v-show="!row.detailsShowing"><i class="fa fa-plus"></i></span>
                  <span v-show="row.detailsShowing"><i class="fa fa-minus"></i></span>
                  {{ row.detailsShowing ? $t('Ocultar') : $t('Ver') }} {{$t('Variantes')}}  ({{row.item.skus.length}})
                </b-button>
                <span v-else><b>{{$t('No contiene variantes')}}</b></span> 
              </template>
              <template slot="row-details" slot-scope="row">
                <b-card>
                  <b-table v-show="!inventoryListLoading" id="productTable"
                    ref="variantTable"
                    show-empty
                    hover
                    :items="row.item.skus"
                    :fields="fieldsVariant"
                    responsive=true
                    @row-clicked="variantClicked"
                    stacked="xl"
                    :empty-text="$t('No hay variantes para mostrar')"
                    :empty-filtered-text="$t('No hay variantes para mostrar')">
                    <template slot="listPrice" slot-scope="row">
                      {{currency}} {{row.item.price.listPrice}}
                    </template>
                    <template  slot="inventory" slot-scope="row">
                      <div class="inventoryContainer" v-b-tooltip.hover :title="$t('La cantidad entre parentesis es stock reservado')">
                        <span class="inventoryTotal">{{row.item.inventory.totalQuantity}}</span>
                        <span class="inventoryReserved">({{row.item.inventory.reservedQuantity ? row.item.inventory.reservedQuantity : 0}})</span>
                      </div>
                    </template>
                    <template slot="price" slot-scope="row">
                      {{currency}} {{row.item.price.price}}
                    </template>
                    <template slot="status"  slot-scope="data">
                      <b v-if="data.value === 'APPROVED'" class="text-success">
                        {{$t(estados.find(p => p.value === data.value).text)}}
                      </b>
                      <b v-if="data.value === 'REJECTED'" class="text-danger">
                        {{$t(estados.find(p => p.value === data.value).text)}}
                      </b>
                      <b v-if="data.value === 'PENDING_APPROVAL'" class="text-warning">
                        {{$t(estados.find(p => p.value === data.value).text)}}
                      </b>
                    </template>
                  </b-table>
                </b-card>
              </template>
            </b-table>
            <b-row class="mb-3">
              <b-col md="6" class="my-1">
                <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
              </b-col>
            </b-row>
          </b-card>
        </b-tab>

        <b-tab :title="$t('Alta de inventario por CSV')" >
          <b-card id="csvCard" class="mt-3" header-bg-variant="primary" border-variant="primary" :header="$t('Listado de inventario que sera importado')">
            <p>{{$t('Archivo')}}: <b>{{csvInventoryFile? csvInventoryFile.name : $t('No hay archivo seleccionado')}}</b></p>
            <p>{{$t('Cantidad de elementos')}}: <b>{{totalRowsCSV}}</b></p>
            <b-row class="mt-3">
              <b-col class="col-md-auto">
                <b-pagination :total-rows="totalRowsCSV" :per-page="perPageCSV" v-model="currentPageCSV" class="mb-3" />
              </b-col>
              <b-col class="col-md-auto per-page">
                <b-form-group id="perPage" horizontal :label="$t('Mostrar por página')">
                  <b-form-select :options="pageOptionsCSV" v-model="perPageCSV" />
                </b-form-group>
              </b-col>
            </b-row>
            <moon-loader :loading="csvEditionInProgress" color="#000" size="30px"></moon-loader>
            <b-table id="" v-show="!csvEditionInProgress"
              ref="inventoryTable"
              :items="itemProviderCSV"
              hover
              :fields="fieldsCSV"
              :small="true"
              show-empty
              :current-page="currentPageCSV"
              :per-page="perPageCSV"
              striped
              stacked="md"
              foot-clone
              :empty-text="$t('No hay filas para mostrar')">
                <template slot="sku" slot-scope="sku">
                  {{sku.value === '' ? '(new)' : sku.value}}
                </template>
                <template slot="image" slot-scope="image">
                  {{image.value.split('/').pop()}}
                </template>
            </b-table>

            <button :disabled="!Boolean(csvInventoryFile) || errorText !== ''" type="button" class="btn btn-primary float-right ml-2"  @click.stop="processCSVFile">
              {{$t('Procesar CSV')}}
            </button>
            <button type="button" class="btn btn-primary float-right ml-2"  @click.stop="resetCSVList">
              {{$t('Limpiar')}}
            </button>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-container>

    <b-modal header-bg-variant="primary" size="lg" ref="selectInventoryCSV" id="selectInventoryCSV" centered :title="$t('Importar CSV')">
      <h5>{{$t('Seleccione el archivo CSV que desea importar')}}</h5>
      <b-form-file
        id="productFileInput"
        ref="inventoryCSVInput"
        :no-drop="true"
        @click.native="$refs.inventoryCSVInput.reset()"
        accept=".csv"
        v-on:change="onInventoryFileChanged"
        v-model="csvInventoryFile"
        :class="$i18n.locale"
        :placeholder="$t('Inventario CSV')">
      </b-form-file>
    </b-modal>

    <b-modal header-bg-variant="primary" size="lg" ref="downloadInventoryCSV" id="downloadInventoryCSV" centered :title="$t('Bajar modelo CSV')" @ok="downloadCSVFile">
      <h5>{{$t('Confirma que desea bajar el modelo de inventario?')}}</h5>
    </b-modal>

    <b-modal
      header-bg-variant="primary"
      size="lg"
      ref="manualInventoryEdition"
      id="manualInventoryEdition"
      centered
      :title="$t('Editar inventario')"
      :ok-title="$t('Guardar')"
      :ok-disabled="!isValidInteger(edition.newQuantity)"
      @ok="confirmationInventoryEdition"
      :cancel-title="$t('Cancelar')">
      <div v-show="!manualEditionInProgress">
        <p>{{$t('UPC')}}: <b>{{edition.upc}}</b></p>
        <p>{{$t('SKU')}}: <b>{{edition.sku}}</b></p>
        <p>{{$t('Producto')}}: <b>{{edition.name}}</b></p>
        <p>{{$t('Stock reservado')}}: <b>{{edition.newReservedQuantity}}</b></p>
        <b-form-group
          id="newInventoryValueLabel"
          :label-cols="4"
          breakpoint="md"
          :label="$t('Nuevo valor de inventario')"
          :invalid-feedback="!isValidInteger(edition.newQuantity, true)? $t('No es un valor válido') : ''"
          :state="isValidInteger(edition.newQuantity, true)"
          label-for="newInventoryValue">
          <b-input-group>
            <b-form-input ref="inventoryInput" maxlength="8" id="newInventoryValue" v-model="edition.newQuantity"></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <moon-loader :loading="manualEditionInProgress" color="#000" size="30px"></moon-loader>
    </b-modal>
  </div>
</template>

<script>
// Date Picker
import Vue from 'vue'
import datePicker from 'vue-bootstrap-datetimepicker'
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'
import CategoryTree from '../catalog/CategoryTree'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import {InventoryServiceMixim} from '@/mixims/InventoryServiceMixim.js'
import {ProductServiceMixim, PRODUCT_STATES} from '@/mixims/ProductServiceMixim.js'
import {ValidationsMixim} from '@/mixims/ValidationsMixim.js'
import appConfig from '@/config/applicationConfig.js'
import Papa from 'papaparse'

Vue.use(datePicker)

export default {
  name: 'inventory-list',
  components: { MoonLoader, CategoryTree },
  mixins: [InventoryServiceMixim, ValidationsMixim, ProductServiceMixim],
  data () {
    return {
      locale: appConfig.LOCALE,
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : '$',
      fields: [
        { key: 'id', label: 'Id', 'class': 'text-left', sortable: true },
        { key: 'name', label: this.$t('Nombre'), 'class': 'text-left', sortable: true },
        { key: 'category', label: this.$t('Categoría'), 'class': 'text-left', sortable: true },
        { key: 'brand', label: this.$t('Marca'), 'class': 'text-left', sortable: true },
        { key: 'action', label: ' ', 'class': 'text-right', sortable: false }
      ],
      fieldsCSV: [
        { key: 'sku', label: this.$t('SKU'), 'class': 'text-left' },
        { key: 'upc', label: this.$t('UPC'), 'class': 'text-left' },
        { key: 'name', label: this.$t('Nombre'), 'class': 'text-left' },
        { key: 'indentify', label: this.$t('Número identificador'), 'class': 'text-left' },
        { key: 'quantity', label: this.$t('Cantidad'), 'class': 'text-left' },
        { key: 'reservedQuantity', label: this.$t('Reservado'), 'class': 'text-left' },
        { key: 'site', label: this.$t('Sitio'), 'class': 'text-left' }
      ],
      fieldsVariant: [
        { key: 'skuName', label: this.$t('Nombre'), 'class': 'text-left' },
        { key: 'upc', label: this.$t('UPC'), 'class': 'text-left' },
        { key: 'id', label: this.$t('SKU'), 'class': 'text-left' },
        { key: 'listPrice', label: this.$t('Precio regular'), 'class': 'text-left' },
        { key: 'price', label: this.$t('Precio con descuento'), 'class': 'text-left' },
        { key: 'inventory', label: this.$t('Inventario'), 'class': 'text-left' },
        { key: 'status', label: this.$t('Estado'), 'class': 'text-left' }
      ],
      estados: PRODUCT_STATES,
      edition: {
        sku: '',
        upc: '',
        name: '',
        newQuantity: '',
        reservedStock: ''
      },
      csvInventoryFile: null,
      csvInventoryElements: [],
      isAdmin: this.$store.getters.isAdmin,
      currentPage: 1,
      currentPageCSV: 1,
      perPageCSV: 10,
      totalRowsCSV: 0,
      delimiter: null,
      perPage: 10,
      totalRows: 0,
      pageOptions: [ 10, 15, 25, 50, 100 ],
      pageOptionsCSV: [ 10, 15, 25, 50, 100 ],
      sortBy: 'id',
      sortDesc: true,
      errorText: '',
      successText: '',
      tabIndex: 0,
      filter: {
        sku: {desde: null, hasta: null},
        upc: {desde: null, hasta: null},
        category: {id: null, text: null},
        fromQuantity: null,
        toQuantity: null,
        fecha: {
          desde: null,
          hasta: null
        }
      },
      showFilters: false,
      tableFilterInfo: null,
      manualEditionInProgress: false,
      csvEditionInProgress: false,
      inventoryListLoading: false
    }
  },
  computed: {
    sortOptions () {
      /* Create an options list from our fields */
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    }
  },
  methods: {
    confirmationInventoryEdition (evt) {
      this.successText = ''
      this.errorText = ''
      evt.preventDefault()
      this.manualEditionInProgress = true
      this.updateInventory(this.edition.sku, this.edition.newQuantity, this.edition.newReservedQuantity).then(
        () => {
          this.$refs.manualInventoryEdition.hide()
          this.manualEditionInProgress = false
          this.$refs.mainTable.refresh()
          this.successText = this.$t('Se actualizó correctamente el inventario para') + ` ${this.edition.sku}`
        },
        (error) => {
          this.$refs.manualInventoryEdition.hide()
          this.manualEditionInProgress = false
          this.errorText = error.body.message || this.$t('Error actualizando el inventario de') + `  ${this.edition.sku}`
        }
      )
    },
    onInventoryFileChanged (evt) {
      const self = this
      this.successText = ''
      this.errorText = ''
      this.csvInventoryElements = []
      this.$refs.inventoryTable.refresh()
      var file = evt.target.files[0]
      if (!file) {
        return
      }

      var reader = new FileReader()
      /* Parse the file */
      reader.onload = (evt) => {
        try {
          let rowError = false
          let parsedData = Papa.parse(
            evt.target.result.trim(),
            {
              skipEmptyLines: true,
              encoding: 'ISO-8859-1'
            }
          )

          if (parsedData.errors.length > 0) {
            self.errorText = this.$t('El archivo tiene un formato que no es soportado')
            self.$refs.inventoryCSVInput.reset()
            return
          }

          self.delimiter = parsedData.meta.delimiter
          if (parsedData.data.length === 0) {
            self.errorText = this.$t('El archivo CSV no contiene datos')
            this.$refs.selectInventoryCSV.hide()
            return
          }

          let header = parsedData.data.shift()
          if (header.length !== 7) {
            self.errorText += this.$t('La cabecera del archivo CSV debe tener 7 columnas.')
            self.errorText += '<ul>'
            self.errorText += '<li>' + this.$t('Verificar que la cabecera es SKU; UPC; Número identificador; Nombre Producto; Total; Reservado; Sitios') + '</li>'
            self.errorText += '</ul>'
            return
          }

          parsedData.data.forEach((elem, index) => {
            let newRowElem = {
              'sku': elem[0],
              'upc': elem[1],
              'indentify': elem[2],
              'name': elem[3],
              'quantity': elem[4],
              'reservedQuantity': elem[5],
              'site': elem[6],
              '_cellVariants': null
            }
            if (elem.length !== 7) {
              rowError = true
              newRowElem = {'_cellVariants': null}
              newRowElem.sku = this.$t('Faltan') + ` ${7 - elem.length} ` + this.$t('campos')
            } else {
              newRowElem._cellVariants = {}
              rowError = this.testQuantity(elem[4], newRowElem)
            }

            if (rowError) {
              self.errorText = this.$t('Hay filas con errores en el archivo CSV')
              self.errorText += '<ul>'
              self.errorText += '<li>' + this.$t('Verifique que las filas tengan la cantidad de items del header (7).') + '</li>'
              self.errorText += '<li>' + this.$t('Verifique que los valores numericos sean correctos.') + '</li>'
              self.errorText += '</ul>'

              if (newRowElem._cellVariants === null) {
                newRowElem._rowVariant = 'danger'
              }
            } else {
              newRowElem._rowVariant = 'success'
            }
            self.csvInventoryElements.push(newRowElem)
          })
          this.totalRowsCSV = this.csvInventoryElements.length
          this.tabIndex = 1
          this.$refs.inventoryTable.refresh()
        } catch (err) {
          this.errorText = this.$t('El archivo CSV tiene fallas')
        }
      }
      this.$refs.selectInventoryCSV.hide()
      reader.readAsText(file, 'ISO-8859-1')
    },
    testQuantity (quantity, newRowElem) {
      if (isNaN(quantity)) {
        newRowElem._cellVariants.quantity = 'danger'
        return true
      }
      return false
    },
    showDownloadCSVFileModal () {
      this.$refs.downloadInventoryCSV.show()
    },
    downloadCSVFile () {
      this.getInventoryCSV().then(
        (csvFile) => {
          let headers = csvFile.headers
          var blob = new Blob([csvFile.body], {
            type: { type: headers.map['content-type'] }
          })
          const blobURL = window.URL.createObjectURL(blob)
          const tempLink = document.createElement('a')
          tempLink.style.display = 'none'
          tempLink.href = blobURL
          tempLink.setAttribute('download', 'inventarioCSV.csv')
          document.body.appendChild(tempLink)
          tempLink.click()
          document.body.removeChild(tempLink)
        },
        (error) => {
          this.errorText = error.body.message || this.$t('Error obteniendo el inventario')
        }
      )
    },
    resetCSVList () {
      this.csvInventoryElements = []
      this.totalRowsCSV = this.csvInventoryElements.length
      this.errorText = ''
      this.successText = ''
      this.$refs.inventoryTable.refresh()
    },
    processCSVFile () {
      this.csvEditionInProgress = true
      this.success = false
      this.errorText = ''

      this.addInventoryCSV(this.csvInventoryFile, this.delimiter).then(
        () => {
          this.$refs.mainTable.refresh()
          this.csvEditionInProgress = false
          this.successText = this.$t('El archivo') + ` ${this.csvInventoryFile.name} ` + this.$t('fue procesado con éxito')
        },
        (error) => {
          this.csvEditionInProgress = false
          this.errorText = error.body.message || this.$t('Error procesando el archivo CSV -') + ` ${this.csvInventoryFile.name}`
          /* Mark every row as error */
          this.csvInventoryElements.forEach((elem, index) => {
            elem._rowVariant = 'danger'
          })
          this.$refs.inventoryTable.refresh()
        }
      )
    },
    goToImportFilePage () {
      this.successText = ''
      this.errorText = ''
      this.tabIndex = 1
      this.$refs.inventoryCSVInput.reset()
      this.$refs.selectInventoryCSV.show()
    },
    cleanFilters () {
      this.tabIndex = 0
      this.filter = {
        cuenta: null,
        seller: null,
        name: null,
        estado: null,
        sku: {desde: null, hasta: null},
        upc: {desde: null, hasta: null},
        fecha: {desde: null, hasta: null},
        category: {id: null, text: null},
        fromQuantity: null,
        toQuantity: null
      }
      this.$refs.mainTable.refresh()
    },
    selectCategory (res) {
      this.filter.category = res
    },
    variantClicked (item) {
      this.edition.sku = item.id
      this.edition.upc = item.upc
      this.edition.name = item.skuName
      this.edition.newQuantity = item.inventory.totalQuantity
      this.edition.newReservedQuantity = item.inventory.reservedQuantity
      this.successText = ''
      this.errorText = ''
      this.$refs.manualInventoryEdition.show()
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filterTable () {
      this.tabIndex = 0
      this.$refs.mainTable.refresh()
    },
    itemProviderCSV (tableComponentInfoCSV) {
      let start = (tableComponentInfoCSV.currentPage - 1) * tableComponentInfoCSV.perPage
      let end = tableComponentInfoCSV.perPage * tableComponentInfoCSV.currentPage
      return this.csvInventoryElements.slice(start, end)
    },
    itemProvider (tableFilterInfo) {
      this.tableFilterInfo = tableFilterInfo
      this.inventoryListLoading = true
      return this.getProducts(tableFilterInfo, this.filter).then(
        (response) => {
          this.inventoryListLoading = false
          this.totalRows = parseInt(response.headers.get('X-Total-Count'))
          return response.body
        },
        (error) => {
          this.inventoryListLoading = false
          this.errorText = error.body.message || this.$t('Error obteniendo el inventario')
          return []
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
  .product-list {

    .btn-link {
      color: #666666;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }

    .filters {
      border: 1px solid #e3e8ec;
      background-color: #FFF
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }

    tr:hover {
      cursor: pointer
    }
    .inventoryContainer {
      display: inline-block;
    
      .inventoryTotal {
        font-weight: bolder;
        font-size: 16px;
      }

      .inventoryReserved {
        color: #777;
      }
    }
  }
</style>
