<template>
  <div class="login-bg app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">

        <b-col md="8">
          <b-form id="userLogin" name="userLogin" autocomplete="on" @submit="login">
            <b-card-group>
              <b-card no-body class="p-4">
                <h5 v-show="waitingLogin">{{$t('Ingresando a Seller Center...')}}</h5>
                <moon-loader :loading="waitingLogin" color="#000" size="30px"></moon-loader>
                <b-card-body v-show="!waitingLogin">
                  <h2>{{$t('Bienvenido a Seller Center')}}</h2>
                  <b-alert :show="errorText !== ''" fade dismissible variant="danger" >{{$t(errorText)}}</b-alert>
                  <p class="text-muted">{{$t('Ingrese sus datos para ingresar a Seller Center.')}}</p>
                    <b-form-group
                      :label="$t('Nombre')"
                      label-for="userEmail"
                      :invalid-feedback="!userState? $t('Debe ingresar un email valido'): ''"
                      :state="userState"
                      :description="$t('Ingrese el email con que fue creada su cuenta')"
                    >
                      <b-form-input trim id="userEmail" name="userEmail" type="email" :state="userState" :placeholder="$t('mail@dominio.com')" v-model="username" required :title="$t('Ingrese un email válido')" autocomplete="userEmail"> </b-form-input>
                    </b-form-group>
                    <b-form-group
                      :label="$t('Contraseña')"
                      label-for="password"
                      :invalid-feedback="!passwordState? $t('Ingrese su password') : ''"
                      :state="passwordState"
                    >
                    <b-form-input id="password" trim  name="password" type="password" :state="passwordState" :placeholder="$t('Password')" v-model="password" required autocomplete="password" :title="$t('Ingrese su password')"></b-form-input>
                    </b-form-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button type="submit" variant="primary" class="px-4" :disabled="loginDisabled">{{$t('Login')}}</b-button>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-card-group>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>

import {UserServiceMixim} from '@/mixims/UserServiceMixim.js'
import {ProductServiceMixim} from '@/mixims/ProductServiceMixim.js'
import {OrdersServiceMixim} from '@/mixims/OrdersServiceMixim'
import {NotificationServiceMixim} from '../../mixims/NotificationServiceMixim.js'
import { ValidationsMixim } from '@/mixims/ValidationsMixim'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

export default {
  name: 'Login',
  mixins: [UserServiceMixim, ProductServiceMixim, OrdersServiceMixim, NotificationServiceMixim, ValidationsMixim],
  components: { MoonLoader },
  computed: {
    userState () {
      /* eslint-disable no-useless-escape */
      return this.validateEmail(this.username, true)
    },
    passwordState () {
      return this.password.length > 0
    },
    loginDisabled () {
      return this.waitingLogin || !this.passwordState || !this.userState
    }
  },
  data () {
    return {
      username: '',
      password: '',
      waitingLogin: false,
      errorText: ''
    }
  },
  methods: {
    login (e) {
      e.preventDefault()
      if (!this.userState || !this.passwordState || this.waitingLogin) return
      localStorage.setItem('timer', false)
      this.waitingLogin = true
      this.loginRequest(this.username, this.password).then(
        (response) => {
          this.$session.start()
          this.$session.set('token', response.body.access_token)
          this.$store.dispatch('setLoginData', response.body)
          let _this = this
          this.getUserData().then(
            (response) => {
              this.$session.set('userData', response.body)
              this.$store.dispatch('setUserData', response.body)
              let hasProductPermission = this.$store.getters.signedRoles.includes('PRODUCT_READ')
              let hasCarrierPermission = this.$store.getters.signedRoles.includes('CARRIER_READ')
              try {
                /* Get categories and brands, after that store it */
                Promise.all([
                  hasProductPermission ? this.getCategories() : null,
                  hasCarrierPermission ? this.getCarrierData() : null
                ]).then(
                  (response) => {
                    if (response[0] !== null) {
                      _this.$store.dispatch('setCategoryData', response[0].body)
                    }
                    if (response[1] !== null) {
                      _this.$store.dispatch('setCarrierData', response[1].body)
                    }
                    this.waitingLogin = false
                    this.$router.push('/dashboard')
                  },
                  (error) => {
                    this.waitingLogin = false
                    this.errorText = error.body.message || 'Error obteniendo datos de la aplicacion'
                  }
                )
              } catch (e) {
                this.errorText = this.$t('Ha ocurrido un error en el Inicio de Sesión')
                this.waitingLogin = false
              }
            },
            (error) => {
              this.waitingLogin = false
              this.errorText = error.body.message || 'Error obteniendo datos de la aplicacion'
            }
          )
        },
        (error) => {
          this.waitingLogin = false
          this.loginError = true
          this.errorText = error.body.message || 'Error al autenticar al usuario'
        }
      )
    }
  }
}
</script>

<style lang="css" scoped>
  .error-text {
    text-align: center;
    color: red;
    font-size: 15px;
  }

  h5 {
    text-align: center;
  }

  .btn-primary.disabled, .btn-primary:disabled {
    cursor: default;
  }
</style>
