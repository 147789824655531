import appConfig from '@/config/applicationConfig.js'

export const AdminServiceMixin = {
  data () {
    return {
      USER_ENDPOINT: `${appConfig.API_END_POINT}/admin/user`,
      USER_HEADER: {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      },
      ADMIN_READ_PERMISSION: 'ADMIN_READ',
      ADMIN_WRITE_PERMISSION: 'ADMIN_WRITE',
      userRoles: this.$store.state.user_data
    }
  },
  methods: {
    getUsers (filter) {
      if (this.userRoles.roles[0].permissions.some(r => r.name === this.ADMIN_READ_PERMISSION)) {
        let endpoint = new URL(this.USER_ENDPOINT)
        Object.keys(filter).forEach(
          key => {
            let value = filter[key]
            if (key === 'sortOrder') value = value ? 'asc' : 'desc'
            if (key === 'from') --value
            if (value !== null) {
              endpoint.searchParams.append(key, value)
            }
          }
        )
        return this.$http.get(endpoint.href, this.USER_HEADER)
      } else {
        throw new Error('Usted no tiene los permisos para visualizar usuarios.')
      }
    },
    getUser (id) {
      if (this.userRoles.roles[0].permissions.some(r => r.name === this.ADMIN_READ_PERMISSION)) {
        return this.$http.get(this.USER_ENDPOINT + `/${id}`, this.USER_HEADER)
      } else {
        throw new Error('Usted no tiene los permisos para visualizar usuarios.')
      }
    },
    createUser (user) {
      if (this.userRoles.roles[0].permissions.some(r => r.name === this.ADMIN_WRITE_PERMISSION)) {
        return this.$http.post(this.USER_ENDPOINT, user, this.USER_HEADER)
      } else {
        throw new Error('Usted no tiene los permisos para crear usuarios.')
      }
    },
    updateUser (user) {
      if (this.userRoles.roles[0].permissions.some(r => r.name === this.ADMIN_WRITE_PERMISSION)) {
        return this.$http.put(this.USER_ENDPOINT + `/${user.id}`, user, this.USER_HEADER)
      } else {
        throw new Error('Usted no tiene los permisos para actualizar usuarios.')
      }
    },
    deleteUser (id) {
      if (this.userRoles.roles[0].permissions.some(r => r.name === this.ADMIN_WRITE_PERMISSION)) {
        return this.$http.delete(this.USER_ENDPOINT + `/${id}`, this.USER_HEADER)
      } else {
        throw new Error('Usted no tiene los permisos para eliminar usuarios.')
      }
    },
    resetPassword (userId) {
      if (this.userRoles.roles[0].permissions.some(r => r.name === this.ADMIN_WRITE_PERMISSION)) {
        return this.$http.post(this.USER_ENDPOINT + `/passwordreset/${userId}`, {}, this.USER_HEADER)
      } else {
        throw new Error('Usted no tiene los permisos para actualizar usuarios.')
      }
    },
    changePassword (userId, body, changingMyPassowrd) {
      if (this.userRoles.roles[0].permissions.some(r => r.name === this.ADMIN_WRITE_PERMISSION) || changingMyPassowrd) {
        return this.$http.post(this.USER_ENDPOINT + `/${userId}/newpassword`, body, this.USER_HEADER)
      } else {
        throw new Error('Usted no tiene los permisos para actualizar usuarios.')
      }
    },
    existEmail (email) {
      if (this.userRoles.roles[0].permissions.some(r => r.name === this.ADMIN_READ_PERMISSION)) {
        return this.$http.get(`${appConfig.API_END_POINT}/admin/emails/${email}`, this.USER_HEADER)
      } else {
        throw new Error('Usted no tiene los permisos para visualizar usuarios.')
      }
    }
  }
}

export const RoleService = {
  data () {
    return {
      ROLES_ENDPOINT: `${appConfig.API_END_POINT}/role`,
      HEADER: {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      },
      ADMIN_READ_PERMISSION: 'ADMIN_READ',
      ADMIN_WRITE_PERMISSION: 'ADMIN_WRITE',
      userRoles: this.$store.state.user_data
    }
  },
  methods: {
    getRoles (filter) {
      if (this.userRoles.roles[0].permissions.some(r => r.name === this.ADMIN_READ_PERMISSION)) {
        let endpoint = new URL(this.ROLES_ENDPOINT)
        if (filter) {
          Object.keys(filter).forEach(
            key => {
              let value = filter[key]
              if (key === 'sortOrder') value = value ? 'asc' : 'desc'
              if (key === 'from') value = value - 1
              if (key === 'sortBy' && value !== 'roleName') value = value + '.keyword'
              if (key === 'userType') {
                if (filter[key] !== 'null') {
                  value = filter[key]
                } else {
                  value = ''
                }
              }
              if (value !== null) {
                endpoint.searchParams.append(key, value)
              }
            }
          )
        }
        return this.$http.get(endpoint.href, this.HEADER)
      } else {
        throw new Error('Usted no tiene los permisos para visualizar roles.')
      }
    },
    getRole (id) {
      if (this.userRoles.roles[0].permissions.some(r => r.name === this.ADMIN_READ_PERMISSION)) {
        return this.$http.get(this.ROLES_ENDPOINT + `/${id}`, this.HEADER)
      } else {
        throw new Error('Usted no tiene los permisos para visualizar roles.')
      }
    },
    createRole (role) {
      if (this.userRoles.roles[0].permissions.some(r => r.name === this.ADMIN_WRITE_PERMISSION)) {
        return this.$http.post(this.ROLES_ENDPOINT, role, this.HEADER)
      } else {
        throw new Error('Usted no tiene los permisos para crear roles.')
      }
    },
    updateRole (role, id) {
      if (this.userRoles.roles[0].permissions.some(r => r.name === this.ADMIN_WRITE_PERMISSION)) {
        return this.$http.put(this.ROLES_ENDPOINT + `/${id}`, role, this.HEADER)
      } else {
        throw new Error('Usted no tiene los permisos para modificar roles.')
      }
    },
    deleteRole (role) {
      if (this.userRoles.roles[0].permissions.some(r => r.name === this.ADMIN_WRITE_PERMISSION)) {
        return this.$http.put(this.ROLES_ENDPOINT + `/delete`, role, this.HEADER)
      } else {
        throw new Error('Usted no tiene los permisos para eliminar roles.')
      }
    },
    sortValidRoles (roles) {
      let response = [
        'ADMIN_USER',
        'ORGANIZATION_ADMIN_USER',
        'ORGANIZATION_STANDARD_USER',
        'STANDARD_USER',
        'CATALOG_USER',
        'ORDERS_USER',
        'INVENTORY_USER',
        'CARRIERS_USER',
        'REPORTS_USER'
      ]
      return response.map(r => roles.find(role => role.roleName === r)).filter(r => r !== undefined)
    }
  }
}
export const OrganizationService = {
  data () {
    return {
      ORGANIZATION_ENDPOINT: `${appConfig.API_END_POINT}/admin/organization`,
      HEADER: {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }
    }
  },
  methods: {
    async getAllOrganizations () {
      let total = 0
      let organizationArray = []
      let size = 100
      let from = 1
      do {
        let response = await this.getOrganizations({
          'from': from,
          'size': size
        })
        total = parseInt(response.headers.get('X-Total-Count'))
        organizationArray = organizationArray.concat(response.body)
        from = from + 1
      } while (total !== organizationArray.length)

      return organizationArray
    },
    getSearchSeller (text) {
      let endpoint = new URL(this.ORGANIZATION_ENDPOINT)
      if (text) {
        endpoint.searchParams.append('text', text)
      }
      return this.$http.get(endpoint.href, this.HEADER)
    },
    getOrganizations (filter) {
      let endpoint = new URL(this.ORGANIZATION_ENDPOINT)
      if (filter) {
        Object.keys(filter).forEach(
          key => {
            let value = filter[key]
            if (key === 'sortOrder') value = value ? 'asc' : 'desc'
            if (key === 'from') value = value - 1
            if (key === 'sortBy' && value !== 'accountName') value = value + '.keyword'
            if (value !== null) {
              endpoint.searchParams.append(key, value)
            }
          }
        )
      }
      return this.$http.get(endpoint.href, this.HEADER)
    },
    searchOrganization (text) {
      let endpoint = new URL(this.ORGANIZATION_ENDPOINT)
      endpoint.searchParams.append('text', text)
      return this.$http.get(endpoint.href, this.HEADER)
    },
    saveOrganization (organization) {
      return this.$http.post(this.ORGANIZATION_ENDPOINT, organization, this.HEADER)
    }
  }
}
