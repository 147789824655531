<template>
    <b-modal ref="incidentsComments" id="incidentsComments"
        size="lg"
        header-bg-variant="primary"
        centered
        :hide-footer="false"
        @hide="cancel"
        :title="$t('Agregar comentario')"
        @cancel="cancel"
        :cancel-title="$t('Cerrar')"
        @ok="modalOk"
        :ok-title="$t('Seleccionar')">
        <div v-show="errorText !== ''" class="alert alert-danger" role="alert" dismissible @dismised="errorText = ''">
          {{errorText}}
        </div>
        <section class="px-4">
          <section v-if="incident">
            <h6 class="mt-2 mb-3">{{$t('Historial')}}</h6>
            <article class="historial-comments">
                <div class="comment mb-2" v-for="(comments, index) in incident.comments" :key="index">
                    <p class="my-0"><strong>{{comments.author}}</strong></p>
                    <div class="message-blue">
                      <p class="message-content">{{comments.text}}</p>
                      <div class="message-timestamp-left"><i class="far fa-calendar-check calendar-history-incidents text-primary"></i>{{new Date(comments.date).toLocaleDateString(locale)}}</div>
                    </div>
                </div>
            </article>
          </section>
          <section>
              <b-form>
                  <b-row>
                    <b-col cols="12" sm="12">
                      <label for="comment">{{$t('Nuevo comentario:')}}</label>
                      <b-form-textarea
                          name="comment"
                          v-model="comment.body"
                          :rows="3"
                          :max-rows="6">
                      </b-form-textarea>
                      <b-row align-h="between">
                        <b-col cols="6" class="limit--reached">{{commentReachLimit()}}</b-col>
                        <b-col cols="6" class="limit text-right" :class="{'no-characters': remainingCharcaters < 1}">
                          {{remainingCharcaters}}
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
              </b-form>
          </section>
        </section>
        <div slot="modal-footer" class="w-100">
          <div class="w-100">
            <b-button
                variant="secondary"
                class="float-right mx-1"
                @click="cancel()">
                {{$t('Cancelar')}}
            </b-button>
            <b-button
                :disabled="disableSave()"
                variant="primary"
                class="float-right mx-1"
                @click="modalOk">
                {{$t('Agregar')}}
            </b-button>
          </div>
        </div>
        </b-modal>
</template>

<script>
import { ValidationsMixim } from '@/mixims/ValidationsMixim'
import { IncidentsServices } from '@/mixims/IncidentsServices'
import appConfig from '@/config/applicationConfig.js'

export default {
  name: 'incidents-comment-modal',
  props: ['show', 'historical', 'incident'],
  mixins: [ValidationsMixim, IncidentsServices],
  data () {
    return {
      errorText: '',
      comment: {},
      limitComment: 500,
      locale: appConfig.LOCALE
    }
  },
  watch: {
    show () {
      if (this.show) {
        this.$refs.incidentsComments.show()
      } else if (!this.show) {
        this.$refs.incidentsComments.hide()
      }
    }
  },
  computed: {
    remainingCharcaters () {
      return this.comment.body !== undefined ? this.limitComment - this.comment.body.length : this.limitComment
    },
    userText () {
      return this.$store.state.user_data.firstName + ' ' + this.$store.state.user_data.lastName
    },
    state () {
      return {
        comment: this.isValidTextArea(this.comment.body, true, this.limitComment)
      }
    }
  },
  methods: {
    commentReachLimit () {
      return !this.state.comment ? this.comment.body !== undefined && this.comment.body.length > this.limitComment ? this.$t('El máximo número de caracteres es: ') + this.limitComment : this.$t('Ingrese un comentario') : ''
    },
    disableSave () {
      return Object.values(this.state).includes(false)
    },
    resetModal () {
      this.comment = {}
    },
    cancel () {
      this.resetModal()
      this.$emit('update:show', false)
    },
    modalOk () {
      let comment = {
        text: this.comment.body
      }

      this.addCommentToIncident(this.incident.id, comment).then(
        response => {
          this.$refs.incidentsComments.hide()
          this.$emit('saved')
        }
      ).catch(
        error => {
          this.errorText = error.body.message || this.$t('Ocurrió un error al crear la incidencia')
        }
      )
    }
  }
}
</script>

<style lang="scss">
  .limit{
    font-weight: bold;
    font-size: 14px;
    &:not(.no-characters) {
      color: #5e5e5e;
    }
    &.no-characters{
      color: #f86c6b;
    }
  }
  .limit--reached{
    font-size: 15px;
    color: #f86c6b;
  }
  .calendar-history-incidents {
    margin-right:3px; 
    margin-top:3px;
  }
  // message styles 
  .message-blue {
    position: relative;
    margin-left: 20px;
    margin-bottom: 10px;
    padding: 5px 10px 10px 10px;
    background-color: #eff9ff;
    display: inline-block;
    min-width: 200px;
    min-height: 50px;
    text-align: left;
    border: 1px solid #97C6E3;
    border-radius: 10px;
  }

  .message-content {
      padding: 0;
  }

  .message-timestamp-left {
      position: absolute;
      font-weight: 300;
      bottom: 5px;
      left: 10px;
  }

  .message-blue:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 15px solid #eff9ff;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      top: 0;
      left: -15px;
  }

  .message-blue:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 17px solid #97C6E3;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      top: -1px;
      left: -17px;
  }
</style>
