var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"id":"nameInputGroup","label-cols":2,"breakpoint":"md","description":_vm.$t('Nombre del impuesto'),"invalid-feedback":!_vm.state.name ? _vm.$t('No es un valor válido') : '',"state":_vm.state.name,"label":_vm.$t('Nombre'),"label-for":"nameInput"}},[_c('b-form-input',{attrs:{"id":"nameInput"},model:{value:(_vm.taxModel.name),callback:function ($$v) {_vm.$set(_vm.taxModel, "name", $$v)},expression:"taxModel.name"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"id":"descriptionAreaGroup","label-cols":2,"breakpoint":"md","description":_vm.$t('Descripción del impuesto'),"invalid-feedback":!_vm.state.description
            ? _vm.$t('No es un valor válido')
            : '',"state":_vm.state.description,"label":_vm.$t('Descripción'),"label-for":"descriptionArea"}},[_c('b-form-textarea',{attrs:{"id":"descriptionArea","rows":3,"max-rows":6},model:{value:(_vm.taxModel.description),callback:function ($$v) {_vm.$set(_vm.taxModel, "description", $$v)},expression:"taxModel.description"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"id":"zipCodeStartGroup","label-cols":2,"breakpoint":"md","invalid-feedback":!_vm.state.zipCodeStart
            ? _vm.$t('No es un valor válido')
            : '',"state":_vm.state.zipCodeStart,"label":_vm.$t('Código Postal Desde'),"label-for":"zipCodeStartInput"}},[_c('b-form-input',{attrs:{"id":"zipCodeStartInput","type":"number"},model:{value:(_vm.taxModel.zipCodeStart),callback:function ($$v) {_vm.$set(_vm.taxModel, "zipCodeStart", $$v)},expression:"taxModel.zipCodeStart"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"zipCodeEndGroup","label-cols":2,"breakpoint":"md","invalid-feedback":!_vm.state.zipCodeEnd
            ? _vm.$t('No es un valor válido')
            : '',"state":_vm.state.zipCodeEnd,"label":_vm.$t('Código Postal Hasta'),"label-for":"zipCodeEndInput"}},[_c('b-form-input',{attrs:{"id":"zipCodeToInput","type":"number"},model:{value:(_vm.taxModel.zipCodeEnd),callback:function ($$v) {_vm.$set(_vm.taxModel, "zipCodeEnd", $$v)},expression:"taxModel.zipCodeEnd"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"id":"percentageInputGroup","label-cols":2,"breakpoint":"md","invalid-feedback":!_vm.state.percentage
            ? _vm.$t('No es un valor válido')
            : '',"state":_vm.state.percentage,"label":_vm.$t('Porcentaje'),"label-for":"percentageInput"}},[_c('b-form-input',{attrs:{"id":"percentageInput","type":"number"},model:{value:(_vm.taxModel.percentage),callback:function ($$v) {_vm.$set(_vm.taxModel, "percentage", $$v)},expression:"taxModel.percentage"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"activeInputGroup","label-cols":2,"breakpoint":"md","label":_vm.$t('Impuesto habilitado')}},[_c('c-switch',{attrs:{"inputType":"checkbox","type":"text","variant":"success","on":_vm.$t('Si'),"off":_vm.$t('No'),"pill":true,"size":"m","id":"s0"},model:{value:(_vm.taxModel.isActive),callback:function ($$v) {_vm.$set(_vm.taxModel, "isActive", $$v)},expression:"taxModel.isActive"}})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"float-right",on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(_vm._s(_vm.$t('Cancelar')))]),_c('b-button',{staticClass:"float-right mr-2",attrs:{"variant":"success","disabled":_vm.disabledSave},on:{"click":function($event){return _vm.handleClick()}}},[_vm._v(_vm._s(_vm.taxModel.id ? 'Editar' : 'Crear'))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }