import appConfig from '@/config/applicationConfig'
import qs from 'qs'

const DEFAULT_PARAMS = {
  sortOrder: "desc",
  sortBy: "creationDate"
}

export function getOrders (params) {
  const url = new URL(`${appConfig.API_END_POINT}/orders`)

  return this.$http.get(
    url.href,
    {
      headers: {
        Authorization: `Bearer ${this.$store.state.access_token}`// TODO, this auth method should be defined always
      },
      params: {
        ...DEFAULT_PARAMS,
        ...params
      },
      paramsSerializer: params => qs.stringify(params)
      
    }
  )
}
