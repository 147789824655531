<template>
  <div class="animated fadeIn">
    <b-container fluid>
      <div v-show="errorText !== ''" class="alert alert-danger" role="alert">
        {{errorText}}
      </div>
      <b-row>
        <b-col md="3">
          <b-form-group
            id="dateFilterFromGroup"
            :label="$t('Rango de fechas')"
            :description="$t('Se filtrara los reportes por el rango de fechas seleccionado')"
            label-for="filterRangeDate">
            <date-picker
              v-on:contextmenu.native.stop.prevent
              v-on:keydown.native.stop.prevent
              id="filterRangeDate"
              name="filterRangeDate"
              ref="filterBeginDate"
              v-model="rangeDate"
              format="YYYY-MM-DD"
              valueType="YYYY-MM-DD"
              :confirm="true"
              :lang="lang"
              :confirm-text="$t('Seleccione rango')"
              :clearable="true"
              range>
            </date-picker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4" v-if="isAdmin">
          <b-form-group :label="$t('Seller_label')" class="mb-3">
            <b-form-select :options="organizations" v-model="account">
              <option slot="first" :value="null">{{$t('Todos')}}</option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('Sitio')" class="mb-3" :description="$t('Seleccione el sitio a filtrar')">
            <b-form-select :options="optionSites" v-model="siteName">
              <option slot="first" :value="null">{{$t('Todos')}}</option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-tabs v-model="tabIndex" class="mt-3">
            <b-tab :title="$t('Ventas')">
              <sales-report :account="account" :rangeDate="rangeDate" :marketplace="siteName" @error="showError"></sales-report>
            </b-tab>
            <b-tab :title="$t('Estado de pedidos')">
              <orders-report :account="account" :rangeDate="rangeDate" :marketplace="siteName" @error="showError"></orders-report>
            </b-tab>
            <b-tab :title="$t('Inventario de productos')">
              <inventory-report :account="account" :rangeDate="rangeDate" :marketplace="siteName" @error="showError"></inventory-report>
            </b-tab>
            <b-tab :title="$t('Catálogo de productos')">
              <products-report :account="account" :rangeDate="rangeDate" :marketplace="siteName" @error="showError"></products-report>
            </b-tab>
            <!-- <b-tab title="$t('Liquidación de pedidos')">
              <orders-report :account="account" :rangeDate="rangeDate" @error="showError" :liquidationReport="true"></orders-report>
            </b-tab> -->
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import OrdersReport from '@/components/reports/OrdersReport'
import ProductsReport from '@/components/reports/ProductsReport'
import InventoryReport from '@/components/reports/InventoryReport'
import SalesReport from '@/components/reports/SalesReport'
import 'vue2-datepicker/index.css'
import DatePicker from 'vue2-datepicker'

export default {
  name: 'reports-view',
  components: {
    OrdersReport,
    ProductsReport,
    InventoryReport,
    SalesReport,
    DatePicker
  },
  data () {
    return {
      isAdmin: this.$store.getters.isAdmin,
      errorText: '',
      lang: {
        formatLocale: {
          // MMMM
          months: [this.$t('Enero'), this.$t('Febrero'), this.$t('Marzo'), this.$t('Abril'), this.$t('Mayo'), this.$t('Junio'), this.$t('Julio'), this.$t('Agosto'), this.$t('Septiembre'), this.$t('Octubre'), this.$t('Noviembre'), this.$t('Diciembre')],
          // MMM
          monthsShort: [this.$t('Ene'), this.$t('Feb'), this.$t('Marz'), this.$t('Abr'), this.$t('May'), this.$t('Jun'), this.$t('Jul'), this.$t('Ago'), this.$t('Sep'), this.$t('Oct'), this.$t('Nov'), this.$t('Dic')],
          // dddd
          weekdays: [this.$t('Domingo'), this.$t('Lunes'), this.$t('Martes'), this.$t('Miercoles'), this.$t('Jueves'), this.$t('Viernes'), this.$t('Sabado')],
          // ddd
          weekdaysShort: [this.$t('Dom'), this.$t('Lun'), this.$t('Mar'), this.$t('Mie'), this.$t('Jue'), this.$t('Vie'), this.$t('Sab')],
          // dd
          weekdaysMin: [this.$t('Do'), this.$t('Lu'), this.$t('Ma'), this.$t('Mi'), this.$t('Ju'), this.$t('Vi'), this.$t('Sa')]
        }
      },
      rangeDate: null,
      account: null,
      optionSites: this.$store.getters.marketplacesCombo,
      siteName: null
    }
  },
  computed: {
    organizations () {
      return this.$store.getters.organizations
    },
    tabIndex: {
      get() {
        return this.$store.getters.getCurrentReportTab;
      },
      set(value) {
        this.$store.dispatch('setCurrentReportTab', value);
      },
    },
  },
  methods: {
    tabChange () {
      this.errorText = ''
    },
    showError (errorText) {
      this.errorText = errorText
    }
  }
}
</script>
