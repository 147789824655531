import appConfig from '@/config/applicationConfig.js'

export const PRODUCT_STATES = [
  {value: 'APPROVED', text: 'Activo'},
  {value: 'PENDING_APPROVAL', text: 'Pendiente'},
  {value: 'REJECTED', text: 'Rechazado'}
]

export const PRODUCT_STATES_EDITION = [
  {value: 'APPROVED', text: 'Activo'},
  {value: 'PENDING_APPROVAL', text: 'Pendiente', disabled: true},
  {value: 'REJECTED', text: 'Rechazado'}
]

export const ProductServiceMixim = {
  methods: {
   _getProducts (params) {
    const DEFAULT_PARAMS = {
      sortOrder: "desc",
      sortBy: "creationDate"
    }

    return this.$http.get(`${appConfig.API_END_POINT}/products`,
      {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        },
        params: {
          ...DEFAULT_PARAMS,
          ...params
        }
      }
    )
   },
    /**
    * Get all the products
    * @param tableInfo data to filter the query related the table like sort, pagination, etc
    * @param filters related the custom filters
    */
    getProducts (tableInfo, filters, isCSVExport) {
      let url = null
      if (isCSVExport) {
        url = new URL(`${appConfig.API_END_POINT}/products/downloadcsv`)
      } else {
        url = new URL(`${appConfig.API_END_POINT}/products`)
      }

      let params = {}

      if (tableInfo) {
        if (tableInfo.sortBy) {
          if (tableInfo.sortBy === 'category') {
            params.sortBy = 'category.name.keyword'
          } else if (tableInfo.sortBy === 'upc') {
            params.sortBy = 'skus.upc.filter'
          } else if (tableInfo.sortBy === 'price') {
            params.sortBy = 'skus.price.price'
          } else if (tableInfo.sortBy === 'status') {
            params.sortBy = 'skus.status'
          } else if (tableInfo.sortBy === 'name') {
            params.sortBy = 'name.keyword'
          } else if (tableInfo.sortBy === 'quantity') {
            params.sortBy = 'skus.inventory.totalQuantity'
          } else {
            params.sortBy = tableInfo.sortBy
          }
          params.sortOrder = tableInfo.sortDesc ? 'desc' : 'asc'
        }
        params.from = tableInfo.currentPage - 1
        params.size = tableInfo.perPage
      }

      params.fromQuantity = filters.fromQuantity
      params.toQuantity = filters.toQuantity
      params.accountName = filters.seller
      params.fromSkuId = filters.sku.desde
      params.toSkuId = filters.sku.hasta
      params.fromUpc = filters.upc.desde
      params.toUpc = filters.upc.hasta
      params.status = filters.estado
      params.categoryId = filters.category.id
      params.name = filters.name
      params.fromDate = filters.fecha.desde
      params.toDate = filters.fecha.hasta
      params.text = filters.text
      params.marketplaces = filters.marketplaces ? filters.marketplaces : null

      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null && params[key] !== undefined) {
            url.searchParams.append(key, params[key])
          }
        }
      )

      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }

      if (isCSVExport) {
        config.responseType = 'arraybuffer'
        config.headers.Accept = 'application/octet-stream; charset=ISO-8859-1'
      }

      return this.$http.get(
        url.href,
        config
      )
    },

    productsReport (tableInfo, filters, isCSVExport) {
      let url = null
      if (isCSVExport) {
        url = new URL(`${appConfig.API_END_POINT}/products/downloadcsv`)
      } else {
        url = new URL(`${appConfig.API_END_POINT}/products`)
      }

      let params = {}

      if (tableInfo) {
        if (tableInfo.sortBy) {
          if (tableInfo.sortBy === 'category') {
            params.sortBy = 'category.name.keyword'
          } else if (tableInfo.sortBy === 'upc') {
            params.sortBy = 'skus.upc.filter'
          } else if (tableInfo.sortBy === 'price') {
            params.sortBy = 'skus.price.price'
          } else if (tableInfo.sortBy === 'status') {
            params.sortBy = 'skus.status'
          } else if (tableInfo.sortBy === 'name') {
            params.sortBy = 'name.keyword'
          } else if (tableInfo.sortBy === 'quantity') {
            params.sortBy = 'skus.inventory.totalQuantity'
          } else {
            params.sortBy = tableInfo.sortBy
          }
          params.sortOrder = tableInfo.sortDesc ? 'desc' : 'asc'
        }
        params.from = tableInfo.currentPage - 1
        params.size = tableInfo.perPage
      }
      if (filters.useFilters) {
        params.fromQuantity = filters.fromQuantity
        params.toQuantity = filters.toQuantity
        params.accountName = filters.seller
        params.status = filters.estado
        params.categoryId = filters.category.id
        params.text = filters.text
        params.fromDate = filters.fecha.desde
        params.toDate = filters.fecha.hasta
        params.marketplaces = filters.marketplaces ? filters.marketplaces : null
      }
      params.text = filters.text

      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null && params[key] !== undefined) {
            url.searchParams.append(key, params[key])
          }
        }
      )

      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }

      if (isCSVExport) {
        config.responseType = 'arraybuffer'
        config.headers.Accept = 'application/octet-stream; charset=ISO-8859-1'
      }

      return this.$http.get(
        url.href,
        config
      )
    },

    // Activa un sku para un maketplace en especifico
    activateSku (skuId, skuByMarketplace) {
      let url = new URL(`${appConfig.API_END_POINT}/sku/approve/${skuId}`)
      return this.$http.put(
        url.href, skuByMarketplace,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    rejectSku (skuId, skuByMarketplace) {
      let url = new URL(`${appConfig.API_END_POINT}/sku/reject/${skuId}`)
      return this.$http.put(
        url.href, skuByMarketplace,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    /**
    * Activate a list of product
    * @param products
    */
    activateProducts (products, areVariants) {
      let transactionType = ''
      let productModelArray = products.map(
        (elem) => {
          return {id: elem}
        }
      )

      let endPoint = ''
      if (!areVariants) {
        endPoint = `${appConfig.API_END_POINT}/products/approve`
        transactionType = 'APPROVE_PRODUCT'
      } else {
        endPoint = `${appConfig.API_END_POINT}/sku/approve`
        transactionType = 'APPROVE_SKU'
      }

      let body = {
        transactionType: transactionType,
        items: productModelArray
      }

      return this.$http.put(
        endPoint, body,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    /**
    * Reject a list of product
    * @param products
    */
    rejectProducts (products, areVariants) {
      let transactionType = ''
      let productModelArray = products.map(
        (elem) => {
          return {id: elem}
        }
      )

      let endPoint = ''
      if (!areVariants) {
        endPoint = `${appConfig.API_END_POINT}/products/reject`
        transactionType = 'REJECT_PRODUCTS'
      } else {
        endPoint = `${appConfig.API_END_POINT}/sku/reject`
        transactionType = 'REJECT_SKU'
      }

      let body = {
        transactionType: transactionType,
        items: productModelArray 
      }

      return this.$http.put(
        endPoint, body,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    /**
    * Delete a list of product
    * @param id
    */
    deleteProducts (products, areVariants) {
      let transactionType = ''
      let productModelArray = products.map(
        (elem) => {
          return {id: elem}
        }
      )

      let endPoint = ''
      if (!areVariants) {
        endPoint = `${appConfig.API_END_POINT}/products/delete`
        transactionType = 'DELETE_PRODUCT'
      } else {
        endPoint = `${appConfig.API_END_POINT}/sku/delete`
        transactionType = 'DELETE_SKU'
      }

      let body = {
        transactionType: transactionType,
        items: productModelArray
      }

      return this.$http.put(
        endPoint, body,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    /**
    * Get a product details given a product id
    * @param productId
    */
    getProduct () {
      return this.$http.get(
        `${appConfig.API_END_POINT}/products/${this.productId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    createSku (productId, skuPayload) {
      return this.$http.post(
        `${appConfig.API_END_POINT}/products/${productId}/sku`, skuPayload,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }).then(res => res.body)
    },

    createProduct (productPayload) {
      return this.$http.post(
        `${appConfig.API_END_POINT}/products/`, productPayload,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }).then(res => res.body)
    },

    updateProduct (product) {
      return this.$http.put(
        `${appConfig.API_END_POINT}/products/${product.id}`, product,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    updateSku (skuId, skuPayload) {
      return this.$http.put(
        `${appConfig.API_END_POINT}/sku/${skuId}`, skuPayload,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    getSkuHistory (skuId) {
      return this.$http.get(
        `${appConfig.API_END_POINT}/sku/${skuId}/historical?sortBy=date&sortOrder=asc`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      ).catch(
        () => {
          return null
        }
      )
    },

    getSku (skuId) {
      return this.$http.get(
        `${appConfig.API_END_POINT}/sku/${skuId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    _getBrands (from, size) {
      return this.$http.get(
        `${appConfig.API_END_POINT}/brands/list?from=${from}&size=${size}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      ).catch(
        () => {
          return null
        }
      )
    },

    /**
    * Get all the brands
    */
    getBrands () {
      return Promise.all([
        this._getBrands(0, 1000),
        this._getBrands(1, 1000),
        this._getBrands(2, 1000),
        this._getBrands(3, 1000),
        this._getBrands(4, 1000)
      ]).then(
        (response) => {
          let brandArray = []
          for (let i = 0; i < 5; i++) {
            if (response[i]) {
              brandArray = brandArray.concat(response[i].body)
            }
          }
          return brandArray.sort(
            (brand1, brand2) => {
              return brand1.name.localeCompare(brand2.name)
            }
          )
        })
    },
    getSearchBrands (text) {
      return this.$http.get(
        `${appConfig.API_END_POINT}/brands?text=${text}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      ).catch(
        () => {
          return null
        }
      )
    },

    /**
    * Get the category three
    */
    getCategories () {
      return this.$http.get(
        `${appConfig.API_END_POINT}/categories/tree`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    getProductAtributes (categoryId) {
      return this.$http.get(
        `${appConfig.API_END_POINT}/specifications?categoryId=${categoryId}&isStockKeepingUnit=false`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    getSKUAttributes (categoryId) {
      return this.$http.get(
        `${appConfig.API_END_POINT}/specifications?categoryId=${categoryId}&isStockKeepingUnit=true`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    /**
    * Get the specifications given the category id
    * @param categoryId
    */
    getSpecsFor (categoryId) {
      return this.$http.get(
        `${appConfig.API_END_POINT}/specifications?categoryId=${categoryId}&size=50`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    /**
    * Add product given the product
    * @param product
    */
    addProduct (product) {
      return this.$http.post(`${appConfig.API_END_POINT}/products`, product,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
    },

    /**
    * Find a product given the upc id
    */
    findProductByUpc (upc) {
      return this.$http.get(`${appConfig.API_END_POINT}/products/upc/${upc}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(res => res.body)
    },

    /**
    * Get the price csv list
    */
    getPriceList () {
      return this.$http.get(
        `${appConfig.API_END_POINT}/sku/prices/downloadcsv`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    /**
    * Upload the price csv list to update the prices
    * @param csvPriceFile
    */
    uploadCsvPriceList (csvPriceFile, delimiter) {
      return this.$http.post(`${appConfig.API_END_POINT}/sku/prices/importcsv?delimiter=${delimiter}`, csvPriceFile,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
    },

    /**
    * Get the price csv list
    */
    getShippingRatesPriceList () {
      return this.$http.get(
        `${appConfig.API_END_POINT}/shippingrates/downloadcsv/`,
        {
          headers: {
            Accept: 'application/octet-stream; charset=ISO-8859-1',
            Authorization: `Bearer ${this.$store.state.access_token}`
          },
          responseType: 'arraybuffer'
        }
      )
    },

    /**
    * Upload the price csv list to update the prices
    * @param csvPriceFile
    */
    uploadCsvShippingRatesPriceList (csvPriceFile, delimiter) {
      return this.$http.post(`${appConfig.API_END_POINT}/shippingrates/importcsv?delimiter=${delimiter}`, csvPriceFile,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
    },

    /**
    * Delete a given image
    * @param sku
    * @param srcImage
    */
    deleteImageExec (skuId, srcImage1, srcImage2, srcImage3, srcImage4, srcImage5, srcImage6, srcImage7) {
      let images = []
      images.push({'imageUrl': srcImage1})
      images.push({'imageUrl': srcImage2})
      images.push({'imageUrl': srcImage3})
      images.push({'imageUrl': srcImage4})
      images.push({'imageUrl': srcImage5})
      images.push({'imageUrl': srcImage6})
      images.push({'imageUrl': srcImage7})

      return this.$http.put(
        `${appConfig.API_END_POINT}/sku/${skuId}/images`,
        {
          'images': images
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
    /**
     * Filters by categories and products
     */
    getSearchProducts (text) {
      let url = new URL(`${appConfig.API_END_POINT}/products`)
      url.searchParams.append('text', text)
      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }
      return this.$http.get(
        url.href,
        config
      )
    },
    getSearchCategories (text) {
      let filters = {
        text
      }
      return this.getFlatCategories(filters)
    },
    getFlatCategories (filters) {
      let url = new URL(`${appConfig.API_END_POINT}/categories/`)
      let params = {}

      params.sortOrder = filters.sortOrder ? 'asc' : 'desc'
      params.sortBy = filters.sortBy + '.keyword'
      params.from = filters.from > 0 ? filters.from - 1 : filters.from
      params.size = filters.size
      params.text = filters.text

      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null && params[key] !== undefined) {
            url.searchParams.append(key, params[key])
          }
        }
      )

      return this.$http.get(
        url.href,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    }
  }
}
