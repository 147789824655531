export const ValidationsMixim = {
  methods: {
    cleanJSON(objetoJSON) {
      if (objetoJSON && typeof objetoJSON === 'object') {
        if (Array.isArray(objetoJSON)) {
          // Si es un array, limpiar cada elemento
          return objetoJSON.map(elemento => this.cleanJSON(elemento)).filter(elemento => elemento !== null);
        } else {
          // Si es un objeto, limpiar cada propiedad
          return Object.keys(objetoJSON)
            .reduce((resultado, clave) => {
              const valorLimpiado = this.cleanJSON(objetoJSON[clave]);
              if (valorLimpiado !== null) {
                resultado[clave] = valorLimpiado;
              }
              return resultado;
            }, {});
        }
      } else {
        // No es un objeto o es nulo, simplemente devolver el valor
        return objetoJSON;
      }
    },
    validateUrl (url, mandatory) {
      if (mandatory && this.isEmptyData(url)) {
        return false
      }
      if (!mandatory && this.isEmptyData(url)) {
        return true
      }
      var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g)
      return (res !== null)
    },
    validateEmail (email, mandatory) {
      if (mandatory && this.isEmptyData(email)) {
        return false
      }
      if (!mandatory && this.isEmptyData(email)) {
        return true
      }
      /* eslint-disable no-useless-escape */
      let rx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return rx.test(String(email).toLowerCase())
    },
    validIntegerCompare (value, inicial, mandatory) {
      if (mandatory && this.isEmptyData(value)) {
        return 'No es un valor válido'
      }
      if (!mandatory && !this.isEmptyData(value)) {
        if (!this.isValidInteger(value)) {
          return 'No es un valor válido'
        }
      }

      if (!this.isValidInteger(value, mandatory, true)) {
        return 'No es un valor válido'
      }

      if (!this.isEmptyData(inicial) && this.isValidInteger(inicial)) {
        if (parseInt(value) <= parseInt(inicial)) {
          return `El valor 'Hasta' es menor o igual que 'Desde'`
        }
      }
      return ''
    },
    validCPCompare (value, inicial, mandatory) {
      if (mandatory && this.isEmptyData(value)) {
        return 'No es un valor válido'
      }
      if (!mandatory && !this.isEmptyData(value)) {
        if (!this.isValidInteger(value)) {
          return 'No es un valor válido'
        }
      }

      if (!this.isValidCP(value, mandatory)) {
        return 'No es un valor válido'
      }

      if (!this.isEmptyData(inicial) && this.isValidCP(inicial)) {
        if (parseInt(value) < parseInt(inicial)) {
          return `El valor 'Hasta' es menor que 'Desde'`
        }
      }
      return ''
    },
    isValidFloat (value, mandatory, noZero) {
      if (mandatory && this.isEmptyData(value)) {
        return false
      }
      if (!mandatory && this.isEmptyData(value)) {
        return true
      }
      if (String(value).indexOf('00') === 0) {
        return false
      }
      if (/^-?\d+(\.?\d+)?$/.test(String(value)) === false) {
        return false
      }
      if (noZero === true) {
        return parseFloat(value) !== 0
      }

      if (value < 0) {
        return false
      }

      return true
    },
    isValidInteger (value, mandatory, noZero) {
      if (mandatory && this.isEmptyData(value)) {
        return false
      }
      if (!mandatory && this.isEmptyData(value)) {
        return true
      }
      if (String(value).indexOf('00') === 0) {
        return false
      }
      if (String(value).indexOf('.') !== -1 || isNaN(String(value)) || !Number.isInteger(parseFloat(value))) {
        return false
      }
      if (noZero === true) {
        return parseInt(value) !== 0
      }
      if (value < 0) {
        return false
      }
      return true
    },
    isValidInt32Integer (value, mandatory, noZero) {
      if (mandatory && this.isEmptyData(value)) {
        return false
      }
      if (!mandatory && this.isEmptyData(value)) {
        return true
      }
      if (String(value).indexOf('00') === 0) {
        return false
      }
      if (String(value).indexOf('.') !== -1 || isNaN(String(value)) || !Number.isInteger(parseFloat(value))) {
        return false
      }
      if (parseInt(value) > 2147483648) return false
      if (value < 0) return false
      if (noZero === true) {
        return parseInt(value) !== 0
      }
      return true
    },
    isValidUPC (value, mandatory, noZero) {
      if (mandatory && this.isEmptyData(value)) {
        return false
      }
      if (!mandatory && this.isEmptyData(value)) {
        return true
      }
      if (String(value).indexOf('.') !== -1 || isNaN(String(value)) || !Number.isInteger(parseFloat(value))) {
        return false
      }
      if (noZero === true) {
        return parseInt(value) !== 0
      }
      if (value < 0) {
        return false
      }
      return true
    },
    isValidCP (value, mandatory) {
      if (mandatory && this.isEmptyData(value)) {
        return false
      }
      if (!mandatory && this.isEmptyData(value)) {
        return true
      }
      if (String(value).indexOf('.') !== -1 || isNaN(String(value)) || !Number.isInteger(parseFloat(value))) {
        return false
      }
      return true
    },
    isValidTextOneWord (value, mandatory, size) {
      return this.isValidText(value, mandatory, size) && value.indexOf(' ') === -1
    },
    isValidText (value, mandatory, size) {
      if (mandatory && this.isEmptyData(value)) {
        return false
      }
      if (!mandatory && this.isEmptyData(value)) {
        return true
      }

      if (size) {
        if (value.length > size) {
          return false
        }
      }

      return /^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9- ,_ !"·$%&/()=?¿¡'″^´,.-;:+*{}[\]º<>@]*$/.test(value)
    },
    isValidTextArea (value, mandatory, size) {
      if (mandatory && this.isEmptyData(value)) {
        return false
      }
      if (!mandatory && this.isEmptyData(value)) {
        return true
      }

      if (size) {
        if (value.length > size) {
          return false
        }
      }

      return true
    },

    validateCP (evt) {
      if (evt.keyCode !== 8 && evt.keyCode !== 37 && evt.keyCode !== 39 && evt.keyCode !== 46 && evt.keyCode !== 9) {
        if (isNaN(String(evt.key))) {
          evt.stopPropagation()
          evt.preventDefault()
        }
      }
    },
    _validateNumber (evt, float, preventCero) {
      let currentValue = String(evt.target.value)
      let newChar = String(evt.key)

      /* Allow paste & copy, in other case ctr + anytring is just a prevent */
      if (evt.ctrlKey === true) {
        if (newChar !== 'v' && newChar !== 'c') {
          evt.stopPropagation()
          evt.preventDefault()
        }
      } else {
        let nextValue = currentValue + newChar

        /* When the input do not allow float, the dot is not allowed */
        if (!float) {
          if (newChar === '.') {
            evt.stopPropagation()
            evt.preventDefault()
            return
          }
        }
        /*
          - Is not an arrow, delete or backspace key
          - The current value is 0, so we prevent multiple 0 on the left
          - In the case when 0 is not allowe, we prevent 0 as next input value
          - the current is 0 and the next value is not 0. */
        if (evt.keyCode !== 8 && evt.keyCode !== 37 && evt.keyCode !== 39 && evt.keyCode !== 46 && evt.keyCode !== 9) {
          if (isNaN(nextValue) || nextValue === '00' || (preventCero && nextValue === '0') || (currentValue === '0' && newChar !== '.')) {
            evt.stopPropagation()
            evt.preventDefault()
            return
          }
          /* the size of the number is more than 19 characters */
          if (evt.target.value.length > 19) {
            evt.stopPropagation()
            evt.preventDefault()
          }
        }
      }
    },
    validateNumber (evt, preventCero) {
      return this._validateNumber(evt, false, preventCero)
    },
    validatePriceNumber (evt, preventCero) {
      return this._validateNumber(evt, true, preventCero)
    },
    // º<>
    textInputValidationSpecialChars (evt) {
      if (/^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9- ,_ !"·$%&/()=?¿¡^´,.-;:+*{}[\]º<>]*$/.test(evt.key) === false) {
        evt.stopPropagation()
        evt.preventDefault()
      }
    },
    textInputValidation (evt) {
      if (/^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9- ,_]*$/.test(evt.key) === false) {
        evt.stopPropagation()
        evt.preventDefault()
      }
    },
    isEmptyData (data) {
      if (typeof (data) === 'string') {
        data = data.trim()
        if (data === '') {
          return true
        }
      }

      if (typeof (data) === 'number' || typeof (data) === 'boolean') {
        return false
      }
      if (typeof (data) === 'undefined' || data === null) {
        return true
      }
      if (typeof (data.length) !== 'undefined') {
        return data.length === 0
      }

      var count = 0
      for (var i in data) {
        if (Object.prototype.hasOwnProperty.call(data, i)) {
          count++
        }
      }
      return count === 0
    }
  }
}
