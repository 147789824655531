<template>
  <!-- <div class="sidebar-header"></div> -->
  <span class="d-none"></span>
</template>
<script>

export default {
  name: 'sidebar-header'
}
</script>
