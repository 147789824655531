<template>
  <b-container fluid>
    <div>
      <b-modal header-bg-variant="primary" size="lg" ref="confirmationModal" id="confirmationModal" centered :title="$t(confirmationModalData.confirmationModalTitle)"
        @ok="confirmationModalData.confirmationModalOkHandler"
        @cancel="confirmationModalData.confirmationModalCancelHandler"
        :cancel-title="$t('Cancelar')">
        <moon-loader class="mb-2" :loading="loading" color="#000" size="30px"></moon-loader>
        <span v-show="!loading">{{confirmationModalData.confirmationModalText}}</span>
      </b-modal>

      <b-modal @hidden="handleHide" :ok-disabled="createButtonDisabled || isAdmin" header-bg-variant="primary" centered size="lg" ref="skuModal" id="skuModal" :title="title" o-close-on-backdrop="true" :cancel-title="$t('Cerrar')" @ok="handleOk" @cancel="handleHide" :ok-title="okTitle" >
        <moon-loader class="mb-2" :loading="loading" color="#000" size="30px"></moon-loader>
        <b-row>
          <b-col>
            <p v-show="product.skus && product.skus.length === 0" class="alert alert-warning"><i class="fa fa-exclamation-triangle"></i> {{$t('Es obligatorio crear un SKU para que el producto sea vendible en el marketplace')}}</p>
          </b-col>
        </b-row>
        <div v-html="errorText" v-show="errorText !== ''" class="alert alert-danger" role="alert">
          {{errorText}}
        </div>
        <div class="w-100 text-right">
          <router-link :to="{ path: '/incidents/list', query: { sku: sku.id }}">{{$t('Crear incidencia')}}</router-link>
        </div>
        <div v-show="successText !== ''" class="alert alert-success" role="alert">
          {{successText}}
        </div>
        <b-row class="mt-4">
          <b-col v-if="isAdmin">
            <b-form-group
              id="estadoSelectGroup"
              :label-cols="2"
              breakpoint="md"
              :label="$t('Estado')"
              label-for="estadoSelectInput">
              <b-form-select id="estadoSelectInput"
                @change="changeStatusModal"
                required
                :disabled="sku.status !== 'PENDING_APPROVAL'"
                v-model="sku.status">
                <option v-for="item in estados" 
                  :key="item.value"
                  :value="item.value">{{$t(item.text)}}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col v-if="!isAdmin">
            <button :disabled="!product.id || !sku.id" type="button" class="btn btn-primary float-right"  @click="deleteSku">
              <i class="far fa-trash-alt"></i> {{$t('Eliminar Variante')}}
            </button>
          </b-col>
        </b-row>
        <b-row class="border my-3 pt-3">
          <b-col>
            <b-form-group id="upcInput"
                :label-cols="2"
                breakpoint="md"
                :invalid-feedback="!isValidUPC(sku.upc, true, false)? $t('No es un valor válido') : ''"
                :state="isValidUPC(sku.upc, true, false)"
                :label="$t('UPC')">
                <b-form-input
                  v-model="sku.upc"
                  :disabled="Boolean(sku.id)"
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="skuInput"
                :label-cols="2"
                breakpoint="md"
                :description="$t('Generado automáticamente por Vtex una vez aprobado')"
                :label="$t('SKU')"
                label-for="skuFormatterHelp">
              <b-form-input type="number" id="skuFormatterHelp" v-model="sku.id" disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="nombreInput"
              :label-cols="2"
              breakpoint="md"
              :label="$t('Nombre')"
              :description="$t('Máximo 100 caracteres')"
              :invalid-feedback="!isValidText(sku.skuName, true) && !editionDisabled ? $t('El nombre no es válido') : ''"
              :state="isValidText(sku.skuName, true)"
              label-for="nombreFormatterHelp">
              <b-form-input
                id="nombreFormatterHelp"
                v-model="sku.skuName"
                :disabled="editionDisabled || sku.status === 'APPROVED'"
                maxlength="100">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-3 pt-3">
          <b-col md="6">
            <b-form-group id="inventoryInput"
                :label-cols="2"
                breakpoint="md"
                :invalid-feedback="!isValidInt32Integer(sku.inventory.totalQuantity, false, false)? $t('No es un inventario válido') : ''"
                :state="isValidInt32Integer(sku.inventory.totalQuantity, false, false)"
                :description="$t('Ingrese un valor de inventario válido')"
                :label="$t('Inventario')">
                <b-form-input
                  v-model="sku.inventory.totalQuantity"
                  :disabled="editionDisabled"
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="refId"
                :label-cols="2"
                breakpoint="md"
                :description="$t('Ingrese el código de referencia')"
                :invalid-feedback="!isValidText(sku.refId, false, refIdMaxChars) && !editionDisabled ? $t('El código de referencia no es válido') : ''"
                :state="isValidText(sku.refId, false, refIdMaxChars)"
                :label="$t('Código de referencia')"
                label-for="refId">
              <b-form-input 
                  :disabled="editionDisabled || sku.status === 'APPROVED'" 
                  type="text" 
                  id="refId" 
                  v-model="sku.refId"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <price-and-dimencion :pricesDisabled="editionDisabled" :sizesDisabled="editionDisabled || sku.status === 'APPROVED'" :priceData="sku.price" :dimension="sku.dimension"></price-and-dimencion>
        </b-row>
        <b-row>
          <image-loader :disabled="editionDisabled" @imageJustLoaded="imageJustLoaded" @imageDeleted="_addImagesAndFinishCreation" :skuId="sku.id" :productImages="sku.images" ref="imageLoader"></image-loader>
        </b-row>
        <b-row>
          <product-attributes :disabled="editionDisabled || sku.status === 'APPROVED'" :mergedInfo="specificationsComputedMap"></product-attributes>
        </b-row>
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import { ValidationsMixim } from '@/mixims/ValidationsMixim.js'
import PriceAndDimencion from './PriceAndDimencion'
import ProductAttributes from './ProductAttributes'
import { ProductServiceMixim, PRODUCT_STATES_EDITION } from '@/mixims/ProductServiceMixim.js'
import ImageLoader from '@/components/common/ImageLoader'

export default {
  name: 'sku-modal',
  components: {
    MoonLoader,
    PriceAndDimencion,
    ProductAttributes,
    ImageLoader
  },
  mixins: [ValidationsMixim, ProductServiceMixim],
  data () {
    return {
      isAdmin: this.$store.getters.isAdmin,
      title: '',
      loading: false,
      specifications: [],
      successText: '',
      mute: true,
      errorText: '',
      currentStatus: '',
      estados: PRODUCT_STATES_EDITION,
      confirmationModalData: {
        confirmationModalOkHandler: '',
        confirmationModalCancelHandler: '',
        confirmationModalText: '',
        confirmationModalTitle: ''
      },
      sku: {
        price: {},
        dimension: {},
        inventory: {},
        images: [],
        skuSpecifications: []
      },
      product: {}
    }
  },
  watch: {
  },
  computed: {
    okTitle: function () {
      return this.sku.id ? this.$t('Guardar') : this.$t('Crear Variante')
    },
    editionDisabled: function () {
      if (!this.sku.upc || !this.isValidUPC(this.sku.upc, true, false) || this.isAdmin) {
        return true
      }
      return false
    },
    createButtonDisabled: function () {
      if (!this.isValidText(this.sku.skuName, true)) {
        return true
      }

      if (!this.isValidText(this.sku.refId, false, this.refIdMaxChars)) {
        return true
      }

      if (!this.isValidFloat(this.sku.dimension.height, true, true) || !this.isValidFloat(this.sku.dimension.length, true, true) || !this.isValidFloat(this.sku.dimension.weight, true, true) || !this.isValidFloat(this.sku.dimension.width, true, true)) {
        return true
      }

      if (!this.isValidFloat(this.sku.price.listPrice, true, true)) {
        return true
      }

      if (this.isValidFloat(this.sku.price.price, true) && !this.isValidFloat(this.sku.price.listPrice, true, true)) {
        return true
      }

      if (!this.isValidInt32Integer(this.sku.inventory.totalQuantity, false, false)) {
        return true
      }

      if (this.isValidFloat(this.sku.price.price, true) && this.isValidFloat(this.sku.price.listPrice, true, true)) {
        if (parseFloat(this.sku.price.price) > parseFloat(this.sku.price.listPrice)) {
          return true
        }
      }

      if (parseFloat(this.sku.price.price) < parseFloat(this.sku.price.listPrice)) {
        if (this.isEmptyData(this.sku.price.priceValidUntil)) return true

        let dateSelected = new Date(this.sku.price.priceValidUntil)
        let today = new Date()
        today.setHours(0, 0, 0, 0)
        if (today > dateSelected) return true
      }

      if (this._pendingProps()) {
        return true
      }
      return !this.$refs.imageLoader.hasImages()
    },
    specificationsComputedMap: function () {
      if (!this.sku.skuSpecifications) {
        this.emptySpec()
      }
      let attributes = this.sku.skuSpecifications
      return this.specifications.map(spec => {
        let newAttr
        let foundAttr = attributes.find(attr => attr && attr.name === spec.name)
        if (!foundAttr) {
          newAttr = {
            ...spec,
            specificationFieldValues: [{}]
          }
          this.sku.skuSpecifications.push(newAttr)
        }
        return {
          spec,
          value: foundAttr !== undefined ? foundAttr : newAttr}
      })
    },
    refIdMaxChars () {
      if (process.env.VUE_APP_SKU_REF_ID_MAX_CHARS && parseInt(process.env.VUE_APP_SKU_REF_ID_MAX_CHARS)) {
        return parseInt(process.env.VUE_APP_SKU_REF_ID_MAX_CHARS)
      } else {
        return false
      }
    }
  },
  methods: {
    changeStatusModal (status) {
      this.confirmationModalData.confirmationModalTitle = `${this.$t('Cambio de estado de')} ${this.sku.skuName}`
      this.confirmationModalData.confirmationModalText = `${this.$t('¿Cambiar el estado a')} ${status === 'REJECTED' ? this.$t('rechazado') : this.$t('activado')}?`
      this.confirmationModalData.confirmationModalOkHandler = () => {
        this.execChangeStatus()
      }
      this.confirmationModalData.confirmationModalCancelHandler = () => {
        this.sku.status = 'PENDING_APPROVAL'
        this.hurryShow()
      }
      this.currentStatus = status
      this.$refs.confirmationModal.show()
    },
    execChangeStatus () {
      this.hurryShow()
      this.loading = true
      this.errorText = ''
      this.successText = ''
      let errorVerv = ''
      let actionFunction = ''
      switch (this.currentStatus) {
        case 'APPROVED':
          actionFunction = this.activateProducts
          errorVerv = this.$t('aprobando')
          break
        case 'REJECTED':
          actionFunction = this.rejectProducts
          errorVerv = this.$t('rechazando')
          break
      }
      actionFunction([this.sku.id], true).then(
        () => {
          this.successText = ` ${this.$t('El estado fue modificado con éxito')}`
          this.loading = false
          this.mute = false
        },
        (error) => {
          console.log(error)
          this.loading = false
          this.sku.status = 'PENDING_APPROVAL'
          this.errorText = `${this.$t('Error')} ${errorVerv} ${this.$t('el producto')} ${this.sku.id}`
          if (this.currentStatus === 'APPROVED') {
            if (this.sku.images.length === 0) {
              this.errorText += '<ul>'
              this.errorText += '  <li>' + this.$t('El producto no tiene imagenes cargadas.') + '</li>'
              this.errorText += '</ul>'
            }
          }
        }
      )
    },
    deleteSku () {
      this.confirmationModalData.confirmationModalTitle = `${this.$t('Eliminar SKU')}`
      this.confirmationModalData.confirmationModalText = `${this.$t('Se procederá a eliminar la variante')} ${this.sku.skuName}`
      this.confirmationModalData.confirmationModalOkHandler = () => {
        this.loading = true
        this.deleteProducts([this.sku.id], true).then(
          () => {
            this.loading = false
            this.$emit('needReload', {status: true, text: this.$t('La variante fue eliminada con éxito')})
            this.$refs.confirmationModal.hide()
          },
          (error) => {
            this.loading = false
            this.$emit('needReload', {status: false, text: error.body.message || this.$t('Error eliminando la variante')})
            this.$refs.confirmationModal.hide()
          }
        )
      }
      this.confirmationModalData.confirmationModalCancelHandler = () => {
        this.hurryShow()
      }
      this.mute = true
      this.$refs.confirmationModal.show()
    },
    _addImagesAndFinishCreation (successText, isCreation) {
      let _this = this
      /* Upload the images */
      let temp = this.sku.dimension.height
      this.sku.dimension.height = 0
      this.sku.dimension.height = temp
      this.$refs.imageLoader.uploadImages(this.sku.id).then(
        (images) => {
          _this.sku.images = images
          /* Update the SKU with the just upload images */
          this.updateSku(_this.sku.id, _this.sku).then(
            (skuJustCreated) => {
              if (isCreation) {
                _this.product.skus.push(skuJustCreated.body)
              } else {
                this.mute = false
              }
              this.successText = successText
              this.loading = false
            },
            (error) => {
              console.log(error)
              if (error.status === 500) {
                this.errorText = `${this.$t('Error al guardar la variante.')} ${this.$t('Revise los campos ingresados.')}`
              } else {
                this.errorText = this.$t('Error al guardar la variante.')
              }
              this.loading = false
            }
          )
        },
        (error) => {
          console.log(error)
          this.errorText = this.$t('Error ejecutando el servicio para subir las imágenes')
          this.loading = false
        }
      )
    },
    _pendingProps () {
      return this.specificationsComputedMap.some(
        (prop) => {
          if (prop.value.isRequired) {
            return Object.keys(prop.value.specificationFieldValues).length === 0 || !prop.value.specificationFieldValues[0].value
          }
        }
      )
    },
    _createSku () {
      this.loading = true
      if (!this.$refs.imageLoader.hasImages()) {
        this.loading = false
        this.errorText = this.$t('No hay ninguna imagen seleccionada')
        return
      }
      /* Create the SKU */
      this.createSku(this.product.id, this.sku).then(
        (res) => {
          this.sku.id = res.id
          this.sku.status = res.status
          /* We have the sku, so we can upload the images and update de sku */
          this._addImagesAndFinishCreation(this.$t('La variante fue creada con éxito'), true)
        },
        (error) => {
          console.log(error)
          this.errorText = this.$t('Error ejecutando el servicio de creación de variantes')
          this.loading = false
        }
      )
    },
    _updateSku () {
      this.loading = true
      if (!this.$refs.imageLoader.hasImages()) {
        this.loading = false
        this.errorText = this.$t('No hay ninguna imagen seleccionada')
        return
      }
      /* We have the sku, so we can upload the images and update de sku */
      this._addImagesAndFinishCreation(this.$t('La variante fue actualizada con éxito'))
    },
    handleOk (evt) {
      evt.preventDefault()
      this.errorText = ''
      this.successText = ''
      if (!this.sku.id) {
        this.confirmationModalData.confirmationModalTitle = `${this.$t('Procesar alta de Variante:')} ${this.sku.skuName}`
      } else {
        this.confirmationModalData.confirmationModalTitle = `${this.$t('Procesar modificación de Variante:')} ${this.sku.skuName}`
      }
      this.confirmationModalData.confirmationModalText = this.$t('¿Desea continuar?')
      this.confirmationModalData.confirmationModalOkHandler = () => {
        this.hurryShow()
        if (!this.sku.id) {
          this._createSku()
        } else {
          this._updateSku()
        }
      }
      this.confirmationModalData.confirmationModalCancelHandler = () => {
        this.hurryShow()
      }
      this.$refs.confirmationModal.show()
    },
    handleHide () {
      if (!this.mute) {
        this.$emit('needReload')
      }
      this.$refs.skuModal.hide()
    },
    imageJustLoaded () {
      this.errorText = ''
      let temp = this.sku.dimension.height
      this.sku.dimension.height = 0
      this.sku.dimension.height = temp
    },
    hurryShow () {
      this.$refs.skuModal.show()
    },
    show (sku, product) {
      this.sku = sku
      this.product = product
      this.specifications.splice(0, this.specifications.length)
      this.mute = true
      if (this.sku.id) {
        this.title = `${this.$t('Editar variante para el producto:')} ` + this.product.name
      } else {
        this.title = `${this.$t('Crear variante para el producto:')} ` + this.product.name
      }

      this.errorText = ''
      this.successText = ''
      this.loading = true
      this.getSKUAttributes(this.product.category.id).then(
        (specifications) => {
          this.loading = false
          this.specifications = specifications.body
        },
        (error) => {
          console.log(error)
          this.loading = false
          this.errorText = this.$t('Error obteniendo las especificaciones de la variante')
        }
      )
      this.$refs.skuModal.show()
    },
    emptySpec () {
      this.sku.skuSpecifications = []
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
