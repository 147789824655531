<template>
  <b-container fluid class="promotions">

     <!-- MODAL PROMO -->
    <b-modal
      header-bg-variant="note"
      size="lg"
      ref="promoModal"
      id="promoModal"
      centered
      :title="modalTitle"
      @ok="handleSave"
      :cancel-title="$t('Cancelar')"
      :ok-title="okButtonText"
      :ok-disabled="disableEdition || haveErrors"
    >
      <b-form>
      <b-card :header="$t('Datos generales de la promoción')">
        <b-alert :show="disableEdition" variant="warning">
          <b>{{$t('Promoción gestionada por el administrador, los valores no se pueden modificar')}}</b>
        </b-alert>
        <b-row>
          <b-col>
            <b-alert :show="errorText !== ''" fade variant="danger" v-html="errorText" dismissible>{{errorText}}</b-alert>
            <b-form-group
                id="nameInputGroup"
                :label-cols="2"
                breakpoint="md"
                :invalid-feedback="!promoStates.name? $t('No es un valor válido') : ''"
                :state="promoStates.name"
                :description="$t('Nombre de la promoción')"
                :label="$t('Nombre')">
                <b-form-input
                  :disabled="disableEdition"
                  :state="promoStates.name"
                  v-model="promo.name"
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="activeInputGroup"
              :label-cols="2"
              breakpoint="md"
              :label="$t('¿La promoción esta activa?')">
                <c-switch
                  inputType="checkbox"
                  type="text"
                  variant="success"
                  :on="$t('Si')"
                  :off="$t('No')"
                  :pill="true"
                  :disabled="disableEdition"
                  v-model="promo.isActive"
                  size="m"
                  id="s0"></c-switch>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="descriptionGroup"
              :label-cols="2"
              breakpoint="md"
              :label="$t('Descripción')"
              :description="$t('Descripción de la promoción')"
              :invalid-feedback="!promoStates.description ? 'La descripción no es un texto válido' : ''"
              :state="promoStates.description"
              label-for="inputDescription">
              <b-form-textarea
                id="inputDescription"
                :disabled="disableEdition"
                v-model="promo.description"
                :state="promoStates.description"
                :placeholder="$t('Ingrese el texto de la descripción.')"
                :rows="3"
                :max-rows="6">
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card :header="$t('Validez de la promoción')">
        <b-row>
          <b-col>
            <b-form-group
              id="dateFromGroup"
              breakpoint="md"
              :label="$t('Desde')"
              :invalid-feedback="!promoStates.beginDate ? $t('Fecha inválida') : ''"
              :state="promoStates.beginDate"
              label-for="fromDate">
              <date-picker
                :disabled="disableEdition"
                v-on:contextmenu.native.stop.prevent
                v-on:keydown.native.stop.prevent
                id="fromDate"
                :value="promo.beginDate"
                v-model="promo.beginDate"
                format="YYYY-MM-DD HH:mm:ss"
                valueType="YYYY-MM-DD HH:mm:ss"
                :clearable="true"
                :confirm="true"
                :lang="lang"
                type="datetime">
              </date-picker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="dateToGroup"
              breakpoint="md"
              :label="$t('Hasta')"
              :invalid-feedback="!promoStates.endDate ? $t('Fecha inválida') : ''"
              :state="promoStates.endDate"
              label-for="toDate">
              <date-picker
                :disabled="disableEdition  || !promoStates.beginDate"
                v-on:contextmenu.native.stop.prevent
                v-on:keydown.native.stop.prevent
                id="toDate"
                v-model="promo.endDate"
                format="YYYY-MM-DD HH:mm:ss"
                valueType="YYYY-MM-DD HH:mm:ss"
                :clearable="true"
                :confirm="true"
                :lang="lang"
                type="datetime">
              </date-picker>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card :header="$t('Montos y tipo de descuento de la promoción')">
        <b-row>
          <b-col>
            <b-form-group
                :label-cols="4"
                :description="$t('Defina el tipo de promoción, puede ser nominal o porcentual')"
                :label="$t('Seleccione el tipo de promoción')"
              >
              <b-form-radio-group
                :disabled="disableEdition"
                @change="promoTypeChange"
                v-model="promo.promoType"
                :options="[$t('Nominal'), $t('Porcentual')]">
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="promoValueInputGroup"
              :label-cols="2"
              breakpoint="md"
              :invalid-feedback="!promoStates.promoValue? $t('No es un valor válido') : ''"
              :state="promoStates.promoValue"
              :description="promo.promoType === $t('Nominal') ?  $t('Ingrese el valor exacto de descuento que se aplicará en la promoción') : $t('Ingrese el valor porcentual de descuento que se aplicará en la promoción')"
              :label="promo.promoType === $t('Nominal') ?  $t('Descuento exacto que se aplicara') : $t('Descuento porcentual que se aplicará')">
              <b-form-input
                :disabled="disableEdition"
                @input="valuePromoChange"
                :state="promoStates.promoValue"
                v-model="promo.promoValue"
              ></b-form-input>
            </b-form-group>
            <hr>
            <p>{{$t('Valor nominal:')}} <b>{{currency}} {{promo.nominalDiscountValue}}</b></p>
            <p>{{$t('Valor descuento:')}} <b>{{promo.percentualDiscountValue}}%</b></p>
          </b-col>
        </b-row>
      </b-card>
      <b-card :header="$t('¿A qué elementos se aplica la promoción?')">
        <p v-if="promo.sellers.length === 0 && promo.userType === 'ADMIN'">
          <b-alert show variant="warning">
            <b><i class="far fa-bell mr-2"></i>{{$t('Promoción aplicada a todos los Sellers.')}}</b>
          </b-alert>
        </p>
        <hr/>
        <b-row>
          <b-col class="mb-3">
            <b-form-group
              id="inputBrandGroup"
              :label-cols="2"
              breakpoint="md"
              :label="$t('Marcas a la cual aplica la promoción')"
              label-for="inputBrand">
              <autocomplete
                id="inputBrand"
                name="inputBrand"
                :maxElements="100"
                :provider="getSearchBrands"
                :disabled="disableEdition"
                v-model="promo.brands">
              </autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mb-3">
            <b-form-group
               id="inputProductGroup"
              :label-cols="2"
              breakpoint="md"
              :label="$t('Productos a los cuales aplica la promoción')"
              label-for="inputProduct">
              <autocomplete
                id="inputProduct"
                name="inputProduct"
                :maxElements="100"
                :provider="getSearchProducts"
                :disabled="disableEdition"
                :searchText="$t('Enter para agregar Producto')"
                v-model="promo.products">
              </autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
               id="inputCategoriesGroup"
              :label-cols="2"
              breakpoint="md"
              :label="$t('Categorías a las cuales aplica la promoción')"
              label-for="inputCategories">
              <autocomplete
                id="inputCategories"
                name="inputCategories"
                :maxElements="100"
                :provider="getSearchCategories"
                :disabled="disableEdition"
                :searchText="$t('Enter para agregar Categoría')"
                v-model="promo.categories">
              </autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row >
          <b-col>
            <b-form-group
               id="inputMarketGroup"
              :label-cols="2"
              breakpoint="md"
              :label="$t('Marketplaces a los cuales aplica la promocion')"
              label-for="inputMarket">
              <autocomplete
                id="inputMarket"
                name="inputMarket"
                :maxElements="100"
                :searcheable="false"
                :provider="getSearchMarketplaces"
                :disabled="disableEdition"
                v-model="promo.marketplaces">
              </autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isAdmin">
          <b-col>
            <b-form-group
               id="inputSellersGroup"
              :label-cols="2"
              breakpoint="md"
              :label="$t('Sellers a los cuales aplica la promoción')"
              label-for="inputSellers">
              <autocomplete
                id="inputSellers"
                name="inputSellers"
                :maxElements="100"
                :provider="getSearchSeller"
                :disabled="disableEdition"
                :searchText="$t('Enter para agregar Seller')"
                v-model="promo.sellers">
              </autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
    </b-modal>
    <!-- END MODAL PROMO -->

  </b-container>
</template>

<script>
// Mixims
import { ProductServiceMixim } from '@/mixims/ProductServiceMixim'
import { OrganizationService } from '@/mixims/AdminServiceMixin'
import { PromotionsServiceMixim } from '@/mixims/PromotionsServiceMixim'
import { MarketplaceServiceMixim } from '@/mixims/MarketplaceServiceMixim'
import { ValidationsMixim } from '@/mixims/ValidationsMixim'
import appConfig from '../../config/applicationConfig.js'
import autocomplete from '@/components/common/vue-autocomplete'
import 'vue2-datepicker/index.css'
import DatePicker from 'vue2-datepicker'
import cSwitch from '@/components/common/Switch'

export default {
  name: 'promo-details',
  mixins: [
    ProductServiceMixim,
    ValidationsMixim,
    OrganizationService,
    PromotionsServiceMixim,
    MarketplaceServiceMixim
  ],
  components: {
    autocomplete,
    DatePicker,
    cSwitch
  },
  props: ['promo'],
  data () {
    return {
      lang: {
        formatLocale: this.$i18n.locale === 'en-gr' ? {} : {
          // MMMM
          months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          // MMM
          monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          // dddd
          weekdays: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
          // ddd
          weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vier', 'Sab'],
          // dd
          weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']
        }
      },
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : '$',
      locale: appConfig.LOCALE,
      isAdmin: this.$store.getters.isAdmin,
      modalTitle: '',
      okButtonText: this.$t('Guardar'),
      errorText: ''
    }
  },
  computed: {
    haveErrors () {
      return Object.values(this.promoStates).includes(false)
    },
    promoStates () {
      return {
        name: this.isValidText(this.promo.name, true, 25),
        description: this.isValidTextArea(this.promo.description, true),
        promoValue: this.isValidFloat(this.promo.promoValue, true, true),
        beginDate: this.validateDateBegin(this.promo.beginDate),
        endDate: this.validateDateEnd(this.promo.beginDate, this.promo.endDate)
      }
    },
    disableEdition () {
      if (this.promo.userType === 'ADMIN' && !this.isAdmin) {
        return true
      }
      return false
    }
  },
  methods: {
    validateDateBegin (dateBegin, mandatory = true) {
      if (!dateBegin) return !mandatory
      return true
    },
    validateDateEnd (dateBegin, dateEnd, mandatory = true) {
      if (!dateEnd) return !mandatory

      let dBegin = new Date(dateBegin)
      let dEnd = new Date(dateEnd)
      if (dEnd <= dBegin) {
        return false
      }
      return true
    },
    handleSave (evt) {
      evt.preventDefault()
      this.errorText = ''
      this.okButtonText = this.$t('Espere...')

      if (this.promo.id) {
        this.processPromotion()
      } else {
        this.getPromotionByName(this.promo.name).then(
          (response) => {
            if (!this.hasSamePromoName(response.body)) {
              this.processPromotion()
            } else {
              document.getElementById('promoModal').scroll(0, 0)
              this.errorText = `${this.$t('Ya existe una promoción con el mismo nombre')}`
              this.okButtonText = this.$t('Guardar')
            }
          },
          (error) => {
            this.loading = false
            this.errorText = error.body.message || this.$t('Error obteniendo las promociones')
            return []
          }
        )
      }
    },
    processPromotion () {
      this.savePromotion(this.promo).then(
        () => {
          let successText = this.promo.id !== null ? this.$t('La promoción fue modificada con éxito') : this.$t('La promoción fue creada con éxito')
          this.$emit('done', { key: 'successText', value: successText })
          this.$refs.promoModal.hide()
          this.okButtonText = this.$t('Guardar')
        },
        () => {
          let errorText = `${this.$t('Ocurrió un error')} ${this.promo.id ? this.$t('actualizando') : this.$t('creando')} ${this.$t('la promoción')}`
          this.$emit('done', { key: 'errorText', value: errorText })
          this.$refs.promoModal.hide()
          this.okButtonText = this.$t('Guardar')
        }
      )
    },
    hasSamePromoName (names) {
      let hasSameName = false

      names.forEach((promo) => {
        if (promo.name === this.promo.name) { hasSameName = true }
      })

      return hasSameName
    },
    promoTypeChange (type) {
      if (type === 'Nominal') {
        this.promo.nominalDiscountValue = this.promo.promoValue
        this.promo.percentualDiscountValue = 0
      } else {
        this.promo.nominalDiscountValue = 0
        this.promo.percentualDiscountValue = this.promo.promoValue
      }
    },
    valuePromoChange (value) {
      if (this.promo.promoType === 'Nominal') {
        this.promo.nominalDiscountValue = value
        this.promo.percentualDiscountValue = 0
      } else {
        this.promo.nominalDiscountValue = 0
        this.promo.percentualDiscountValue = value
      }
    },
    showModal (item) {
      this.errorText = ''
      this.okButtonText = this.$t('Guardar')
      if (this.promo.id) {
        this.modalTitle = this.$t('Actualizar promoción')
      } else {
        this.modalTitle = this.$t('Crear promoción')
      }
      this.$refs.promoModal.show()
    }
  }
}
</script>
