<template>
  <div>
    <role-form :id="roleId"/>
  </div>
</template>

<script>
import RoleForm from '@/components/roles/RoleForm'

export default {
  name: 'role-view',
  components: {
    RoleForm
  },
  data () {
    return {
      roleId: null
    }
  },
  created () {
    this.roleId = this.$route.params.id ? this.$route.params.id : null
  }
}
</script>