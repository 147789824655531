<template>
  <div class="noticeModalContainer">
    <b-modal header-bg-variant="primary" centered ref="noticeModal" size="lg" :title="titleModal" o-close-on-backdrop="true" @ok="handleOkClick" cancel-title="Cancelar" ok-title="Aceptar" :ok-disabled="disableSave">
      <b-container>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Mensaje"
              :invalid-feedback="state.text ? '' : 'No es un mensaje válido'"
              :state="state.text">
              <b-form-textarea v-model="notice.text" placeholder="Ingrese el mensaje" rows="3" max-rows="6" aria-describedby="message-helper-text"></b-form-textarea>
              <b-form-text id="message-helper-text" class="text-right">Cantidad de Carácteres {{currentCharacters}}/{{maxCharacters}}</b-form-text>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Fecha y hora de notificacion"
              :invalid-feedback="state.date ? '' : 'La fecha y hora son obligatorios'"
              :state="state.date">
              <date-picker v-model="notice.notifyDate" type="datetime" placeholder="Seleccione fecha y hora" :disabled-date="disableDateBeforeToday" :value-type="'YYYY-MM-DDTHH:mm:ss'" :format="'DD/MM/YYYY HH:mm:ss'"></date-picker>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/es'
import { ValidationsMixim } from '@/mixims/ValidationsMixim'
import { NotificationServiceMixim } from '@/mixims/NotificationServiceMixim.js'
export default {
  props: ['notice', 'action'],
  mixins: [ValidationsMixim, NotificationServiceMixim],
  components: {
    DatePicker
  },
  data () {
    return {
      maxCharacters: 500
    }
  },
  computed: {
    titleModal () {
      if (this.action === 'edit') {
        return 'Editar notificación'
      }
      return 'Crear notificación'
    },
    currentCharacters () {
      return this.notice.text ? this.notice.text.length : 0
    },
    state () {
      return {
        text: this.isValidTextArea(this.notice.text, true, this.maxCharacters),
        date: Boolean(this.notice.notifyDate)
      }
    },
    disableSave () {
      return Object.values(this.state).includes(false)
    }
  },
  methods: {
    disableDateBeforeToday (date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    show () {
      this.$refs.noticeModal.show()
    },
    handleOkClick () {
      if (this.action === 'edit') {
        this.editNotification(this.notice).then(
          (response) => {
            this.$emit('showAlertNotice', {text: `Se modificó la notificación`, variant: 'success'})
          },
          () => {
            this.$emit('showAlertNotice', {text: `Error al modificar la notificación`, variant: 'danger'})
          }
        )
      }
      if (this.action === 'create' || this.action === 'copy') {
        this.createNotification(this.notice).then(
          (response) => {
            this.$emit('showAlertNotice', {text: `La notificación se creó con éxito`, variant: 'success'})
          },
          () => {
            this.$emit('showAlertNotice', {text: `Error al crear la notificación`, variant: 'danger'})
          }
        )
      }
    }
  }
}
</script>
<style scoped>
.modal-content {
  border: 1px solid rgb(18 83 155);
}
</style>