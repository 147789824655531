<template>
  <div class="app">
    <AppHeader/>
    <div class="app-body">
      <main class="main-noaside" :class="isAdmin ? 'admin' : ''">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
    </div>
    <AppFooter/>
  </div>
</template>

<script>
import { Header as AppHeader, Footer as AppFooter } from '../components/'

export default {
  name: 'Header',
  components: {
    AppHeader,
    AppFooter
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    }
  }
}
</script>

<style>
  .main.admin {
    background: #EEE;
  }
</style>
