<template>
  <b-container fluid>
    <div v-show="errorText !== ''" class="alert alert-danger" role="alert">
      {{$t(errorText)}}
    </div>

    <b-alert :show="succesTextNotifyTracking !== ''" variant="success" dismissible>
      {{succesTextNotifyTracking}}
    </b-alert>

    <!-- Modals and  confirms -->
    <invoice-modal ref="invoiceModalComponent" centered :order="orderDetail" :successCallback="invoiceSucessCallback" :carrierIntegration="isShippingExcluded"></invoice-modal>
    <controversy-modal :isGoingToAttend="isGoingToAttend" :order="orderDetail" :visible="controversyModalVisible" :controversySelected="controversySelected" @onControversyModalHide="_controversyModalHideCallback" :successCallback="_updateButtonsVisibility"></controversy-modal>
    <controversy-detail-modal :order="orderDetail" :visible="controvercyDetailModalVisible" :controversySelected="controversySelected" @onControversyModalHide="_controversyDetailsModalHideCallback"></controversy-detail-modal>
    <return-order-modal ref="returnModalComponent" centered :order="orderDetail" @onSuccessReturn="onSuccessReturn" @onErrorReturn="onErrorReturn"></return-order-modal>

    <b-modal centered headerBgVariant="primary" ref="deliveryModal" id="deliveryModal" size="lg" :title="$t('Confirmar entrega')" o-close-on-backdrop="true" :cancel-title="$t('Cancelar')" :ok-title="$t('Confirmar')" :ok-disabled="toDeliveryArray.length === 0 || loading" @ok="_execDelivery" >
      <template v-if="orderDetail.packageAttachment && orderDetail.packageAttachment.packages && orderDetail.packageAttachment.packages.length > 0">
        <template v-for="(item, index) in orderDetail.packageAttachment.packages">
          <div class="package-container" v-if="item.courierStatus && item.courierStatus.finished" :key="index">
            <p><b>{{$t('Factura')}}: {{item.invoiceNumber}}<span class="package-finished-label">{{$t('Entrega confirmada')}}</span></b></p>
              <ul>
                <li class="mb-1" v-for="(product, index) in item.items" :key="index">
                  {{getProductNameById(product.id)}} ({{product.quantity}} items)
                </li>
              </ul>
          </div>
          <div class="package-container" v-if="item.courierStatus && !item.courierStatus.finished" :key="index + 'b'">
            <b-form-checkbox :disabled="!item.trackingNumber" class="ml-4" @click.native.stop :value="item" v-model="toDeliveryArray">
              <b>{{$t('Factura')}}: {{item.invoiceNumber}} {{!item.trackingNumber ? $t('(No es posible entregar ya que no tiene datos de tracking)') : ''}}</b>
            </b-form-checkbox>
            <div class="ml-4">
              <p><b>{{$t('Productos')}} </b>{{$tc('{count} items', item.totalItems,{count: item.totalItems})}}</p>
              <ul>
                <li class="mb-1 textControl" v-for="(product, index) in item.items" :key="index">
                  {{product.name ? product.name : getProductNameById(product.id)}} ({{product.quantity}} items) - {{currency}} {{product.price}}
                </li>
              </ul>
            </div>
          </div>
        </template>
        <h5 class="mt-3">{{$t('Paquetes que seran confirmados de entrega')}}</h5>
        <p v-for="(pack, index) in toDeliveryArray" :key="index">
          <b>{{$t('Paquete')}} {{parseInt(index) + 1}}</b> - {{$t('Factura')}} ({{pack.invoiceNumber}})
        </p>
      </template>
    </b-modal>

    <b-modal centered headerBgVariant="primary" ref="confirm" id="confirm" size="lg" :title="confirm.title" o-close-on-backdrop="true"
      :cancel-title="$t('Cancelar')" @ok="confirm.callback" :ok-title="$t('Aceptar')" >
      <template v-html="confirm.confirmBody">
        <div class="warning-text">
          <i class="fa fa-exclamation-triangle"></i>
          <div v-html="confirm.confirmBody">{{confirm.confirmBody}}</div>
        </div>
      </template>
    </b-modal>

    <b-modal centered headerBgVariant="primary" ref="notifyTrackingModal" id="notifyTrackingModal" size="lg" :title="$t('Notificar envío')" o-close-on-backdrop="true"
      :cancel-title="$t('Cancelar')" @ok="notifyTracking" :ok-title="$t('Aceptar')" >
      <template>
        <div class="warning-text">
          <i class="fa fa-exclamation-triangle"></i>
          <div><h6>{{$t('Desea notificar el envío del Paquete ')}} {{itemToNotifyTracking.indexPackage}} - {{$t('Factura')}} ({{$t(itemToNotifyTracking.invoiceNumber)}})?</h6></div>
        </div>
      </template>
    </b-modal>

    <b-row>
      <b-col>
        <moon-loader :loading="loading" class="topLoading" color="#000" size="30px"></moon-loader>
      </b-col>
    </b-row>

    <!-- Action list header row  -->
    <b-row class="mb-3">
      <b-col md="7">
        <h2>{{$t('Orden {orderId} ({accountName})', {'orderId': orderId, 'accountName': orderDetail.accountName })}}<b-button @click="print" variant="link"><i class="fas fa-print mr-1"></i>{{$t('Imprimir')}}</b-button></h2>
        <p class="order-date">
          <b class="mr-2" v-if="orderDetail.creationDate">{{dateToLocale(orderDetail.creationDate)}}</b>
          <span
            v-for="(tagElemKey, index) in orderDetail.tags"
            v-b-tooltip.hover
            :title="$t(tagsMapping[tagElemKey].description)"
            class="tag"
            :class="tagsMapping[tagElemKey].bg"
            :key="index"
            >
            {{tagElemKey}}
          </span>
        </p>
      </b-col>
      <b-col md="5">
        <b-button :disabled="buttonsDisabled" @click="$router.back()" class="btn btn-primary float-right mr-2"><i class="fas fa-undo-alt mr-1"></i>{{$t('Regresar')}}</b-button>
        <button v-show="invoiceButtonVisible && !loadingIntegrationData && !isShippingExcluded  && !hasSubStatusBlocking" :disabled="buttonsDisabled" @click="$refs.invoiceModalComponent.show()" type="button" class="btn btn-primary float-right mr-2"><i class="far fa-money-bill-alt mr-1"></i>{{$t('Facturar')}}</button>
        <button v-show="invoiceButtonVisible && !loadingIntegrationData && isShippingExcluded && !hasSubStatusBlocking" :disabled="buttonsDisabled" @click="$refs.invoiceModalComponent.show()" type="button" class="btn btn-primary float-right mr-2">{{$t('Solicitar guía')}}</button>
        <button v-show="startHandlingButtonVisible && !hasSubStatusBlocking" :disabled="buttonsDisabled" type="button" v-on:click="startHandlingAction" class="btn btn-primary float-right mr-2"><i class="fas fa-play mr-1"></i>{{ $t('Iniciar Manejo') }}</button>
        <button v-show="cancelButtonVisible && !hasSubStatusBlocking" :disabled="buttonsDisabled" type="button" v-on:click="cancelAction" class="btn btn-primary float-right mr-2"><i class="far fa-times-circle mr-1"></i>{{$t('Cancelar')}}</button>
        <button v-show="deliveryButtonVisible && !hasSubStatusBlocking" :disabled="buttonsDisabled" v-on:click="deliverAction" type="button" class="btn btn-primary float-right mr-2"><i class="fas fa-truck mr-1"></i>{{$t('Entregar')}}</button>
        <button v-show="returnButtonVisible" :disabled="buttonsDisabled" @click="$refs.returnModalComponent.show()" type="button" class="btn btn-danger float-right mr-2"><i class="fa fa-undo mr-1" aria-hidden="true"></i>{{$t('Devolución')}}</button>
      </b-col>
    </b-row>

    <b-row v-if="orderDetail.shippingData" v-show="isShippingExcluded && orderDetail.status !== 'order-handling'">
      <b-col>
        <div class="alert alert-info" role="alert">{{$t('El pedido tiene asignado el carrier')}} {{ orderDetail.shippingData.logisticsInfo[0].deliveryCompany }}, {{$t('TEXT_DOWNLOAD_GUIDE')}}</div>
      </b-col>
    </b-row>

    <!-- Beggining of the order details  -->
    <b-row>
      <b-col md="6" lg="3">
        <b-card :header="$t('Cliente')">
            <template v-if="orderDetail.clientProfileData">
              <p><span class="h5">{{ orderDetail.clientProfileData.firstName }} {{ orderDetail.clientProfileData.lastName }}</span></p>
              <p class="user-email">
                <i class="far fa-envelope"></i>
                <a class="ml-1" :href="`mailto:${ orderDetail.clientProfileData.email }`">{{ orderDetail.clientProfileData.email }}</a>
              </p>
              <p><i class="fa fa-phone"></i> {{ orderDetail.clientProfileData.phone }}</p>
              <p>{{$t('DNI')}} {{orderDetail.clientProfileData.document}}</p>
          </template>
        </b-card>
      </b-col>
      <b-col md="6" lg="3">
        <b-card :header="$t('Dirección de entrega')">
          <template v-if="orderDetail.shippingData">
            <p><span class="h5">{{orderDetail.shippingData.address.receiverName}}</span></p>
            <p>{{orderDetail.shippingData.address.street}}, {{orderDetail.shippingData.address.number}}<template v-if="orderDetail.shippingData.address.complement">, {{orderDetail.shippingData.address.complement}}</template></p>
            <p>{{orderDetail.shippingData.address.neighborhood}}</p>
            <p>{{orderDetail.shippingData.address.city}} / {{orderDetail.shippingData.address.state}}</p>
            <p>{{orderDetail.shippingData.address.postalCode}} - {{orderDetail.shippingData.address.country}}</p>
            <p v-if="orderDetail.shippingData.address.reference">{{$t('Punto de referencia')}}: {{orderDetail.shippingData.address.reference}}</p>
          </template>
        </b-card>
      </b-col>
      <b-col md="6" lg="3">
        <b-card :header="$t('Total (') + (orderDetail.status === 'payment-pending' ? $t('Pedido no pagado)') : $t('Pedido pagado)'))">
          <h4>{{currency}} {{orderDetail.value}}</h4>
          <p v-for="(item,index) in orderDetail.totals" :key="index">
            <span v-if="item.value > 0 && item.id !== 'Tax'">
              {{ capitalizeText(item) }} - <b>{{currency}} {{item.value}}</b>
            </span>
          </p>
          <hr/>
          <!-- Promotion applied -->
          <div class="my-3">
            <b-button v-if="promoLoaded" variant="link" v-b-toggle.collapse-promos size="md" class="p-0">
              <span><b>{{$t('Promociones aplicadas')}} ({{orderPromos.length}})</b></span>
            </b-button>
            <span v-if="!promoLoaded">{{$t('Obteniendo las promociones...')}}</span>
          </div>
          <b-collapse id="collapse-promos" class="mt-2">
            <p v-for="(promo,index) in orderPromos" :key="index">
              <span>
                <i class="fas fa-tag mr-2"></i>{{promo.scName}}
                <span class="usertype-tag">{{promo.userType}}</span>
              </span>
              <b-button variant="link" v-b-toggle="'collapse-' + promo.scName" size="sm">
                <span>ver</span>
              </b-button>
              <b-collapse :id="'collapse-' + promo.scName" class="mt-2">
                <b-card>
                  <p>{{promo.description}}</p>
                  <p><b>{{promo.percentualDiscountValue !== 0 ? promo.percentualDiscountValue + '%' : promo.nominalDiscountValue + ' ' +currency}}</b></p>
                </b-card>
              </b-collapse>
            </p>
          </b-collapse>
        </b-card>
      </b-col>
      <b-col md="6" lg="3">
        <b-card class="status-card" :header-bg-variant="statusVariantClass" :border-variant="statusVariantClass">
          <template #header>
              <div class="d-flex">
                <p class="col-10 p-0 card-title">{{$t('Estado')}}</p>
                <p class="col-2 p-0 text-center btn-light btn-history" title="Ver historial" @click="showHistorialStatus= !showHistorialStatus">
                  <i class="fas fa-history"></i>
                </p>
              </div>
          </template>
          <p><span class="h4">{{$t(statusMapping[orderDetail.status])}}</span></p>
        </b-card>

        <b-card class="status-card" :header-bg-variant="statusVariantClass" :border-variant="statusVariantClass">
          <template #header>
              <div class="d-flex">
                <p class="col-10 p-0 card-title">{{$t('Sub-estado')}}</p>
                <p class="col-2 p-0 text-center btn-light btn-history" title="Ver historial" @click="showHistorialSubStatus= !showHistorialSubStatus">
                  <i class="fas fa-history"></i>
                </p>
              </div>
          </template>
          <div class="d-flex">
            <p class="h6 col-10 substatus-name p-0">
              <span v-if="orderDetail.subStatus">
                {{orderDetail.subStatus.name}}
              </span>
              <span v-else>-</span>
            </p>
            <p class="col-2 btn-radius btn text-center" @click="showModalSubStatus()">
              <i class="fa fa-edit"></i>
            </p>
          </div>
          <p v-if="orderDetail.subStatus && orderDetail.subStatus.blocking" class="text-danger">El sub-estado es bloqueante. Para realizar alguna acción, debe cambiar de sub-estado.</p>
        </b-card>
        <b-card class="status-card" header-bg-variant="primary" border-variant="primary" :header="$t('Sitio / Política comercial')">
          <h4>
            {{orderDetail.marketplace ? orderDetail.marketplace.name: '-'}} / {{orderDetail.salesChannelName ? orderDetail.salesChannelName: '-'}}
          </h4>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="showHistorialStatus">
        <h5>Historial Estados</h5>
        <b-table
            striped
            :fields="statusHistoriesFields"
            :items="orderDetail.statusHistories"
            hover
            outlined
            head-variant='light'
            :small="true"
            >
            <template slot="user" slot-title="Usuario" slot-scope="row">
              <span v-if="row.item.user">{{row.item.user.lastName}}, {{row.item.user.firstName}} ({{row.item.user.email}})</span>
              <span v-else>-</span>
            </template>
            <template slot="status" slot-scope="row">
              <span>{{statusMapping[row.item.status]}}</span>
            </template>
            <template slot="creationDate" slot-scope="row">
              <span>{{ formatDate(row.item.creationDate) }}</span>
            </template>

          </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="showHistorialSubStatus">
        <h5>Historial Sub-estados</h5>
        <b-table
            striped
            :fields="subStatusHistoriesFields"
            :items="orderDetail.subStatusHistories"
            hover
            outlined
            head-variant='light'
            :small="true"
            >
            <template slot="user" slot-title="Usuario" slot-scope="row">
              <span v-if="row.item.user">{{row.item.user.lastName}}, {{row.item.user.firstName}} ({{row.item.user.email}})</span>
              <span v-else>-</span>
            </template>
            <template slot="name" slot-scope="row">
              <span>{{row.item.status.name}}</span>
            </template>
            <template slot="creationDate" slot-scope="row">
              <span>
                {{ formatDate(row.item.creationDate) }}
              </span>
            </template>

          </b-table>
      </b-col>
    </b-row>
    <b-row>
      <!-- Order Item list -->
      <b-col md="7">
        <b-card :header="$t('Items')">
          <b-table
            striped
            :items="orderDetail.items"
            hover
            outlined
            head-variant='light'
            :fields="isAdmin? itemsFieldsAdmin : itemsFields"
            :small="true"
            >
            <template slot="price" slot-scope="row">
              <span>{{currency}} {{row.item.price}}</span>
            </template>
            <template slot="status" slot-scope="row">
              <span
                v-for="(tagElemKey, index) in row.item.tags"
                v-b-tooltip.hover
                :title="$t(tagsMapping[tagElemKey].description)"
                class="tag tag-small"
                :class="tagsMapping[tagElemKey].bg"
                :key="index"
                >
                {{tagElemKey}}
              </span>
            </template>
            <template slot="actions" slot-scope="row">
              <template v-if="row.item.priceTags && row.item.priceTags.length > 0">
                <b-button variant="link" size="sm" @click="row.toggleDetails" class="mr-2">
                  <span v-show="!row.detailsShowing"><i class="fa fa-plus"></i></span>
                  <span v-show="row.detailsShowing"><i class="fa fa-minus"></i></span>
                  {{$t(Promociones)}}
                </b-button>
              </template>
            </template>
            <template slot="row-details" slot-scope="row">
             <div class="p-2">
               <div v-for="promo in row.item.priceTags" :key="promo.identifier">
                 <p v-if="promo.description">
                    <span><i class="fas fa-tag mr-2"></i>{{promo.scName}}</span>
                    <span>(<b>{{promo.percentualDiscountValue !== 0 ? promo.percentualDiscountValue + '%' : promo.nominalDiscountValue + ' ' +currency}}</b>)</span>
                    <span class="usertype-tag">{{promo.userType}}</span>
                  </p>
                  <p v-else>
                    <span><i class="fas fa-tag mr-2"></i>{{$t('Promo Ecommerce')}}</span>
                    <span>(<b>{{promo.percentualDiscountValue !== 0 ? promo.percentualDiscountValue + '%' : promo.nominalDiscountValue + ' ' +currency}}</b>)</span>
                    <span class="usertype-tag">{{$t('ECOMMERCE')}}</span>
                  </p>
               </div>
             </div>
            </template>
          </b-table>
          <div class="table-total">{{$t('Total de los items')}}: <b>{{currency}} {{orderDetail.totals !== undefined? orderDetail.totals[0].value : '0'}}</b></div>
          <div class="table-total">{{$t('Total con descuentos:')}} <b>{{currency}} {{orderDetail.value !== undefined? orderDetail.value : '0'}}</b></div>
        </b-card>
      </b-col>

      <!-- Shiping data -->
      <b-col md="5">
        <b-card class="shipping" :header="$t('Datos de envío')">
          <!-- Show the tracking information -->
          <template v-if="orderDetail.packageAttachment && orderDetail.packageAttachment.packages && orderDetail.packageAttachment.packages.length > 0">
            <p>
              <b><i class="fas fa-truck mr-2"></i>{{$t('Forma de entrega')}}: </b> {{$t(orderDetail.shippingData.logisticsInfo[0].selectedSla)}} - {{orderDetail.shippingData.logisticsInfo[0].deliveryCompany}} </p>
            <p class="mb-4"><b><i class="far fa-clock mr-2"></i>{{$t('Tiempo Estimado')}}: </b> {{orderDetail.shippingData.logisticsInfo[0].shippingEstimate.replace('bd', ' ' + $t('días hábiles'))}}</p>
            <div class="package-container" v-for="(item, index) in orderDetail.packageAttachment.packages" :key="index">
              <p><b>{{$t('Paquete')}} {{parseInt(index) + 1}} - {{$t('Factura')}} ({{item.invoiceNumber}}) <span class="package-finished-label" v-if="item.courierStatus.finished">{{$t('entregado')}}</span></b></p>
              <p v-if="!item.trackingNumber && !isShippingExcluded"><i>{{$t('(Faltan datos de tracking, ver detalles)')}}</i></p>
              <p>{{$t('Fecha de emisión')}}: {{new Date(item.issuanceDate).toLocaleDateString(locale)}}</p>
              <p class="mb-2">{{$t('Total')}}: {{currency}} {{item.invoiceValue}}</p>

              <b-button :disabled="buttonsDisabled" v-if="!isAdmin && !item.trackingNumber && !isShippingExcluded" @click="$refs.invoiceModalComponent.show(item.invoiceNumber)" variant="outline-primary">{{$t('Datos de Tracking')}}</b-button>
              <b-button :disabled="!item.trackingNumber || buttonsDisabled" v-if="!isAdmin && item.courierStatus && !item.courierStatus.finished && !isShippingExcluded" @click="deliverPackage(item.invoiceNumber)" variant="outline-primary" class="ml-1">{{$t('Entregar')}}</b-button>
              <b-button v-if="!isAdmin && isShippingExcluded && !item.notifiedTrackingMarketplace && item.trackingNumber" @click="openNotifyTrackingModal(item, index)" variant="outline-primary">{{$t('Notificar envío')}}</b-button>
              <div>
              <b-button class="p-0 my-2" variant="link" v-b-toggle="'collapse-' + item.invoiceNumber" size="sm">{{$t('(Tracking y Productos)')}}</b-button>
              </div>
              <!-- Tracking data -->
              <b-collapse :id="'collapse-' + item.invoiceNumber">
                <template v-if="item.trackingNumber">
                  <p>{{$t('Empresa de mensajería')}} <b>{{item.courier}}</b></p>
                  <p>{{$t('Número guía')}} <b>{{item.trackingNumber}}</b></p>
                  <p class="mb-2">{{$t('URL guía')}} <b>{{item.trackingUrl}}</b></p>
                  <p v-if="Boolean(item.urlTrackingLabel)"><a target="_blank" :href="item.urlTrackingLabel">{{$t('Descargar Guia')}}</a></p>
                </template>
                <p class="mt-3"><b>{{$t('Productos')}}</b></p>
                <ul>
                  <li v-for="(product, index) in item.items" :key="index"><span class="quantity">{{product.quantity}}</span> {{getProductNameById(product.id)}}</li>
                </ul>
              </b-collapse>
            </div>
          </template>
          <template v-else>
            <p>{{$t('El estado de la orden es')}} <b>{{$t(statusMapping[orderDetail.status])}}</b>, {{$t('no dispone en este estado datos de envío.')}}</p>
            <hr/>
            <div v-if="Boolean(orderDetail.shippingData)" v-b-tooltip.hover :title="$t('TEXT_TOOLTIP_CHANGE_COURIER')">
              <p>
                <b><i class="fas fa-truck mr-1"></i>{{$t('Forma de entrega')}}: </b>
                {{orderDetail.shippingData ? $t(orderDetail.shippingData.logisticsInfo[0].selectedSla) : ''}} -
                {{orderDetail.shippingData.logisticsInfo[0].deliveryCompany}}
              </p>
              <p class="mb-3">
               <b><i class="far fa-clock mr-1"></i>{{$t('Tiempo Estimado')}}: </b> {{orderDetail.shippingData ? orderDetail.shippingData.logisticsInfo[0].shippingEstimate.replace('bd', ' ' + $t('días hábiles')) : ''}}
              </p>
            </div>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-3 mt-3">
      <!-- Controversy list table -->
      <b-col md="12" v-if="false">
        <b-card class="controversy-card" :title="$t('Controversias')">
          <button v-show="controversyButtonVisible" :disabled="buttonsDisabled" @click.stop="showControversyModal(null, false)" type="button" class="add-controversy btn btn-primary float-right mr-2"><i class="fa fa-plus mr-2"></i>{{$t('Crear controvesia')}}</button>
          <b-table
            :items="orderDetail.controversies"
            hover
            :fields="controversyFields"
            outlined
            :small="true"
            stacked="md"
            head-variant='light'
            >
            <!-- Controversy notes -->
            <template slot="row-details" slot-scope="row">
              <ul>
                <li class="controversy-note" v-for="(comment, index) in row.item.comments" :key="index">
                  <b>{{new Date(comment.date).toLocaleDateString(locale)}}</b> - {{comment.text}}
                </li>
              </ul>
            </template>

            <template slot="date" slot-scope="date">
              {{new Date(date.value).toLocaleDateString(locale)}}
            </template>

            <template slot="text" slot-scope="text">
              <strong>
                [{{$t(text.value.split('> ')[0].replace(/(^.*\[|\].*$)/g, '' ))}}]
              </strong>
               {{text.value.split('/b>')[1]}}
            </template>

            <template slot="actions" slot-scope="row">
              <b-button class="float-right ml-1" :disabled="buttonsDisabled" size="sm" @click.stop="showControversyModal(row, true)" v-show="!isAdmin && !row.item.active" >{{$t('Atender')}}</b-button>
              <b-button class="float-right ml-1" :disabled="buttonsDisabled" size="sm" v-show="isAdmin" @click.stop="deleteControversyAction(row)">{{$t('Eliminar')}}</b-button>
              <b-button class="float-right ml-1" :disabled="buttonsDisabled" size="sm" @click.stop="showControversyModal(row, false)">{{$t('Agregar Nota')}}</b-button>
              <b-button class="float-right ml-1" :disabled="buttonsDisabled" size="sm" @click.stop="showControversyDetailModal(row, false)">{{$t('Detalles')}}</b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>

      <!-- Enable or disable order tags like liquidated and punishment -->
      <b-col md="2" v-if="false">
        <b-card class="tags-card" :title="$t('Etiquetas')">
            <b-row class="d-flex justify-content-center">
               <span
                v-for="tagElemKey in orderDetail.tags"
                v-b-tooltip.hover
                :title="$t(tagsMapping[tagElemKey].description)"
                class="tag"
                :class="tagsMapping[tagElemKey].bg"
                :key="tagsMapping[tagElemKey].description"
              >
                {{tagElemKey}}
              </span>
            </b-row>
        </b-card>
      </b-col>

      <!-- Incidence table -->
      <b-col md="12">
        <b-card class="controversy-card" :title="$t('Incidencias')" v-if="orderDetail.id">
          <button @click.stop="createIncidence()" type="button" class="add-controversy btn btn-primary float-right mr-2"><i class="fa fa-plus mr-2"></i>{{$t('Crear incidencia')}}</button>
          <b-col>
            <incidents-table
              ref="indicentsTable"
              :filters="filters"
              :showIncidenceDetail="true"
            >
            </incidents-table>
          </b-col>
        </b-card>
      </b-col>
    </b-row>

    <b-modal ref="modalSubStatus" centered title="Sub-estado de la orden">
      <b-form-select v-model="selectedSubStatus" v-if="substatus">
          <option slot="first" :value="null" selected disabled>Seleccione subestado</option>
          <option v-for="(text, value) in substatus"
              :key="value"
              :value="text.id"
              >{{capitalizeFirstLetter($t(text.name)) }}</option>
      </b-form-select>
      <h6 v-if="!substatus && isAdmin">No hay sub-estados para este estado. Por favor, agregar un sub-estado haciendo click
        <a href="#/catalog/substatus">aquí</a>
      </h6>
      <h6 v-if="!substatus && !isAdmin">No hay sub-estados para este estado. Por favor, comuniquese con el administrador.</h6>
      <template #modal-footer>
        <b-button class="btn-radius" :block="true" variant="primary" @click.stop="saveSubStatus" v-if="substatus">
          Guardar
        </b-button>
        <b-button class="btn-radius" :block="true" variant="secondary" @click.stop="hideModalSubStatus" v-if="!substatus">
          Cancelar
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>

import {OrdersServiceMixim} from '../../mixims/OrdersServiceMixim'
import {SubStatusMixim} from '@/mixims/SubStatusMixim.js'
import {ControversyServiceMixim} from '../../mixims/ControversyServiceMixim.js'
import { ProductServiceMixim } from '@/mixims/ProductServiceMixim.js'
import { CarrierServiceMixin } from '@/mixims/CarrierServiceMixin.js'
import { PromotionsServiceMixim } from '@/mixims/PromotionsServiceMixim'
import { IntegrationServiceMixin } from '@/mixims/IntegrationServiceMixin.js'
import InvoiceModal from './InvoiceModal'
import ReturnOrderModal from './ReturnOrderModal'
import ControversyModal from './ControversyModal'
import ControversyDetailModal from './ControversyDetailModal'
import incidentsTable from '@/components/incidents/IncidentsTable'

import appConfig from '../../config/applicationConfig.js'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import Vue from 'vue'
import moment from 'moment'

export default {
  name: 'order-details-board',
  mixins: [OrdersServiceMixim, SubStatusMixim, ControversyServiceMixim, ProductServiceMixim, PromotionsServiceMixim, CarrierServiceMixin, IntegrationServiceMixin],
  components: {
    InvoiceModal,
    ControversyModal,
    ControversyDetailModal,
    MoonLoader,
    incidentsTable,
    ReturnOrderModal
  },
  computed: {
    /* 00:00-24:00 hrs color verde
       24:01-48:00 hrs color amarillo
      48:01- en adelante color rojo */
    controversyTagClass: function () {
      let days = 0
      if (this.orderDetail.controversies && this.orderDetail.controversies.length > 0) {
        let auxDays = 0
        this.orderDetail.controversies.forEach((cont) => {
          let timeDiff = (new Date()) - (new Date(cont.updateDate))
          auxDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
          days = auxDays >= days ? auxDays : days
        })
      }
      if (days < 1) return 'green'
      if (days < 2) return 'yellow'
      return 'red'
    },
    deliveryButtonVisible: function () {
      /* Only the seller can deliver */
      if (!this.isAdmin && !this.isShippingExcluded && (this.orderDetail.status === 'invoiced' || this.orderDetail.status === 'partially-invoiced' || this.orderDetail.status === 'partially-delivered')) {
        /* Have packages? */
        if (this.orderDetail.packageAttachment.packages && this.orderDetail.packageAttachment.packages.length > 0) {
          /* Theres is a non finished package */
          return (this.orderDetail.packageAttachment.packages.find(
            (packItem) => {
              return packItem.courierStatus.finished === false
            }
          ) !== undefined)
        }
      }
      return false
    },
    returnButtonVisible: function () {
      return this.isAdmin && this.orderDetail && (this.orderDetail.status === 'delivered' || this.orderDetail.status === 'partial-return')
    }
  },
  props: ['orderId'],
  data () {
    return {
      isAdmin: this.$store.getters.isAdmin,
      canWrite: this.$store.getters.signedRoles.includes('ORDER_WRITE'),
      buttonsDisabled: false,
      statusVariantClass: 'primary',
      errorText: '',

      loading: false,
      loadingIntegrationData: false,
      contLoading: false,
      /* Callback for the change controversy state modal, this will be set before open the modal */
      stateModalCallback: '',
      cancelStateCallback: '',
      stateModalText: '',

      /* Button */
      invoiceButtonVisible: false,
      startHandlingButtonVisible: false,
      cancelButtonVisible: false,
      updateTagsButtonVisible: true,
      controversyButtonVisible: false,
      controversyModalVisible: false,
      controvercyDetailModalVisible: false,

      /* When the controversy id is set, we create a note else create a controversy */
      controversySelected: null,
      isGoingToAttend: false,
      orderDetail: {},
      orderPromos: [],
      toDeliveryArray: [],

      /* Confirm bind data to have only one confirm for all actions like
         start handling or invoice */
      confirm: {
        title: '',
        callback: '',
        confirmBody: ''
      },

      itemsFields: [
        { key: 'id', label: this.$t('SKU') },
        { key: 'refId', label: this.$t('Referencia') },
        { key: 'name', label: this.$t('Nombre') },
        { key: 'quantity', label: this.$t('Cantidad') },
        { key: 'price', label: this.$t('Precio') },
        { key: 'status', label: this.$t('Estado') }
      ],
      itemsFieldsAdmin: [
        { key: 'id', label: this.$t('SKU') },
        { key: 'refId', label: this.$t('Referencia') },
        { key: 'name', label: this.$t('Nombre') },
        { key: 'quantity', label: this.$t('Cantidad') },
        { key: 'price', label: this.$t('Precio') },
        { key: 'status', label: this.$t('Estado') }
      ],
      devolutionFields: [
        { key: 'date', label: this.$t('Fecha') },
        { key: 'name', label: this.$t('Nombre') },
        { key: 'id', label: this.$t('SKU') },
        { key: 'quantity', label: this.$t('Cantidad') },
        { key: 'price', label: this.$t('Precio') }
      ],
      devolutions: [],
      controversyStateListCDE: appConfig.CONTROVERSY_STATES_CDE,
      controversyStateListCF: appConfig.CONTROVERSY_STATES_CF,
      controversyFields: [
        { key: 'date', label: this.$t('Fecha de creación'), _showDetails: false },
        { key: 'text', label: this.$t('Descripción'), _showDetails: false },
        { key: 'actions', label: this.$t('Acciones'), _showDetails: false, class: 'controversy-actions' }
      ],
      statusMapping: appConfig.STATUS_MAPPING,
      statusMappingBgColors: appConfig.STATUS_MAPPING_COLORS,
      tagsMapping: appConfig.TAGS_MAPPING,
      marketplaceInvoice: appConfig.MARKETPLACE_INVOICE,
      locale: appConfig.LOCALE,
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : '$',
      promoLoaded: false,
      shippingsExcluded: appConfig.SHIPPING_EXCLUDED,
      filters: {
        fromDate: null,
        toDate: null,
        incidenceType: null,
        accountName: null,
        priority: null,
        status: null
      },
      isShippingExcluded: false,
      itemToNotifyTracking: {},
      succesTextNotifyTracking: '',
      substatus: '',
      selectedSubStatus: null,
      statusHistoriesFields: [
        {key: 'user', label: 'Usuario', sortable: true},
        {key: 'status', label: 'Estado', sortable: true},
        {key: 'creationDate', label: 'Fecha creación', sortable: true}
      ],
      showHistorialStatus: false,
      subStatusHistoriesFields: [
        {key: 'user', label: 'Usuario', sortable: true},
        {key: 'name', label: 'Sub-Estado', sortable: true},
        {key: 'creationDate', label: 'Fecha creación', sortable: true}
      ],
      showHistorialSubStatus: false,
      hasSubStatusBlocking: false,
    }
  },
  methods: {
    dateToLocale (date) {
      if (!date) return

      if(!date.endsWith('Z'))
        date += 'Z'
      return new Date(date).toLocaleString()
    },
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    },
    print () {
      window.print()
    },
    showModalSubStatus () {
      this.substatusName = this.orderDetail.status
      this.$refs['modalSubStatus'].show()
      this.getSubStatus()
    },
    hideModalSubStatus () {
      this.$refs['modalSubStatus'].hide()
    },
    getSubStatus () {
      return this.getAllStatus(null).then(function (response) {
        this.substatus = response.body.length > 0 ? response.body : false
      },
      (error) => {
        this.errorText = error.body.message || this.$t('Error obteniendo el listado de estados')
        return []
      })
    },
    saveSubStatus () {
      return this.putSubstatus(this.selectedSubStatus, this.orderDetail.id).then(function (response) {
        this.$refs['modalSubStatus'].hide()
        this._getOrdersDetails()
        this.substatus = response.body
      },
      (error) => {
        this.errorText = error.body.message || this.$t('Error obteniendo el listado de estados')
        return []
      })
    },
    controversyStateChange (row) {
      let allStates = appConfig.CONTROVERSY_STATES_CF.concat(appConfig.CONTROVERSY_STATES_CDE)

      let from = allStates.find((elem) => {
        return elem.value === row.value
      }).text

      let to = allStates.find((elem) => {
        return elem.value === row.item.state
      }).text

      /* Prevent the case when dropdown changes on get controversies */
      /* Cases: On getOrderDetails, on cancel where the item value is set as the previous select value */
      if (row.value === row.item.state) {
        return
      }

      /* Cancel the  change state, called on modal @cancel */
      this.cancelStateCallback = () => {
        row.item.state = row.value
      }

      /* Update the state callback on modal @ok */
      this.stateModalCallback = () => {
        this.contLoading = true
        this.updateControversy(this.orderDetail.id, row.item.id, row.item.state).then(
          /* Add the note with the state changed */
          () => {
            this.contLoading = false
            this._getOrdersDetails()
          },
          (error) => {
            row.item.state = row.value
            this.contLoading = false
            this.errorText = error.body.message || this.$t('error actualizando el estado de la controversia')
          }
        )
      }

      if (row.item.state === 'DONE' || row.item.state === 'INVALID') {
        this.stateModalText = this.$t('¿Se cambiará el estado de') + ` <b>"${from}"</b> ` + this.$t('a') + ` <b>"${to}"</b>, ` + this.$t('desea continuar?')
        this.$refs.controversyStateModal.show()
        return
      }
      this.stateModalCallback()
    },
    /* Get the number of delivered items given the sku */
    getDeliveredItemQuantity (id) {
      let quantity = 0
      if (this.orderDetail.packageAttachment && this.orderDetail.packageAttachment.packages && this.orderDetail.packageAttachment.packages.length > 0) {
        this.orderDetail.packageAttachment.packages.forEach(
          (packageItem) => {
            packageItem.items.forEach(
              (item) => {
                if (item.id === id) {
                  quantity = quantity + item.quantity
                }
              }
            )
          }
        )
      }
      return quantity
    },
    getProductNameById (id) {
      let name = ''
      this.orderDetail.items.forEach((item) => {
        if (item.id === id) {
          name = item.name
        }
      })
      return name
    },
    showControversyModal (controversy, isGoingToAttend) {
      this.controversySelected = controversy ? controversy.item : null
      this.controversyModalVisible = true
      this.isGoingToAttend = isGoingToAttend
    },

    showControversyDetailModal (controversy) {
      this.controversySelected = controversy ? controversy.item : null
      this.controvercyDetailModalVisible = true
    },

    _controversyDetailsModalHideCallback () {
      this.controvercyDetailModalVisible = false
    },

    _controversyModalHideCallback () {
      this.controversyModalVisible = false
      this.isGoingToAttend = false
    },

    _getControversies () {
      this.contLoading = true
      this.errorText = ''
      return this.getControversies(this.orderDetail.id).then(
        (controversies) => {
          controversies.body.forEach((controversy) => {
            if (!controversy.active) {
              controversy._rowVariant = 'danger'
            } else {
              controversy._rowVariant = 'success'
            }
          })
          this.buttonsDisabled = false
          this.contLoading = false
          this.orderDetail.controversies = controversies.body
        },
        (error) => {
          this.errorText = error.body.message || this.$t('error obteniendo las controversias')
        }
      )
    },

    deleteControversyAction (controversy) {
      this.showConfirm(this.$t('Eliminar controversia'), this.$t('Confirma que desea eliminar la controvesia ') + ` ${controversy.item.id}?`, () => {
        this.$refs.confirm.hide()
        this.buttonsDisabled = true
        this.loading = true
        this.deleteControversy(this.orderDetail.id, controversy.item.id).then(
          () => {
            this.loading = false
            return this._getOrdersDetails()
          },
          (error) => {
            this.loading = false
            this.errorText = error.body.message || this.$t('error eliminando la controvesia')
          }
        )
      })
    },

    deliverPackage (invoiceNumber) {
      this.showConfirm(this.$t('Marcar como entregado'), this.$t('Confirma que desea marcar como entregado el paquete') + ` ${invoiceNumber}?`, () => {
        this.loading = true
        this.$refs.confirm.hide()
        this.buttonsDisabled = true
        this.deliverOrders(this.orderDetail.id, [invoiceNumber]).then(
          () => {
            return this._getOrdersDetails()
          },
          (error) => {
            this.errorText = error.body.message || this.$t('error entregando el paquete')
          }
        )
      })
    },

    showConfirm (confirmTitle, confirmBody, callback) {
      this.confirm.title = confirmTitle
      this.confirm.confirmBody = confirmBody
      this.confirm.callback = callback
      this.$refs.confirm.show()
    },

    _execDelivery () {
      let invoicesToMarkAsDelivered = this.toDeliveryArray.map(
        (packageItem) => {
          return packageItem.invoiceNumber
        }
      )
      this.$refs.deliveryModal.hide()
      this.buttonsDisabled = true
      this.loading = true

      this.deliverOrders(this.orderDetail.id, invoicesToMarkAsDelivered).then(
        () => {
          this.toDeliveryArray = []
          return this._getOrdersDetails()
        },
        () => {
          this.buttonsDisabled = false
          this.loading = false
        }
      )
    },

    deliverAction (e) {
      e.preventDefault()
      this.$refs.deliveryModal.show()
    },

    startHandlingAction (e) {
      e.preventDefault()
      this.showConfirm(this.$t('Iniciar Manejo'), this.$t('Confirma que desea iniciar el manejo del pedido') + ` ${this.orderDetail.id}?`, () => {
        this.$refs.confirm.hide()
        this.buttonsDisabled = true
        this.loading = true
        this.startHandling(this.orderDetail.id).then(
          () => {
            return this._getOrdersDetails()
          },
          (error) => {
            this.errorText = error.body.message ||
            this.$t('error iniciando el manejo')
            this.buttonsDisabled = false
            this.loading = false
          }
        )
      })
    },

    cancelAction (e) {
      this.showConfirm(this.$t('Cancelar Orden'), this.$t('Confirma que desea cancelar el pedido') + ` ${this.orderDetail.id}?`, () => {
        this.$refs.confirm.hide()
        this.loading = true
        this.buttonsDisabled = true
        this.cancelOrder(this.orderDetail.id).then(
          () => {
            this._getOrdersDetails()
          },
          (error) => {
            this.errorText = error.body.message || this.$t('error cancelando el pedido')
            this.buttonsDisabled = false
            this.loading = false
          }
        )
      })
    },

    _getPackageItemCount (packageItem) {
      let count = 0
      packageItem.items.forEach(
        (item) => {
          count += item.quantity
        }
      )
      return count
    },

    _getOrdersDetails () {
      return this.getOrderDetails(this.orderId).then(
        (response) => {
          this.orderDetail = response.body
          this.hasSubStatusBlocking = this.orderDetail.subStatus ? this.orderDetail.subStatus.blocking : false
          if (this.$route.query.status && window.location.hostname.indexOf('mysellercenter') === -1) {
            this.orderDetail.status = this.$route.query.status
            if (this.$route.query.status === 'invoiced') {
              this.orderDetail.packageAttachment = {
                packages: [
                  {
                    courier: 'servientrega',
                    invoiceValue: 110100,
                    trackingNumber: '123456',
                    invoiceNumber: '000004',
                    invoiceUrl: null,
                    issuanceDate: '2021-05-04T18:54:5',
                    invoiceKey: null,
                    trackingUrl: 'www.pepepe.co',
                    embeddedInvoice: '',
                    type: null,
                    courierStatus: {
                      status: '',
                      finished: true
                    },
                    items: [
                      {
                        itemIndex: null,
                        id: '1614713114409',
                        quantity: 1,
                        price: 1000
                      }
                    ],
                    urlTrackingLabel: 'www.test.com'
                  }
                ]
              }
            }
            if (this.$route.query.status === 'received-guide') {
              this.orderDetail.notifiedTrackingMarketplace = false
              this.orderDetail.packageAttachment = {
                packages: [
                  {
                    courier: 'servientrega',
                    invoiceValue: 110100,
                    trackingNumber: '123456',
                    invoiceNumber: '000004',
                    invoiceUrl: null,
                    issuanceDate: '2021-05-04T18:54:5',
                    invoiceKey: null,
                    trackingUrl: 'www.pepepe.co',
                    embeddedInvoice: '',
                    type: null,
                    courierStatus: {
                      status: '',
                      finished: true
                    },
                    items: [
                      {
                        itemIndex: null,
                        id: '1614713114409',
                        quantity: 1,
                        price: 1000
                      }
                    ],
                    urlTrackingLabel: 'www.test.com'
                  }
                ]
              }
            }
          }
          if (this.orderDetail.shippingData && this.orderDetail.shippingData.logisticsInfo.length && this.orderDetail.shippingData.logisticsInfo[0] && this.orderDetail.shippingData.logisticsInfo[0].deliveryCompany) this._getIntegrationsData(this.orderDetail.shippingData.logisticsInfo[0].deliveryCompany)
          this.orderPromos.splice(0, this.orderPromos.length)
          this.promoLoaded = false
          this.filters.text = this.orderDetail.id

          if (this.orderDetail.controversies && this.orderDetail.controversies !== null) {
            this.orderDetail.controversies.forEach((controversy) => {
              if (!controversy.active) {
                controversy._rowVariant = 'danger'
              } else {
                controversy._rowVariant = 'success'
              }
            })
          }

          var index = 0
          var _this = this
          function syncMagicPromisseCall () {
            let sku = _this.orderDetail.items[index]
            return _this.getSku(sku.id).then(
              (data) => {
                Vue.set(_this.orderDetail.items[index], 'refId', data.body.refId)
                if (++index < _this.orderDetail.items.length) {
                  return syncMagicPromisseCall()
                }
              },
              (error) => {
                console.log(error)
              }
            )
          }
          syncMagicPromisseCall()
          /* Get the carrier name from the selected sla */
          if (this.orderDetail.packageAttachment && this.orderDetail.packageAttachment.packages && this.orderDetail.packageAttachment.packages.length > 0) {
            this.orderDetail.packageAttachment.packages.forEach(
              (packItem) => {
                packItem.totalItems = this._getPackageItemCount(packItem)
              }
            )
          }

          let promises = []

          this.orderDetail.items.forEach((item) => {
            if (item.priceTags && item.priceTags.length > 0) {
              item.priceTags.forEach((promo) => {
                promises.push(this.updatePromoName(promo, item))
              })
            }
          })
          Promise.all(promises).then(() => {
            this.promoLoaded = true
          })

          this.loading = false
          this.buttonsDisabled = false
          this._updateButtonsVisibility()
        },
        (error) => {
          this.loading = false
          this.orderDetail = ''
          this.errorText = error.body.message || this.$t('error obteniendo el listado de pedidos')
        }
      )
    },

    _getIntegrationsData (item) {
      this.loadingIntegrationData = true
      this.getCarriers({currentPage: 1, perPage: 10, name: item, type: 'ADMIN'}).then(resultCarrier => {
        if (resultCarrier.body && resultCarrier.body.length) {
          this.getIntegrationsData({currentPage: 1, perPage: 10}, {reference: resultCarrier.body[0].id || ' '}).then(result => {
            this.loadingIntegrationData = false
            if (result.body && result.body.length) {
              if (result.body[0].reference.findIndex(ref => ref === resultCarrier.body[0].id) > -1 && result.body[0].type === 'CARRIER') {
                this.isShippingExcluded = true
              }
            } else {
              this.isShippingExcluded = false
            }
          },
          () => {
            this.loadingIntegrationData = false
            console.log('error getting data')
          })
        } else {
          this.loadingIntegrationData = false
        }
      },
      () => {
        this.loadingIntegrationData = false
        console.log('error getting carrier')
      })
    },

    openNotifyTrackingModal (item, index) {
      this.itemToNotifyTracking = item
      this.itemToNotifyTracking.indexPackage = parseInt(index) + 1
      this.succesTextNotifyTracking = ''
      this.$refs.notifyTrackingModal.show()
    },

    notifyTracking () {
      let item = this.itemToNotifyTracking
      this.addTrackingInformation(
        this.orderId,
        item.invoiceNumber,
        item.courier,
        item.trackingUrl,
        item.trackingNumber
      ).then(result => {
        this.succesTextNotifyTracking = this.$t('Se notificó correctamente el envío')
        this._getOrdersDetails()
      })
    },

    invoiceSucessCallback () {
      this._getOrdersDetails()
    },
    onSuccessReturn () {
      this._getOrdersDetails()
    },
    onErrorReturn () {
      this.errorText = this.$t('Error al devolver el/los skus, intente nuevamente')
    },
    updatePromoName (promo, item) {
      if (!promo.identifier.includes('TAXHUB')) {
        return this.getPromotion(promo.identifier).then(
          (promoData) => {
            promo.scName = promoData.body.name
            promo.nominalDiscountValue = promo.isPercentual === false ? promo.rawValue : 0
            promo.percentualDiscountValue = promo.isPercentual === true ? promo.rawValue : 0
            promo.description = promoData.body.description
            promo.userType = promoData.body.userType
            this.orderPromos.push({
              scName: promoData.body.name,
              nominalDiscountValue: promo.isPercentual === false ? promo.rawValue : 0,
              percentualDiscountValue: promo.isPercentual === true ? promo.rawValue : 0,
              description: promoData.body.description,
              userType: promoData.body.userType
            })
          },
          () => {
            promo.nominalDiscountValue = promo.isPercentual === false ? promo.rawValue : 0
            promo.percentualDiscountValue = promo.isPercentual === true ? promo.rawValue : 0
            this.orderPromos.push({
              scName: 'Promo Ecommerce',
              nominalDiscountValue: promo.isPercentual === false ? promo.rawValue : 0,
              percentualDiscountValue: promo.isPercentual === true ? promo.rawValue : 0,
              description: 'Promo Ecommerce',
              userType: 'ECOMMERCE'
            })
          }
        )
      }
    },

    _updateButtonsVisibility () {
      /* Change the color for the status card */
      this.statusVariantClass = this.statusMappingBgColors[this.orderDetail.status]

      /* Verify the status of the order to show or hide the invoice button */
      this.invoiceButtonVisible = !this.isAdmin &&
      !this.marketplaceInvoice &&
      (this.orderDetail.status === 'handling' || this.orderDetail.status === 'partially-invoiced')
      && this.canWrite
      /* Verify the status to show or hide the start handling button */
      /* When there is integration by logistics: status ready-for-handling = AUTOMATIC type, status order-handling = MANUAL type */
      this.startHandlingButtonVisible = !this.isAdmin && !this.marketplaceInvoice && this.orderDetail.status === 'ready-for-handling' && this.canWrite

      /* Verify the status to show or hide the cancel order button */
      this.cancelButtonVisible = !this.isAdmin &&
      (this.orderDetail.status === 'ready-for-handling' || this.orderDetail.status === 'handling' || this.orderDetail.status === 'waiting-for-seller-decision')
      && this.canWrite

      /* Verify the status to show or hide the create controversy button */
      this.controversyButtonVisible = this.isAdmin && this.canWrite
    },

    createIncidence () {
      this.$router.push({path: '/incidents/list', query: { order: this.orderId }})
    },
    capitalizeText (item) {
      if (item.id === 'CustomTax') return 'Tax Total'
     return item.name.slice(0, 1).toUpperCase() + item.name.slice(1).toLowerCase()
    },
    capitalizeFirstLetter (item) {
      return item.slice(0, 1).toUpperCase() + item.slice(1).toLowerCase()
    }
  },
  mounted () {
    this.loading = true
    this._getOrdersDetails()
    setInterval(() => {
      this.loading = true
      this._getOrdersDetails()
    }, appConfig.ORDER_DETAILS_TTL)
  }
}
</script>

<style lang="scss">
  .contStates {
    min-width: 150px;
  }

  .package-container {
    span.custom-control-description {
      line-height: 25px !important;
    }
  }

  .textControl {
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<style lang="scss" scoped>

  .controversy-note {
    border-bottom: 1px solid #DDDDDD;
    padding: 10px 0 10px 10px;
  }

  p {
    margin-bottom: 2px;

    &.user-email {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .warning-text {
    float: left;
    height: auto;

    .fa-exclamation-triangle {
      color: #f8cb00;
      font-size: 30px;
      float: left;
      margin-right: 5px;
      display: inline-block;
    }

    div {
      display: inline-block;
    }
  }

  a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #397496;
  }

  .card {
    .table-total {
      float: right;
      margin-right: 0.3rem;
    }

    .status-card,
    .payments-card,
    .tags-card {
      .card-body {
        text-align: center
      }
    }
 }

 .tag {
   width: 56px;
   display: inline-block;
   border-radius: 5px;
   line-height: 35px;
   margin-right: 4px;
   cursor: pointer;
   text-align: center;

   &.green {
     background: #4DBD74;
     color: #FFFFFF;
   }
   &.yellow {
     background: #e8f48b;
   }
   &.red {
     background: #f23c3c;
     color: #FFFFFF;
   }
 }

 .tag-small {
    width: 32px;
    line-height: 22px;
    margin-right: 0px;
 }

 .add-controversy {
   position: absolute;
   top: 14px;
   right: 12px;
 }

  .delivered {
    color: green;
    font-size: 21px;
  }

  .not-delivered {
    font-size: 21px;
    color: #CCCCCC;
  }

  .topLoading {
  }

  .contLoading {
    &.v-spinner {
      position: relative;
      margin: 0px auto;
      margin-bottom: 5px;
    }
  }

  .package-container {
      border: 1px solid #CCCCCC;
      background: #FFFFFF;
      padding: 10px;
      margin-bottom: 7px;
      border-radius: 1px;
      font-size: 12px;

      .package-finished-label {
          background-color: #4DBD74;
          color: #FFFFFF;
          border-radius: 1px;
          padding: 2px 10px 2px 10px;
          margin-left: 10px;
          font-size: 12px;
      }
  }

  .packages-wrapper {
    .package-container {
      label {
        padding-top: 2px;
      }
      li {
        margin-bottom: 5px;
      }
    }
  }

  .controversy-note:hover {
    background: #ECECEC;
  }

  .btn-link {
    &.history-button {
      width: 150px;
      text-align: left;
    }
    &:hover {
      color: #555555;
      text-decoration: none;
    }
    .fa-plus {
      margin-right: 5px;
    }
    color: #888888;
  }

  .order-date .tag {
    width: 30px;
    line-height: 18px;
    font-size: 12px;
    box-shadow: none;
  }

  .card-title{
    margin-top: 4px;
  }

  .fa-history{
    margin-top:5px !important;
  }

  .btn-history{
    border-radius: 5px;
  }

  .btn, .btn-history:hover{
    cursor: pointer;
    user-select: none;
  }

  .substatus-name{
    line-height: 40px;
  }
  .substatus-name::first-letter{
    text-transform: uppercase;
  }

</style>
