import Vue from 'vue'
import Router from 'vue-router'
import {store} from '@/store/store.js'
import Full from '@/containers/Full'
import Header from '@/containers/Header'

// Views
import DashboardView from '@/views/DashboardView'
import OrdersView from '@/views/OrdersView'
import OrderDetailsView from '@/views/OrderDetailsView'
import NewProductView from '@/views/NewProductView'
import ActivableProductsView from '@/views/ActivableProductsView'
import ProductDetailView from '@/views/ProductDetailView'
import Login from '@/views/Pages/Login'
import Page401 from '@/views/Pages/Page401'
import Page404 from '@/views/Pages/Page404'
import Page500 from '@/views/Pages/Page500'
import PunishmentComponentView from '@/views/PunishmentComponentView'
import LiquidationsComponentView from '@/views/LiquidationsComponentView'
import AddMasiveProductsComponentView from '@/views/AddMasiveProductsComponentView'
import ChangePricesByCSVView from '@/views/ChangePricesByCSVView'
import InventoryView from '@/views/InventoryView'
import ReportsView from '@/views/ReportsView'
import IncidentsView from '@/views/incidents/IncidentsView'
import UserAdminView from '@/views/UserAdminView'
import ProfileView from '@/views/ProfileView'
import OrganizationsView from '@/views/OrganizationsView'
import CarriersView from '@/views/CarriersView'
import ShippingGratesView from '@/views/ShippingGratesView'
import AddPricesCSVView from '@/views/AddPricesCSVView'
import CategoryAndBrandUpdaterView from '@/views/CategoryAndBrandUpdaterView'
import AttributesUpdaterView from '@/views/AttributesUpdaterView'
import PromoList from '@/components/promotion/PromoList'
import IntegrationsView from '@/views/IntegrationsView'
import TaxesView from '@/views/TaxesView'
import SkuDetailView from '@/views/SkuDetailView'
import Substatus from '@/views/Substatus'
import RolesView from '@/views/RolesView'
import RoleView from '@/views/RoleView'
import UserView from '@/views/UserView'
import NoticesView from '@/views/NoticesView'
import PickupPointsView from '@/views/PickupPointsView'
import PickupPointDetailView from '@/views/PickupPointDetailView'

Vue.use(Router)

export const router = new Router({
  /* mode: 'history', */
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/pages',
      redirect: '/pages/login',
      name: 'Pages',
      component: {
        render (c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: '401',
          name: 'No Autorizado',
          component: Page401
        }
      ]
    },
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: Full,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: DashboardView,
          meta: {
            requiresAuth: true,
            requiresPermission: false
          }
        }
      ]
    },
    {
      path: '/orders',
      redirect: 'orders/list',
      name: 'Ventas',
      component: Full,
      children: [
        {
          path: 'list',
          name: 'Ordenes',
          component: OrdersView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'ORDER_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'addPunishmentCSV',
          name: 'Alta de Penalizaciones por CSV',
          component: PunishmentComponentView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'ORDER_WRITE',
            adminNotAllowed: false
          }
        },
        {
          path: 'addLiquidationsCSV',
          name: 'Alta de Liquidacion por CSV',
          component: LiquidationsComponentView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'ORDER_WRITE',
            adminNotAllowed: false
          }
        },
        {
          path: 'orderDetail/:orderId',
          name: 'Ordenes / Detalle',
          component: OrderDetailsView,
          props: true,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'ORDER_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'taxes',
          name: 'Impuestos',
          component: TaxesView
        }
      ]
    },
    {
      path: '/catalog',
      redirect: '/catalog/list?page=0&size=10',
      name: 'Catálogo',
      component: Full,
      children: [
        {
          path: 'new',
          name: 'Nuevo producto',
          component: NewProductView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'PRODUCT_WRITE',
            adminNotAllowed: true
          }
        },
        {
          path: 'promotions',
          name: 'Promociones',
          component: PromoList,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'PROMOTION_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'list',
          name: 'Administrar Productos',
          component: ActivableProductsView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'PRODUCT_READ',
            permissionInventory: 'INVENTORY_READ',
            permissionPrice: 'PRICE_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'details/:productId',
          name: 'Detalle',
          component: ProductDetailView,
          props: true,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'PRODUCT_READ',
            permissionInventory: 'INVENTORY_READ',
            permissionPrice: 'PRICE_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'details/:productId/sku',
          name: 'Crear Sku',
          component: SkuDetailView,
          props: true,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'PRODUCT_READ',
            permissionInventory: 'INVENTORY_READ',
            permissionPrice: 'PRICE_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'details/:productId/sku/:skuId',
          name: 'Detalle Sku',
          component: SkuDetailView,
          props: true,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'PRODUCT_READ',
            permissionInventory: 'INVENTORY_READ',
            permissionPrice: 'PRICE_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'addProductsCSV',
          name: 'Alta masiva de productos',
          component: AddMasiveProductsComponentView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'PRODUCT_READ',
            adminNotAllowed: true
          }
        },
        {
          path: 'changePricesCSV',
          name: 'Editar precios por CSV',
          component: ChangePricesByCSVView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'PRICE_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'inventory/list',
          name: 'Inventario',
          component: InventoryView
        },
        {
          path: 'substatus',
          name: 'Sub-estados',
          component: Substatus,
          meta: {
            requiresAuth: true,
          },
        }
      ]
    },
    {
      path: '/carriers',
      redirect: '/carriers/carrierList',
      name: 'Gestión de envíos',
      component: Full,
      children: [
        {
          path: 'carrierList',
          name: 'Transportadoras',
          component: CarriersView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'CARRIER_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'pickupPoints',
          name: 'Puntos de retiro',
          component: PickupPointsView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'CARRIER_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'pickupPoints/new',
          name: 'Crear Punto de retiro',
          component: PickupPointDetailView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'CARRIER_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'pickupPoints/:pickupPointId',
          name: 'Detalle punto de retiro',
          component: PickupPointDetailView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'CARRIER_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'priceList',
          name: 'Precios',
          component: ShippingGratesView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'CARRIER_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'addPricesCSV',
          name: 'Alta masiva de Precios de Transportadoras',
          component: AddPricesCSVView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'CARRIER_READ',
            adminNotAllowed: false
          }
        }
      ]
    },
    {
      path: '/incidents',
      redirect: '/incidents/list',
      name: 'Incidencias',
      component: Full,
      children: [
        {
          path: 'list',
          name: 'Manejo de incidencias',
          component: IncidentsView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'INCIDENCE_READ',
            adminNotAllowed: false
          }
        }
      ]
    },
    {
      path: '/reports',
      redirect: '/reports/list',
      name: 'Reportes',
      component: Full,
      children: [
        {
          path: 'list',
          name: 'Ver Reportes',
          component: ReportsView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'REPORT_READ',
            adminNotAllowed: false
          }
        }
      ]
    },
    {
      path: '/admin',
      redirect: '/admin/users',
      name: 'Administración',
      component: Full,
      children: [
        {
          path: 'users',
          name: 'Usuarios',
          component: UserAdminView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'ADMIN_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'user',
          name: 'Crear Usuario',
          component: UserView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'ADMIN_WRITE',
            adminNotAllowed: false
          }
        },
        {
          path: 'user/:id',
          name: 'Editar Usuario',
          component: UserView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'ADMIN_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'sellers',
          name: 'Sellers',
          component: OrganizationsView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'ADMIN_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'roles',
          name: 'Roles',
          component: RolesView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'ADMIN_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'roles/role',
          name: 'Crear Nuevo Rol',
          component: RoleView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'ADMIN_WRITE',
            adminNotAllowed: false
          }
        },
        {
          path: 'roles/role/:id',
          name: 'Editar Rol',
          component: RoleView,
          props: true,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'ADMIN_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'integrations',
          name: 'Integraciones',
          component: IntegrationsView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'ADMIN_READ',
            adminNotAllowed: false
          }
        },
        {
          path: 'notices',
          name: 'Notificaciones',
          component: NoticesView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'ADMIN_READ',
            adminNotAllowed: false
          }
        }
      ]
    },
    {
      path: '/profile',
      redirect: '/profile/me',
      name: 'Perfil',
      component: Full,
      children: [
        {
          path: 'me',
          name: 'Usuario',
          component: ProfileView,
          meta: {
            requiresAuth: true,
            requiresPermission: false
          }
        }
      ]
    },
    {
      path: '/updates',
      name: 'Actualizaciones',
      redirect: '/updates/catalogAndBrand',
      component: Full,
      children: [
        {
          path: 'catalogAndBrand',
          name: 'Categorias y Marcas',
          component: CategoryAndBrandUpdaterView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'UPDATE_WRITE',
            adminNotAllowed: false
          }
        },
        {
          path: 'attributes',
          name: 'Atributos',
          component: AttributesUpdaterView,
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permission: 'UPDATE_WRITE',
            adminNotAllowed: false
          }
        }
      ]
    },
    {
      path: '*',
      name: '404',
      component: Header,
      redirect: 'pages/404',
      children: [
        {
          path: 'pages/404',
          name: 'Page404',
          component: Page404
        }
      ]
    }

  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && (sessionStorage.getItem('vue-session-key') === null || sessionStorage.getItem('vue-session-key') === '{}')) {
    next('/pages/login')
  } else {
    if (to.meta.requiresPermission) {
      if ((!store.getters.signedRoles.includes(to.meta.permission) && !store.getters.signedRoles.includes(to.meta.permissionInventory) && 
      !store.getters.signedRoles.includes(to.meta.permissionPrice)) || (to.meta.adminNotAllowed && store.getters.isAdmin)) {
        next('/pages/401')
      } else {
        if ((to.name === 'Sellers' || to.name === 'Roles') && !store.getters.isAdmin) {
          next('/pages/401')
        }
        next()
      }
    } else {
      next()
    }
  }
})
