<template>
  <div>
    <hr v-if="$store.getters.signedRoles.includes('ADMIN_USER')"/>
    <b-form-group horizontal :label-cols="3" :label="$t('Permisos')" v-if="$store.getters.signedRoles.includes('ADMIN_USER') || $store.getters.signedRoles.includes('ORGANIZATION_ADMIN_USER')">
      <b-form-checkbox-group
        v-model="roles"
        size="md"
        stacked
        :disabled="!($store.getters.signedRoles.includes('ADMIN_USER') || $store.getters.signedRoles.includes('ORGANIZATION_ADMIN_USER'))"
        >
        <b-form-checkbox
          v-for="option in rolesOptions"
          :key="option.text"
          :value="option.value"
          :disabled="option.disabled"
        >
          <span>{{$t(option.text)}}</span>
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>
    <b-alert show>{{getAlertMesage}}</b-alert>
    <div class="disclaimer mb-3">
      <ul>
        <li><strong>{{$t('Administrador Marketplace')}}:</strong> {{$t('Permite generar nuevos usuarios, tanto Administradores como Sellers. Los permisos')}} <i>"{{$t('Administrador Seller')}}"</i> {{$t('están contenidos en este rango')}}.</li>
        <li><strong>{{$t('Administrador Seller')}}:</strong> {{$t('Permite generar nuevos productos y activar nuevos SKUs, sólo habilita a generar nuevos usuarios del tipo seller')}}</li>
        <li><strong>{{$t('Notas')}}:</strong> {{$t('Si ninguna de las opciones anteriores está seleccionada se creará un usuario de tipo')}}: <strong>{{$t('Seller_label')}}</strong>, {{$t('sin permisos para la creación de nuevos usuarios')}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import {RoleService} from '@/mixims/AdminServiceMixin'

export default {
  props: ['value', 'options'],
  mixins: [RoleService],
  data () {
    return {
      rolesAllow: [
        'CATALOG_USER',
        'ORDERS_USER',
        'INVENTORY_USER',
        'CARRIERS_USER',
        'REPORTS_USER',
        'ADMIN_USER',
        'ORGANIZATION_ADMIN_USER'
      ],
      sellerRole: 'ORGANIZATION_ADMIN_USER',
      marketplaceRole: 'STANDARD_USER',
      supportMarketplaceUser: false,
      idUsuario: ''
    }
  },
  methods: {
    permitions (r) {
      return this.rolesAllow.includes(r.roleName)
    },
    containsRole (role) {
      return Boolean(this.roles && this.roles
        .find(r => r.roleName === role))
    },
    organizationPermitionsAccion (marketplaceUser, admin) {
      if (this.idUsuario !== this.value.id) {
        this.idUsuario = this.value.id
        this.rolesAllow = [
          'CATALOG_USER',
          'ORDERS_USER',
          'INVENTORY_USER',
          'CARRIERS_USER',
          'REPORTS_USER',
          'ADMIN_USER',
          'ORGANIZATION_ADMIN_USER'
        ]
        this.supportMarketplaceUser = false
      }
      if (admin && marketplaceUser && this.permitionIndex(this.sellerRole) > -1) {
        this.rolesAllow.splice(this.permitionIndex(this.sellerRole), 1)
        this.removePermition(this.sellerRole)
        if (this.permitionIndex(this.marketplaceRole) > -1) {
          this.rolesAllow.splice(this.permitionIndex(this.marketplaceRole), 1)
          this.removePermition(this.marketplaceRole)
        }
      }
      if (!admin && !marketplaceUser && this.permitionIndex(this.sellerRole) < 0) {
        this.rolesAllow.push(this.sellerRole)
      }
      if (!admin && this.permitionIndex(this.marketplaceRole) < 0 && this.supportMarketplaceUser) {
        this.rolesAllow.push(this.marketplaceRole)
      }
      if (admin && !marketplaceUser && this.permitionIndex(this.marketplaceRole) > -1) {
        this.rolesAllow.splice(this.permitionIndex(this.marketplaceRole), 1)
        this.removePermition(this.marketplaceRole)
      }
      if (!admin && marketplaceUser && this.permitionIndex(this.marketplaceRole) === -1) {
        this.supportMarketplaceUser = true
        this.rolesAllow.push(this.marketplaceRole)
      }
      if (!admin && marketplaceUser && this.permitionIndex(this.marketplaceRole) > -1) {
        if (this.permitionIndex(this.sellerRole) > -1) {
          this.rolesAllow.splice(this.permitionIndex(this.sellerRole), 1)
          this.removePermition(this.sellerRole)
        }
      }
      if (admin && marketplaceUser && this.permitionIndex(this.marketplaceRole) > -1) {
        this.rolesAllow.splice(this.permitionIndex(this.marketplaceRole), 1)
      }
      if (admin && this.value.roles.findIndex(f => f.id === 'ORGANIZATION_STANDARD_USER') > -1) {
        this.removePermition('ORGANIZATION_STANDARD_USER')
      }
      if (admin && this.value.roles.findIndex(f => f.id === 'STANDARD_USER') > -1) {
        this.removePermition('STANDARD_USER')
      }
      if (!admin && marketplaceUser && this.value.roles.findIndex(f => f.id === 'ORGANIZATION_STANDARD_USER') > -1) {
        this.removePermition('ORGANIZATION_STANDARD_USER')
      }
      if (this.standardOption && this.standardOption.id && !admin && this.value.roles.findIndex(f => f.id === this.standardOption.id) === -1) {
        this.value.roles.push(this.standardOption)
      }
    },
    permitionIndex (role) {
      return this.rolesAllow.findIndex(f => f === role)
    },
    removePermition (role) {
      for (let roleIndex in this.value.roles) {
        if (this.value.roles[roleIndex].id === role) {
          this.value.roles.splice(roleIndex, 1)
        }
      }
    }
  },
  computed: {
    getAlertMesage () {
      if (this.value && this.value.roles[0] && this.options) {
        var roleName = null;
        if(typeof this.value.roles[0] === 'string')
          roleName = this.options.find(x => x.id === this.value.roles[0]).roleName
        if(typeof this.value.roles[0] === 'object')
          roleName = this.value.roles[0].roleName

        if (this.value && this.value.userType === 'ADMIN') {
          return this.$t('Usuario Administrador con rol') + ' ' + roleName
        } else if (this.value && this.value.userType === 'SELLER') {
          return this.$t('Usuario Seller con rol') + ' ' + roleName
        } else {
          return this.$t('Usuario standard')
        }
      } else {
        return this.$t('Usuario standard')
      }
    },
    rolesOptions () {
      return this.sortValidRoles(this.options)
        .filter(this.permitions)
        .map(r => {
          return {text: this.roleProperties[r.roleName].name, value: r, disabled: this.$store.getters.signedRoles.findIndex(f => f === 'ADMIN_USER') === -1 && r.roleName === 'ADMIN_USER'}
        })
    },
    roles: {
      set (newVal) {
        if (!newVal) this.value.roles = [this.standardOption]
        else if (!Array.isArray(newVal)) this.value.roles = [newVal]
        else this.value.roles = newVal
        this.organizationPermitionsAccion(this.marketplaceUser, this.admin)
      },
      get () {
        return this.value.roles
      }
    },
    marketplaceUser () {
      return this.containsRole('ADMIN_USER') || this.containsRole('STANDARD_USER')
    },
    adminOption () {
      return this.marketplaceUser ? this.options.find(r => r.roleName === 'ADMIN_USER') : this.options.find(r => r.roleName === 'ORGANIZATION_ADMIN_USER')
    },
    standardOption () {
      return this.marketplaceUser ? this.options.find(r => r.roleName === 'STANDARD_USER') : this.options.find(r => r.roleName === 'ORGANIZATION_STANDARD_USER')
    },
    admin () {
      return this.containsRole('ADMIN_USER') || this.containsRole('ORGANIZATION_ADMIN_USER')
    }
  },
  mounted () {
    this.organizationPermitionsAccion(this.marketplaceUser, this.admin)
  }
}
</script>

<style lang="scss" scoped>
  .disclaimer {
    background-color: #f5f3f3;
    border-radius: 5px;
    margin-top: 20px;
    padding: 10px 25px;

    ul {
      list-style: none;
      padding: 0;

      li {
        color: #777777;
        font-size: 10px;
      }
    }
  }

</style>
