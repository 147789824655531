<template>
  <div class="noticeModalContainer">
    <b-modal centered headerBgVariant="primary" ref="noticeDeleteModal" id="noticeDeleteModal" size="lg" title="Confirmar eliminar notificación" o-close-on-backdrop="true" cancel-title="Cancelar" ok-title="Eliminar" @ok="deleteNotice" >
      ¿Confirma que desea eliminar esta notificación?
    </b-modal>
  </div>
</template>
<script>
import { NotificationServiceMixim } from '@/mixims/NotificationServiceMixim.js'
export default {
  props: ['notice'],
  mixins: [NotificationServiceMixim],
  methods: {
    disableDateBeforeToday (date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    show () {
      this.$refs.noticeDeleteModal.show()
    },
    deleteNotice () {
      this.deleteNotification(this.notice.id).then(
        (response) => {
          this.$emit('showAlertNotice', {text: `Se eliminó la notificación`, variant: 'success'})
        },
        () => {
          this.$emit('showAlertNotice', {text: `Error al eliminar la notificación`, variant: 'danger'})
        }
      )
    }
  }
}
</script>
<style scoped>
.modal-content {
  border: 1px solid rgb(18 83 155);
}
</style>