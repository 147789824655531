<template>
  <div class="animated fadeIn">
    <b-container fluid class="noticesView">
      <notices-list ref="noticeList" @showNoticeModal="handleShowNoticeModal" @showDeleteNoticeModal="handleShowDeleteModal"/>
      <notice-modal ref="noticeModal" :notice="notice" :action="action" @showAlertNotice="handleShowAlertNotice"></notice-modal>
      <notice-delete-modal ref="noticeDeleteModal" :notice="notice" @showAlertNotice="handleShowAlertNotice"></notice-delete-modal>
    </b-container>
  </div>
</template>
<script>
import NoticesList from '@/components/notices/NoticesList.vue'
import NoticeModal from '@/components/notices/NoticeModal.vue'
import NoticeDeleteModal from '@/components/notices/NoticeDeleteModal.vue'
export default {
  name: 'noticesView',
  components: { NoticesList, NoticeModal, NoticeDeleteModal },
  data () {
    return {
      notice: {
        title: '',
        message: '',
        date: ''
      },
      action: 'create'
    }
  },
  methods: {
    handleShowNoticeModal (value) {
      this.notice = value.notice
      this.action = value.action
      this.$refs.noticeModal.show()
    },
    handleShowAlertNotice (value) {
      this.$refs.noticeList.refreshTable()
      this.$refs.noticeList.showAlertNoticesList(value.text, value.variant)
    },
    handleShowDeleteModal (value) {
      this.notice = value
      this.$refs.noticeDeleteModal.show()
    }
  }
}
</script>