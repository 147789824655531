import appConfig from '@/config/applicationConfig.js'

export const IncidentsServices = {
  data () {
    return {
      ENDPOINT: `${appConfig.API_END_POINT}/incidents`,
      HEADER: {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }
    }
  },
  methods: {
    getIncidents (tableInfo, filters) {
      let url = new URL(this.ENDPOINT)
      url.searchParams.append('sortBy', 'creationDate')
      url.searchParams.append('sortOrder', 'desc')
      if (tableInfo) {
        url.searchParams.append('from', tableInfo.currentPage - 1)
        url.searchParams.append('size', tableInfo.perPage)
      }
      Object.keys(filters).forEach((key) => {
        if (filters[key] !== null) {
          url.searchParams.append(key, filters[key])
        }
      })

      return this.$http.get(url.href, this.HEADER)
    },
    getIncidentDetail (incidentId) {
      return this.$http.get(`${this.CARRIERS_ENDPOINT}/${incidentId}`, this.HEADER)
    },
    createIncident (incidentDetail) {
      return this.$http.post(this.ENDPOINT, incidentDetail, this.HEADER)
    },
    updateIncident (incidentId, incidentDetail) {
      return this.$http.put(`${this.ENDPOINT}/${incidentId}`, incidentDetail, this.HEADER)
    },
    addCommentToIncident (incidentId, incidentComment) {
      return this.$http.post(`${this.ENDPOINT}/${incidentId}/comments`, incidentComment, this.HEADER)
    }
  }
}
