<template>
  <div>
    <inventory-list/>
  </div>
</template>

<script>
import InventoryList from '@/components/inventory/InventoryList'

export default {
  name: 'inventory-view',
  components: {
    InventoryList
  }
}
</script>
