<template>
    <b-container fluid>
      <b-row v-if="(props !== null && props.length === 0) || !props">
        <b-col>
            {{$t('No hay especificaciones para seleccionar.')}}
        </b-col>
      </b-row>
      <div v-else v-for="info in props" :key="info.name">
        <b-row v-show="props" class="mt-3 attr-row">
          <b-col md="6">
            <b-form-group
                v-if="['Texto', 'Texto Grande', 'Texto Indexado', 'Texto Grande Indexado'].includes(info.spec.specificationFieldType.fieldTypeName) && info.value.specificationFieldValues.length > 0"
                :label-cols="4"
                :description="info.spec.description"
                :label="info.spec.name"
                :invalid-feedback="!isValidText(info.value.specificationFieldValues[0].value, Boolean(info.value.isRequired)) && !disabled? `${$t('El atributo')} ${info.spec.name} ${$t('es inválido')}`: ''"
                :state="isValidText(info.value.specificationFieldValues[0].value, Boolean(info.value.isRequired))"
            >
                <b-form-input
                  v-if="['Texto', 'Texto Grande', 'Texto Indexado', 'Texto Grande Indexado'].includes(info.spec.specificationFieldType.fieldTypeName)"
                  v-model="info.value.specificationFieldValues[0].value"
                  :disabled="disabled"
                  >
                </b-form-input>
              </b-form-group>

              <b-form-group
                v-if="info.spec.specificationFieldType.fieldTypeName === 'Número' && info.value.specificationFieldValues.length > 0"
                :label-cols="4"
                :description="info.spec.description"
                :label="info.spec.name"
                :invalid-feedback="!isValidInteger(info.value.specificationFieldValues[0].value, Boolean(info.value.isRequired))? `${$t('El atributo')} ${info.spec.name} ${$t('es inválido')}`: ''"
                :state="isValidInteger(info.value.specificationFieldValues[0].value, Boolean(info.value.isRequired))"
            >
                <b-form-input
                  v-if="info.spec.specificationFieldType.fieldTypeName === 'Número'"
                  v-model="info.value.specificationFieldValues[0].value"
                  :disabled="disabled"
                  type="number"
                  >
                </b-form-input>
            </b-form-group>
            <b-form-group
                v-if="info.spec.specificationFieldType.fieldTypeName === 'Combo' && info.value.specificationFieldValues.length > 0"
                :label-cols="4"
                :description="info.spec.description"
                :label="info.spec.name"
                :invalid-feedback="info.value.isRequired && !Boolean(Object.keys(info.value.specificationFieldValues[0]).length) && !disabled ? ` ${info.spec.name} ${$t('es obligatorio')}`: ''"
                :state="info.value.isRequired && Boolean(Object.keys(info.value.specificationFieldValues[0]).length)"
              >
                <b-form-select
                  v-model="info.value.specificationFieldValues[0]"
                  :options="convertToOptions(info.spec.specificationFieldValues)"
                  :disabled="disabled">
                </b-form-select>
              </b-form-group>

              <b-form-group
                  v-if="info.spec.specificationFieldType.fieldTypeName === 'Radio' && info.value.specificationFieldValues.length > 0"
                  :label-cols="4"
                  :description="info.spec.description"
                  :label="info.spec.name"
                  :invalid-feedback="info.value.isRequired && !Boolean(Object.keys(info.value.specificationFieldValues[0]).length) && !disabled ? `${$t('El atributo')} ${info.spec.name} ${$t('es obligatorio')}`: ''"
                  :state="info.value.isRequired && Boolean(Object.keys(info.value.specificationFieldValues[0]).length)"
                >
                <b-form-radio-group
                  v-model="info.value.specificationFieldValues[0]"
                  :options="convertToOptions(info.spec.specificationFieldValues)"
                  :disabled="disabled">
                </b-form-radio-group>
              </b-form-group>

              <b-form-group
                    v-if="info.spec.specificationFieldType.fieldTypeName === 'CheckBox' && info.value.specificationFieldValues.length > 0"
                    :label-cols="4"
                    :description="info.spec.description"
                    :label="info.spec.name"
                    :invalid-feedback="info.value.isRequired && !Boolean(Object.keys(info.value.specificationFieldValues[0]).length) && !disabled ? `${$t('El atributo')} ${info.spec.name} ${$t('es obligatorio')}`: ''"
                    :state="info.value.isRequired && Boolean(Object.keys(info.value.specificationFieldValues[0]).length)"
                  >
                  <b-form-checkbox-group
                    v-model="info.value.specificationFieldValues[0]"
                    :options="convertToOptions(info.spec.specificationFieldValues)"
                    :disabled="disabled"
                  ></b-form-checkbox-group>
              </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-container>
</template>

<script>

import { ProductServiceMixim } from '@/mixims/ProductServiceMixim'
import { ValidationsMixim } from '@/mixims/ValidationsMixim.js'

export default {
  name: 'product-attributes',
  mixins: [ProductServiceMixim, ValidationsMixim],
  props: ['mergedInfo', 'disabled'],
  watch: {
    mergedInfo (newValue) {
      this.props = newValue
    }
  },
  data () {
    return {
      props: null
    }
  },
  methods: {
    convertToOptions (specificationFieldValues) {
      return specificationFieldValues.map(sfv => {
        return {text: sfv.value, value: sfv}
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .attr-row {
    border: 1px solid #CCCCCC;
    border-radius: 10px;
    padding: 12px;
  }
</style>
