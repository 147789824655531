<template>
  <div class="animated fadeIn">
    <b-container fluid class="order-list">
      <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Filtros')">
        <b-row>
          <b-col xl="4">
            <b-form-group
              :label="$t('Busqueda')"
              class="mb-0"
              :invalid-feedback="!isValidText(filter, false)? $t('No es un valor válido') : ''"
              :state="isValidText(filter, false)"
              :description="$t('Ingrese el texto a buscar en cliente o número')">
              <b-input-group>
                <b-form-input v-model="filter" :placeholder="$t('Texto a buscar')" />
                <b-input-group-button>
                  <b-btn :disabled="!filter" @click="filter = ''">{{$t('Limpiar')}}</b-btn>
                </b-input-group-button>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col xl="3" v-show="liquidationReport !== true">
            <b-form-group :label="$t('Estado')" class="mb-3" :description="$t('Seleccione el estado del pedido')">
              <b-form-select v-model="statusName">
                <option slot="first" :value="null">{{$t('Todos')}}</option>
                <option v-for="(text, value) in filterStatusMapping"
                      :key="value"
                      :value="value">{{$t(text)}}</option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="liquidationReport !== true">
          <b-col xl="4" class="col-multiselect" text-right>
            <b-form-group
              :label="$t('Filtrar por TAGS')"
              :description="$t('Selecciones los tags de ordenes que desea filtrar en la busqueda')">
              <multiselect
                v-model="filterTags"
                label="text"
                :placeholder="$t('Todos')"
                :selectedLabel="$t('Seleccionado')"
                :selectLabel="$t('Enter para agregar el tag')"
                :deselectLabel="$t('Enter para retirar el tag')"
                track-by="key"
                :options="tagsToFilter"
                :multiple="true"
                :taggable="true">
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col xl="4">
              <b-form-group :label="$t('Sub-estados')" class="mb-3">
                <b-form-select v-model="substatusFilter">
                  <option slot="first" :value="null">{{$t('Todos')}}</option>
                  <option v-for="(text, value) in substatus"
                    :key="value"
                    :value="text.id">{{ capitalizeText($t(text.name)) }}</option>
                </b-form-select>
              </b-form-group>
            </b-col>
          <b-col xl="6">
              <b-button class="float-right mr-2" :disabled="loading || disableFilter" variant="primary" @click.stop="filterTable" >{{$t('Filtrar')}}</b-button>
              <b-button class="float-right mr-2"  :disabled="loading || disableFilter" variant="primary" @click.stop="exportCSV" >{{$t('Exportar CSV')}}</b-button>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <b-button class="float-right mr-2" :disabled="loading || disableFilter" variant="primary" @click.stop="filterTable" >{{$t('Filtrar')}}</b-button>
            <b-button class="float-right mr-2" variant="primary" @click.stop="exportCSV" >{{$t('Exportar CSV')}}</b-button>
          </b-col>
        </b-row>
      </b-card>

      <b-row class="mt-3">
        <b-col class="col-md-auto">
          <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="mb-3" />
        </b-col>
        <b-col class="col-md-auto per-page">
          <b-form-group id="perPage" horizontal :label="$t('Mostrar por página')">
            <b-form-select :options="pageOptions" v-model="perPage" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col md="12">
          <b>{{totalRows}}</b> {{liquidationReport? totalRows > 1? $t('liquidaciones encontradas') : $t('liquidacion encontrada') : totalRows > 1? $t('Pedidos encontrados') : $t('Pedido encontrado')}}
        </b-col>
      </b-row>
      <b-card header-bg-variant="primary" border-variant="primary" :header="liquidationReport ? $t('Liquidaciones') : $t('Listado de pedidos')">
        <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
        <b-table v-show="!loading" id="mainTable"
          ref="mainTable"
           show-empty
           striped
           hover
           stacked="xl"
           :items="itemProvider"
           foot-clone
           :fields="isAdmin ? fields: fieldsNotAdmin"
           :current-page="currentPage"
           :per-page="perPage"
           :sort-by.sync="sortBy"
           :sort-desc.sync="sortDesc"
           @row-clicked="rowClick"
           @filtered="onFiltered"
           :empty-text="$t('No hay pedidos para mostrar')"
           :empty-filtered-text="$t('No hay pedidos para mostrar')">
          <template slot="clientProfileData" slot-scope="data">
            {{data.value ? data.value.firstName : ''}} {{data.value ? data.value.lastName : ''}}
          </template>
          <template slot="liquidationDate" slot-scope="row">
            {{new Date(row.item.liquidated.date).toLocaleDateString(locale)}}
          </template>
          <template slot="invoiceNumber" slot-scope="row">
            {{row.item.liquidated.invoiceNumber}}
          </template>
          <template slot="liquidationValue" slot-scope="row">
            {{currency}} {{row.item.liquidated.value}}
          </template>
          <template slot="value" slot-scope="value">
            {{currency}} {{value.value}}
          </template>
          <template slot="marketplace" slot-scope="marketplace">
            {{ marketplace && marketplace.value && marketplace.value.name ? marketplace.value.name : '-' }}
          </template>
          <template slot="status" slot-scope="status">
            <span :class="getStatusColorClass(status.value)"><b>{{$t(statusMapping[status.value])}}</b></span>
          </template>
          <template slot="subStatus" slot-scope="subStatus">
            <span v-if="subStatus.item.subStatus">{{capitalizeText(subStatus.item.subStatus.name)}}</span>
            <span v-else>-</span>
          </template>
          <template slot="creationDate" slot-scope="creationDate">
            {{new Date(creationDate.value).toLocaleDateString(locale)}}
          </template>
          <template slot="tags" slot-scope="row">
            <span
              v-for="tagElemKey in row.item.tags"
              v-b-tooltip.hover
              :title="$t(tagsMapping[tagElemKey].description)"
              class="tag"
              :class="tagsMapping[tagElemKey].bg"
              :key="tagsMapping[tagElemKey].description"
            >
              {{tagElemKey}}
            </span>
          </template>
          <template slot="action" slot-scope="row">
            <b-button size="sm" variant="link" @click.stop="row.toggleDetails" v-show="showPromoDetail(row.item.items)">
              <span v-show="!row.detailsShowing"><i class="fa fa-plus"></i></span>
              <span v-show="row.detailsShowing"><i class="fa fa-minus"></i></span>
              {{ row.detailsShowing ? $t('Ocultar') : $t('Ver')}} {{$t('promociones')}}
            </b-button>
          </template>
          <template slot="row-details" slot-scope="row">
            <b-row>
              <!-- Order row details -->
                <b-col md="12">
                  <b-card>
                    <div v-for="item in row.item.items" :key="item.id">
                      <h5>{{$t('Producto')}}: {{item.name}} (sku: {{item.id}})</h5>
                      <b-table
                          striped
                          :items="item.priceTags"
                          hover
                          outlined
                          head-variant='light'
                          :fields="promotionFields"
                          :small="true">
                      </b-table>
                    </div>
                  </b-card>
                </b-col>
            </b-row>
          </template>
        </b-table>
      </b-card>
      <b-row>
        <b-col md="6" class="my-1">
          <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {OrdersServiceMixim} from '@/mixims/OrdersServiceMixim'
import appConfig from '@/config/applicationConfig.js'
import {SubStatusMixim} from '@/mixims/SubStatusMixim.js'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import Multiselect from 'vue-multiselect'
import {CsvReportExportMixim} from './CsvReportExportMixim.js'
import { ValidationsMixim } from '@/mixims/ValidationsMixim.js'

export default {
  name: 'orders-report',
  mixins: [SubStatusMixim, OrdersServiceMixim, CsvReportExportMixim, ValidationsMixim],
  components: {MoonLoader, Multiselect},
  props: ['rangeDate', 'account', 'liquidationReport', 'marketplace'],
  watch: {
    rangeDate: function (val, oldVal) {
      this.fromDate = val[0]
      this.toDate = val[1]
      this.$refs.mainTable.refresh()
    },
    account: function (val, oldVal) {
      this.$refs.mainTable.refresh()
    },
    marketplace: function (val, oldVal) {
      this.siteNameFilter = val
      this.$refs.mainTable.refresh()
    }
  },
  data () {
    return {
      fields: !this.liquidationReport ? [
        { key: 'id', label: this.$t('Número'), 'class': 'text-left', sortable: true },
        { key: 'accountName', label: this.$t('Seller'), 'class': 'text-left', sortable: true },
        { key: 'clientProfileData', label: this.$t('Cliente'), 'class': 'text-left', sortable: true },
        { key: 'creationDate', label: this.$t('Fecha'), 'class': 'text-left', sortable: true },
        { key: 'value', label: this.$t('Total'), 'class': 'text-left', sortable: true },
        { key: 'marketplace', label: this.$t('Sitio'), 'class': 'text-left', sortable: true },
        { key: 'status', label: this.$t('Estado'), 'class': 'text-left', sortable: true },
        { key: 'subStatus', label: this.$t('Sub-estado'), 'class': 'text-left', sortable: true },
        { key: 'tags', label: this.$t('Etiquetas'), 'class': 'text-left' },
        { key: 'action', label: ' ', 'class': 'text-right', sortable: false }
      ] : [
        { key: 'id', label: this.$t('Número'), 'class': 'text-left', sortable: true },
        { key: 'accountName', label: this.$t('Seller'), 'class': 'text-left', sortable: true },
        { key: 'clientProfileData', label: this.$t('Cliente'), 'class': 'text-left', sortable: true },
        { key: 'liquidationDate', label: this.$t('Fecha de liquidacion'), 'class': 'text-left', sortable: false },
        { key: 'invoiceNumber', label: this.$t('Factura'), 'class': 'text-left', sortable: false },
        { key: 'liquidationValue', label: this.$t('Monto de liquidacion'), 'class': 'text-left', sortable: false }

      ],
      fieldsNotAdmin: !this.liquidationReport ? [
        { key: 'id', label: this.$t('Número'), 'class': 'text-left', sortable: true },
        { key: 'clientProfileData', label: this.$t('Cliente'), 'class': 'text-left', sortable: true },
        { key: 'creationDate', label: this.$t('Fecha'), 'class': 'text-left', sortable: true },
        { key: 'value', label: this.$t('Total'), 'class': 'text-left', sortable: true },
        { key: 'marketplace', label: this.$t('Sitio'), 'class': 'text-left', sortable: true },
        { key: 'status', label: this.$t('Estado'), 'class': 'text-left', sortable: true },
        { key: 'subStatus', label: this.$t('Sub-estado'), 'class': 'text-left', sortable: true },
        { key: 'tags', label: this.$t('Etiquetas'), 'class': 'text-left', sortable: false },
        { key: 'action', label: ' ', 'class': 'text-right', sortable: false }
      ] : [
        { key: 'id', label: this.$t('Número'), 'class': 'text-left', sortable: true },
        { key: 'clientProfileData', label: this.$t('Cliente'), 'class': 'text-left', sortable: true },
        { key: 'liquidationDate', label: this.$t('Fecha de liquidacion'), 'class': 'text-left', sortable: false },
        { key: 'invoiceNumber', label: this.$t('Factura'), 'class': 'text-left', sortable: false },
        { key: 'liquidationValue', label: this.$t('Monto de liquidacion'), 'class': 'text-left', sortable: false }
      ],
      promotionFields: [
        { key: 'description', label: this.$t('Nombre'), formatter: (value) => { return value === null ? 'Promo Ecommerce' : value } },
        { key: 'promotionUserType', label: this.$t('Usuario'), formatter: (value) => { return value === null ? 'ECOMMERCE' : value } },
        { key: 'rawValue', label: this.$t('Descuento'), formatter: (value, key, item) => { return item.isPorcentual ? `${value}%` : `${this.currency} ${value}` } },
        { key: 'value', label: this.$t('Total descuento'), 'class': 'text-right', formatter: (value) => { return `${this.currency} ${value}` } }
      ],
      isAdmin: this.$store.getters.isAdmin,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [ 10, 15, 25, 50, 100 ],
      sortBy: null,
      sortDesc: false,
      filter: null,
      loading: false,
      locale: appConfig.LOCALE,
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : '$',
      filterTags: [],
      statusName: null,
      config: {
        format: 'YYYY-MM-DD',
        useCurrent: false
      },
      statusMapping: appConfig.STATUS_MAPPING,
      filterStatusMapping: appConfig.FILTER_STATUS_MAPPING,
      statusMappingColors: appConfig.STATUS_MAPPING_COLORS,
      tagsMapping: appConfig.TAGS_MAPPING,
      showFilters: true,
      tableComponentInfo: null,
      tagsToFilter: [],
      substatus: '',
      substatusFilter: null,
      siteNameFilter: null
    }
  },
  computed: {
    disableFilter () {
      if (this.isEmptyData(this.filter) && this.liquidationReport) {
        return true
      }

      if (!this.isValidText(this.filter)) {
        return true
      }
      return false
    },
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    }
  },
  methods: {
    capitalizeText (item) {
     return item.slice(0, 1).toUpperCase() + item.slice(1).toLowerCase()
    },
    getStatusColorClass: function (status) {
      return 'text-' + this.statusMappingColors[status] + ' h6'
    },
    rowClick (item) {
      this.$router.push(`/orders/orderDetail/${item.id}`)
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filterTable () {
      this.$refs.mainTable.refresh()
    },
    exportCSV () {
      this.loading = true
      this.$emit('error', '')
      return this.getAllOrders(this.tableComponentInfo, true).then(
        (csvFile) => {
          let filename = this.filterTags.indexOf('LQ') !== -1 ? 'liquidatedReportCSV.csv' : 'orderReportCSV.csv'
          this.loading = false
          this._exportCSV(csvFile, filename)
        },
        (error) => {
          this.loading = false
          let errorText = error.body.message || this.$t('Error obteniendo el reporte de pedidos')
          this.$emit('error', errorText)
        }
      )
    },
    itemProvider (tableComponentInfo) {
      this.loading = true
      // is the component in liquidated report tab
      // so, the filters will be set to get only liquidated orders.
      if (this.liquidationReport) {
        this.filterTags = ['LQ']
      }
      this.$emit('error', '')
      this.tableComponentInfo = tableComponentInfo
      return this.getAllOrders(tableComponentInfo).then(
        (response) => {
          let items = response.body
          this.totalRows = parseInt(response.headers.get('X-Total-Count'))
          this.loading = false
          return items
        },
        (error) => {
          this.loading = false
          let errorText = error.body.message || this.$t('Error obteniendo el reporte de pedidos')
          this.$emit('error', errorText)
          return []
        }
      )
    },
    showPromoDetail (arrDetails) {
      let showDetail = false
      arrDetails.forEach(itemDetail => {
        if (itemDetail.priceTags) {
          showDetail = true
        }
      })
      return showDetail
    },
    getSubStatus () {
      return this.getAllStatus(null).then(function (response) {
        this.substatus = response.body
      },
      (error) => {
        this.errorText = error.body.message || this.$t('Error obteniendo el listado de estados')
        return []
      })
    }
  },
  mounted () {
    this.tagsToFilter = Object.keys(appConfig.TAGS_MAPPING).map((key) => {
      return {
        'text': this.$t(appConfig.TAGS_MAPPING[key].text),
        'key': appConfig.TAGS_MAPPING[key].key,
        'isCt': appConfig.TAGS_MAPPING[key].isCt
      }
    }).filter(tag => !tag.isCt)
    this.getSubStatus()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
  .order-list {
    .orderTable {
      table-layout: fixed;
    }

    .btn-link {
      color: #666666;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    .filter-row {
      .btn-primary {
        width: 80px;
      }

      button {
        i {
          margin-right: 5px;
        }
      }
    }

    .filters {
      border: 1px solid #e3e8ec;
      background-color: #FFF
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }

    .tag {
      width: 26px;
      display: inline-block;
      border-radius: 5px;
      line-height: 17px;
      margin-right: 4px;
      cursor: pointer;
      text-align: center;
    }

    tr:hover {
      cursor: pointer
    }

    .col-multiselect {
      z-index: 3;
      position: relative;
    }
  }

</style>
