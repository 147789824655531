<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
import appConfig from '@/config/applicationConfig.js'
export default {
  name: 'app',
  title: appConfig.SELLER_CENTER_TITLE ? appConfig.SELLER_CENTER_TITLE : 'Summa Seller Center'
}
</script>

<style>
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/css/font-awesome.min.css';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/css/simple-line-icons.css';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
</style>
<style lang="scss">
  // Import Main styles for this application
  @import './scss/style';
</style>
