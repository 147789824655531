<template>
  <div>
    <organization-list/>
  </div>
</template>

<script>
import OrganizationList from '@/components/user/OrganizationList'

export default {
  name: 'organizations-view',
  components: {
    OrganizationList
  }
}
</script>
