<template>
      <b-nav-item-dropdown right no-caret toggle-class="user-main-navbar">
        <template slot="button-content">
          <div class="userName"><b class="mr-2 adminAdvice" v-if="isAdmin">{{$t('(Administrador)')}}</b>{{ userText }}</div>
          <img :src="require('@/assets/img/avatars/user.png')" class="img-avatar" alt="admin@bootstrapmaster.com">
        </template>
        <b-dropdown-item @click="$router.push('/profile/me')"><i class="fa fa-user"></i> {{$t('Ver perfil')}}</b-dropdown-item>
        <b-dropdown-item @click="logout"><i class="fa fa-sign-out-alt"></i> {{$t('Logout')}}</b-dropdown-item>
      </b-nav-item-dropdown>
</template>
<script>
  import { UserServiceMixim } from '@/mixims/UserServiceMixim.js'

  export default {
    name: 'header-dropdown-accnt',
    mixins: [UserServiceMixim],
    data: () => {
      return {
        itemsCount: 42
      }
    },
    computed: {
      userText () {
        return this.$store.state.user_data.firstName + ' ' + this.$store.state.user_data.lastName
      },
      isAdmin () {
        return this.$store.getters.isAdmin
      }
    }
  }
</script>

<style lang="scss">
  .nav-item.b-nav-dropdown.dropdown{
    &[toggle-class="user-main-navbar"]{
      a.nav-link{
        display: flex;
        align-items:center;
        .userName{
          font-size: 14px;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .navbar-nav.ml-auto.b-navbar-custom.b-navbar-custom{
      &.mr-1{
        margin-right: 5px !important;
      }
    }
    .nav-item.b-nav-dropdown.dropdown{
    &[toggle-class="user-main-navbar"]{
      a.nav-link{
        .userName{
          display: none !important;
        }
      }
    }
  }
}
</style>
