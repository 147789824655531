<template>
  <div class="animated fadeIn">
    <b-container fluid class="order-list">
      <b-row>
        <b-col md="4">
          <b-form-group :label="$t('Estado')" :description="$t('Seleccione el estado del pedido')">
            <b-form-select v-model="statusName">
              <option slot="first" :value="null">{{$t('Todos')}}</option>
              <option v-for="(text, value) in statusMapping"
                      :key="value"
                      :value="value">{{$t(text)}}</option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Listado de pedidos')">
        <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
        <b-table v-show="!loading" id="mainTable"
          ref="mainTable"
           show-empty
           striped
           hover
           stacked="xl"
           :items="itemProvider"
           :fields="isAdmin ? fields: fieldsNotAdmin"
           :current-page="currentPage"
           :per-page="perPage"
           :filter="filter"
           :sort-by.sync="sortBy"
           :sort-desc.sync="sortDesc"
           @row-clicked="rowClick"
           :empty-text="$t('No hay pedidos para mostrar')"
           :empty-filtered-text="$t('No hay pedidos para mostrar')">
          <template slot="clientProfileData" slot-scope="data">
            {{data.value ? data.value.firstName : ''}} {{data.value ? data.value.lastName : ''}}
          </template>
          <template slot="status" slot-scope="status">
            <span :class="getStatusColorClass(status.value)"><b>{{$t(statusMapping[status.value])}}</b></span>
          </template>
          <template slot="creationDate" slot-scope="creationDate">
            {{new Date(creationDate.value).toLocaleDateString(locale)}}
          </template>
          <template slot="value" slot-scope="value">
            {{currency}} {{value.value}}
          </template>
        </b-table>
      </b-card>
    </b-container>
  </div>
</template>

<script>
// Date Picker
import Vue from 'vue'
import datePicker from 'vue-bootstrap-datetimepicker'
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'
import {OrdersServiceMixim} from '../../mixims/OrdersServiceMixim'
import appConfig from '../../config/applicationConfig.js'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

Vue.use(datePicker)

export default {
  name: 'ordersReport',
  mixins: [OrdersServiceMixim],
  components: {MoonLoader},
  props: ['fromDate', 'toDate', 'account'],
  watch: {
    statusName: function (val, oldVal) {
      this.$refs.mainTable.refresh()
    },
    fromDate: function (val, oldVal) {
      if (!this.validRange()) return
      this.$refs.mainTable.refresh()
    },
    toDate: function (val, oldVal) {
      if (!this.validRange()) return
      this.$refs.mainTable.refresh()
    },
    account: function (val, oldVal) {
      this.$refs.mainTable.refresh()
    }
  },
  data () {
    return {
      fields: [
        { key: 'id', label: this.$t('Número'), 'class': 'text-left' },
        { key: 'accountName', label: this.$t('Seller'), 'class': 'text-left' },
        { key: 'clientProfileData', label: this.$t('Cliente'), 'class': 'text-left' },
        { key: 'creationDate', label: this.$t('Fecha'), 'class': 'text-left' },
        { key: 'value', label: this.$t('Total'), 'class': 'text-left' },
        { key: 'status', label: this.$t('Estado'), 'class': 'text-left' }
      ],
      fieldsNotAdmin: [
        { key: 'id', label: this.$t('Número'), 'class': 'text-left' },
        { key: 'clientProfileData', label: this.$t('Cliente'), 'class': 'text-left' },
        { key: 'creationDate', label: this.$t('Fecha'), 'class': 'text-left' },
        { key: 'value', label: this.$t('Total'), 'class': 'text-left' },
        { key: 'status', label: this.$t('Estado'), 'class': 'text-left' }
      ],
      locale: appConfig.LOCALE,
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : '$',
      isAdmin: this.$store.getters.isAdmin,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      sortBy: null,
      sortDesc: false,
      filter: null,
      showFilters: true,
      loading: false,
      filterTags: [],
      statusName: null,
      config: {
        format: 'YYYY-MM-DD',
        useCurrent: false,
        icons: {
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right'
        }
      },
      statusMapping: appConfig.STATUS_MAPPING,
      statusMappingColors: appConfig.STATUS_MAPPING_COLORS
    }
  },
  computed: {
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    },
    invalidDateTo () {
      if (this.fromDate && this.toDate) {
        let fDate = new Date(this.fromDate)
        let tDate = new Date(this.toDate)
        return fDate > tDate ? this.$t('La fecha ingresada es anterior que la inicial') : ''
      }
      return ''
    }
  },
  methods: {
    getStatusColorClass: function (status) {
      return 'text-' + this.statusMappingColors[status] + ' h6'
    },
    validRange () {
      if (this.fromDate && this.toDate) {
        let fDate = new Date(this.fromDate)
        let tDate = new Date(this.toDate)
        if (tDate > new Date()) {
          return false
        }
        if (fDate > new Date()) {
          return false
        }
        if (fDate >= tDate) {
          return false
        }
        return true
      }
      return false
    },
    rowClick (item) {
      this.$router.push(`/orders/orderDetail/${item.id}`)
    },
    changeStatusExternally (newStatusName) {
      if (newStatusName === 'controversy') {
        this.filterTags = ['I']
        this.statusName = null
      } else {
        this.filterTags = []
        this.statusName = newStatusName
      }
      this.$refs.mainTable.refresh()
      document.getElementById('mainTable').scrollIntoView()
    },
    filterTable () {
      this.$refs.mainTable.refresh()
    },
    itemProvider (tableComponentInfo) {
      this.loading = true
      this.$emit('error', '')
      return this.getAllOrders(tableComponentInfo).then(
        (response) => {
          let items = response.body
          this.totalRows = parseInt(response.headers.get('X-Total-Count'))
          this.$emit('total', this.totalRows)
          this.loading = false
          return items
        },
        (error) => {
          this.loading = false
          let errorText = error.body.message || this.$t('Error obteniendo el reporte de pedidos')
          this.$emit('error', errorText)
          return []
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>

  .order-list {
    .orderTable {
      table-layout: fixed;
    }

    .filter-row {
      .btn-primary {
        width: 80px;
      }

      button {
        i {
          margin-right: 5px;
        }
      }
    }

    .filters {
      border: 1px solid #e3e8ec;
      background-color: #FFF
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }

    .tag {
      width: 26px;
      display: inline-block;
      border-radius: 5px;
      line-height: 17px;
      margin-right: 4px;
      cursor: pointer;
      text-align: center;
    }

    tr:hover {
      cursor: pointer
    }
  }

</style>
