<template>
  <div>
    <h5 class="mb-3">{{$t('Datos del usuario')}}</h5>
    <b-row>
      <b-col md="12">
        <b-form-group
          horizontal
          :label-cols="2"
          :label="$t('Nombre')"
          :invalid-feedback="!state.firstName? $t('El nombre es invalido'): ''"
          :state="state.firstName"
        >
          <b-form-input v-model="user.firstName"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          horizontal
          :label-cols="2"
          :label="$t('Apellido')"
          :invalid-feedback="!state.lastName? $t('El apellido es invalido') : ''"
          :state="state.lastName"
        >
          <b-form-input v-model="user.lastName"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          horizontal
          :label="$t('Correo')"
          :label-cols="2"
          :description="Boolean(verifyInProgress) ? $t('Verificando el correo, espere...') : ''"
          :invalid-feedback="$t(emailFeedBack)"
          :state="state.email && uniqueEmail"
        >
          <b-form-input v-model="email"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-show="!selectedIsMarketAdmin">
      <b-col md="12">
        <b-form-group horizontal
          :label-cols="2"
          :label="$t('Sellers')"
          :description="selectedIsMarketAdmin ? '' : $t('Solo un usuario administrador del Marketplace puede seleccionar mas de un Seller')"
          :invalid-feedback="$t(alertInvalidFeedBack())"
          :state="state.sellers && user.id === null">
          <autocomplete
            id="inputSellers"
            class="input_class optional"
            name="inputSellers"
            :maxElements="isAdmin && selectedIsMarketAdmin ? 100 : 1"
            :disabled="!isAdmin"
            :searchText="$t('Enter para agregar Seller')"
            :provider="getSearchSeller"
            v-model="user.organizations"
            :showSelectAll="selectedIsMarketAdmin"
            :seletecAll="true">
          </autocomplete>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <roles-checkbox :value="user" :options="roleOptions"/>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {RoleService, AdminServiceMixin, OrganizationService} from '@/mixims/AdminServiceMixin'
import OrganizationsCheckbox from './OrganizationsCheckbox'
import { ValidationsMixim } from '@/mixims/ValidationsMixim'
import RolesCheckbox from './RolesCheckbox'
import autocomplete from '@/components/common/vue-autocomplete'

export default {
  mixins: [RoleService, AdminServiceMixin, OrganizationService, ValidationsMixim],
  components: { RolesCheckbox, autocomplete },
  props: ['user', 'roleOptions', 'allOrganizations', 'action'],
  data () {
    return {
      loading: false,
      emailValidation: true,
      emailFeedBack: '',
      timeout: null,
      prev: '',
      verifyInProgress: false,
      isAdmin: this.$store.getters.isAdmin,
      uniqueEmail: true,
      selectedAction: 'create',

    }
  },
  methods: {
    save (saveAction) {
      this.getRoles().then(res => {
        return res.body
      }).then(r => {
        saveAction(this.user)
      })
    },

    /**
     * establece el mensaje de invalid feedback
     */
    handleInvalidFeedback () {
      if (!this.user.email || this.user.email === '') this.emailFeedBack = 'El email es obligatorio'
      else if (!this.validateEmail(this.user.email, true)) this.emailFeedBack = 'Email inválido'
      else this.emailFeedBack = ''
    },
    alertInvalidFeedBack () {
      const commonAlert = 'Se debe seleccionar'
      return !this.user.organizations.length ? `${commonAlert} al menos un Seller` : !this.selectedIsMarketAdmin && this.user.organizations.length > 1 ? `${commonAlert} solo un Seller` : ''
    }
  },
  computed: {
    selectedIsMarketAdmin () {
      return this.user.userType === 'ADMIN'
    },
    disableSave () {
      return Object.values(this.state).includes(false) || this.loading
    },
    state () {
      return {
        firstName: this.isValidText(this.user.firstName, true, 20),
        lastName: this.isValidText(this.user.lastName, true, 20),
        email: Boolean(this.user.email) && this.validateEmail(this.user.email, true) && (this.uniqueEmail || this.selectedAction === 'update'),
        roles: Boolean(this.user.roles) && this.user.roles.length > 1,
        sellers: (this.alertInvalidFeedBack() === '')
      }
    },
    email: {
      set (newVal) {
        this.uniqueEmail = false
        this.user.email = newVal
        if (this.user.id && this.prev === newVal) {
          this.emailValidation = true
          clearTimeout(this.timeout)
          return
        }
        this.emailValidation = false
        this.handleInvalidFeedback()
        if (this.validateEmail(newVal, true)) {
          this.loading = true
          this.verifyInProgress = true
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.existEmail(this.user.email || '').then(res => {
              this.emailValidation = !res.body
              this.verifyInProgress = false
              if (!this.emailValidation) {
                this.emailFeedBack = 'El correo ingresado se encuentra en uso'
                this.uniqueEmail = false
                this.loading = false
                this.selectedAction = 'create'
                return false
              } else {
                this.uniqueEmail = true
                this.loading = false
                this.selectedAction = 'update'
                return true
              }
            })
          }, 2000)
        }
      },
      get () {
        this.handleInvalidFeedback()
        return this.user.email
      }
    }
  },
  watch: {
    user (newUser, oldUser) {
      if (newUser.id !== oldUser.id) {
        this.prev = newUser.email
      }
    },
    action (value) {
      this.selectedAction = value
    }
  }
}
</script>
