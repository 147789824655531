<template>
  <b-col class="p-0 history-wrapper">
    <b-card header="Historial de Cambios" class="my-3" header-bg-variant="primary">
      <b-table
        class="history-table"
        striped
        :items="history"
        hover
        outlined
        head-variant='light'
        :fields="fields"
        :small="true"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        show-empty
        empty-text="No hay historial para mostrar"
        >
          <template slot="user" slot-scope="row">
            {{row.item.user ? row.item.user.firstName : ''}} {{row.item.user ? row.item.user.lastName: ''}}
          </template>
          <template slot="date" slot-scope="row">
            {{new Date(row.item.date).toLocaleDateString(locale)}}
            {{new Date(row.item.date).toLocaleTimeString(locale)}}
          </template>
          <template slot="type" slot-scope="row">
            {{types[row.item.type]}}
          </template>
          <template slot="oldSku" slot-scope="row">
            <div v-if="row.item.type === 'PRICE'">
              {{currency}} {{row.item.oldSku.price.price}}
            </div>
            <div v-if="row.item.type === 'INVENTORY'">
              {{row.item.oldSku.inventory.totalQuantity}}
            </div>
          </template>
          <template slot="newSku" slot-scope="row">
            <div v-if="row.item.type === 'PRICE'">
              {{currency}} {{row.item.newSku.price.price}}
            </div>
            <div v-if="row.item.type === 'INVENTORY'">
              {{row.item.newSku.inventory.totalQuantity}}
            </div>
          </template>

      </b-table>
    </b-card>
  </b-col>
</template>
<script>
import appConfig from '@/config/applicationConfig.js'

export default {
  name: 'SkuHistory',
  props: ['history'],
  data () {
    return {
      fields: [
        { key: 'user', label: 'Usuario', 'class': 'text-left' },
        { key: 'date', label: 'Fecha', 'class': 'text-left' },
        { key: 'type', label: 'Cambio', 'class': 'text-left' },
        { key: 'oldSku', label: 'Valor ant.', 'class': 'text-left' },
        { key: 'newSku', label: 'Nuevo valor', 'class': 'text-left' }
      ],
      sortBy: 'date',
      sortDesc: false,
      locale: appConfig.LOCALE,
      timeOptions: { timeZone: 'UTC', timeZoneName: 'short' },
      types: {
        'INVENTORY': 'Inventario',
        'PRICE': 'Precio'
      }
    }
  },
  computed: {
    currency () {
      return appConfig.CURRENCY
    }
  }
}
</script>

<style lang="scss" scoped>
  .priceOff {
    font-weight: 700;
    color: #4DBD74;
  }
  .history-wrapper {
    .card-body {
      max-height: 300px;
      overflow-y: auto;
    }
    div.card{
      border-bottom-color: transparent;
    }
  }
</style>
