<template>
  <div>
    <div v-show="errorMsg" class="alert alert-danger" role="alert">
      {{ errorMsg }}
    </div>
    <b-row class="filter-row">
      <b-col lg="4">
        <b-form-group
          :label="$t('Buscar')"
          :invalid-feedback="
            !isValidText(filterOptions.text, false)
              ? $t('No es un valor válido')
              : ''
          "
          :state="isValidText(filterOptions.text, false)"
          class="mb-0"
          :description="$t('Ingrese el texto a buscar')"
        >
          <b-input-group>
            <b-form-input
              v-model="filterOptions.text"
              :placeholder="$t('Texto a buscar')"
            />
            <b-input-group-button>
              <b-btn :disabled="!filterOptions.text" @click="onTextSearch">{{
                $t("Buscar")
              }}</b-btn>
            </b-input-group-button>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md-="12">
        <b-button
          variant="primary"
          class="btn btn-primary float-right"
          v-b-toggle.order-filters
        >
          <i class="fa fa-filter" /> Filtros
        </b-button>
      </b-col>
    </b-row>

    <b-collapse id="order-filters">
      <div class="filters mt-3">
        <b-row>
          <b-col lg="4">
            <b-form-group
              label="Desde"
              class="mb-0"
              description="Fecha de inicio para filtrar datos"
              :state="fromDateError == null"
              :invalid-feedback="fromDateError"
            >
              <b-input-group>
                <date-picker
                  v-on:contextmenu.native.stop.prevent
                  v-on:keydown.native.stop.prevent
                  v-model="filterOptions.fromDate"
                  :config="datePickerConfig"
                ></date-picker>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group
              label="Hasta"
              class="mb-0"
              description="Fecha final para filtrar datos"
              :state="toDateError == null"
              :invalid-feedback="toDateError"
            >
              <b-input-group>
                <date-picker
                  v-on:contextmenu.native.stop.prevent
                  v-on:keydown.native.stop.prevent
                  v-model="filterOptions.toDate"
                  :config="datePickerConfig"
                ></date-picker>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group
              label="Estado"
              class="mb-3"
              description="Seleccione el estado del pedido"
            >
              <b-form-select
                :options="statusOptions"
                v-model="filterOptions.status"
              >
                <option slot="first" :value="null">Todos</option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4" class="col-multiselect">
            <b-form-group
              :label="$t('Filtrar por TAGS')"
              :description="
                $t(
                  'Selecciones los tags de ordenes que desea filtrar en la busqueda'
                )
              "
            >
              <multiselect
                v-model="filterOptions.tags"
                label="text"
                :placeholder="$t('Todos')"
                :selectedLabel="$t('Seleccionado')"
                :selectLabel="$t('Enter para agregar el tag')"
                :deselectLabel="$t('Enter para retirar el tag')"
                track-by="key"
                :options="tagOptions"
                :multiple="true"
                :taggable="true"
              >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <!-- v-if="isAdmin" -->
            <b-form-group :label="$t('Seller')" class="mb-3">
              <b-form-select
                :options="accountOptions"
                v-model="filterOptions.accountName"
              >
                <option slot="first" :value="null">{{ $t("Todos") }}</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group :label="$t('Sub-estados')" class="mb-3">
              <b-form-select v-model="filterOptions.subStatus">
                <option slot="first" :value="null">{{ $t("Todos") }}</option>
                <option
                  v-for="(text, value) in substatusOptions"
                  :key="value"
                  :value="text.id"
                  >{{ $t(text.name) }}</option
                >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group
              :label="$t('Sitio')"
              class="mb-3"
              :description="$t('Seleccione el sitio del pedido')"
            >
              <b-form-select
                :options="marketplacesOptions"
                v-model="filterOptions.marketplaces"
              >
                <option slot="first" :value="null">{{ $t("Todos") }}</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group
              :label="$t('Política comercial')"
              class="mb-3"
              :description="
                filterOptions.marketplaces
                  ? $t('Seleccione la política comercial del pedido')
                  : $t('Debe seleccionar un sitio previamente')
              "
            >
              <b-form-select
                :options="marketplaceTradeAgreements"
                v-model="filterOptions.tradeAgreementFilter"
                :disabled="!filterOptions.marketplaces"
              >
                <option slot="first" :value="null">{{ $t("Todas") }}</option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-input-group-button class="float-right mt-3">
              <b-button
                :block="true"
                variant="primary"
                @click.stop="handleFilter"
                >{{ $t("Filtrar") }}</b-button
              >
            </b-input-group-button>
          </b-col>
        </b-row>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { ValidationsMixim } from "@/mixims/ValidationsMixim";
import { SubStatusMixim } from "@/mixims/SubStatusMixim";
import { MarketplaceServiceMixim } from "@/mixims/MarketplaceServiceMixim";

import DatePicker from "vue-bootstrap-datetimepicker";
import applicationConfig from "@/config/applicationConfig";
import Multiselect from "vue-multiselect";

const today = new Date();

const formattedTodaysDate = `${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`;

const INITIAL_FILTER_STATE = {
  fromDate: null,
  toDate: null,
  status: null,
  accountName: null,
  subStatus: null,
  marketplaces: null,
  tradeAgreementFilter: null,
  tags: null,
  text: null
};

export default {
  name: "orders-filters",
  components: { DatePicker, Multiselect },
  props: ["onFiltered"],
  mixins: [ValidationsMixim, SubStatusMixim, MarketplaceServiceMixim],
  data() {
    return {
      datePickerConfig: {
        format: "YYYY-MM-DD",
        useCurrent: false,
        icons: {
          previous: "fa fa-chevron-left",
          next: "fa fa-chevron-right"
        }
      },
      filterOptions: {
        ...INITIAL_FILTER_STATE
      },
      tagOptions: Object.keys(applicationConfig.TAGS_MAPPING)
        .map(key => {
          return {
            text: this.$t(applicationConfig.TAGS_MAPPING[key].text),
            key: applicationConfig.TAGS_MAPPING[key].key,
            isCt: applicationConfig.TAGS_MAPPING[key].isCt
          };
        })
        .filter(tag => !tag.isCt),
      statusOptions: applicationConfig.STATUS_MAPPING,
      accountOptions: this.$store.getters.organizations,
      substatusOptions: [],
      marketplacesOptions: [],
      errorMsg: null,
      invalidDateFrom: ""
    };
  },
  computed: {
    fromDateError() {
      if (this.filterOptions.fromDate !== null) {
        const today = new Date();
        const fromDate = new Date(this.filterOptions.fromDate);

        if (fromDate > today)
          return "La fecha seleccionada es mayor al día de hoy";
      }
      if (
        this.filterOptions.fromDate !== null &&
        this.filterOptions.toDate !== null
      ) {
        const fromDate = new Date(this.filterOptions.fromDate);
        const toDate = new Date(this.filterOptions.toDate);

        if (fromDate > toDate)
          return "La fecha inicial no puede ser mayor a la fecha final";
      }

      return null;
    },
    toDateError() {
      if (this.filterOptions.toDate !== null) {
        const today = new Date();
        const toDate = new Date(this.filterOptions.toDate);

        return toDate > today
          ? "La fecha seleccionada es mayor al día de hoy"
          : null;
      }

      return null;
    },
    marketplaceTradeAgreements() {
      return (
        this.marketplacesOptions.find(
          marketplace => marketplace.value == this.filterOptions.marketplaces
        )?.tradeAgreements ?? []
      );
    }
  },
  mounted() {
    const queryParams = { ...this.$route.query };

    if (typeof queryParams.tags === "string") {
      const tagKeys = queryParams.tags.split(",");

      const selectedTags = this.tagOptions.filter(tagOpt =>
        tagKeys.includes(tagOpt.key)
      );

      queryParams.tags = selectedTags;
    }

    this.filterOptions = {
      accountName: queryParams.accountName ?? null,
      fromDate: queryParams.fromDate ?? null,
      status: queryParams.status ?? null,
      subStatus: queryParams.subStatus ?? null,
      toDate: queryParams.toDate ?? null,
      marketplaces: queryParams.marketplaces ?? null,
      tags: queryParams.tags ?? null,
      text: queryParams.text ?? null,
      tradeAgreementFilter: queryParams.tradeAgreementFilter ?? null
    };

    this.setSubStatus();
    this.setMarketplaces();
  },
  methods: {
    onTextSearch() {
      this.$router.push({
        query: {
          ...this.$route.query,
          text: this.filterOptions.text
        }
      });
    },
    setSubStatus() {
      return this.getAllStatus(null).then(
        function({ body: substatus }) {
          this.substatusOptions = substatus;
        },
        error => {
          this.errorText =
            error.body.message ||
            this.$t("Error obteniendo el listado de estados");
          return [];
        }
      );
    },
    setMarketplaces() {
      this.getMarketplaces(0, 100).then(
        res => {
          for (let i = 0; i < res.body.length; i++) {
            this.marketplacesOptions.push({
              value: res.body[i].id,
              text: res.body[i].name,
              tradeAgreements: res?.body[i]?.tradeAgreements?.map(ta => ({
                value: ta.id,
                text: ta.name
              }))
            });
          }
        },
        error => {
          this.errorText =
            error.body.message || "Ocurrió un error al obtener Sitios";
        }
      );
    },
    validateFilterFields() {
      //validate dates
      const today = new Date();
      const fromDate = new Date(this.fromDate);
      const toDate = new Date(this.toDate);
    },
    handleFilter() {
      try {
        this.validateFilterFields();

        const jsonWithoutNulls = this.cleanJSON(this.filterOptions);

        if (jsonWithoutNulls.tags) {
          jsonWithoutNulls.tags = jsonWithoutNulls.tags
            .map(filter => filter.key)
            .join(",");
        }

        this.onFiltered(jsonWithoutNulls);
      } catch (error) {
        this.errorMsg = error.message;
      }
    }
  }
};
</script>
