<template>
  <taxes-list/>
</template>

<script>
import TaxesList from '@/components/taxes/TaxesList'
export default {
  components: {
    TaxesList
  }
}
</script>
