<template>
  <b-container fluid class="animated fadeIn">
    <div v-show="errorText !== ''" class="alert alert-danger" role="alert" dismissible @dismised="errorText = ''">
      {{errorText}}
    </div>
    <div v-show="success" class="alert alert-success" role="alert" dismissible @dismised="success=false">
      La acción fue completada con éxito
    </div>
    <b-row class="mb-5">
      <b-col md="10" class="mb-3 d-flex">
        <b-form-group id="nombre"
            class="mb-0 col-5"
            :label="$t('Busqueda por texto')"
            :invalid-feedback="!isValidText(filterRules.text, false)? $t('No es un valor válido') : ''"
            :state="isValidText(filterRules.text, false)"
            :description="$t('Ingrese el texto a buscar')"
            label-for="nameFormatter">
            <b-input-group>
                <b-form-input :state="isValidText(filterRules.text, false)" id="nameFormatter" v-model="filterRules.text" :placeholder="$t('Texto a buscar')" />
                <b-input-group-button>
                    <b-btn :disabled="!isValidText(filterRules.text, false)" @click="filterTable">{{$t('Buscar')}}</b-btn>
                </b-input-group-button>
            </b-input-group>
        </b-form-group>
        <b-form-group id="user-type"
            class="mb-0 col-5"
            :label="$t('Búsqueda por tipo de usuario')"
            :description="$t('Seleccione el tipo de usuario a buscar')"
            label-for="roleSearch">
          <b-form-select
              id="roleSearch"
              v-model="filterRules.userType"
              @change="filterTable"
            >
            <option value="null" slot="first">{{$t('Todos')}}</option>
            <option
              v-for="role in userTypeOptions"
              :key="role.userType"
              :value="role.userType">
              {{role.label}}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <button v-if="$store.getters.signedRoles.includes('ADMIN_WRITE')" @click="goToCreateRole" class="btn btn-primary mr-2 float-right">Crear rol</button>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col class="col-md-auto">
        <b-pagination :total-rows="totalRows" :per-page="filterRules.size" v-model="filterRules.from" class="mb-3" />
      </b-col>
      <b-col class="col-md-auto d-flex flex-row mb-3">
        <div class="d-flex align-items-center mr-2">
          Mostrar por página
        </div>
        <div class="d-flex align-items-center">
          <b-form-select :options="pageOptions" v-model="filterRules.size" />
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col class="mt-1" align-self="start">
        <b>{{totalRows}}</b> roles encontrados
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card header-bg-variant="primary" border-variant="primary" header="Listado de Roles">
          <moon-loader :loading="loading" class="mb-2" color="#000" size="30px"></moon-loader>
          <b-table id="mainTable"
            ref="mainTable"
            @row-dblclicked="roleClicked"
            show-empty
            striped
            hover
            foot-clone
            :sort-by.sync="filterRules.sortBy"
            :sort-desc.sync="filterRules.sortOrder"
            :per-page="filterRules.size"
            :current-page="filterRules.from"
            :items="itemProvider"
            :filter="filter"
            :fields="!$store.getters.signedRoles.includes('ADMIN_WRITE') ? fieldsRead : fields"
            :fixed=true
            responsive=true
            stacked="lg"
            empty-text="No hay roles para mostrar"
            empty-filtered-text="No hay roles para mostrar">
            <template slot="actions" slot-scope="row" >
              <el-tooltip class="item" effect="dark" content="Eliminar rol" placement="top-start">
                <span @click="showDeleteRole(row.item)"  style="cursor:pointer"><i class="far fa-trash-alt fa-lg"></i></span>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Copiar rol" placement="top-start">
                <span @click="showDuplicateRole(row.item)" style="cursor:pointer; margin: 0px 10px 0px 10px"><i class="far fa-copy fa-lg"></i></span>
              </el-tooltip>
          </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-modal header-bg-variant="primary" centered ref="deleteModal" title="Eliminar rol" o-close-on-backdrop="true" @ok="handleDeleteRole" cancel-title="Cancelar">
      ¿Desea continuar?
    </b-modal>

    <b-modal header-bg-variant="primary" centered ref="duplicateModal" title="Copiar rol" o-close-on-backdrop="true" @ok="handleDuplicateRole" cancel-title="Cancelar">
      ¿Desea continuar?
    </b-modal>
  </b-container>
</template>
<script>
  import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
  import { RoleService } from '@/mixims/AdminServiceMixin'
  import { ValidationsMixim } from '@/mixims/ValidationsMixim'

  export default {
    components: { MoonLoader },
    mixins: [RoleService, ValidationsMixim],
    data () {
      return {
        success: null,
        errorText: '',
        loading: false,
        fields: [
          { key: 'roleName', label: this.$t('Rol'), 'class': 'text-left', sortable: true },
          { key: 'userType', label: this.$t('Tipo de usuario'), 'class': 'text-left', sortable: true },
          { key: 'actions', label: this.$t('Acciones'), 'class': 'text-right', sortable: false }
        ],
        fieldsRead: [
          { key: 'roleName', label: this.$t('Rol'), 'class': 'text-left', sortable: true },
          { key: 'userType', label: this.$t('Tipo de usuario'), 'class': 'text-left', sortable: true }
        ],
        filterRules: {
          // sortBy: 'roleName',
          // sortOrder: false,
          size: 10,
          from: 1,
          text: null,
          userType: null
        },
        totalRows: 0,
        pageOptions: [ 10, 15, 25, 50, 100 ],
        selectedRol: {
          'id': '',
          'roleName': '',
          'description': '',
          'permissions': [],
          'userType': '',
          'roleStatus': '',
          'creationDate': '',
          'updateDate': ''
        }
      }
    },
    mounted () {

    },
    computed: {
      userTypeOptions () {
      let userTypes = [
        {
          userType: 'SELLER',
          label: 'Seller'
        }
      ]
      if (this.$store.getters.userData.userType === 'ADMIN') {
        userTypes.unshift({
          userType: 'ADMIN',
          label: 'Administrador'
        })
      }
      return userTypes
    }
    },
    methods: {
      goToCreateRole () {
        this.$store.dispatch('setRole', '')
        this.$router.push({ path: '/admin/roles/role' })
      },
      roleClicked (item) {
        this.$router.push({ path: `/admin/roles/role/${item.id}` })
      },
      showDeleteRole (item) {
        this.success = false
        this.$refs['deleteModal'].show()
        this.selectedRol = item
      },
      filterTable () {
        this.$nextTick(() => {
          this.$refs.mainTable.refresh()
        })
      },
      showDuplicateRole (item) {
        this.success = false
        this.$refs['duplicateModal'].show()
        this.selectedRol = item
      },
      filter () {
        return this.filterRules
      },
      itemProvider (ctx) {
        let filter = ctx.filter()
        this.loading = true
        return this.getRoles(filter).then(
          response => {
            this.totalRows = parseInt(response.headers.get('X-Total-Count'))
            this.loading = false
            return response.body
          }, error => {
            this.errorText = error.body.message || 'Error obteniendo el listado de roles'
            this.loading = false
            return []
          }
        )
      },
      handleDeleteRole () {
        let roleToDelete = []
        roleToDelete.push(this.selectedRol)
        try {
          this.deleteRole(roleToDelete).then(() => {
            window.scrollTo(0, 0)
            this.success = true
            this.$refs.mainTable.refresh()
          }).catch((er) => {
            window.scrollTo(0, 0)
            this.errorText = 'Ocurrió un error al eliminar el rol'
          })
        } catch (e) {
          console.log(e.message)
          this.errorText = 'Ha ocurrido un error en el módulo de Roles'
        }
      },
      handleDuplicateRole () {
        this.$store.dispatch('setRole', this.selectedRol)
        this.$router.push({ path: '/admin/roles/role' })
      }
    }
  }
</script>
