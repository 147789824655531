import appConfig from '@/config/applicationConfig.js'

export const SubStatusMixim = {
  methods: {
    /**
    * Get all the substatus
    * @param tableInfo data to filter the query
    */
    getAllStatus (tableInfo) {
      let url = null
      let params = {}
      url = new URL(`${appConfig.API_END_POINT}/ordersubstatus`)
      if (tableInfo) {
        params.from = tableInfo.currentPage - 1 
        params.size = tableInfo.perPage
  
        if (tableInfo.sortBy) {
          params.sortBy = tableInfo.sortBy
          params.sortOrder = tableInfo.sortDesc ? 'desc' : 'asc'
        } else {
          params.sortBy = 'creationDate'
          params.sortOrder = 'desc'
        }
      }

      if (this.filter) params.text = this.filter
      if (this.substatusName) params.status = this.substatusName
      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null) {
            url.searchParams.append(key, params[key])
          }
        }
      )

      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }
      return this.$http.get(
        url.href,
        config
      )
    },
    saveStatus (statusPayload) {
      return this.$http.post(
        `${appConfig.API_END_POINT}/ordersubstatus/`, statusPayload,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }).then(res => res.body)
    },
    editSubStatus (statusPayload) {
      return this.$http.put(
        `${appConfig.API_END_POINT}/ordersubstatus/${statusPayload.id}`, statusPayload,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }).then(res => res.body)
    },
    /**
    * Cancel the sub-status given the id
    * @param statusId
    */
    deleteSubState (statusId) {
      return this.$http.delete(
        `${appConfig.API_END_POINT}/ordersubstatus/${statusId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
    getOrdersSubstatus (statusId) {
      return this.$http.get(
        `${appConfig.API_END_POINT}/orders/?from=0&size=1&subStatus=${statusId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
  }
}
