<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-form-group
          horizontal
          oncontextmenu="return false;"
          :label-cols="2"
          :invalid-feedback="!isValidText(value.name, true) ? $t('Ingrese un nombre') : ''"
          :state="isValidText(value.name, true)"
          :label="$t('Nombre')">
          <b-form-input v-model="value.name" :disabled="value.type === 'ADMIN' && !isAdmin ? true : false"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group 
          id="slaType"
          horizontal
          oncontextmenu="return false;"
          :label-cols="2"
          breakpoint="md"
          :label="$t('Tipo SLA')"
          label-for="slaListInput"
          :invalid-feedback="!isValidText(value.slaType, true)? $t('Seleccione un tipo de SLA') : ''"
          :state="isValidText(value.slaType, true)">
          <b-form-select 
            id="slaListInput"
            :disabled="value.type === 'ADMIN' && !isAdmin ? true : false"
            v-model="value.slaType">
            <option v-for="(sla, index) in slaList" 
                :key="index"
                :value="sla.value"
                :disabled="sla.disabled ? sla.disabled : false">
                  {{$t(sla.text)}}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          id="factorCubicWeight"
          horizontal
          oncontextmenu="return false;"
          :label-cols="2"
          breakpoint="md"
          :description="$t('peso_volumetrico')"
          :label="$t('Factor peso volumétrico')"
          label-for="slaListInput"
          :invalid-feedback="!isValidFloat(value.factorCubicWeight, false, true)? $t('El factor de peso volumetrico es inválido') : ''"
          :state="isValidFloat(value.factorCubicWeight, false, true)"
          >
          <b-form-input
            id="factorCubicWeightInput"
            :disabled="value.type === 'ADMIN' && !isAdmin ? true : false"
            v-model="value.factorCubicWeight">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-show="!isAdmin">
      <b-col md="12">
        <b-form-group
          horizontal
          oncontextmenu="return false;"
          :label-cols="2"
          :label="$t('Activo')">
          <b-form-checkbox class="mt-1" v-model="value.isActive"></b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-show="value.type === 'ADMIN' && !isAdmin ? true : false">
      <b-col md="12">
        {{$t('Transportadora gestionada por el administrador, los valores no se pueden modificar.')}}
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { ValidationsMixim } from '@/mixims/ValidationsMixim'
import appConfig from '@/config/applicationConfig.js'
import { OrganizationService } from '@/mixims/AdminServiceMixin'

export default {
  mixins: [ValidationsMixim, OrganizationService],
  props: ['value', 'organizationOptions'],
  data () {
    return {
      carrierList: appConfig.CARRIER_LIST.concat([{value: null, text: this.$t('seleccione transportadora'), disabled: true}]),
      slaList: appConfig.SLA_LIST_INPUT ? appConfig.SLA_LIST.map(trn => { return {value: trn, text: trn} }) : appConfig.SLA_LIST.map(trn => { return {value: trn, text: trn} }).concat([{value: null, text: this.$t('seleccione tipo de SLA'), disabled: true}]),
      selectedSeller: null,
      sellerOptions: [],
      isAdmin: this.$store.getters.isAdmin
    }
  },
  methods: {
    handleAccountName (seller) {
      this.selectedSeller = seller
      this.value.accountName = seller.accountName
    },
    removeAccountName () {
      this.value.accountName = null
      this.selectedSeller = null
    }
  },
  computed: {
    showSlaInput () {
      return appConfig.SLA_LIST_INPUT
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.selectedSeller = newVal.accountName && this.organizationOptions ? this.organizationOptions.find(org => org.accountName === newVal.accountName) : null
    }
  }
}
</script>
