<template>
  <div>
    <substatus></substatus>
  </div>
</template>

<script>
import Substatus from '@/components/Substatus'

export default {
  name: 'substate-view',
  components: {
    Substatus
  }
}
</script>