<template>
  <div>
    <add-prices-c-s-v/>
  </div>
</template>

<script>
import AddPricesCSV from '@/components/carrier/AddPricesCSV'

export default {
  name: 'add-prices-csv-view',
  components: {
    AddPricesCSV
  }
}
</script>
