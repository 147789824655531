<template>
  <sku-form :productId="productId" :skuId="skuId"></sku-form>
</template>

<script>
import SkuForm from '../components/catalog/SkuForm.vue'
export default {
  components: { SkuForm },
  data () {
    return {
      productId: null,
      skuId: null
    }
  },
  created () {
    this.productId = Number.parseInt(this.$route.params.productId)
    if (this.$route.params.skuId) {
      this.skuId = Number.parseInt(this.$route.params.skuId)
    }
  }
}
</script>

<style>

</style>