import appConfig from '@/config/applicationConfig.js'

export const ControversyServiceMixim = {
  methods: {
    /**
    * Given the related contriversies of a given order
    * @param orderId
    */
    getControversies (orderId) {
      return this.$http.get(
        `${appConfig.API_END_POINT}/orders/${orderId}/controversies`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    /**
    * Create a controversy for the given order
    * @param orderId
    * @param text the text of the controversy
    */
    createControversy (orderId, text, type) {
      /* Create a crontroversy */
      return this.$http.post(
        `${appConfig.API_END_POINT}/orders/${orderId}/controversies`,
        {text, type},
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    /**
    * Create a note for the given controversy
    * @param orderId
    * @param controversyId
    * @param text the text of the controversy
    */
    addControversyNote (orderId, controversyId, text) {
      return this.$http.post(
        `${appConfig.API_END_POINT}/orders/${orderId}/controversies/${controversyId}/comments`, {text},
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    /**
    * Delete the given controversy
    * @param orderId
    * @param controversyId
    */

    deleteControversy (orderId, controversyId) {
      /* Create a crontroversy */
      return this.$http.delete(
        `${appConfig.API_END_POINT}/orders/${orderId}/controversies/${controversyId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },

    /**
    * Update the given controversy
    * @param orderId
    * @param controversyId
    * @param status can be true or false to set is asctive or not
    */
    updateControversy (orderId, controversyId, status) {
      return this.$http.put(
        `${appConfig.API_END_POINT}/orders/${orderId}/controversies`,
        {'active': status, 'id': controversyId},
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    }
  }
}
