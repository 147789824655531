<template>
  <div class="app flex-row align-items-center not-found-page">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">401</h1>
            <h4 class="pt-3">{{$t('No cuentas con los permisos necesarios.')}}</h4>
           <p class="text-muted">{{$t('Puedes intentar con algunas de estas secciones:')}}</p>
          </div>
          <div class="input-prepend input-group">
            <router-link v-for="link in links" :key="link.name" :to="link.value">
              <b-button variant="info">{{$t(link.name)}}</b-button>
            </router-link>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'Page401',
  data () {
    return {
      links: [
        { name: 'Dashboard', value: '/dashboard' }
      ]
    }
  }
}
</script>
