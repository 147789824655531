<template>
  <b-container fluid class="userView">
    <b-alert :show="showMessage" :variant="variantMessage" fade dismissible @dismised="showMessage = false">
      {{messageText}}
    </b-alert>
    <el-steps :active="active" finish-status="success">
      <el-step title="Rol y permisos"></el-step>
      <el-step title="Información personal"></el-step>
      <el-step title="Finalizar"></el-step>
    </el-steps>
    <div id="steps">
      <role-selector
        v-if="active === 0"
        ref="roleSelector"
        :user="selectedUser"
        :isAvailable="true"
        :state="state"
        :roles="roleOptions"
        :adminWrite="adminWrite">
      </role-selector>
      <user-form
        v-if="active === 1"
        ref="userForm"
        :user="selectedUser"
        :allOrganizations="allOrganizations"
        :state="state"
        :roleOptions="roleOptions"
        :adminWrite="adminWrite">
      </user-form>
      <resume-user-information v-if="active === 2" :user="selectedUser" :roles="roleOptions"></resume-user-information>
    </div>
     <b-button class="mr-2" @click="$router.push({ name: 'Usuarios' })">Cancelar</b-button>
    <b-button class="mr-2" v-show="active > 0 && active < 3" @click="previousStep">Atrás</b-button>
    <b-button variant="success" v-show="active < 2" :disabled="nextStepDisabled" @click="nextStep">Siguiente</b-button>
    <b-button :disabled="saveClicked" variant="success" v-show="active === 2 && adminWrite" @click="handleSave">Finalizar</b-button>
    <b-button class="float-right" variant="primary" v-if="$route.params.id && adminWrite" @click="handleResetPassword()">Reiniciar clave</b-button>
  </b-container>
</template>
<script>
import UserForm from '@/components/user/UserForm.vue'
import RoleSelector from '@/components/user/RoleSelector.vue'
import ResumeUserInformation from '@/components/user/ResumeUserInformation.vue'
import { AdminServiceMixin, RoleService } from '@/mixims/AdminServiceMixin.js'
import { ValidationsMixim } from '@/mixims/ValidationsMixim'
export default {
  components: { UserForm, RoleSelector, ResumeUserInformation },
  mixins: [ AdminServiceMixin, ValidationsMixim, RoleService ],
  data () {
    return {
      roleOptions: [],
      allOrganizations: {},
      active: 0,
      selectedUser: {
        firstName: null,
        lastName: null,
        email: null,
        id: null,
        organizations: this.$store.getters.isAdmin ? [] : this.$store.state.user_data.organizations,
        userType: null,
        roles: []
      },
      adminWrite: this.$store.getters.signedRoles.includes('ADMIN_WRITE'),
      saveClicked: false,
      messageText: '',
      showMessage: false,
      variantMessage: 'success',
      isLoadingRol: true,
      isLoadingUser: true
    }
  },
  computed: {
    selectedIsMarketAdmin () {
      if (this.selectedUser.roles.length > 0) {
        const id = this.selectedUser.roles[0].id ? this.selectedUser.roles[0].id : this.selectedUser.roles[0]
        const userRole = this.roleOptions.find(r => r.id === id)
        return userRole ? userRole.userType === 'ADMIN' : false
      }
      return false
    },
    state () {
      return {
        firstName: this.isLoadingRol || this.isLoadingUser ||  this.isValidText(this.selectedUser.firstName, true, 20),
        lastName:  this.isLoadingRol || this.isLoadingUser ||  this.isValidText(this.selectedUser.lastName, true, 20),
        email:     this.isLoadingRol || this.isLoadingUser ||  Boolean(this.selectedUser.email) && this.validateEmail(this.selectedUser.email, true),
        sellers:   this.isLoadingRol || this.isLoadingUser ||  (this.alertInvalidFeedBack() === ''),
        userType:  this.isLoadingRol || this.isLoadingUser ||  this.selectedUser.userType === 'ADMIN' || this.selectedUser.userType === 'SELLER',
        roles:     this.isLoadingRol || this.isLoadingUser ||  this.selectedUser.roles.length > 0 && this.roleOptions.find(r => r.id === this.selectedUser.roles[0] || r.id === this.selectedUser.roles[0].id) !== undefined
      }
    },
    nextStepDisabled () {
      if (this.active === 0) {
        return !(this.state.userType && this.state.roles)
      }
      if (this.active === 1 && this.state.userType) {
        return !(this.state.firstName && this.state.lastName && this.state.email)
      }
      return Object.values(this.state).includes(false)
    }
  },
  methods: {
    nextStep () {
      if (this.active++ > 2) this.active = 0
    },
    previousStep () {
      if (this.active-- < 0) this.active = 0
    },
    handleSave () {
      this.active++
      this.saveClicked = true
      let action = this.selectedUser.id ? this.updateUser : this.createUser
      this.selectedUser.roles[0] = this.roleOptions.find(r => r.id === this.selectedUser.roles[0])
      try {
        action(this.selectedUser).then(() => {
          this.messageText = `Usuario ${this.selectedUser.id ? 'actualizado' : 'creado'} de manera exitosa`
          this.showMessage = true
          this.variantMessage = 'success'
          setTimeout(() => this.$router.push({ name: 'Usuarios' }), 2000)
        }).catch(er => {
          this.messageText = er.body.message || 'Ocurrió un error al crear el usuario'
          this.showMessage = true
          this.variantMessage = 'danger'
          this.saveClicked = false
          this.active--
        })
      } catch (e) {
        console.log(e.message)
        this.messageText = 'Ha ocurrido un error en el módulo de Administración'
        this.showMessage = true
        this.variantMessage = 'danger'
        this.saveClicked = false
        this.active--
      }
    },
    alertInvalidFeedBack () {
      const commonAlert = 'Se debe seleccionar'
      return !this.selectedUser.organizations.length ? `${commonAlert} al menos un Seller` : !this.selectedIsMarketAdmin && this.selectedUser.organizations.length > 1 ? `${commonAlert} solo un Seller` : ''
    },
    handleResetPassword () {
      try {
        this.resetPassword(this.selectedUser.id).then(() => {
          this.messageText = 'Contraseña reseteada de manera exitosa'
          this.showMessage = true
          this.variantMessage = 'success'
          if (this.active < 2) setTimeout(() => this.$router.push({ name: 'Usuarios' }), 2000)
        }).catch(er => {
          this.messageText = er.body.message || 'Ocurrió un error al reestablecer una contraseña'
          this.showMessage = true
          this.variantMessage = 'danger'
        })
      } catch (e) {
        console.log(e.message)
        this.messageText = 'Ha ocurrido un error en el módulo de Administración'
        this.showMessage = true
        this.variantMessage = 'danger'
      }
    },
    filterRoles (roles) {
      if (this.$store.getters.userData.userType === 'SELLER' && this.$store.getters.signedRoles.indexOf('ADMIN_WRITE') !== -1) {
        this.selectedUser.userType = 'SELLER'
        this.selectedUser.roles.push(this.$store.getters.userData.roles[0])
        this.roleOptions = roles.filter(r => r.id === this.$store.getters.userData.roles[0].id)
        console.log('en if')
      } else {
        this.roleOptions = roles
      }
    }
  },
  mounted () {
    this.getRoles().then(
      response => {
        this.filterRoles(response.body)
        this.isLoadingRol = false
      })
      .catch(error => {
        console.error('Error al cargar roles:', error);
        this.isLoadingRol = false;
      });
    const userId = this.$route.params.id;
    if (userId) {
      this.getUser(userId)
        .then(response => {
          this.selectedUser = response.body;
          this.isLoadingUser = false;
        })
        .catch(error => {
          console.error('Error loading user:', error);
          this.isLoadingUser = false;
        });
    }
  }
}
</script>
<style lang="scss" scoped>
.userView {
  #steps {
    margin-top: 20px;
    height: auto;
  }
}
</style>