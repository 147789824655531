<template>
<div>
    <ul>
      <v-treeview-item class="v-treeview-item" v-for="item in value" :key="item.id" 
        :model="item" :treeTypes="treeTypes" :openAll="openAll"
        @selected="selected"></v-treeview-item>            
    </ul>
  </div>
</template>

<script>
import VTreeviewItem from './VTreeviewItem.vue'

export default {
  props: ['value', 'treeTypes', 'openAll'],
  name: 'v-treeview',
  created () {},
  methods: {
    selected (node) {
      this.$emit('selected', node)
    }
  },
  components: {
    VTreeviewItem
  }
}
</script>

<style scoped>
ul {
  position: relative;
}
</style>


