<template>
<div>
  <multiselect
    :value="value"
    :label="label !== undefined ? label : 'name'"
    track-by="id"
    open-direction="bottom"
    :options="items"
    :multiple="multiple !== undefined ? multiple : true"
    :searchable="true"
    :loading="isLoading"
    :internal-search="false"
    :clear-on-select="true"
    :close-on-select="true"
    :options-limit="300"
    :limit="100"
    :placeholder="$t('Ingrese el texto para buscar')"
    :disabled="disabled"
    :limit-text="limitText"
    :max-height="600"
    :selectLabel="selectLabel !== undefined ? selectLabel : $t('Enter para agregar marca')"
    :show-no-results="true"
    :showPointer="true"
    :hideSelected="true"
    @input="onInput"
    :max="maxElements"
    :hide-selected="true"
    @search-change="asyncFind"
    @select="handleSelect"
    ref="autocomplete">
    <template slot="tag" slot-scope="{ option, remove }">
      <span class="custom__tag"><span>{{ label !== undefined ? option[label] : option.name }}</span>
        <span
          class="custom__remove" @click="remove(option)"
          v-b-tooltip.hover
          :title="$t('Eliminar')"
        >
          <i class="text-danger fas fa-minus-circle"></i>
        </span>
      </span>
    </template>
     <template slot="beforeList" v-if="showSelectAll && seletecAll">
      <section class="select-all" @click.prevent="isAllSelected ? deselectAll() : selectAll()" v-if="showSelectAll && seletecAll">
        <b-form-checkbox name="check-button" switch class="select-all--checkbox" :checked="isAllSelected">
        </b-form-checkbox>
        <span class="select-all--title" v-if="!isAllSelected">{{$t('Seleccionar todo')}}</span>
        <span class="select-all--title" v-if="isAllSelected">{{$t('Deseleccionar todo')}}</span>
      </section>
    </template>
    <template slot="maxElements">{{$t('No se pueden seleccionar mas elementos')}}</template>
    <span slot="noResult">{{$t('No hay elementos para mostrar')}}</span>
  </multiselect>
 </div>
</template>

<script>

// Mixims
import { ProductServiceMixim } from '@/mixims/ProductServiceMixim'
import Multiselect from 'vue-multiselect'

export default {
  mixins: [ProductServiceMixim],
  props: [
    'disabled',
    'value',
    'maxElements',
    'provider',
    'showSelectAll',
    'seletecAll',
    'selectLabel',
    'label',
    'multiple'
  ],
  components: {
    Multiselect
  },
  data () {
    return {
      items: [],
      isLoading: false,
      isAllSelected: false
    }
  },
  watch: {
    value (vl) {
      this.handleSelect()
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    },
    limitText (count) {
      return this.$t('y') + ` ${count} ` + this.$t('otros items')
    },
    asyncFind (text) {
      this.isLoading = true
      this.provider(text).then(response => {
        this.items = response.body
        this.isLoading = false
      })
    },
    selectAll () {
      this.$refs.autocomplete.deactivate()
      this.$emit('input', this.items)
    },
    deselectAll () {
      this.$refs.autocomplete.deactivate()
      this.$emit('input', [])
    },
    handleSelect () {
      this.isAllSelected = Array.isArray(this.value) ? this.value.length === this.items.length : false
    }
  },
  mounted () {
    this.isLoading = true
    this.provider('').then(response => {
      this.items = response.body
      this.isLoading = false
    })
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
  .multiselect-wrapper{
    &.no-height{
      .multiselect__content-wrapper{
        height: auto !important;
        border-color:transparent;
      }
    }
  }
  .select-all{
    padding: 12px;
    display: block;
    padding: 12px;
    min-height: 40px;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
    &:hover{
      background-color: #f2f2f2;
    }
    .select-all--checkbox.custom-control{
      padding: 0px 10px;
      margin: 0px;
    }
    .select-all--title{
      margin-left: 10px;
    }
  }
  .custom__tag {
    border-radius: 3px;
    background: #DDD;
    padding: 2px;
    margin-right: 5px;
  }

  .custom__remove {
    margin-left: 2px;
    margin-right: 2px;
    font-size: 12px;
  }
</style>
