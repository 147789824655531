import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    role: null,
    category_data: null,
    access_token: null,
    organization: null,
    brand_data: null,
    carrier_data: null,
    user_data: null,
    notification: null,
    current_report_tab: 0,
    brands: [],
    notifications: {
      actives: 0,
      items: []
    },
    marketplaces: []
  },
  mutations: {
    setMarketplaces: (state, data) => {
      state.marketplaces = data
    },
    setCurrentReportTab: (state, data) => {
      state.current_report_tab = data
    },
    setRole: (state, data) => {
      state.role = data
    },
    setNotifications: (state, data) => {
      state.notifications.items = data
    },
    pushNotifications: (state, data) => {
      state.notifications.items = state.notifications.items.concat(data)
    },
    setNewNotifications: (state, data) => {
      state.notifications.actives = data
    },
    markAsReaded: (state, data) => {
      for (let index = 0; index < state.notifications.items.length; index++) {
        if (state.notifications.items[index].id === data) {
          state.notifications.items[index].read = true
        }
      }
    },
    setLoginData: (state, data) => {
      state.access_token = data.access_token
      state.organization = data.organization
    },
    setUserData: (state, data) => {
      state.user_data = data
    },
    logout: (state) => {
      state.category_data = null
      state.access_token = null
      state.organization = null
      state.brand_data = null
      state.carrier_data = null
      state.user_data = null
      state.current_report_tab = 0
    },
    setCategoryData: (state, data) => {
      state.category_data = data
      state.category_data_plain = []
      state._category_data_plain = []
      let createNodeList = function (items) {
        return items.map(item => {
          if (item.children && item.children.length > 0) {
            state.category_data_plain.push(item.namePath)
            state._category_data_plain.push({id: item.id, namePath: item.namePath})
            createNodeList(item.children)
          } else {
            state._category_data_plain.push({id: item.id, namePath: item.namePath})
            state.category_data_plain.push(item.namePath)
          }
        })
      }
      createNodeList(data)
    },
    setBrandData: (state, data) => {
      state.brand_data = data
    },
    setCarrierData: (state, data) => {
      state.carrier_data = data
    },
    setImputabilityCauses: (state, data) => {
      let drowDownData = []
      drowDownData.push(
        {
          'text': 'Seleccione un motivo de cancelación',
          'value': null,
          'disabled': true
        }
      )
      data.forEach(
        (item) => {
          drowDownData.push(
            {
              'text': item.description,
              'value': item.id
            }
          )
        }
      )
      state.imputability_causes = drowDownData
    },
    setNotificationData: (state, data) => {
      state.notification = data
    },
    addBrand: (state, data) => {
      state.brands.push(data)
    }
  },
  actions: {
    setMarketplaces (context, data) {
      context.commit('setMarketplaces', data)
    },
    setRole: (context, data) => {
      context.commit('setRole', data)
    },
    setNotifications: (context, data) => {
      context.commit('setNotifications', data)
    },
    setCurrentReportTab: (context, data) => {
      context.commit('setCurrentReportTab', data)
    },
    pushNotifications: (context, data) => {
      context.commit('pushNotifications', data)
    },
    setNewNotifications: (context, data) => {
      context.commit('setNewNotifications', data)
    },
    markAsReaded: (context, data) => {
      context.commit('markAsReaded', data)
    },
    /* Set the login data after the login request was performed */
    setLoginData (context, loginData) {
      context.commit('setLoginData', loginData)
    },
    setUserData (context, userData) {
      context.commit('setUserData', userData)
    },
    setCategoryData (context, categoryData) {
      context.commit('setCategoryData', categoryData)
    },
    setBrandData (context, brandData) {
      context.commit('setBrandData', brandData)
    },
    setCarrierData (context, carrierData) {
      context.commit('setCarrierData', carrierData)
    },
    setImputabilityCauses (context, imputabilityCauses) {
      context.commit('setImputabilityCauses', imputabilityCauses)
    },
    setNotificationData (context, notificationData) {
      context.commit('setNotificationData', notificationData)
    },
    logout (context) {
      context.commit('logout')
    },
    addBrand (context, data) {
      context.commit('addBrand', data)
    }
  },
  getters: {
    marketplaces: (state) => {
      return state.marketplaces
    },
    marketplacesCombo: (state) => {
      let comboData = []
      for (let i = 0; i < state.marketplaces.length; i++) {
        comboData.push({'value': state.marketplaces[i].id, 'text': state.marketplaces[i].name})
      }
      return comboData
    },
    role: (state) => {
      return state.role
    },
    notifications: (state) => {
      return state.notifications
    },
    isLogged: state => {
      return sessionStorage.getItem('vue-session-key') !== null && sessionStorage.getItem('vue-session-key') !== '{}'
    },

    userWelcome: (state) => {
      if (state.user_data) {
        return `Bienvenido ${state.user_data.firstName} ${state.user_data.lastName}`
      }
      return ''
    },

    isAdmin: (state) => {
      if (!state.user_data) return false
      let admin = state.user_data.userType === 'ADMIN'
      return !!admin
    },
    isSeller: (state) => {
      if (!state.user_data) return false
      let seller = state.user_data.userType === 'SELLER'
      return !!seller
    },
    hasPermition: (state) => (name) => {
      if (!state.user_data) return false
      let permission = state.user_data.roles.find(
        (role) => {
          return role.roleName === name
        }
      )
      return !!permission
    },
    categories: (state) => {
      return state.category_data
    },
    categoriesPlain: (state) => {
      return state.category_data_plain
    },
    _categoriesPlain: (state) => {
      return state._category_data_plain
    },
    brands: (state) => {
      return state.brand_data
    },
    carriers: (state) => {
      return state.carrier_data
    },
    getCurrentReportTab: (state) => {
      return state.current_report_tab
    },
    imputabilityCauses: (state) => {
      return state.imputability_causes
    },
    organizations: (state) => {
      let orgs = {}
      state.user_data.organizations.forEach((element) => {
        orgs[element.accountName] = element.name
      })
      return orgs
    },
    signedRoles: state => {
      if(state.user_data && state.user_data.roles && state.user_data.roles[0] && state.user_data.roles[0].permissions)
        return state.user_data.roles[0].permissions.map(r => r.name)
      else []
    },
    notification: (state) => {
      return state.notification
    },
    cacheBrands: (state) => {
      return state.brands
    },
    userData: state => {
      return state.user_data
    }
  }
})
