import appConfig from '@/config/applicationConfig.js'

export const MarketplaceServiceMixim = {
  data () {
    return {
      MARKETPLACE_ENDPOINT: `${appConfig.API_END_POINT}/admin/marketplaces`,
      HEADER: {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }
    }
  },
  methods: {
    getSearchMarketplaces (text) {
      let endpoint = new URL(this.MARKETPLACE_ENDPOINT)
      endpoint.searchParams.append('text', text)
      return this.$http.get(endpoint.href, this.HEADER)
    },
    getMarketplaces (from, size) {
      let endpoint = new URL(this.MARKETPLACE_ENDPOINT)
      endpoint.searchParams.append('from', from)
      endpoint.searchParams.append('size', size)
      endpoint.searchParams.append('sortBy', 'name.keyword')
      endpoint.searchParams.append('sortOrder', 'asc')
      return this.$http.get(endpoint.href, this.HEADER)
    }
    // saveMarketplace (marketplace) {
    //   console.log(marketplace)
    //   if (!marketplace.id) {
    //     marketplace.id = marketplace.accountName
    //     return this.$http.post(this.MARKETPLACE_ENDPOINT, marketplace, this.HEADER)
    //   }
    //   return this.$http.put(`${this.MARKETPLACE_ENDPOINT}/${marketplace.id}`, marketplace, this.HEADER)
    // }
  }
}
