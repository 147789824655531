import appConfig from '@/config/applicationConfig.js'

export const NotificationServiceMixim = {
  methods: {
    getNotifications (params, unread) {
      let from = params.from || 0
      let size = params.size || 10
      let url = ''
      if (unread) {
        url = `${appConfig.API_END_POINT}/notifications?from=0&size=1&mode=INBOX&read=false`
      } else {
        url = `${appConfig.API_END_POINT}/notifications?from=${from}&size=${size}&mode=INBOX&sortBy=creationDate&sortOrder=desc`
      }
      return this.$http.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
    getNotificationsNotices (tableInfo, filters) {
      let url = new URL(`${appConfig.API_END_POINT}/notifications`)
      let params = {}
      if (tableInfo) {
        params.sortBy = tableInfo.sortBy
        params.sortOrder = tableInfo.sortDesc ? 'desc' : 'asc'
        params.from = tableInfo.currentPage - 1
        params.size = tableInfo.perPage
        params.type = 'MESSAGE_NOTIFICATION'
        params.isActive = false
      }

      if (filters) {
        Object.entries(filters).forEach(([key, value]) => {
          params[key] = value
        })
      }

      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null && params[key] !== undefined) {
            url.searchParams.append(key, params[key])
          }
        }
      )
      return this.$http.get(
        url.href,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
    markNotificationRead (notificationId) {
      return this.$http.post(
        `${appConfig.API_END_POINT}/notifications/${notificationId}/read`, '',
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
    createNotification (notification) {
      return this.$http.post(
        `${appConfig.API_END_POINT}/notifications`, notification,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
    editNotification (notification) {
      console.log(notification.id)
      return this.$http.put(
        `${appConfig.API_END_POINT}/notifications/${notification.id}`, notification,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    },
    deleteNotification (notificationId) {
      return this.$http.delete(
        `${appConfig.API_END_POINT}/notifications/${notificationId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }
      )
    }
  }
}
