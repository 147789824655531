import appConfig from '@/config/applicationConfig.js'

export const UserServiceMixim = {
  methods: {
    /**
    * Loguin the user
    * @param userName
    * @param password
    */
    loginRequest (userName, password) {
      return this.$http.post(`${appConfig.API_END_POINT}/oauth/token?grant_type=password&username=${encodeURIComponent(userName)}&password=${password}`, '', {
        headers: {
          Authorization: `Basic ${window.btoa(appConfig.BASIC_AUTENTICATION_OAUTH_USER + ':' + appConfig.BASIC_AUTENTICATION_OAUTH_PASS)}`
        }
      })
    },

    /**
    * Get the user data given the bearer token
    */
    getUserData () {
      return this.$http.get(`${appConfig.API_END_POINT}/admin/loggeduser`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      })
    },

    /**
    * Get the carrier list
    */
    getCarrierData () {
      return this.$http.get(`${appConfig.API_END_POINT}/carriers`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      })
    },

    logout () {
      this.$session.destroy()
      this.$store.dispatch('logout')
      this.$router.replace('/pages/login')
    }
  }
}
