<template>
  <aside class="aside-menu">

  </aside>
</template>

<script>
export default {
  name: 'asidesc'
}
</script>
