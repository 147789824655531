<template>
  <div>
    <h5 class="mb-3">Revise la información ingresada</h5>
    <b-row>
      <b-col cols="2">
        <p class="label">Nombre</p>
      </b-col>
      <b-col cols="10">
        <p>{{user.firstName}}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <p class="label">Apellido</p>
      </b-col>
      <b-col cols="10">
        <p>{{user.lastName}}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <p class="label">Correo</p>
      </b-col>
      <b-col cols="10">
        <p>{{user.email}}</p>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="2">
        <p class="label">Vendedores</p>
      </b-col>
      <b-col cols="10">
        <div>
          <b-badge
            class="badgeSeller"
            v-for="seller in user.organizations"
            :key="seller.id"
            variant="secondary">
            {{seller.accountName}}
          </b-badge>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <p class="label">Tipo de usuario</p>
      </b-col>
      <b-col cols="10">
        <p>{{user.userType}}</p>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="2">
        <p class="label">Rol</p>
      </b-col>
      <b-col cols="10">
          <b-badge
            class="badgeSeller"
            variant="secondary">
            {{roles.find(r => r.id === user.roles[0]).roleName}}
          </b-badge>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: ['user', 'roles']
}
</script>
<style lang="scss" scoped>
.label {
  font-weight: bold;
  font-size: 16;
}
.badgeSeller {
  margin: 5px;
  border-radius: 7px;
  padding: 10px;
}
</style>