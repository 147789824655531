<template>
<div>
  <b-modal
    v-model="hasToChoose"
    title="Por favor seleccione que vista desea utilizar"
    :hide-footer="true"
    :header-class="'modalTitle'"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    header-bg-variant="primary">
    <h6 class="text-center">La "Vista Antigua" estará disponible hasta el: {{formatDate(lastDateAvailable)}} </h6>
    <div class="buttonContainer">
      <b-btn @click="selectNewView">Vista Nueva</b-btn>
      <b-btn @click="selectOldView">Vista Antigua</b-btn>
    </div>
  </b-modal>
  <new-product v-if="newView"/>
  <product-detail v-if="oldView" :productId="null"/>
</div>
</template>

<script>
import NewProduct from '../components/catalog/NewProduct.vue'
import ProductDetail from '../components/catalog/ProductDetail.vue'
import appConfig from '@/config/applicationConfig.js'
export default {
  components: {
    NewProduct,
    ProductDetail
  },
  data () {
    return {
      hasToChoose: true,
      newView: false,
      oldView: false,
      oldViewStartDate: '',
      lastDateAvailable: ''
    }
  },
  methods: {
    selectNewView () {
      this.newView = true
      this.oldView = false
      this.hasToChoose = false
    },
    selectOldView () {
      this.oldView = true
      this.newView = false
      this.hasToChoose = false
    },
    checkDate () {
      const oldViewStartDateSplit = appConfig.OLD_VIEW_START_DATE ? appConfig.OLD_VIEW_START_DATE.split('/') : null
      const today = new Date()
      if (oldViewStartDateSplit) {
        this.oldViewStartDate = new Date(oldViewStartDateSplit[2], oldViewStartDateSplit[1]-1, oldViewStartDateSplit[0])
        this.lastDateAvailable = this.oldViewStartDate.setDate(this.oldViewStartDate.getDate() + 30)
        const difference = Math.abs(today.getTime() - this.oldViewStartDate.getTime())
        const totalDays = difference / (1000 * 60 * 60 * 24)
        if (totalDays >= 31) {
          this.hasToChoose = false
          this.newView = true
          this.oldView = false
        }
      } else {
        this.hasToChoose = false
        this.newView = true
        this.oldView = false
      }
    },
    formatDate (date) {
      const selectedDate = new Date(date)
      return new Intl.DateTimeFormat('cl-ES', { dateStyle: 'long'}).format(selectedDate)
    }
  },
  created () {
    this.checkDate()
  }
}
</script>
<style lang="scss" scoped>
.buttonContainer {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
</style>
