<template>
  <div>
    <div v-html="errorText" v-show="errorText !== ''" class="ml-3 alert alert-danger" role="alert">
     {{errorText}}
    </div>
    <div v-show="success" class="ml-3 alert alert-success" role="alert">
      {{$t('Las liquidaciones fueron cargadas con éxito')}}
    </div>
    <b-col md="8">
      <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Seleccionar Archivo CSV')">
        <h5>{{$t('Archivo seleccionado')}}: {{csvFile && csvFile.name}}</h5>
        <h6>{{$t('Cantidad pedidos liquidados')}}: {{ordersCount}}</h6>
        <b-form-file
          class="mt-3"
          ref="fileinput"
          :no-drop="true"
          @click.native="$refs.fileinput.reset()"
          accept=".csv"
          v-on:change="onFileChanged"
          v-model="csvFile"
          :class="$i18n.locale"
          :placeholder="$t('Archivo CSV')">
        </b-form-file>
        <b-button :disabled="!Boolean(csvFile) || errorText !== ''" class="mt-5" v-on:click="uploadFile" variant="primary">{{$t('Subir CSV')}}</b-button>
        <b-button class="mt-5 float-right" v-on:click="downloadFormatCSVExampleFile" variant="primary" v-if="isAdmin">{{$t('Obtener formato de planilla')}}</b-button>
      </b-card>
    </b-col>
    <b-col md="12">
      <b-card  header-bg-variant="primary" border-variant="primary" :header="$t('Previsualizacion Archivo CSV')">
        <moon-loader :loading="inProgress" color="#000" size="30px"></moon-loader>
        <b-table v-show="!inProgress"
          :items="csvElements"
          hover
          :fields="csvFields"
          :small="true"
          show-empty
          striped
          :empty-text="$t('No hay liquidaciones para mostrar')"
          stacked="md"
          foot-clone
          >
        </b-table>
      </b-card>
    </b-col>
  
  </div>
  </template>
  
  <script>
  import Vue from 'vue'
  import appConfig from '@/config/applicationConfig.js'
  import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
  import Papa from 'papaparse'
  
  export default {
    name: 'liquidations-component-view',
    components: {
      MoonLoader
    },
    methods: {
      resetFile () {
        this.$refs.fileinput.reset()
      },
      uploadFile (evt) {
        this.inProgress = true
        this.errorText = ''
        this.success = false
        let endPoint = `${appConfig.API_END_POINT}/orders/liquidations?delimiter=${this.delimiter}`
        let formData = new FormData()
        formData.append('file', this.csvFile)
        return this.$http.post(endPoint, formData, {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }).then(
          () => {
            this.inProgress = false
            this.success = true
          },
          (error) => {
            this.inProgress = false
            if (error && error.body && error.body.message) {
              this.errorText = error.body.message === 'No value present' ? this.$t('El número de la orden es incorrecto') : error.body.message
            } else {
              this.errorText = this.$t('Se produjo un error al procesar el archivo CSV.')
            }
          }
        )
      },
      onFileChanged (evt) {
        const self = this
        this.errorText = ''
        this.success = false
        this.csvElements = []
        var file = evt.target.files[0]
        if (!file) {
          return
        }
  
        var reader = new FileReader()
        reader.onload = (evt) => {
          try {
            let rowError = false
            let parsedData = Papa.parse(evt.target.result.trim(), {skipEmptyLines: true, encoding: 'UTF-8'})
  
            if (parsedData.errors.length > 0) {
              self.errorText = this.$t('El archivo tiene un formato que no es soportado')
              self.$refs.fileinput.reset()
              return
            }
  
            this.delimiter = parsedData.meta.delimiter
  
            let header = parsedData.data.shift()
            if (header.length !== 4) {
              self.errorText += this.$t('La cabecera del archivo CSV debe tener 4 columnas.')
              self.errorText += '<ul>'
              self.errorText += '<li>' + this.$t("Verificar que la cabecera es 'OrderId'; 'Date'; 'Value'; 'InvoiceNumber'.") + '</li>'
              self.errorText += '</ul>'
              return
            }
            self.ordersCount = parsedData.data.length
            parsedData.data.forEach((elem, index) => {
              rowError = false
              let newRowElem = {
                'orderId': elem[0],
                'date': elem[1],
                'ammount': elem[2],
                'invoiceNumber': elem[3],
                '_cellVariants': null
              }
              if (elem.length !== 4) {
                rowError = true
                newRowElem = {'_cellVariants': null}
                newRowElem.orderId = this.$t('Faltan') + ` ${4 - elem.length} ` + this.$t('campos')
              } else {
                newRowElem._cellVariants = {}
                let myRegExp = /^\d{4}-\d{2}-\d{2}$/
                if (!elem[1].match(myRegExp)) {
                  rowError = true
                  newRowElem._cellVariants.date = 'danger'
                }
                if (isNaN(elem[2])) {
                  rowError = true
                  newRowElem._cellVariants.ammount = 'danger'
                }
              }
              if (rowError) {
                self.errorText = this.$t('Hay filas con errores en el archivo CSV')
                self.errorText += '<ul>'
                self.errorText += '<li>' + this.$t('Verifique que las filas tengan la misma cantidad de items que la cabecera(4).') + '</li>'
                self.errorText += '<li>' + this.$t('Verifique que las fechas tengan formato YYYY-MM-DD.') + '</li>'
                self.errorText += '<li>' + this.$t('Verifique que los valores numericos sean válidos.') + '</li>'
                self.errorText += '</ul>'
                if (newRowElem._cellVariants === null) {
                  newRowElem._rowVariant = 'danger'
                }
              } else {
                newRowElem._rowVariant = 'success'
              }
  
              self.csvElements.push(newRowElem)
            })
          } catch (err) {
            this.errorText = this.$t('El archivo CSV tiene fallas')
          }
        }
        reader.readAsText(file, 'UTF-8')
      },
      _downloadFileExec (endPoint, fileName) {
        Vue.http.options.emulateHTTP = true
        return this.$http.get(
          endPoint,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.access_token}`,
              Accept: 'application/octet-stream; charset=ISO-8859-1'
            },
            responseType: 'arraybuffer'
          }
        ).then((csvFile) => {
          let headers = csvFile.headers
          var blob = new Blob([csvFile.body], {
            type: { type: headers.map['content-type'] }
          })
          const blobURL = window.URL.createObjectURL(blob)
          const tempLink = document.createElement('a')
          tempLink.style.display = 'none'
          tempLink.href = blobURL
          tempLink.setAttribute('download', fileName)
          document.body.appendChild(tempLink)
          tempLink.click()
          document.body.removeChild(tempLink)
        })
      },
      downloadFormatCSVExampleFile () {
        let endPoint = `${appConfig.API_END_POINT}/orders/liquidations/downloadcsv`
        let fileName = `${this.$t('modelo-liquidacion')}.csv`
        return this._downloadFileExec(endPoint, fileName)
      }
    },
    data () {
      return {
        success: false,
        inProgress: false,
        ordersCount: 0,
        errorText: '',
        csvFile: null,
        isAdmin: this.$store.getters.isAdmin,
        delimiter: null,
        csvElements: [],
        csvFields: [
          { key: 'orderId', label: this.$t('Id Orden') },
          { key: 'date', label: this.$t('Fecha') },
          { key: 'ammount', label: this.$t('Monto') },
          { key: 'invoiceNumber', label: this.$t('Factura') }
        ]
      }
    }
  }
  </script>
  