<template>
  <div>
    <template>
      <b-form-input
        v-if="!button"
        id="categoryFormatterHelp"
        oncontextmenu="return false;"
        @click.native="openCategoryModal"
        :disabled="!isEditable"
        :value="text"></b-form-input>
    </template>

    <template v-if="button">
      <b-button  @click="openCategoryModal" :class="boostrapClasses" variant="primary">{{buttonTitle? buttonTitle : $t('Categorías')}}</b-button>
    </template>

    <b-modal 
      @show="resetModal" 
      ref="category" 
      header-bg-variant="primary" 
      centered 
      size="lg" 
      :title="$t('Seleccione categoría')"
      o-close-on-backdrop="true"
      :cancel-title="$t('Cerrar')"
      @ok="performOk"
      :ok-title="$t('Seleccionar')">
      <template>
        <b-row>
          <b-col md="4">
            <b-form-group 
                id="buscadorInput"
                :label-cols="4"
                breakpoint="md"
                :label="$t('Buscar categoría')"
                :description="searching? $t('Espere...'): $t('Se mostraran las categorías que contengan el texto buscado')"
                label-for="buscador">
                  <b-form-input
                    id="buscador"
                    @input="performSearch"
                    :disabled="searching"
                    v-model="searchText"  
                    :placeholder="$t('Ingrese la categoría a buscar')"
                    name=buscador ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group 
                id="categoryInput"
                :label-cols="4"
                breakpoint="md"
                :label="$t('Categorías')"
                label-for="categoriesSelect">
                <b-form-select 
                  :options="categorySelectOptions"
                  @change="onDropdownSelect" 
                  v-model="selectedNode">
                </b-form-select>
              </b-form-group>
            </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h4>{{$t('Árbol de categorías')}}</h4>
            <v-treeview 
              v-model="treeData"
              :treeTypes="treeTypes"
              @selected="select"
              :openAll="false"></v-treeview>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>

</template>
<script>

import VTreeview from '@/components/common/v-treeview/VTreeview.vue'
import {ProductServiceMixim} from '@/mixims/ProductServiceMixim.js'

export default {
  name: 'category-tree',
  props: ['editable', 'categorySelected', 'button', 'buttonTitle', 'boostrapClasses', 'rootIneligible'],
  components: {
    VTreeview
  },
  mixins: [ProductServiceMixim],
  data () {
    return {
      categorySelectOptions: [],
      categoryAllSelectOptions: [],
      searching: false,
      searchText: '',
      treeTypes: [
        {
          type: '#',
          max_children: 20,
          max_depth: 4,
          valid_children: [ 'CATEGORY_CONTAINER', 'CATEGORY_ITEM' ]
        },
        {
          type: 'CATEGORY_CONTAINER',
          icon: '',
          valid_children: ['CATEGORY_CONTAINER', 'CATEGORY_ITEM']
        },
        {
          type: 'CATEGORY_ITEM',
          icon: '',
          valid_children: []
        }
      ],
      name: '',
      selectedNode: null
    }
  },
  computed: {
    treeData () {
      return this.createNodeList([{
        id: null,
        text: this.$t('Raiz'),
        name: this.$t('Raiz'),
        namePath: '/',
        type: this.rootIneligible ? 'CATEGORY_CONTAINER' : 'CATEGORY_ITEM',
        count: 0,
        children: this.$store.getters.categories
      }])
    },
    text () {
      return this.categorySelected ? this.categorySelected.namePath : ''
    },
    isEditable () {
      return this.editable === null || this.editable === undefined ? true : this.editable
    }
  },
  methods: {
    performSearch (text) {
      if (text === null) {
        return
      }
      this.searching = true
      this.categorySelectOptions.splice(0, this.categorySelectOptions.length)
      this.categorySelectOptions.push(
        {
          text: this.$t('Seleccione una categoría'),
          value: null,
          disabled: true
        }
      )
      this.categoryAllSelectOptions.forEach(categoryOption => {
        if (categoryOption.text && categoryOption.text.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          this.categorySelectOptions.push(categoryOption)
        }
      })
      this.searching = false
    },
    openCategoryModal () {
      this.$refs.category.show()
    },
    resetModal () {
      this.selectedNode = null
      this.searchText = null
      this.categorySelectOptions.splice(0, this.categorySelectOptions.length)
    },
    performOk () {
      if (this.selectedNode) {
        this.$emit('onCategorySelected', this.selectedNode)
      }
    },
    onDropdownSelect (elem) {
      this.$emit('onCategorySelected', {id: elem.id, name: elem.text, namePath: elem.namePath})
      this.$refs.category.hide()
    },
    select (elem) {
      if (elem.model.type === 'CATEGORY_ITEM') {
        this.$emit('onCategorySelected', {id: elem.model.id, name: elem.model.text, namePath: elem.model.namePath})
        this.$refs.category.hide()
      }
    },
    createNodeList (items) {
      return items.map(item => {
        this.categoryAllSelectOptions.push(
          {
            text: item.namePath,
            value: {
              id: item.id,
              text: item.name,
              namePath: item.namePath
            }
          }
        )
        let newItem = {
          id: item.id,
          text: item.name,
          namePath: item.namePath,
          type: 'CATEGORY_ITEM',
          count: 0,
          children: []
        }
        if (item.children && item.children.length > 0) {
          return {...newItem, children: this.createNodeList(item.children)}
        } else {
          return newItem
        }
      })
    }
  }
}
</script>
