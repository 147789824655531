<template>
<b-card header-bg-variant="primary" border-variant="primary" :header="headerCard">
  <b-row>
    <b-col>
      <b-form-group
        :label-cols="2"
        :invalid-feedback="!state.name ? $t('Ingrese un nombre') : ''"
        :state="state.name"
        :label="$t('Nombre')">
        <b-form-input v-model="pickupPoint.name"></b-form-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        :label-cols="2"
        :label="$t('Descripción')">
        <b-form-input v-model="pickupPoint.description"></b-form-input>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-form-group
        :label-cols="2"
        :label="$t('Instrucciones')">
        <b-form-input v-model="pickupPoint.instructions"></b-form-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        :label-cols="2"
        :label="$t('Activo')">
        <b-form-radio-group
          type="text"
          id="isActiveFilter"
          v-model="pickupPoint.isActive">
          <b-form-radio :value="true">{{$t('Si')}}</b-form-radio>
          <b-form-radio :value="false">{{$t('No')}}</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-form-group
        :label-cols="2"
        :label="$t('Dirección para mostrar')">
        <b-form-input v-model="pickupPoint.formattedAddress"></b-form-input>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row class="mt-2">
    <b-col>
      <b-form-group
        :invalid-feedback="!state.postalCode ? $t('Ingrese un código postal') : ''"
        :state="state.postalCode"
        :label-cols="2"
        :label="$t('Código Postal')">
        <b-form-input v-model="pickupPoint.address.postalCode"></b-form-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        :invalid-feedback="!state.state ? $t('Ingrese un Departamento') : ''"
        :state="state.state"
        :label-cols="2"
        :label="$t('Departamento')">
        <b-form-input v-model="pickupPoint.address.state"></b-form-input>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row class="mt-2">
    <b-col>
      <b-form-group
        :invalid-feedback="!state.city ? $t('Ingrese una ciudad') : ''"
        :state="state.city"
        :label-cols="2"
        :label="$t('Ciudad')">
        <b-form-input v-model="pickupPoint.address.city"></b-form-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        :invalid-feedback="!state.neighborhood ? $t('Ingrese un municipio') : ''"
        :state="state.neighborhood"
        :label-cols="2"
        :label="$t('Municipio')">
        <b-form-input v-model="pickupPoint.address.neighborhood"></b-form-input>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row class="mt-2">
    <b-col>
      <b-form-group
        :invalid-feedback="!state.street ? $t('Ingrese una calle') : ''"
        :state="state.street"
        :label-cols="2"
        :label="$t('Calle')">
        <b-form-input v-model="pickupPoint.address.street"></b-form-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        :invalid-feedback="!state.number ? $t('Ingrese un número') : ''"
        :state="state.number"
        :label-cols="2"
        :label="$t('Número')">
        <b-form-input v-model="pickupPoint.address.number"></b-form-input>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row class="mt-2">
    <b-col>
      <b-form-group
        :invalid-feedback="!state.complement ? $t('Ingrese una complemento') : ''"
        :state="state.complement"
        :label-cols="2"
        :label="$t('Complemento')">
        <b-form-input v-model="pickupPoint.address.complement"></b-form-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        :invalid-feedback="!state.reference ? $t('Ingrese un referencia') : ''"
        :state="state.reference"
        :label-cols="2"
        :label="$t('Referencia')">
        <b-form-input v-model="pickupPoint.address.reference"></b-form-input>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row class="mt-2">
    <b-col>
      <b-form-group
        :invalid-feedback="!state.geoCoordinateLat ? $t('Ingrese una coordenada válida') : ''"
        :state="state.geoCoordinateLat"
        :label-cols="2"
        :label="$t('Coordenada latitud')">
        <b-form-input v-model="pickupPoint.address.geoCoordinates[0]"></b-form-input>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        :invalid-feedback="!state.geoCoordinateLong ? $t('Ingrese una coordenada válida') : ''"
        :state="state.geoCoordinateLong"
        :label-cols="2"
        :label="$t('Coordenada longitud')">
        <b-form-input v-model="pickupPoint.address.geoCoordinates[1]"></b-form-input>
      </b-form-group>
    </b-col>
  </b-row>
  <b-button class="float-right"  @click="handleSave" variant="primary" :disabled="disabledSave"> Aceptar </b-button>
</b-card>
</template>

<script>
import { PickupPointServiceMixin } from '@/mixims/CarrierServiceMixin.js'
import { ValidationsMixim } from '@/mixims/ValidationsMixim'
export default {
  mixins: [ PickupPointServiceMixin, ValidationsMixim ],
  data () {
    return {
      pickupPoint: {
        id: '',
        name: '',
        description: '',
        instructions: '',
        formattedAddress: '',
        address: {
          postalCode: '',
          country: {
            acronym: 'SLV',
            name: 'El Salvador'
          },
          city: '',
          state: '',
          neighborhood: '',
          street: '',
          number: '',
          'complement': '',
          'reference': '',
          'geoCoordinates': []
        },
        isActive: true,
        distance: 0
      }
    }
  },
  methods: {
    fetchData () {
      if (this.checkRouteId) {
        const id = this.$route.params.pickupPointId
        this.getPickupPoint(id).then(
          response => {
            this.pickupPoint = response.body
          }
        )
      } else if (this.$route.name !== 'Crear Punto de retiro') {
        this.$router.back()
      } else {
        this.pickupPoint = {
          id: '',
          name: '',
          description: '',
          instructions: '',
          formattedAddress: '',
          address: {
            postalCode: '',
            country: {
              acronym: 'SLV',
              name: 'El Salvador'
            },
            city: '',
            state: '',
            neighborhood: '',
            street: '',
            number: '',
            'complement': '',
            'reference': '',
            'geoCoordinates': []
          },
          isActive: true,
          distance: 0
        }
      }
    },
    handleSave () {
      window.scrollTo(0, 0)
      const action = this.checkRouteId ? this.updatePickupPoint : this.createPickupPoint
      action(this.pickupPoint).then(
        response => {
          if (response.status === 201) {
            this.$emit('showMessage', { message: 'Punto de retiro creado exitosamente', variant: 'success' })
            setTimeout(() => this.$router.back(), 3000)
          } else if (response.status === 200) {
            this.$emit('showMessage', { message: 'Punto de retiro editado exitosamente', variant: 'success' })
            setTimeout(() => this.$router.back(), 3000)
          } else {
            this.$emit('showMessage', { message: 'Error al guardar el Punto de retiro', variant: 'danger' })
          }
        }
      )
    }
  },
  computed: {
    headerCard () {
      return this.checkRouteId ? 'Editar Punto de retiro' : 'Crear Punto de retiro'
    },
    checkRouteId () {
      return Object.prototype.hasOwnProperty.call(this.$route.params, 'pickupPointId')
    },
    disabledSave () {
      return Object.values(this.state).includes(false)
    },
    state () {
      return {
        name: this.isValidText(this.pickupPoint.name, true),
        postalCode: this.isValidText(this.pickupPoint.address.postalCode, true),
        city: this.isValidText(this.pickupPoint.address.city, true),
        state: this.isValidText(this.pickupPoint.address.state, true),
        neighborhood: this.isValidText(this.pickupPoint.address.neighborhood, true),
        street: this.isValidText(this.pickupPoint.address.street, true),
        number: this.isValidText(this.pickupPoint.address.number, true),
        complement: this.isValidText(this.pickupPoint.address.complement, true),
        reference: this.isValidText(this.pickupPoint.address.reference, true),
        geoCoordinateLat: this.isValidFloat(this.pickupPoint.address.geoCoordinates[0], true, false, true),
        geoCoordinateLong: this.isValidFloat(this.pickupPoint.address.geoCoordinates[1], true, false, true)
      }
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style>

</style>