<template>
<b-container fluid>
  <br>
  <b-row>
    <b-col cols="4"><p style="color: #303133; font-weight: bold;">{{$t('Nombre')}}</p></b-col>
    <b-col cols="8"><p>{{ product.name }}</p></b-col>
  </b-row>
  <b-row>
    <b-col cols="4"><p style="color: #303133; font-weight: bold;">{{$t('Categoría')}}</p></b-col>
    <b-col cols="8"><p>{{ product.category.name }}</p></b-col>
  </b-row>
  <b-row>
    <b-col cols="4"><p style="color: #303133; font-weight: bold;">{{$t('Marca')}}</p></b-col>
    <b-col cols="8">
      <p v-for="brand in product.brand" :key="brand.id">{{brand.name}}</p>
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="4"><p style="color: #303133; font-weight: bold;">{{$t('Descripción')}}</p></b-col>
    <b-col cols="8"><div v-html="product.description"></div></b-col>
  </b-row>
</b-container>
</template>

<script>
export default {
  props: {
    product: Object
  }
}
</script>

<style scoped>
</style>