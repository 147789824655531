<template>
<b-container fluid>
  <b-alert :show="showAlert" fade dismissible :variant="variant">{{$t(message)}}</b-alert>
  <ModalConfirmDeletePickupPoint ref="modalDeletePickupPoint" :pickupPoint="pickupPointSelected" @showMessage="handleShowMessage"/>
  <HeaderPickupPoints @filterTable="handleFilterTable" @showAll="handleFilterTable"/>
  <ListPickupPoints :filters="filters" :refreshTable="refreshTable" @showConfirmDeleteModal="showModal"/>
</b-container>
</template>

<script>
import ModalConfirmDeletePickupPoint from './ModalConfirmDeletePickupPoint'
import HeaderPickupPoints from './HeaderPickupPoints.vue'
import ListPickupPoints from './ListPickupPoints.vue'
export default {
  components: {
    ModalConfirmDeletePickupPoint,
    HeaderPickupPoints,
    ListPickupPoints
  },
  data () {
    return {
      filters: {},
      pickupPointSelected: {},
      message: '',
      variant: 'success',
      showAlert: 0,
      dismissSecs: 3,
      refreshTable: false
    }
  },
  methods: {
    handleFilterTable (filters) {
      this.filters = filters
    },
    showModal (data) {
      this.pickupPointSelected = data
      this.$refs.modalDeletePickupPoint.showConfirmModal()
    },
    handleShowMessage (data) {
      this.showAlert = 0
      this.message = data.message
      this.variant = data.variant
      this.showAlert = this.dismissSecs
      this.refreshTable = true
    }
  }
}
</script>

<style>

</style>