<template>
    <b-modal ref="incidentsModal" id="incidentsModal"
      size="lg"
      header-bg-variant="primary"
      centered
      :hide-footer="false"
      @hide="cancel"
      :title="incident && incident.id ? $t('Detalle de incidencia') : $t('Crear incidencia')"
      @cancel="cancel"
      :cancel-title="$t('Cerrar')"
      @ok="modalOk"
      :ok-title="$t('Seleccionar')">

      <div v-show="errorText !== ''" class="alert alert-danger" role="alert" dismissible @dismised="errorText = ''">
      {{errorText}}
      </div>

      <b-form v-if="incident && incident.id">
        <b-row>
          <b-col cols="12" sm="6">
            <b-form-group
              horizontal
              :label-cols="4"
              :label="$t('Número')">
              <b-form-input v-model="incident.id" :disabled="true"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6">
            <b-form-group
              horizontal
              :label-cols="4"
              :label="$t('Seller')"
              :state="state.seller">
              <b-form-input v-model="incident.accountName" :disabled="true"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" :sm="!referenceIsSelected ? 12 : 6">
            <b-form-group
                horizontal
                :label-cols="!referenceIsSelected ? 2 : 4"
                :label="$t('Referencia')">
                <b-form-input :value="$t(typeOfReference)" :disabled="true"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6">
            <b-form-input v-model="incident.reference" :disabled="true"></b-form-input>
          </b-col>

          <b-col cols="12" sm="12">
            <b-form-group
              horizontal
              :label-cols="2"
              :label="$t('Prioridad')">
              <b-form-input :value="$t(typeOfPriority)" :disabled="true"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12">
            <b-form-group
              horizontal
              :label-cols="2"
              :label="$t('Título')">
              <b-form-input
                v-model="incident.title"
                :disabled="true">
              </b-form-input>
            </b-form-group>
          </b-col>

          <hr class="w-100">
          <b-col cols="12" sm="12">
            <h6 class="mt-2 mb-3">{{$t('Historial')}}</h6>
            <article class="historial-comments">
                <div class="comment mb-2" v-for="(comments, index) in incident.comments" :key="index">
                    <p class="my-0"><strong>{{comments.author}}</strong></p>
                    <div class="message-blue">
                      <p class="message-content">{{comments.text}}</p>
                      <div class="message-timestamp-left"><i class="far fa-calendar-check calendar-history-incidents text-primary"></i>{{new Date(comments.date).toLocaleDateString(locale)}}</div>
                    </div>
                </div>
            </article>
          </b-col>
        </b-row>
      </b-form>

      <b-form v-else>

        <b-row>
          <b-col cols="12" sm="6">
            <b-form-group
              horizontal
              :label-cols="4"
              :label="$t('Número')">
              <b-form-input :disabled="true"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6">
            <b-form-group
              horizontal
              :label-cols="4"
              :label="$t('Seller')">
              <b-form-input :disabled="true" :value="referenceAux && referenceAux.accountName ? referenceAux.accountName : ''"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" :sm="hasSkuOptions ? 4 : 6">
            <b-form-group
                horizontal
                :label-cols="hasSkuOptions ? 6 : 4"
                :label="$t('Referencia')"
                :invalid-feedback="!state.reference? $t('Seleccione una referencia') : ''"
                :state="state.reference">
                <b-form-select
                  @change="handleChange"
                  :options="references"
                  :disabled="incidentDetail.populate"
                  v-model="incidentDetail.incidenceType">
                </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12"
            :sm="hasSkuOptions ? 4 : 6"
            v-show="!incidentDetail.populate && (incidentDetail.incidenceType === 'PRODUCT' || incidentDetail.incidenceType === 'SKU')">
            <autocomplete
              :class="{
                'hide-pannel': filter.length === 1
              }"
              class="autocomplete__incidentModal"
              :maxElements="100"
              :selectLabel="$t('Enter para agregar al detalle')"
              :multiple="false"
              :provider="getSearchProducts"
              v-model="referenceAux">
            </autocomplete>
          </b-col>
          <b-col cols="12" sm="4" v-if="!incidentDetail.populate && hasSkuOptions">
            <b-form-group
                horizontal
                :label="$t('SKU')"
                :state="state.reference">
                <b-form-select
                  :options="skuListOptions"
                  v-model="referenceSkuAux">
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6" v-show="!incidentDetail.populate && incidentDetail.incidenceType === 'ORDER'">
            <autocomplete
              class="autocomplete__incidentModal"
              :maxElements="100"
              :provider="getSearchOrders"
              :label="$t('id')"
              :selectLabel="$t('Enter para agregar la orden')"
              :multiple="false"
              v-model="referenceAux">
            </autocomplete>
          </b-col>
          <b-col cols="12" sm="6" v-if="incidentDetail.populate">
            <b-form-input v-model="incident.reference" :disabled="true"></b-form-input>
          </b-col>

          <b-col cols="12" sm="12">
            <b-form-group
              horizontal
              :label-cols="2"
              :label="$t('Prioridad')"
              :invalid-feedback="!state.priority? $t('Selecione una prioridad') : ''"
              :state="state.priority">
              <b-form-select
                :options="priorities"
                v-model="incidentDetail.priority"
                :disabled="!referenceIsSelected">
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12">
            <b-form-group
              horizontal
              :label-cols="2"
              :label="$t('Título')"
              :invalid-feedback="invalidFeedBackTitle()"
              :state="state.title">
              <b-form-input
                v-model="incidentDetail.title"
                :disabled="!referenceIsSelected">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12">
            <b-form-group
              horizontal
              :label-cols="2"
              :label="$t('Descripción')"
              :invalid-feedback="comment.text && comment.text.length > 500 ? $t('El máximo número de caracteres es: ') + '500' : !state.description ? $t('Ingrese la descripción de la incidencia.') : ''"
              :state="state.description">
              <b-form-textarea
                :disabled="!referenceIsSelected"
                v-model="comment.text"
                :rows="3"
                :max-rows="6">
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <div slot="modal-footer" class="w-100">
          <div class="w-100">
            <b-button variant="secondary" class="float-right mx-1" @click="cancel()">{{incident === null ? $t('Cancelar') : $t('Cerrar')}}</b-button>
            <b-button v-show="incident === null || incidentDetail.populate" :disabled="disableSave()" variant="primary" class="float-right mx-1" @click="modalOk">{{$t('Crear')}}</b-button>
            <b-button
              v-show="incident !== null && incidentDetail.status !== 'CLOSE' && !incidentDetail.populate"
              class="mb-2 mx-1 float-right"
              variant="primary"
              @click.stop="markAsSolved">
              {{$t('Marcar como solucionado')}}
            </b-button>
          </div>
      </div>
    </b-modal>
</template>

<script>
import { ProductServiceMixim } from '@/mixims/ProductServiceMixim'
import { OrdersServiceMixim } from '@/mixims/OrdersServiceMixim'
import { IncidentsServices } from '@/mixims/IncidentsServices'
import { ValidationsMixim } from '@/mixims/ValidationsMixim'
import autocomplete from '@/components/common/vue-autocomplete'
import appConfig from '@/config/applicationConfig.js'

export default {
  name: 'incidents-modal',
  mixins: [ValidationsMixim, ProductServiceMixim, OrdersServiceMixim, IncidentsServices],
  props: ['show', 'incident', 'references', 'priorities'],
  components: {
    autocomplete
  },
  data () {
    return {
      locale: appConfig.LOCALE,
      titlelimit: 30,
      errorText: '',
      filter: '',
      seller: '',
      incidentDetail: {
        creationDate: '',
        id: '',
        incidenceType: '',
        priority: '',
        reference: '',
        title: '',
        status: '',
        updateDate: ''
      },
      comment: {
        author: '',
        date: '',
        text: ''
      },
      referenceAux: null,
      referenceSkuAux: null,
      componentKey: 0
    }
  },
  watch: {
    incident () {
      if (this.incident) {
        this.incidentDetail = this.incident
      }
    },

    filter () {
      if (this.filter !== undefined && (typeof this.filter === 'object' && this.filter.length)) {
        this.seller = this.filter[0].accountName
      } else if (!this.filter.length) {
        this.seller = null
      }
    },
    show () {
      this.show ? this.$refs.incidentsModal.show() : this.$refs.incidentsModal.hide()
    },
    referenceAux: {
      handler () {
        if (this.referenceAux === null) return
        if (this.referenceSkuAux && !this.hasSkuOptions) return
        this.updateReference(this.referenceAux)
      },
      deep: true
    },
    referenceSkuAux () {
      this.updateReferenceSku(this.referenceSkuAux)
    }
  },
  computed: {
    typeOfReference () {
      if (this.incident) {
        let reference = this.incident.incidenceType
        return this.references.filter(referenceItem => { return referenceItem.value === reference })[0].text
      }

      return ''
    },
    typeOfPriority () {
      if (this.incident && this.incident.priority) {
        let priority = this.incident.priority
        return this.priorities.filter(priorityItem => { return priorityItem.value === priority })[0].text
      }

      return ''
    },
    userText () {
      return this.$store.state.user_data.firstName + ' ' + this.$store.state.user_data.lastName
    },
    state () {
      return {
        reference: this.getReferenceState(),
        priority: !this.isEmptyData(this.incidentDetail.priority),
        title: this.isValidText(this.incidentDetail.title, true, this.titlelimit),
        description: this.isValidTextArea(this.comment.text, true, 500)
      }
    },
    referenceIsSelected () {
      return (this.incidentDetail.incidenceType !== '')
    },
    hasSkuOptions () {
      return this.incidentDetail.incidenceType === 'SKU' && this.skuListOptions.length > 0
    },
    skuListOptions () {
      if (this.referenceAux !== null) {
        return this.referenceAux.skus.map(sku => { return {text: sku.skuName, value: sku.id} })
      }

      return []
    }
  },
  methods: {
    invalidFeedBackTitle () {
      return !this.state.title ? this.incidentDetail.title !== undefined && this.incidentDetail.title !== null && this.incidentDetail.title.length > this.titlelimit ? this.$t('El máximo número de caracteres es: ') + this.titlelimit : this.$t('Ingrese un título') : ''
    },
    disableSave () {
      return Object.values(this.state).includes(false)
    },
    resetModal () {
      this.errorText = ''
      this.referenceAux = null
      this.referenceSkuAux = null
      if (this.incidentDetail.populate) this.$router.push(this.$route.path).catch(() => {})
      this.incidentDetail = {}
      this.comment = {}
    },
    cancel () {
      this.resetModal()
      this.$emit('update:show', false)
    },
    modalOk () {
      let thisDate = new Date()

      this.comment.date = thisDate
      this.incidentDetail.updateDate = thisDate

      let incidentFinal = {
        ...this.incidentDetail,
        comments: [this.comment],
        creationDate: thisDate,
        status: 'OPEN'
      }

      this.createIncident(incidentFinal).then(
        response => {
          this.$emit('saved', response.data.id)
        }
      ).catch(
        error => {
          this.errorText = error.body.message || this.$t('Ocurrió un error al crear la incidencia')
        }
      )
    },
    markAsSolved () {
      this.$emit('markAsSolved', this.incident)
    },
    updateReference (referenceAux) {
      if (this.incidentDetail.incidenceType === 'ORDER' || this.incidentDetail.incidenceType === 'PRODUCT') this.incidentDetail.reference = referenceAux.id
    },
    updateReferenceSku (sku) {
      if (this.incidentDetail.incidenceType === 'SKU') this.incidentDetail.reference = sku
    },
    getReferenceState () {
      if (this.incidentDetail.populate) return !this.isEmptyData(this.incidentDetail.reference)
      if (this.incidentDetail.incidenceType === 'ORDER' || this.incidentDetail.incidenceType === 'PRODUCT') return !this.isEmptyData(this.referenceAux)
      return !this.isEmptyData(this.referenceSkuAux)
    },
    handleChange () {
      this.referenceSkuAux = null
      this.referenceAux = null
      this.incidentDetail.reference = null
    }
  }
}
</script>

<style lang="scss">
  .autocomplete__incidentModal{
    &.hide-pannel{
      // border: 4px solid red !important;
      .multiselect{
        .multiselect__content-wrapper{
          display: none !important;
          opacity: 0 !important;
          .multiselect__content{
            display: none !important;
          }
        }
      }
    }
    .multiselect{
      // border:1px solid red;
      min-height: 41px !important;
      .multiselect__tags {
          min-height: 35px !important;
          border-radius: 0px !important;
          border-color: #e3e8ec;
          padding: 5px 40px 0 8px;
      }
    }
  }
  .calendar-history-incidents {
    margin-right:3px; 
    margin-top:3px;
  }
  // message styles 
  .message-blue {
    position: relative;
    margin-left: 20px;
    margin-bottom: 10px;
    padding: 5px 10px 10px 10px;
    background-color: #eff9ff;
    display: inline-block;
    min-width: 200px;
    min-height: 50px;
    text-align: left;
    border: 1px solid #97C6E3;
    border-radius: 10px;
  }

  .message-content {
      padding: 0;
  }

  .message-timestamp-left {
      position: absolute;
      font-weight: 300;
      bottom: 5px;
      left: 10px;
  }

  .message-blue:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 15px solid #eff9ff;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      top: 0;
      left: -15px;
  }

  .message-blue:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 17px solid #97C6E3;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      top: -1px;
      left: -17px;
  }
</style>

