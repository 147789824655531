<template>
  <b-container fluid>
    <div>
      <b-modal :ok-disabled="loading || haveErrors" header-bg-variant="primary" centered ref="invoiceModal" id="invoiceModal" size="lg" :title="isCarrierIntegration ? $t('Solicitar guía') : $t('Facturar')"  @ok="invoiceCall" :cancel-title="$t('Cancelar')" :ok-title="invoiceButtonText" >
        <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
        <b-alert :show="errorText !== ''" fade variant="danger" dismissible>
          <h4 class="alert-heading">Error!</h4>
          <p>{{errorText}}</p>
        </b-alert>
        <b-form v-show="!loading">
          <b-card v-if="!invoiceData.isAddingTrack && order.shippingData" :header="$t('Facturar productos')">
            <b-alert show variant="warning" dismissible>
              {{$t('En la primera factura se imputara el costo de envio automaticamente.')}}
            </b-alert>

            <template v-for="(item, index) in order.items">
              <b-row v-if="toInvoice[item.id] && toInvoice[item.id].values.length > 1" :key="index">
                <b-col>
                  <b-form-group
                    id="toInvoice"
                    breakpoint="md"
                    :label="item.name">
                    <b-form-select v-model="toInvoice[item.id].selected" :options="toInvoice[item.id].values" class="mb-3" />
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
              <b-row>
                <b-col>
                  <b-form-group
                    :label="$t('Numero de factura')"
                    label-class="text-sm-right"
                    label-for="invoiceNumber"
                    :invalid-feedback="!state.invoiceNumber? $t('Numero de factura inválido o repetido') : ''"
                    :state="state.invoiceNumber">
                    <b-form-input
                      id="invoiceNumber"
                      type="text"
                      :placeholder="$t('Numero de factura')"
                      v-model.trim="invoiceData.invoiceNumber">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
          </b-card>
          <b-row v-if="!invoiceData.isAddingTrack && !isCarrierIntegration">
            <b-col>
              <b-form-group
                horizontal
                id="toInvoice"
                breakpoint="md"
                :description="$t('Puedo facturar junto con los datos de seguimiento del paquete, de todas maneras una vez facturado es posible agregar esa informacion en el paquete')"
                :label="$t('Dispongo de datos de seguimiento')"
              >
                 <b-form-checkbox class="ml-4" @click.native.stop v-model="haveDeliveryData"></b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-card v-show="haveDeliveryData" :header="!invoiceData.isAddingTrack ? $t('Tracking del paquete') : $t('Tracking del paquete (Factura: ') + invoiceData.invoiceNumber + ')'">
            <b-alert :show="invoiceData.isAddingTrack" variant="warning" dismissible>
              {{$t('Usted esta a punto de agregar información de tracking al paquete que acompaña la factura')}} {{invoiceData.invoiceNumber}}
            </b-alert>
            <b-form-group
              :label="$t('Número Tracking')"
              label-class="text-sm-right"
              label-for="trackingNumber"
              :invalid-feedback="!stateTrack.trackingNumber? $t('Numero de tracking inválido') : ''"
              :state="stateTrack.trackingNumber">
              <b-form-input
                id="trackingNumber"
                type="text"
                :placeholder="$t('Codigo de tracking')"
                v-model="invoiceData.trackingNumber">
              </b-form-input>
            </b-form-group>
            <b-form-group
              label="Url tracking"
              label-class="text-sm-right"
              label-for="urlTracking"
              :invalid-feedback="!stateTrack.trackingUrl? $t('Url de tracking inválido') : ''"
              :state="stateTrack.trackingUrl">
              <b-form-input
                id="urlTracking"
                :title="$t('Este campo debe ser una url valida.')"
                v-model="invoiceData.trackingUrl">
               </b-form-input>
            </b-form-group>
            <b-form-group
              :label="$t('Transporte')"
              label-class="text-sm-right"
              label-for="transporte"
              :invalid-feedback="!stateTrack.courier? $t('Transportadora inválida') : ''"
              :state="stateTrack.courier">
              <b-form-select
                :options="invoiceData.courierOptions"
                v-model="invoiceData.courier"
              />
            </b-form-group>
          </b-card>
        </b-form>
      </b-modal>
    </div>
  </b-container>
</template>

<script>

import appConfig from '../../config/applicationConfig.js'
import {OrdersServiceMixim} from '../../mixims/OrdersServiceMixim'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import { ValidationsMixim } from '@/mixims/ValidationsMixim'
import { CarrierServiceMixin } from '@/mixims/CarrierServiceMixin'

export default {
  name: 'invoice-modal',
  mixins: [OrdersServiceMixim, ValidationsMixim, CarrierServiceMixin],
  props: ['order', 'successCallback', 'carrierIntegration'],
  computed: {
    invoicedItems () {
      let invoicedItems = []
      if (this.order.packageAttachment.packages && this.order.packageAttachment.packages.length > 0) {
        this.order.packageAttachment.packages.forEach(
          (packageElem) => {
            invoicedItems.concat(packageElem.items)
          }
        )
      }
      return invoicedItems
    },
    stateTrack () {
      return {
        trackingUrl: this.validateUrl(this.invoiceData.trackingUrl, true),
        trackingNumber: this.isValidText(this.invoiceData.trackingNumber, true),
        courier: this.isValidText(this.invoiceData.courier, true)
      }
    },
    state () {
      return {
        invoiceNumber: this.isValidText(this.invoiceData.invoiceNumber, true) && this.isValidInvoice(this.invoiceData.invoiceNumber),
        toInvoinceQuantity: parseInt(this.invoiceData.quantity) > 0
      }
    },
    haveErrors () {
      if (this.haveDeliveryData) {
        if (this.invoiceData.isAddingTrack) {
          /* We have package id.. only validate tracking information */
          return Object.values(this.stateTrack).includes(false)
        }
        return Object.values(this.stateTrack).includes(false) || Object.values(this.state).includes(false)
      }
      return Object.values(this.state).includes(false)
    },
    isCarrierIntegration () {
      return this.carrierIntegration
    }
  },
  watch: {
    toInvoice: {
      handler: function (newVal, oldVal) {
        if (this.invoiceData.isAddingTrack) {
          return
        }
        /* This code is only for cosmetic purpose */
        let quantity = 0
        for (var prop in this.toInvoice) {
          if (this.toInvoice[prop].selected !== 0) {
            quantity += this.toInvoice[prop].selected
          }
        }
        this.invoiceData.quantity = quantity
        if (quantity > 0) {
          this.invoiceButtonText = `${this.$t('Facturar')} (${quantity})`
        } else {
          this.invoiceButtonText = this.$t('Facturar')
        }
        if (this.isCarrierIntegration) {
          this.invoiceButtonText = this.$t('Solicitar guía')
        }
      },
      deep: true
    },
    invoiceData: {
      handler: function (newVal, oldVal) {
        if (this.invoiceData.isAddingTrack) {
          this.invoiceButtonText = this.$t('Agregar tracking')
        }
      },
      deep: true
    }
  },
  components: {
    MoonLoader
  },
  data () {
    return {
      invoiceData: {
        isAddingTrack: false,
        trackingNumber: null,
        trackingUrl: null,
        courier: null,
        invoiceNumber: '',
        courierOptions: [],
        quantity: 0
      },
      errorText: '',
      loading: false,
      toInvoice: {},
      invoiceButtonText: this.$t('Enviar'),
      haveDeliveryData: false,
      shippingsExcluded: appConfig.SHIPPING_EXCLUDED,
      isAdmin: this.$store.getters.isAdmin
    }
  },
  beforeMount () {
  },
  methods: {
    isValidInvoice (invoiceNumber) {
      if (this.order.packageAttachment && this.order.packageAttachment.packages) {
        return !this.order.packageAttachment.packages.find((pack) => {
          if (pack.invoiceNumber === invoiceNumber) {
            return true
          }
        })
      }
      return true
    },
    show (packageInvoiceNumber) {
      this.loading = false
      if (!this.isEmptyData(packageInvoiceNumber)) {
        this.invoiceData.isAddingTrack = true
        this.haveDeliveryData = true
        this.invoiceData.invoiceNumber = packageInvoiceNumber
      } else {
        this.invoiceData.isAddingTrack = false
        this.invoiceData.invoiceNumber = ''
        this.haveDeliveryData = false
      }
      this.invoiceData.quantity = 0
      this.invoiceData.courier = null
      this.order.items.forEach(
        (item) => {
          item.pendingInvoice = []

          /* Get the pending invoice items, I mean the items that have not sent */
          let pendingInvoice = item.quantity - this.getSentItemsQuantity(item.id)
          for (let i = 0; i <= pendingInvoice; i++) item.pendingInvoice.push(i)
          item.toInvoice = 0
        }
      )
      this.order.items.forEach(
        (item) => {
          this.$set(this.toInvoice, item.id, {values: item.pendingInvoice, selected: 0})
        }
      )
      this.errorText = ''
      this.invoiceData.trackingNumber = null
      this.invoiceData.trackingUrl = null
      for (var prop in this.toInvoice) {
        this.toInvoice[prop].selected = 0
      }

      this._getCarriers()
      this.$refs.invoiceModal.show()
    },
    _getCarriers() {
      this.invoiceData.courierOptions = [];
      let filter = {}
      if (!this.isAdmin) {
        filter.type = 'SELLER'
      }
      this.getCarriers(filter).then(
        (carriers) => {
          if(carriers && carriers.body) {
            this.invoiceData.courierOptions.push({value: null, text: this.$t('Seleccione transportadora'), disabled: true})
            carriers.body.forEach((carrier) => {
              if (carrier.isActive)
                this.invoiceData.courierOptions.push({value: carrier.name, text: carrier.name})
            })
          }
        }, (error) => {
          let carriers = this.$store.getters.carriers
          this.invoiceData.courierOptions.push({value: null, text: this.$t('Seleccione transportadora'), disabled: true})
          carriers.forEach(
            (carrier) => {
              if (carrier.isActive)
                this.invoiceData.courierOptions.push({value: carrier.name, text: carrier.name})
            }
          )
        }
      )
    },
    /* Get the number of invoiced items given the sku */
    getSentItemsQuantity (id) {
      let quantity = 0
      if (this.order.packageAttachment && this.order.packageAttachment.packages && this.order.packageAttachment.packages.length > 0) {
        this.order.packageAttachment.packages.forEach(
          (packageItem) => {
            packageItem.items.forEach(
              (item) => {
                if (item.id === id) {
                  quantity = quantity + item.quantity
                }
              }
            )
          }
        )
      }
      return quantity
    },
    invoiceCall (e) {
      e.preventDefault()
      this.loading = true
      /* We have package id.. so.. add tracking information */
      if (this.invoiceData.isAddingTrack) {
        this.addTrackingInformation(
          this.order.id,
          this.invoiceData.invoiceNumber,
          this.invoiceData.courier,
          this.invoiceData.trackingUrl,
          this.invoiceData.trackingNumber
        ).then(
          () => {
            this.loading = false
            this.successCallback()
            this.$refs.invoiceModal.hide()
          },
          () => {
            this.errorText = this.$t('Se ha producido un error')
            this.loading = false
          }
        )
      } else {
        /* Invoice items bacause we do not hace package id */
        let toInvoice = []
        if (this.isCarrierIntegration) {
          this.invoiceData.courier = this.order.shippingData.logisticsInfo[0].deliveryCompany
        }
        Object.keys(this.toInvoice).forEach((item) => {
          if (this.toInvoice[item].selected > 0) {
            toInvoice.push({
              'id': item,
              'quantity': this.toInvoice[item].selected
            })
          }
        })
        this.invoiceOrder(
          this.order.id,
          this.invoiceData.courier,
          this.invoiceData.trackingUrl,
          this.invoiceData.trackingNumber,
          toInvoice,
          this.invoiceData.invoiceNumber
        ).then(
          () => {
            this.loading = false
            this.successCallback()
            this.$refs.invoiceModal.hide()
          },
          (e) => {
            this.errorText = this.$t('Se ha producido un error, verifique que el número de factura es correcto y que no haya sido utilizado previamente en la orden.')
            this.loading = false
          }
        )
      }
    },
    getMaxOfArray (numArray) {
      return Math.max.apply(null, numArray)
    }
  }
}
</script>

<style lang="scss" scoped>
  p {
    margin-bottom: 2px;
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
    }
  }

  span.loading {
    display: inline-block;
    width: 100%;
    text-align: center;

    .loading-icon {
      animation:spin 2s linear infinite;
      font-size: 44px;
      color: #000;
     }
  }

 .fa-exclamation-circle {
   margin-top: 3px;
   margin-right: 3px;
 }

 h5 {
   border-bottom: 1px solid #CCCCCC;
   padding-bottom: 5px;
 }

</style>
