<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['height'],
  data () {
    return {
      graphOptions: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: true,
          text: this.$t('Ordenes')
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: 'rgb(0, 0, 0)'
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 1,
              suggestedMin: 0
            },
            gridLines: {
              display: true
            }
          }]
        },
        elements: {
          line: {
            tension: 0
          },
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      }
    }
  },
  methods: {
    updateGraph () {
      this.renderChart(this.chartData, this.graphOptions)
    }
  }
}
</script>
