<template>
  <div>
    <b-modal header-bg-variant="primary" centered ref="productsModal" id="productsModal" size="lg" :title="$t('Procesar alta de productos')" o-close-on-backdrop="true" @ok="uploadProductFile" :cancel-title="$t('Cancelar')">
      {{$t('¿Desea continuar?')}}
    </b-modal>

    <b-modal header-bg-variant="primary" centered ref="attrModal" id="attrModal" size="lg" :title="$t('Procesar alta de atributos')" o-close-on-backdrop="true" @ok="uploadSpecificationsFile" :cancel-title="$t('Cancelar')">
      {{$t('¿Desea continuar?')}}
    </b-modal>

    <b-modal header-bg-variant="primary" centered ref="attrModalVar" id="attrModalVar" size="lg" :title="$t('Procesar alta de atributos de variantes')" o-close-on-backdrop="true" @ok="uploadVarSpecificationsFile" :cancel-title="$t('Cancelar')">
      {{$t('¿Desea continuar?')}}
    </b-modal>

    <moon-loader :loading="inProgress" color="#666" size="30px"></moon-loader>
    <b-row class="mb-5">
      <b-col>
        <category-tree
          id='selectCategoryProduct'
          boostrapClasses="float-left"
          @onCategorySelected="selectCategory"
          :button="true"
          :buttonTitle="$t('Seleccione una Categoría')">
        </category-tree>
        <span class="categorySelected" v-show="Boolean(categorySelected.id)">{{$t('Categoría seleccionada')}}: <strong>{{categorySelected.text}}</strong> ({{categorySelected.namePath}})</span>
      </b-col>
    </b-row>
    <b-tabs v-model="tabIndex">
      <b-tab active :title="$t('Alta de productos y variantes')">
        <div v-if="!Object.keys(asyncErrorObj).length" v-show="productFeedback.errorText" v-html="productFeedback.errorText" class="alert alert-danger" role="alert">
          {{productFeedback.errorText}}
        </div>
        <ul class="alert alert-danger" style="padding-left: 60px;" v-show="Object.keys(asyncErrorObj).length" role="alert">
          <div v-html="replaceUl(productFeedback.errorText)" v-if="productFeedback.errorText !== $t('Se encontraron errores.')"></div>
          <li v-for="error in asyncErrorObj" :key="error">{{ error }}</li>
        </ul>
        <div v-show="productFeedback.success" class="alert alert-success" role="alert">
          {{$t('Los Productos fueron creados / actualizados con éxito')}}
        </div>
        <b-row>
          <b-col>
            <b-button :disabled="!Boolean(categorySelected.id)" class="float-left mt-3" @click="downloadProductCSVExampleFile" variant="primary">{{$t('Obtener formato para alta de productos')}}</b-button>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col md="12" class="mb-2">{{$t('Archivo Productos seleccionado')}}: <strong>{{csvProductFile && csvProductFile.name}}</strong></b-col>
          <b-col md="6">
            <b-form-group
              label-for="productFileInput"
            >
              <b-form-file
                id="productFileInput"
                ref="fileProductInput"
                @click.native="resetInputProduct"
                :disabled="!Boolean(categorySelected.id)"
                :class="$i18n.locale"
                accept=".csv,.txt"
                v-on:change="onFileChanged"
                v-model="csvProductFile"
                :placeholder="$t('Productos CSV')"></b-form-file>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col class="col-md-auto">
            <b-pagination :total-rows="productsCount" :per-page="perPage" v-model="currentPage" class="mb-3" />
          </b-col>
          <b-col class="col-md-auto per-page">
            <b-form-group id="perPage" horizontal :label="$t('Mostrar por página')">
              <b-form-select :options="pageOptions" v-model="perPage" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="overflow-x-auto">
            <p>{{$t('Cantidad de Productos')}}: <strong>{{productsCount}}</strong></p>
            <b-table
              ref="productsTable"
              :items="itemProvider"
              :current-page="currentPage"
              :per-page="perPage"
              hover
              :fields="csvFields"
              :small="true"
              show-empty
              striped
              :empty-text="$t('No hay productos para mostrar')"
              stacked="md"
              foot-clone>
                <template slot="sku" slot-scope="sku">
                  <b>{{sku.value === '' ? '(new)' : sku.value}}</b>
                </template>
                <template slot="image" slot-scope="image">
                   <template v-for="imageItem in image.value.split('|')">
                     <img @click="zoomImage(imageItem)" class="imageItem" :src="imageItem" @error="errorImage" :key="imageItem"/>
                   </template>
                </template>
                <template slot="video" slot-scope="video">
                   <template v-for="videoUrl in video.value.split('|')">
                    {{videoUrl}}
                   </template>
                </template>
                <template slot="size" slot-scope="row">
                  {{row.item.weight * row.item.height * row.item.length}}cm3, {{row.item.weight}}gr, {{row.item.height}}x{{row.item.width}}x{{row.item.length}}cm
                </template>
            </b-table>
          </b-col>
          <b-col md="12" class="mt-3">
            <b-button
              :disabled="!Boolean(csvProductFile) || !categorySelected || productFeedback.errorText !== '' || inProgress"
              v-b-modal.productsModal
              variant="primary"
              class="float-right">{{$t('Procesar Productos')}}</b-button>
          </b-col>
        </b-row>
        <b-row class="mt-5" v-show="perPage > 100">
          <b-col class="col-md-auto">
            <b-pagination :total-rows="productsCount" :per-page="perPage" v-model="currentPage" class="mb-3" />
          </b-col>
          <b-col class="col-md-auto per-page">
            <b-form-group id="perPage" horizontal :label="$t('Mostrar por página')">
              <b-form-select :options="pageOptions" v-model="perPage" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="$t('Alta de atributos')">
        <div v-show="attrFeedback.errorText" v-html="attrFeedback.errorText" class="alert alert-danger" role="alert">
          {{attrFeedback.errorText}}
        </div>
        <div v-show="attrFeedback.success" class="alert alert-success" role="alert">
          {{$t('Los atributos fueron actualizados con éxito')}}
        </div>
        <b-row>
          <b-col>
            <b-button :disabled="!Boolean(categorySelected.id)" class="float-left mt-3" @click="downloadSpecificationsCSVExampleFile" variant="primary">{{$t('Obtener formato para alta de atributos')}}</b-button>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col md="12" class="mb-2">{{$t('Archivo de Atributos seleccionado')}}: <strong>{{csvProductAttrFile && csvProductAttrFile.name}}</strong></b-col>
          <b-col md="6">
            <b-form-file
              ref="fileProductAttrInput"
              @click.native="$refs.fileProductAttrInput.reset()"
              accept=".csv,.txt"
              v-on:change="onFileAttrChanged"
              v-model="csvProductAttrFile"
              :disabled="!Boolean(categorySelected.id)"
              :class="$i18n.locale"
              :placeholder="$t('Atributos CSV')">
            </b-form-file>
          </b-col>
          <b-col md="12" class="mt-3">
            <b-button
              :disabled="!Boolean(csvProductAttrFile) || !categorySelected  || attrFeedback.errorText !== '' || inProgress"
              v-b-modal.attrModal
              variant="primary"
              class="float-left">{{$t('Procesar Atributos')}}</b-button>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="$t('Alta de atributos variantes')">
        <div v-show="attrVarFeedback.errorText" v-html="attrVarFeedback.errorText" class="alert alert-danger" role="alert">
          {{attrVarFeedback.errorText}}
        </div>
        <div v-show="attrVarFeedback.success" class="alert alert-success" role="alert">
          {{$t('Los atributos fueron actualizados con éxito')}}
        </div>
        <b-row>
          <b-col>
            <b-button :disabled="!Boolean(categorySelected.id)" class="float-left mt-3" @click="downloadVarSpecificationsCSVExampleFile" variant="primary">{{$t('Obtener formato para alta de atributos')}}</b-button>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col md="12" class="mb-2">{{$t('Archivo de Atributos seleccionado')}}: <strong>{{csvProductVarAttrFile && csvProductVarAttrFile.name}}</strong></b-col>
          <b-col md="6">
            <b-form-file
              ref="fileProductVarAttrInput"
              @click.native="$refs.fileProductVarAttrInput.reset()"
              accept=".csv,.txt"
              v-on:change="onFileAttrVarChanged"
              v-model="csvProductVarAttrFile"
              :disabled="!Boolean(categorySelected.id)"
              :class="$i18n.locale"
              :placeholder="$t('Atributos CSV')">
            </b-form-file>
          </b-col>
          <b-col md="12" class="mt-3">
            <b-button
              :disabled="!Boolean(csvProductVarAttrFile) || !categorySelected  || attrVarFeedback.errorText !== '' || inProgress"
              v-b-modal.attrModalVar
              variant="primary"
              class="float-left">{{$t('Procesar Atributos')}}</b-button>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Vue from 'vue'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import VTreeview from 'v-treeview'
import {ProductServiceMixim} from '@/mixims/ProductServiceMixim.js'
import CategoryTree from '@/components/catalog/CategoryTree'
import appConfig from '@/config/applicationConfig.js'
import Papa from 'papaparse'
import zoom from 'vue-image-zoom'
import {ValidationsMixim} from '@/mixims/ValidationsMixim.js'

Vue.use(zoom)

export default {
  name: 'add-masive-products-component-view',
  components: {
    MoonLoader,
    CategoryTree
  },
  mixins: [ProductServiceMixim, ValidationsMixim],
  methods: {
    selectCategory (data) {
      this.categorySelected.id = data.id
      this.categorySelected.text = data.name
      this.categorySelected.namePath = data.namePath
    },
    zoomImage: function (src) {
      this.$zoom(src, {
        allowZoom: true,
        autoScale: true,
        closeOnClickModal: true
      })
    },
    errorImage () {
      if (!this.productFeedback.errorText) this.productFeedback.errorText = this.$t('Se encontraron errores.')
      this.asyncErrorObj['brokenimg'] = this.$t('El archivo contiene imágenes erróneas.')
      this.csvElements.forEach((elem, index) => {
        console.log(elem)
        elem._cellVariants.image = 'danger'
      })
      this.$forceUpdate()
      this.$refs.productsTable.refresh()
    },
    resetInputProduct () {
      this.xlsAlert = false
      this.$refs.fileProductInput.reset()
    },
    resetInputAttr () {
      this.$refs.fileProductAttrInput.reset()
    },
    resetInputAttrVar () {
      this.$refs.fileProductVarAttrInput.reset()
    },
    itemProvider (tableComponentInfo) {
      let start = (tableComponentInfo.currentPage - 1) * tableComponentInfo.perPage
      let end = tableComponentInfo.perPage * tableComponentInfo.currentPage
      this.productsCount = this.csvElements.length
      return this.csvElements.slice(start, end)
    },
    _downloadFileExec (endPoint, fileName) {
      Vue.http.options.emulateHTTP = true

      return this.$http.get(
        endPoint,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`,
            Accept: 'application/octet-stream; charset=ISO-8859-1'
          },
          responseType: 'arraybuffer'
        }
      ).then((csvFile) => {
        let headers = csvFile.headers
        var blob = new Blob([csvFile.body], {
          type: { type: headers.map['content-type'] }
        })
        const blobURL = window.URL.createObjectURL(blob)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', fileName)
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      })
    },
    downloadVarSpecificationsCSVExampleFile () {
      let endPoint = `${appConfig.API_END_POINT}/specifications/downloadcsv?isStockKeepingUnit=true&categoryId=${this.categorySelected.id}`
      let fileName = `${this.$t('modeloAtributosVariants')}.csv`
      return this._downloadFileExec(endPoint, fileName)
    },
    downloadSpecificationsCSVExampleFile () {
      let endPoint = `${appConfig.API_END_POINT}/specifications/downloadcsv?isStockKeepingUnit=false&categoryId=${this.categorySelected.id}`
      let fileName = `${this.$t('modeloAtributos')}.csv`
      return this._downloadFileExec(endPoint, fileName)
    },
    downloadProductCSVExampleFile () {
      let endPoint = `${appConfig.API_END_POINT}/products/downloadcsv?categoryId=${this.categorySelected.id}`
      let fileName = `${this.$t('modeloProductos')}.csv`
      return this._downloadFileExec(endPoint, fileName)
    },
    _uploadFile (endPoint, file, refInput, feedback) {
      this.inProgress = true
      feedback.errorText = ''
      feedback.success = false
      let formData = new FormData()
      formData.append('file', file)
      return this.$http.post(endPoint, formData,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }).then(
        () => {
          this.inProgress = false
          feedback.success = true
          this.success = true
          refInput.reset()
        },
        (error) => {
          this.inProgress = false
          feedback.errorText = error.body.message || this.$t('Error procesando el archivo CSV') + ` - ${file.name}`
          /* Mark every row as error */
          this.csvElements.forEach((elem, index) => {
            elem._rowVariant = 'danger'
          })
          this.$refs.productsTable.refresh()
          refInput.reset()
        }
      )
    },
    uploadSpecificationsFile (evt) {
      let endPoint = `${appConfig.API_END_POINT}/specifications/importcsv?isStockKeepingUnit=false&categoryId=${this.categorySelected.id}&delimiter=${this.delimiterAttr}`
      return this._uploadFile(endPoint, this.csvProductAttrFile, this.$refs.fileProductAttrInput, this.attrFeedback)
    },
    uploadVarSpecificationsFile (evt) {
      let endPoint = `${appConfig.API_END_POINT}/specifications/importcsv?isStockKeepingUnit=true&categoryId=${this.categorySelected.id}&delimiter=${this.delimiterAttrVar}`
      return this._uploadFile(endPoint, this.csvProductVarAttrFile, this.$refs.fileProductVarAttrInput, this.attrVarFeedback)
    },
    uploadProductFile (evt) {
      let endPoint = `${appConfig.API_END_POINT}/products/importcsv?categoryId=${this.categorySelected.id}&delimiter=${this.delimiterProduct}`
      return this._uploadFile(endPoint, this.csvProductFile, this.$refs.fileProductInput, this.productFeedback)
    },
    onFileAttrChanged (evt) {
      const self = this
      this.attrFeedback.errorText = ''
      this.attrFeedback.success = false
      var file = evt.target.files[0]
      if (!file) {
        return
      }
      var reader = new FileReader()
      reader.onload = (evt) => {
        let parsedData = Papa.parse(
          evt.target.result.trim(), {
            delimiter: ';',
            skipEmptyLines: true,
            encoding: 'ISO-8859-1'
          }
        )
        self.delimiterAttr = parsedData.meta.delimiter
        if (parsedData.errors.length > 0) {
          self.attrFeedback.errorText = this.$t('El archivo tiene un formato que no es soportado')
          self.$refs.fileProductAttrInput.reset()
        }
        // fix bug scpb-80
        let headerError = ''
        let data = parsedData.data
        let header = parsedData.data.shift()
        /* There is not rows to proccess */
        if (data.length === 0) {
          headerError = this.$t('No hay filas para procesar en el archivo.')
          self.attrFeedback.errorText = headerError
          self.inProgress = false
          return
        }

        /* The number of columns is incorrect */
        if (header.length !== 5) {
          headerError = this.$t('La cabecera del archivo debe tener 5 columnas.')
          headerError += '<ul>'
          headerError += '  <li>' + this.$t("Verificar que la cabecera es 'ID producto; Nombre del atributo; Tipo de atributo; Opciones de atributo; Valor de atributo'") + '</li>'
          headerError += '</ul>'
          self.attrFeedback.errorText = headerError
          self.inProgress = false
          return
        }

        data.forEach((elem, index) => {
          elem.forEach((elem2, index2) => {
            if (!elem[index2] && index2 !== 3 && index2 !== 4) {
              self.attrFeedback.errorText = this.$t('Verifique que no existan campos faltantes.')
            }
          })
          if (elem.length !== 5) {
            self.attrFeedback.errorText = this.$t('Verifique que existan 5 columnas.')
          }
        })
      }
      reader.readAsText(file, 'ISO-8859-1')
    },
    onFileAttrVarChanged (evt) {
      const self = this
      this.attrVarFeedback.errorText = ''
      this.attrVarFeedback.success = false
      var file = evt.target.files[0]
      if (!file) {
        return
      }
      var reader = new FileReader()
      reader.onload = (evt) => {
        let parsedData = Papa.parse(
          evt.target.result.trim(), {
            delimiter: ';',
            skipEmptyLines: true,
            encoding: 'ISO-8859-1'
          }
        )
        self.delimiterAttrVar = parsedData.meta.delimiter
        if (parsedData.errors.length > 0) {
          self.attrVarFeedback.errorText = this.$t('El archivo tiene un formato que no es soportado')
          self.$refs.fileProductVarAttrInput.reset()
        }
        // fix bug scpb-80
        let headerError = ''
        let data = parsedData.data
        let header = parsedData.data.shift()
        /* There is not rows to proccess */
        if (data.length === 0) {
          headerError = this.$t('No hay filas para procesar en el archivo.')
          self.attrVarFeedback.errorText = headerError
          self.inProgress = false
          return
        }

        /* The number of columns is incorrect */
        if (header.length !== 7) {
          headerError = this.$t('La cabecera del archivo debe tener 7 columnas.')
          headerError += '<ul>'
          headerError += '  <li>' + this.$t("Verificar que la cabecera es 'UPC; ID producto; SKU; Nombre del atributo; Tipo de atributo; Opciones de atributo; Valor de atributo'") + '</li>'
          headerError += '</ul>'
          self.attrVarFeedback.errorText = headerError
          self.inProgress = false
          return
        }

        data.forEach((elem, index) => {
          elem.forEach((elem2, index2) => {
            if (!elem[index2] && index2 !== 6) {
              self.attrVarFeedback.errorText = this.$t('Verifique que no existan campos faltantes.')
            }
          })

          if (elem.length !== 7) {
            self.attrVarFeedback.errorText = this.$t('Verifique que existan 7 columnas.')
          }
        })
      }
      reader.readAsText(file, 'ISO-8859-1')
    },
    _getDelimiter (str) {
      let semiColon = str.match(new RegExp(';', 'g'))
      let colon = str.match(new RegExp(',', 'g'))

      if (!semiColon) {
        return ','
      }

      if (!colon) {
        return ';'
      }

      return semiColon.length > colon.length ? ';' : ','
    },
    /* On product file changed */
    onFileChanged (evt) {
      const self = this
      self.inProgress = true
      self.productFeedback.errorText = ''
      self.productFeedback.success = false
      self.xlsAlert = false
      self.csvElements = []
      this.asyncErrorObj = {}
      self.$refs.productsTable.refresh()
      var file = evt.type === 'drop' ? evt.dataTransfer.files[0] : evt.target.files[0]
      if (!file) {
        return
      }

      var reader = new FileReader()
      reader.onload = (evt) => {
        let headerError = ''
        this.productFeedback.errorArray = {}
        try {
          let parsedData = Papa.parse(
            evt.target.result.trim(), {
              delimiter: ';',
              skipEmptyLines: true,
              encoding: 'ISO-8859-1'
            }
          )
          self.delimiterProduct = parsedData.meta.delimiter
          console.log('Guess Delimiter from Papa: ' + self.delimiterProduct)

          if (parsedData.errors.length > 0) {
            console.log('There are some errors in the parsing, the file format is not supported')
            console.log(parsedData)
            self.productFeedback.errorText = this.$t('El archivo tiene un formato que no es soportado')
            self.$refs.fileinput.reset()
            return
          }

          let data = parsedData.data
          let header = parsedData.data.shift()
          console.log('------------ Header ------------')
          console.log(header)
          console.log('------------ Data ------------')
          console.log(data)

          /* There is not rows to proccess */
          if (data.length === 0) {
            headerError = this.$t('No hay filas para procesar en el archivo.')
            self.productFeedback.errorText = headerError
            self.inProgress = false
            return
          }

          /* The number of columns is incorrect */
          console.log('COLUM ', header.length)
          if (header.length !== 19) {
            headerError = this.$t('La cabecera del archivo debe tener 19 columnas.')
            headerError += '<ul>'
            headerError += '  <li>' + this.$t("Verificar que la cabecera es 'ID de producto; SKU; Nombre; Nombre SKU; Descripción del producto; Marca; UPC; Indentificador; Precio regular; Precio con descuento; Precio válido hasta; URL de imágenes; Alto; Largo; Peso; Ancho; Inventario; Sitios; Url de videos'") + '</li>'
            headerError += '</ul>'
            self.productFeedback.errorText = headerError
            self.inProgress = false
            return
          }

          data.forEach((elem, index) => {
            let newRowElem = {
              'row': index,
              'idProd': elem[0],
              'sku': elem[1],
              'name': elem[2],
              'nameSku': elem[3],
              'description': elem[4],
              'brand': elem[5],
              'upc': elem[6],
              'refId': elem[7],
              'listPrice': elem[8],
              'price': elem[9],
              'priceValidUntil': elem[10],
              'image': elem[11],
              'height': elem[12],
              'length': elem[13],
              'weight': elem[14],
              'width': elem[15],
              'inventory': elem[16],
              '_cellVariants': null,
              'sites': elem[17],
              'video': elem[18],
            }

            if (elem.length !== 19) {
              newRowElem = {'_cellVariants': null}
              newRowElem.sku = 19 - elem.length > 0 ? this.$t('Faltan') + ` ${19 - elem.length} ` + this.$t('campos') : this.$t('Sobran') + ` ${elem.length - 19} ` + this.$t('campos')
              this.productFeedback.errorArray['columns'] = '<li>' + this.$t('Verifique que existan 19 columnas.') + '</li>'
            } else {
              newRowElem._cellVariants = {}
              let testUPC = this.testUPC(elem[6], newRowElem)
              let brandTestResult = this.testBrand(elem[5], newRowElem)
              let testName = this.testName(elem[2], newRowElem)
              let testNameSku = this.testNameSku(elem[3], newRowElem)
              let testIdentifier = this.testIdentifier(elem[7], newRowElem)
              let testDescription = this.testDescription(elem[4])
              let testPriceResult = this.testPrice(elem[8], elem[9], newRowElem)
              let testImageResult = this.testImages(elem[11], newRowElem)
              let testPriceValidUntil = this.testPriceValidUntil(elem[10], elem[9], elem[8], newRowElem)
              let testSizes = this.testSizes(elem[12], elem[13], elem[14], elem[15], newRowElem)
              let testInventory = this.testInventory(elem[16], newRowElem)
              if (testName || testDescription || testUPC || testImageResult || brandTestResult || testPriceResult || testPriceValidUntil || testSizes || testIdentifier || testInventory || testNameSku) {
                newRowElem._cellVariants.row = 'danger'
              } else {
                newRowElem._cellVariants.row = 'success'
              }
            }
            self.csvElements.push(newRowElem)
          })
          /* Show the error list */
          console.log(this.productFeedback.errorArray)
          if (Object.keys(this.productFeedback.errorArray).length > 0) {
            this.productFeedback.errorText += '<ul class="mb-0">'
            for (let errorKey in this.productFeedback.errorArray) {
              console.log(errorKey)
              this.productFeedback.errorText += this.productFeedback.errorArray[errorKey]
            }
            this.productFeedback.errorText += '</ul>'
          }
          this.inProgress = false
          this.$refs.productsTable.refresh()
        } catch (err) {
          console.log(err)
          this.inProgress = false
          this.productFeedback.errorText = this.$t('El archivo CSV de productos tiene fallas.')
        }
      }
      let extencion = this._getFileExtencion(file.name)
      console.log(file)
      console.log('File extencion: ' + extencion)
      if (appConfig.CSV_MIME_TYPES.includes(file.type)) {
        console.log('CSV File mime type recognized ---------------------')
        console.log(file.type)
        reader.readAsText(file, 'ISO-8859-1')
      } else {
        if (['csv', 'txt'].includes(extencion)) {
          console.log('Mime type non recognized, tried with file extencion')
          reader.readAsText(file, 'ISO-8859-1')
        }
      }
    },
    testDescription (description) {
      if (this.isEmptyData(description.trim())) {
        this.productFeedback.errorArray['description'] = '<li>' + this.$t('Verificar que la Descripción no esta vacía y es un texto válido.') + '</li>'
        return true
      }
      return false
    },
    testName (name, newRowElem) {
      if (name.length > 100 || this.isEmptyData(name.trim()) || !this.isValidText(name, true)) {
        newRowElem._cellVariants.name = 'danger'
        this.productFeedback.errorArray['name'] = '<li>' + this.$t('Verificar que el Nombre no está vacío, sea válido y contiene menos de 100 caracteres.') + '</li>'
        return true
      }
      return false
    },
    testNameSku (name, newRowElem) {
      if (name.length > 100 || this.isEmptyData(name.trim()) || !this.isValidText(name, true)) {
        newRowElem._cellVariants.nameSku = 'danger'
        this.productFeedback.errorArray['nameSku'] = '<li>' + this.$t('Verificar que el Nombre de SKU no está vacío, sea válido y contiene menos de 100 caracteres.') + '</li>'
        return true
      }
      return false
    },
    testInventory (inventory, newRowElem) {
      if (!this.isValidInteger(inventory, false)) {
        newRowElem._cellVariants.inventory = 'danger'
        this.productFeedback.errorArray['inventory'] = '<li>' + this.$t('Verificar que el inventario sea válido.') + '</li>'
        return true
      }
      return false
    },
    testIdentifier (testIdentifier, newRowElem) {
      if (!this.isValidText(testIdentifier, false)) {
        newRowElem._cellVariants.refId = 'danger'
        this.productFeedback.errorArray['indentifier'] = '<li>' + this.$t('Verificar que el identificador sea válido.') + '</li>'
        return true
      }
      return false
    },
    _getFileExtencion (fileName) {
      return (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName)[0] : undefined
    },
    testImages (imagesSrc, newRowElem) {
      let images = imagesSrc.split('|')
      if (imagesSrc.trim() === '') {
        newRowElem._cellVariants.image = 'danger'
        this.productFeedback.errorArray['images'] = '<li>' + this.$t('Verificar que hay imágenes seleccionadas.') + '</li>'
        return true
      }

      if (images.length > 7) {
        newRowElem._cellVariants.image = 'danger'
        this.productFeedback.errorArray['images'] = '<li>' + this.$t('Verificar la extensión de las imágenes, que la cantidad no sea mayor a 7 y que cada imagen tenga el tamaño adecuado (Min 500 x 500, max 2,000 x 2,000).') + '</li>'
        return true
      }

      images.forEach((imageSrc) => {
        let extencion = this._getFileExtencion(imageSrc.split('?')[0])
        if (extencion !== undefined) {
          if (appConfig.IMAGE_FILES_SUPPORTED.indexOf(extencion.toUpperCase()) === -1) {
            newRowElem._cellVariants.image = 'danger'
            this.productFeedback.errorArray['images'] = '<li>' + this.$t('Verificar la extensión de las imágenes, que la cantidad no sea mayor a 7 y que cada imagen tenga el tamaño adecuado (Min 500 x 500, max 2,000 x 2,000).') + '</li>'
            return true
          }
        }
      })

      if (images.length < 3) {
        newRowElem._cellVariants.image = 'danger'
        this.productFeedback.errorArray['images'] = '<li>' + this.$t('Se deben seleccionar como mínimo 3 imágenes') + '</li>'
        return true
      }

      return false
    },
    testPriceValidUntil (date, price, listPrice, newRowElem) {
      if (price === listPrice && this.isEmptyData(date)) {
        return false
      }

      if (price) {
        let myRegExp = /^\d{4}-\d{2}-\d{2}$/
        if (!date.match(myRegExp)) {
          newRowElem._cellVariants.priceValidUntil = 'danger'
          this.productFeedback.errorArray['priceValidUntil'] = '<li>' + this.$t('Verificar que las fecha sean válidas y no menores al día de hoy.') + '</li>'
          return true
        }

        let d = new Date(date)
        if (Number.isNaN(d.getTime())) {
          this.productFeedback.errorArray['priceValidUntil'] = '<li>' + this.$t('Verificar que las fecha sean válidas y no menores al día de hoy.') + '</li>'
          newRowElem._cellVariants.priceValidUntil = 'danger'
          return true
        }

        if (new Date(date) <= new Date()) {
          this.productFeedback.errorArray['priceValidUntil'] = '<li>' + this.$t('Verificar que las fecha sean válidas y no menores al día de hoy.') + '</li>'
          newRowElem._cellVariants.priceValidUntil = 'danger'
          return true
        }
      }
      return false
    },
    testSizes (height, length, weight, width, newRowElem) {
      let fail = false
      if (isNaN(height) || isNaN(length) || isNaN(weight) || isNaN(width)) {
        newRowElem._cellVariants.size = 'danger'
        this.productFeedback.errorArray['sizes'] = '<li>' + this.$t('Verificar que los valores para tamaño sean numéricos y distintos de 0.') + '</li>'
        fail = true
      }

      if (Number(height) < 1 || Number(length) < 1 || Number(weight) < 1 || Number(width) < 1) {
        newRowElem._cellVariants.size = 'danger'
        this.productFeedback.errorArray['sizes'] = '<li>' + this.$t('Verificar que los valores para tamaño sean numéricos y distintos de 0.') + '</li>'
        fail = true
      }

      return fail
    },
    testPrice (listPrice, price, newRowElem) {
      if (isNaN(listPrice) || Number(listPrice) === 0) {
        newRowElem._cellVariants.listPrice = 'danger'
        this.productFeedback.errorArray['price'] = '<li>' + this.$t('Verificar que el precio regular y precio con descuento sean números válidos') + '</li>'
        return true
      }

      if (price) {
        if (isNaN(price) || Number(price) === 0) {
          newRowElem._cellVariants.price = 'danger'
          this.productFeedback.errorArray['price'] = '<li>' + this.$t('Verificar que el precio regular y precio con descuento sean números válidos.') + '</li>'
          return true
        }
      }

      if (Number(listPrice) < Number(price)) {
        newRowElem._cellVariants.price = 'danger'
        newRowElem._cellVariants.listPrice = 'danger'
        this.productFeedback.errorArray['price'] = '<li>' + this.$t('Verificar que el precio regular y precio con descuento sean números válidos.') + '</li>'
        return true
      }

      return false
    },
    testUPC (upc, newRowElem) {
      let fail = this.isEmptyData(upc.trim()) || isNaN(upc) || upc.toString().toUpperCase().includes('E') || upc.trim().length > 20
      if (fail) {
        newRowElem._cellVariants.upc = 'danger'
        this.productFeedback.errorArray['upc'] = '<li>' + this.$t('Verificar que el UPC no esta vacío y es un valor numérico no mayor de 20 caracteres.') + '</li>'
        return true
      }
      return false
    },
    testBrand (brand, newRowElem) {
      let fail = true

      if (this.$store.getters.cacheBrands.indexOf(brand) > -1) {
        return false
      }
      let self = this
      this.getSearchBrands(brand).then(
        (response) => {
          console.log(response.data)
          if (response.data.length > 0) {
            if (response.data.find(brnd => brnd.name === brand)) {
              fail = false
              self.$store.dispatch('addBrand', brand)
            }
          }
          if (fail) {
            if (!this.productFeedback.errorText) this.productFeedback.errorText = this.$t('Se encontraron errores.')
            this.asyncErrorObj['brand'] = this.$t('Verificar que las marcas sean válidas.')
            newRowElem._cellVariants.brand = 'danger'
            this.$refs.productsTable.refresh()
            this.$forceUpdate()
            return true
          }
          return false
        },
        (error) => {
          console.log(error.body.message || this.$t('Error obteniendo datos de la aplicacion'))
        }
      )
    },
    replaceUl (text) {
      return text.replace('<ul class="mb-0">', '').replace('</ul>', '')
    }
  },

  data () {
    return {
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 50, 70, 100, 1000],

      productFeedback: {
        success: false,
        errorText: '',
        errorArray: {}
      },

      asyncErrorObj: {},

      attrFeedback: {
        success: false,
        errorText: ''
      },

      attrVarFeedback: {
        success: false,
        errorText: ''
      },

      inProgress: false,
      productsCount: 0,
      fileTypeSelected: 'CSV',
      tabIndex: 0,
      csvProductFile: null,
      delimiterProduct: null,
      delimiterAttr: null,
      delimiterAttrVar: null,
      csvProductAttrFile: null,
      csvProductVarAttrFile: null,
      xlsAlert: false,
      csvElements: [],
      csvFields: [
        { key: 'row', label: this.$t('Fila'), class: 'rowNumber' },
        { key: 'idProd', label: this.$t('Id del producto') },
        { key: 'sku', label: this.$t('Sku') },
        { key: 'upc', label: this.$t('Upc') },
        { key: 'refId', label: this.$t('Identificador') },
        { key: 'name', label: this.$t('Nombre del producto'), class: 'previewNameField' },
        { key: 'nameSku', label: this.$t('Nombre del SKU'), class: 'previewNameField' },
        { key: 'brand', label: this.$t('Marca') },
        { key: 'listPrice', label: this.$t('Precio regular') },
        { key: 'price', label: this.$t('Precio con descuento') },
        { key: 'priceValidUntil', label: this.$t('Fecha') },
        { key: 'image', label: this.$t('Imágenes') },
        { key: 'video', label: this.$t('Videos') },
        { key: 'size', label: this.$t('Tamaño') },
        { key: 'inventory', label: this.$t('inventario') },
        { key: 'sites', label: this.$t('Sitios') }
      ],
      csvAttrFields: [
        { key: 'fieldId', label: this.$t('Id Atributo') },
        { key: 'fieldName', label: this.$t('Atributo') },
        { key: 'fieldType', label: this.$t('Tipo de campo') },
        { key: 'attrId', label: this.$t('Id Valor seleccionado') },
        { key: 'attrValue', label: this.$t('Valor ingresado') }
      ],
      categorySelected: {
        id: null,
        text: this.$t('No hay categoría seleccionada')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .file-container {
      width: 75%;
      margin-right: 5px;
      display: inline-block;
      float: left;
  }

  .b-form-file.custom-file {
    .custom-file-control[data-choose] {
      &::before {
          content: "Seleccionar" !important;
      }
    }
  }

  .fa-exclamation-triangle {
    color: #f8cb00;
    font-size: 20px;
    float: left;
    margin-right: 5px;
    display: inline-block;
  }

  .categorySelected {
    display: inline-block;
    margin-left: 5px;
    line-height: 35px;
  }
</style>

<style lang="scss">

  .previewNameField > div {
    word-wrap: break-word;
    max-width: 300px;
  }

  .imageItem {
    width: 40px;
    margin-right: 3px;
    cursor: pointer;
    transition: transform .2s;
    &:hover {
      transform: scale(1.5);
    }
  }

  .overflow-x-auto {
    overflow-x: auto;
  }
</style>
