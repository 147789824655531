<template>
  <section class="notifications">
      <b-nav-item-dropdown ref="notificationDropdown" right no-caret toggle-class="notifications--dropdown-as-btn">
        <template slot="button-content" class="image-dropdown">
            <button class="btn rounded-circle notifications--bell-btn">
                <div class="notifications--bell-bag rounded-circle" :class="{
                    'major-valid':notifications.actives >= 10
                }" v-if="notifications.actives >= 1">
                  {{ notifications.actives >= majorNumberAllowed ? '9+': notifications.actives }}
                </div>
                <i class="notifications--bell-icon icon-bell"></i>
            </button>
        </template>
            <section class="notifications-wrapper">
                <header class="notifications-header">{{$t('Notificaciones')}}</header>
                <section class="notifications-body" v-if="ifEmptyNotification()">
                    <NotificationsTimeCard
                      v-for="notification of notifications.items" :key="notification.id"
                      :notification="notification"
                      @markRead="markAsReaded"
                      @hideNotify="hideNotifications"
                      :reading="currentRead"
                      />
                    <infinite-loading ref="InfiniteLoading" force-use-infinite-wrapper=".notifications-body" @infinite="infiniteHandler" :distance="0">
                      <div slot="no-more"></div>
                    </infinite-loading>
                </section>
            </section>
            <section class="notifications-footer" :class="{
              'updating-data': endpointResponse != ''
            }">
                <span v-if="endpointResponse != ''">
                  {{endpointResponse}}
                </span>
                <span v-if="ifEmptyNotification() && endpointResponse == ''">
                  {{notifications.actives}} {{notifications.actives == 1 ? $t('Nueva notificación') : $t('Nuevas notificaciones')}}
                </span>
               <section v-if="!ifEmptyNotification() && endpointResponse == ''" class="body-empty">
                  {{ $t('No tienes notificaciones.') }}
               </section>
            </section>
      </b-nav-item-dropdown>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import NotificationsTimeCard from './Notification__timeCard.vue'
  import {NotificationServiceMixim} from '@/mixims/NotificationServiceMixim.js'

  import InfiniteLoading from 'vue-infinite-loading'

  export default {
    name: 'HeaderNotifications',
    components: {
      NotificationsTimeCard,
      InfiniteLoading
    },
    mixins: [NotificationServiceMixim],
    data () {
      return {
        majorNumberAllowed: 10,
        endpointResponse: '',
        currentRead: '',
        params: {from: 0, size: 10}
      }
    },
    computed: {
      ...mapGetters(['notifications'])
    },
    mounted () {
      this.getAllNotifications()
      this.getUnreadNotifications()
    },
    methods: {
      getAllNotifications () {
        const _this = this
        this.getNotifications(this.params).then((response) => {
          if (response.body !== null) {
            _this.$store.dispatch('setNotifications', response.body)
          }
        })
      },
      getUnreadNotifications () {
        this.getNotifications(this.params, true).then((response) => {
          if (response.body !== null) {
            let totalCount = parseInt(response.headers.get('X-Total-Count'))
            this.$store.dispatch('setNewNotifications', totalCount)
          }
        })
      },
      ifEmptyNotification () {
        return this.notifications.items.length >= 1
      },
      markAsReaded (id) {
        this.endpointResponse = this.$t('Actualizando...')
        this.currentRead = id
        this.markNotificationRead(id).then((response) => {
          this.endpointResponse = ''
          this.currentRead = ''
          this.getUnreadNotifications()
          this.$store.dispatch('markAsReaded', id)
        },
        (error) => {
          this.endpointResponse = ''
          this.currentRead = ''
          this.endpointResponse = error.body.message || this.$t('Ha ocurrido un error')
        })
      },
      infiniteHandler ($state) {
        this.params.from += 1
        this.getNotifications(this.params).then((response) => {
          if (response.body && response.body.length && response.body !== null) {
            this.$store.dispatch('pushNotifications', response.body).then(success => {
              $state.loaded()
            })
          } else {
            $state.complete()
          }
        })
      },
      hideNotifications () {
        this.$refs.notificationDropdown.hide()
      }
    }
  }
</script>

<style lang="scss">
    @import './_notifiVars.scss';
    @import 'scss/_bootstrap-variables.scss';
    .test-section{
      border:1px solid red;
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p {
        text-align: center;
        width:100%;
      }
      button{
        width: 100%;
        margin-bottom:2px !important;
      }
    }

    @mixin btn-bell-icon {
        .notifications--bell-btn{
            width: 46px;
            height: 44px;
            padding: 0px !important;
            background-color: #556080 !important;
            position: relative;
            .notifications--bell-bag{
                position: absolute;
                background-color: $bag-notification-color;
                border:1px solid $local-header-color;
                width: 17px;
                height: 17px;
                top: 5px;
                left: 6px;
                color: $local-header-color;
                font-weight: bold;
                text-align: center;
                &:not(.major-valid){
                    line-height: 1.5;
                    font-size: 9px;
                }
                &.major-valid{
                    font-size: 7px;
                    line-height: 2;
                }
            }
            .notifications--bell-icon.icon-bell{
                font-size: 19px;
                color: $bell-icon-color;
            }
        }
    }

    @mixin notifications-wrapper {
        .notifications{
            position: relative;
            @include btn-bell-icon;
            .notifications-wrapper{
                .notifications-header{
                    padding: 15px 30px;
                    text-align:center;
                    border-bottom: 4px solid $header-border-bottom-color;
                    font-weight: 700;
                    font-size: 17px;
                    color: $header-font-color;
                }
                .notifications-body{
                    max-height: 344px;
                    overflow-y: scroll;
                    scroll-behavior: smooth;
                     /* width */
                     &::-webkit-scrollbar {
                        width: 5px;
                    }

                    /* Track */
                    &::-webkit-scrollbar-track {
                        background: $background-dropdown-notifications;
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: darken($primary-color, 0);
                    }

                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover {
                        background: darken($primary-color, 5);
                    }
                }
            }
            .notifications-footer{
                border-top: 3px solid $header-border-bottom-color;
                padding:15px;
                text-align: center;
                font-size: 13px;
                font-weight: 600;
                cursor: pointer;
                &.updating-data{
                  background-color: $primary-color !important;
                  color: $bell-icon-color;
                }
                .body-empty{
                    padding: 20px 0px;
                    color: $header-font-color;
                    text-align:center;
                    font-weight: 600;
                    font-size: 12px;
                }
            }
        }
    }
    @mixin custom-notification-dropdown {
        // Configurando solo este dropdown
        .nav-item.b-nav-dropdown.dropdown{
            &[toggle-class="notifications--dropdown-as-btn"]{
                &.show{
                    // boton bell activo
                    .notifications--bell-btn{
                        background-color: darken($primary-color, 10) !important;
                        .notifications--bell-bag{
                            border:1px solid darken($primary-color, 10) !important;
                        }
                    }
                }
                .dropdown-item{
                    margin: 0px;
                    padding: 0px;
                    white-space: normal !important;
                }
                .dropdown-menu.dropdown-menu-right{
                    min-width: 19rem;
                    border:1px solid $dropdown-border-color;
                    a.dropdown-item{
                        max-width: 298px !important;
                        background-color: $background-dropdown-notifications;
                    }
                }
            }
        }
    }

    ul.navbar-nav{
       @include notifications-wrapper;
       @include custom-notification-dropdown;
    }
</style>
