<template>
  <b-container>
    <b-row>
      <b-col>
        <b-form-group
          id="nameInputGroup"
          :label-cols="2"
          breakpoint="md"
          :description="$t('Nombre del impuesto')"
          :invalid-feedback="
            !state.name ? $t('No es un valor válido') : ''
          "
          :state="state.name"
          :label="$t('Nombre')"
          label-for="nameInput"
        >
          <b-form-input id="nameInput" v-model="taxModel.name"> </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          id="descriptionAreaGroup"
          :label-cols="2"
          breakpoint="md"
          :description="$t('Descripción del impuesto')"
          :invalid-feedback="
            !state.description
              ? $t('No es un valor válido')
              : ''
          "
          :state="state.description"
          :label="$t('Descripción')"
          label-for="descriptionArea"
        >
          <b-form-textarea
            id="descriptionArea"
            :rows="3"
            :max-rows="6"
            v-model="taxModel.description"
          >
          </b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          id="zipCodeStartGroup"
          :label-cols="2"
          breakpoint="md"
          :invalid-feedback="
            !state.zipCodeStart
              ? $t('No es un valor válido')
              : ''
          "
          :state="state.zipCodeStart"
          :label="$t('Código Postal Desde')"
          label-for="zipCodeStartInput"
        >
          <b-form-input id="zipCodeStartInput" type="number" v-model="taxModel.zipCodeStart">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="zipCodeEndGroup"
          :label-cols="2"
          breakpoint="md"
          :invalid-feedback="
            !state.zipCodeEnd
              ? $t('No es un valor válido')
              : ''
          "
          :state="state.zipCodeEnd"
          :label="$t('Código Postal Hasta')"
          label-for="zipCodeEndInput"
        >
          <b-form-input id="zipCodeToInput" type="number" v-model="taxModel.zipCodeEnd">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          id="percentageInputGroup"
          :label-cols="2"
          breakpoint="md"
          :invalid-feedback="
            !state.percentage
              ? $t('No es un valor válido')
              : ''
          "
          :state="state.percentage"
          :label="$t('Porcentaje')"
          label-for="percentageInput"
        >
          <b-form-input id="percentageInput" type="number" v-model="taxModel.percentage">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="activeInputGroup"
          :label-cols="2"
          breakpoint="md"
          :label="$t('Impuesto habilitado')"
        >
          <c-switch
            inputType="checkbox"
            type="text"
            variant="success"
            :on="$t('Si')"
            :off="$t('No')"
            :pill="true"
            v-model="taxModel.isActive"
            size="m"
            id="s0"
          ></c-switch>
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col cols="12">
        <b-button class="float-right" @click="closeModal()">{{$t('Cancelar')}}</b-button>
        <b-button class="float-right mr-2" variant="success" :disabled="disabledSave" @click="handleClick()">{{taxModel.id ? 'Editar' : 'Crear'}}</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import cSwitch from "@/components/common/Switch";
import { ValidationsMixim } from "@/mixims/ValidationsMixim.js";
import { TaxesServiceMixin } from "@/mixims/TaxesServiceMixin.js";
export default {
  props: ["refreshPage", "closeModal", "taxSelected"],
  components: {
    cSwitch,
  },
  mixins: [ValidationsMixim, TaxesServiceMixin],
  data() {
    return {
      taxModel: {
        id: null,
        name: "",
        description: "",
        isActive: true,
        percentage: null,
        zipCodeStart: "",
        zipCodeEnd: "",
      },
    };
  },
  methods: {
    handleClick () {
      const action = this.taxModel.id === null ? this.createTax : this.updateTax
      action(this.taxModel).then(
        (response) => {
          console.log(response)
          const textMessage = this.taxModel.id === null ? 'Impuesto creado exitosamente' : 'Impuesto editado exitosamente'
          this.$emit('showMessage', {text:  textMessage, variant: 'success'})
          this.refreshPage()
          this.closeModal()
        },
        (error) => {
          const textMessage = error.body.message || this.taxModel.id === null ? 'Ha ocurrido un error al crear el impuesto' : 'Ha ocurrido un error al editar el impuesto'
          this.$emit('showMessage', {text:  textMessage, variant: 'danger'})
        }
      )
    }
  },
  computed: {
    disabledSave () {
      return Object.values(this.state).includes(false)
    },
    state () {
      return  {
        name: this.isValidText(this.taxModel.name, true),
        description: this.isValidTextArea(this.taxModel.description, true),
        percentage: this.isValidInteger(this.taxModel.percentage, true),
        zipCodeStart: this.isValidInteger(this.taxModel.zipCodeStart, true),
        zipCodeEnd: this.isValidInteger(this.taxModel.zipCodeEnd, true),
      }
    }
  },
  watch: {
    taxSelected (val) {
      this.taxModel = val
    }
  }
};
</script>

<style>
</style>