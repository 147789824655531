<template>
  <b-container fluid>
    <div>
      <b-modal header-bg-variant="primary" centered size="lg" @shown="onShown" @hide="onHide" ref="controversyModal" id="controversyModal" :title="title" o-close-on-backdrop="true" @ok="createControversyActionButton" :cancel-title="$t('Cancelar')" :ok-disabled="!isValidTextArea(controversyData.text, true)" :ok-title="okTitle" >
        <moon-loader :loading="controversyData.actionInProgress" color="#000" size="30px"></moon-loader>
        <b-row class="alert alert-danger" v-show="errorText !== ''"><i class="fa fa-exclamation-circle"></i>{{errorText}}</b-row>
        <b-col v-show="!controversyData.actionInProgress" ref="controversyForm">
          <b-form-group
              :invalid-feedback="!isValidTextArea(controversyData.text, true) ? $t('El campo esta vacío') : ''"
              :state="isValidTextArea(controversyData.text, true)"
          >
            <b-form-textarea id="textarea1"
               v-model="controversyData.text"
               :placeholder="$t('Ingrese el texto de la controvesia.')"
               :rows="3"
               :max-rows="6">
             </b-form-textarea>
           </b-form-group>
           <b-form-group
             :label="$t('Seleccione el tipo de controvesia')"
             v-if="!Boolean(controversySelected)"
            >
              <b-form-select
                v-model="controversyData.tag"
                :options="ctOptions"
              >
              </b-form-select>
            </b-form-group>
        </b-col>
      </b-modal>
    </div>
  </b-container>
</template>

<script>

import {ControversyServiceMixim} from '../../mixims/ControversyServiceMixim.js'
import {OrdersServiceMixim} from '../../mixims/OrdersServiceMixim'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import appConfig from '@/config/applicationConfig.js'
import { ValidationsMixim } from '@/mixims/ValidationsMixim.js'

export default {
  name: 'controversy-modal',
  components: {
    MoonLoader
  },
  mixins: [ControversyServiceMixim, OrdersServiceMixim, ValidationsMixim],
  props: ['visible', 'controversySelected', 'isGoingToAttend', 'order'],
  data () {
    return {
      title: null,
      okTitle: '',
      errorText: '',
      controversyData: {
        text: null,
        tag: appConfig.TAGS_MAPPING['CT'].key,
        actionInProgress: false
      },
      tagsMapping: appConfig.TAGS_MAPPING
    }
  },
  watch: {
    visible (newVal, oldVal) {
      if (this.visible === true) {
        this.$refs.controversyModal.show()
      }
    }
  },
  computed: {
    ctOptions: function () {
      let data = Object.entries(this.tagsMapping)
      let options = []

      for (var i = 0; i < data.length; i++) {
        if (data[i][1].isCt === true) {
          options.push(
            {
              text: `${this.$t(data[i][1].text)} [${data[i][1].key}]`,
              value: data[i][1].key
            }
          )
        }
      }
      return options
    }
  },
  methods: {
    onShown (evt) {
      this.controversyData.text = null
      this.controversyData.tag = appConfig.TAGS_MAPPING['CT'].key

      if (this.isGoingToAttend === true) {
        this.title = this.$t('Atender Controversia')
        this.okTitle = this.$t('Atender Controversia')
      } else {
        this.okTitle = this.controversySelected ? this.$t('Agregar Nota') : this.$t('Crear Controversia')
        this.title = this.controversySelected ? this.$t('Agregar Nota') : this.$t('Crear Controversia')
      }
    },
    onHide (evt) {
      this.$emit('onControversyModalHide')
    },

    _performAction () {
      /* The id is not set, so is a controversy creation flow */
      this.errorText = ''
      if (!this.controversySelected) {
        return this._createControversy()
      } else {
        if (this.isGoingToAttend === true) {
          return this._attendControversy()
        } else {
          return this._addNote()
        }
      }
    },

    _attendControversy () {
      this.controversyData.actionInProgress = true
      /* Attend the controversy */
      this.updateControversy(this.order.id, this.controversySelected.id, true).then(
        () => {
          /* Mark the row as attended */
          this.controversySelected._rowVariant = 'success'
          this.controversySelected.active = true

          /* Add the note just in case we have one */
          if (this.controversyData.text === null) {
            this.controversyData.actionInProgress = false
            this.controversyData.text = null
            this.$refs.controversyModal.hide()
            return Promise.resolve('Success')
          }

          return this.addControversyNote(this.order.id, this.controversySelected.id, this.controversyData.text).then(
            (newComment) => {
              this.controversySelected.comments.push(newComment.body)
              this.controversyData.actionInProgress = false
              this.controversyData.text = null
              this.$refs.controversyModal.hide()
            },
            (error) => {
              this.errorText = error.body.message || this.$t('Controvercia atendida pero la nota de atencion no fue creada')
            }
          )
        },
        (error) => {
          this.errorText = error.body.message || this.$t('error atendiendo la controvercia')
        }
      )
    },

    _addNote () {
      this.controversyData.actionInProgress = true
      this.addControversyNote(this.order.id, this.controversySelected.id, this.controversyData.text).then(
        (newComment) => {
          /* Update the order controversies list */
          this.controversySelected.comments.push(newComment.body)
          this.controversyData.actionInProgress = false
          this.controversyData.text = null
          this.$refs.controversyModal.hide()
        },
        (error) => {
          this.errorText = error.body.message || this.$t('error agregando la nota a la controvercia')
        }
      )
    },
    _createControversy () {
      this.controversyData.actionInProgress = true
      let text = `<b>[${this.tagsMapping[this.controversyData.tag].text}]</b> ${this.controversyData.text}`
      this.createControversy(this.order.id, text, this.controversyData.tag).then(
        (newControversy) => {
          /* Update the order controversies list */
          if (!this.order.controversies) {
            this.order.controversies = []
          }
          newControversy.body._rowVariant = 'danger'
          this.order.controversies.push(newControversy.body)

          if (this.order.tags.indexOf(this.controversyData.tag) === -1) {
            this.order.tags.push(this.controversyData.tag)
          }

          this.controversyData.actionInProgress = false
          this.$refs.controversyModal.hide()
        },
        (error) => {
          this.errorText = error.body.message || this.$t('error creando la controvercia')
        }
      )
    },
    createControversyActionButton (e) {
      e.preventDefault()
      this.controversyData.actionInProgress = true
      this._performAction()
    }
  }
}
</script>

<style lang="scss" scoped>
  p {
    margin-bottom: 2px;
  }

 .fa-exclamation-circle {
   margin-top: 3px;
   margin-right: 3px;
 }

  img.preview {
    width: 180px;
    background-color: white;
    border: 1px solid #DDDDDD;
    padding: 5px;
    cursor: pointer;
  }
</style>
