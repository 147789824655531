<template>
  <b-modal header-bg-variant="primary" centered ref="confirmDeleteModal" id="confirmDeleteModal" size="lg" :title="$t('Confirmar borrado')" o-close-on-backdrop="true" @ok="handleDeleteModal" :ok-title="$t('Aceptar')" :cancel-title="$t('Cancelar')">
    <h4 class="text-center">{{$t('¿Esta seguro que desea eliminar el Punto de retiro seleccionado?')}}</h4>
    <br>
    <h5 class="text-center">{{$t('Punto de retiro a eliminar:')}} {{pickupPoint.name}}</h5>
  </b-modal>
</template>

<script>
import { PickupPointServiceMixin } from '@/mixims/CarrierServiceMixin.js'
export default {
  mixins: [PickupPointServiceMixin],
  props: {
    pickupPoint: {
      type: Object,
      default: null
    }
  },
  methods: {
    showConfirmModal () {
      this.$refs.confirmDeleteModal.show()
    },
    handleDeleteModal () {
      this.deletePickupPoint(this.pickupPoint.id).then(
        response => {
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            this.$refs.confirmDeleteModal.hide()
            this.$emit('showMessage', { message: 'Punto de retiro eliminado exitosamente', variant: 'success' })
          } else {
            this.$refs.confirmDeleteModal.hide()
            this.$emit('showMessage', { message: 'Error al eliminar el Punto de retiro', variant: 'danger' })
          }
        }
      )
    }
  }
}
</script>

<style>

</style>