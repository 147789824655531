<template>
  <b-container fluid class="animated fadeIn">
    <div v-show="errorText !== ''" class="alert alert-danger" role="alert" dismissible @dismised="errorText = ''">
      {{errorText}}
    </div>
    <b-alert :show="successText !== ''" variant="success" dismissible>
      {{successText}}
    </b-alert>

    <b-row class="mb-3">
      <b-col md="12">
        <button type="button" class="btn btn-primary float-right mr-2"  @click.stop="showFilters = !showFilters">
          <span v-show="showFilters"><i class="fa fa-times" /></span>
          <span v-show="!showFilters"><i class="fa fa-filter" /></span>
           {{$t('Filtros')}}
        </button>
      </b-col>
    </b-row>

    <transition name="fade">
      <div class="filters mt-3 p-3 mb-3" v-show="showFilters">
        <b-row>
          <b-col lg="4">
            <b-form-group
              :label="$t('Buscar')"
              :invalid-feedback="!isValidText(filters.text, false)? $t('No es un valor válido') : ''"
              :state="isValidText(filters.text, false)"
              class="mb-0"
              :description="$t('Ingrese el texto a buscar')">
              <b-input-group>
                <b-form-input v-model="filters.text" :placeholder="$t('Texto a buscar')" />
                <b-input-group-button>
                  <b-btn @click="filterTable">{{$t('Buscar')}}</b-btn>
                </b-input-group-button>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group :label="$t('Transportadora')" class="mb-3">
              <b-form-select :options="carriers" v-model="filters.reference">
                <option slot="first" :value="null">{{$t('Todas')}}</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          
          <b-col>
            <b-input-group-button class="float-right mt-4">
              <b-button :disabled="loading || filterDisabled" :block="true" variant="primary" @click.stop="filterTable">{{$t('Filtrar')}}</b-button>
            </b-input-group-button>
            <b-input-group-button class="float-right mt-4 mr-3">
              <b-button :disabled="loading" :block="true" variant="primary" @click.stop="cleanFilters">{{$t('Mostrar Todos')}}</b-button>
            </b-input-group-button>
          </b-col>
        </b-row>
      </div>
    </transition>

    <b-row class="mt-3">
      <b-col class="col-md-auto">
        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="mb-3" />
      </b-col>
      <b-col class="col-md-auto">
        <b-form-group
          id="perPage"
          horizontal
          :label="$t('Mostrar por página')"
          >
          <b-form-select :options="pageOptions" v-model="perPage" for="perPage"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col md="10">
        <b>{{totalRows}}</b> {{$t('integraciones encontradas')}}
      </b-col>
    </b-row>
    <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Listado de integraciones')">
      <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
      <b-table v-show="!loading" id="mainTable"
         ref="mainTable"
         show-empty
         striped
         hover
         stacked="xl"
         :items="itemProvider"
         foot-clone
         :fields="fieldsAdmin"
         :current-page="currentPage"
         :per-page="perPage"
         :sort-by.sync="sortBy"
         :sort-desc.sync="sortDesc"
         @filtered="onFiltered"
         :empty-text="$t('No hay integraciones para mostrar')"
         :empty-filtered-text="$t('No hay integraciones para mostrar')">
         <template slot="accountName" slot-scope="row">
            {{row.item.accountName? row.item.accountName : 'No definido'}}
         </template>
         <template slot="integrationDataId" slot-scope="row">
           {{row.item.integrationDataId}}
         </template>
         <template slot="actions"  slot-scope="row">
            <b-button class="primary" size="sm" variant="link" @click.stop="row.toggleDetails">
              <span v-show="!row.detailsShowing"><i class="fa fa-plus"></i></span>
              <span v-show="row.detailsShowing"><i class="fa fa-minus"></i></span>
               {{$t('Sellers_label')}}
            </b-button>
          </template>
          <template slot="row-details" slot-scope="row">
            <div>
              <integrations-details
                :filterIntegration="row.item.id"
                :filteraccountName="filters.accountName"
                :showIncidenceDetail="true"
              >
              </integrations-details>
            </div>
          </template>
      </b-table>
    </b-card>

    <b-modal :ok-title="$t('Guardar')" :cancel-title="$t('Cancelar')" :ok-disabled="saveDisabled" header-bg-variant="primary" @ok="changeIntegration(selIntegration)" @cancel="resetIntegrationData" size="lg" ref="integrationModal" id="integrationModal" centered :title="$t('Editar integración')">
      <moon-loader :loading="loadingModal" color="#000" size="30px"></moon-loader>
      <b-row v-if="selIntegration.integrationData"> 
        <b-col md="8" v-for="(data, index) in selIntegration.integrationData" :key="index">
          <b-form-group
            horizontal
            :label-cols="2"
            :label="data.type === 'RADIO' && data.name === 'integrationType' ? $t('Guía') : data.name"
            :invalid-feedback="!stateIntegrationData(selIntegration.values[data.name]) ? `${$t('Campo')} ${data.type === 'RADIO' && data.name === 'integrationType' ? $t('Guía') : data.name}${$t('inválido')}` : ''"
            :state="stateIntegrationData(selIntegration.values[data.name])"
          >
            <b-form-input v-model="selIntegration.values[data.name]" :type="'text'" v-if="data.type === 'STRING'"></b-form-input>
            <b-form-input v-model="selIntegration.values[data.name]" :type="'number'" v-if="data.type === 'NUMBER'"></b-form-input>
            <b-form-radio-group
              class="custom-carrier-form pt-1"
              v-if="data.type === 'RADIO'"
              v-model="selIntegration.values[data.name]"
              :options="data.values">
            </b-form-radio-group>
            <b-form-radio-group
              class="custom-carrier-form pt-1"
              v-if="data.type === 'RADIO' && data.name === 'integrationType'"
              v-model="selIntegration.values[data.name]"
              :options="[{ text: $t('Guía manual'), value: 'MANUAL' }, { text: $t('Guía automática'), value: 'AUTOMATIC' }]">
            </b-form-radio-group>
            <b-form-select
              v-if="data.type === 'COMBO'"
              v-model="selIntegration.values[data.name]"
              :options="data.values">
            </b-form-select>
            <b-form-radio-group
              class="custom-carrier-form pt-1"
              v-if="data.type === 'BOOLEAN'"
              v-model="selIntegration.values[data.name]"
              :options="[{value: true, text: $t('Si')}, {value: false, text: $t('No')}]">
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-else>
        <h6 v-if="!loadingModal" class="px-3 py-5 font-weight-bold text-center">{{$t('No se cuentan con campos editables para la integración seleccionada, debe agregarse y relacionarse la data de integración.')}}</h6>
      </b-row>
    </b-modal>
  </b-container>
</template>
<script>

import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import { CarrierServiceMixin } from '@/mixims/CarrierServiceMixin'
import appConfig from '@/config/applicationConfig.js'
import { ValidationsMixim } from '@/mixims/ValidationsMixim.js'
import { IntegrationServiceMixin } from '@/mixims/IntegrationServiceMixin.js'
import IntegrationsDetails from '@/components/integrations/IntegrationsDetails'

export default {
  name: 'integrations-list',
  components: { MoonLoader, IntegrationsDetails },
  mixins: [CarrierServiceMixin, ValidationsMixim, IntegrationServiceMixin],
  computed: {
    organizations () {
      return this.$store.getters.organizations
    },
    carriers () {
      try {
        return this.$store.getters.carriers.map(carrier => {
          return {text: carrier.name, value: carrier.id}
        })
      } catch (e) {
        console.log(e)
        return []
      }
    },
    filterDisabled () {
      if (this.isAdmin) {
        return false
      }
      return false
    },
    saveDisabled () {
      if (!this.selIntegration || !this.selIntegration.integrationData) {
        return true
      }

      return false
    }
  },
  data () {
    return {
      locale: appConfig.LOCALE,
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : '$',
      countries: appConfig.COUNTRY_CODES,
      isAdmin: this.$store.getters.isAdmin,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [ 10, 15, 25, 50, 100 ],
      success: null,
      errorText: '',
      successText: '',
      loading: true,
      loadingModal: false,
      toDelete: null,
      disableFilterButton: false,
      showFilters: true,
      carrierList: null,
      selIntegration: {values: {}},
      filters: {
        accountName: null,
        carrierId: null,
        text: null,
        reference: null
      },
      sortBy: null,
      sortDesc: true,
      selected: [],
      currentPageElement: [],
      fieldsAdmin: [
        { key: 'name', label: this.$t('Integración'), 'class': 'text-left', sortable: false },
        { key: 'type', label: this.$t('Tipo'), 'class': 'text-left', sortable: false },
        { key: 'id', label: this.$t('ID'), 'class': 'text-left', sortable: false },
        { key: 'actions', label: this.$t('Acciones'), 'class': 'text-right' }
      ],
      currentActionGroupSelection: null,
      groupAction: null
    }
  },
  methods: {
    confirmationModalCancelHandler () {
      this.currentActionGroupSelection = null
    },
    resetIntegrationData () {
      this.selIntegration = {}
    },
    showActionModal (action) {
      this.groupAction = action
      this.$refs.confirmationModal.show()
    },
    _execDelete () {
      this.loading = true
      this.deleteShippingrates(this.toDelete).then(
        () => {
          this.loading = false
          this.$refs.mainTable.refresh()
        },
        (error) => {
          this.errorText = error.body.message || this.$t('Error eliminando la integración')
          this.loading = false
        }
      )
    },
    changeIntegration (integration) {
      let integrationData = {}
      integrationData.values = integration.values
      integrationData.id = integration.id
      integrationData.accountName = integration.accountName
      integrationData.integrationDataId = integration.integrationDataId
      this.postIntegrations(integrationData, integration.id).then(
        () => {
          this.$refs.mainTable.refresh()
          this.successText = this.$t('Datos de integración modificados satisfactoriamente')
        },
        (error) => {
          this.errorText = error.body.message || this.$t('Error guardando la integración')
        }
      )
    },
    rowDbClick (item) {
      this.successText = ''
      this.selIntegration = {}
      this._getIntegrationsData(item)
      this.$refs.integrationModal.show()
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filterTable () {
      this.$refs.mainTable.refresh()
      this.errorText = ''
    },
    cleanFilters () {
      this.filters.accountName = null
      this.filters.carrierId = null
      this.filters.text = null
      this.filters.reference = null
      this.$refs.mainTable.refresh()
      if (this.$route.query.carrierId) this.$router.push(this.$route.path).catch(() => {})
    },
    itemProvider (ctx) {
      this.loading = true
      return this.getIntegrationsData(ctx, this.filters).then(response => {
        this.loading = false
        this.totalRows = parseInt(response.headers.get('X-Total-Count'))
        if (response.body && response.body.length === 1 && this.$route.query.accountName) {
          response.body[0]['_showDetails'] = true
        }
        return response.body
      },
      () => {
        console.log('error obtaining Integrations')
      })
    },
    _getIntegrationsData (item) {
      this.loadingModal = true
      if (!item.integrationDataId || item.integrationDataId === null) {
        this.loadingModal = false
        this.selIntegration = {values: {}}
        return false
      }
      this.getIntegrationsDataById(item.integrationDataId).then(
        response => {
          this.loadingModal = false
          if (response.body) {
            this.selIntegration = {integrationData: [], values:{}}
            if (item.values) {
              this.selIntegration.values = {...item.values}
            }
            this.selIntegration.integrationData = response.body.values
            this.selIntegration.id = item.id
            this.selIntegration.accountName = item.accountName
            this.selIntegration.integrationDataId = item.integrationDataId
          }
        },
        error => {
          this.errorText = error.body.message || this.$t('Error obteniendo data de integración')
          this.loadingModal = false
          return []
        }
      )
    },
    stateIntegrationData (field) {
      return this.isValidText(field, true)
    }
  },
  mounted () {
    this.loading = true
  },
  created () {
    this.loading = true
    if (this.$route.query.carrierId) {
      this.filters.reference = this.$route.query.carrierId
    }
    if (this.$route.query.accountName) {
      this.filters.accountName = this.$route.query.accountName
    }
  }
}
</script>

<style lang="scss" scoped>
  .filters {
    border: 1px solid #e3e8ec;
    background-color: #FFF
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .separator {
    border-top: 1px solid #CCCCCC;
  }

  #timeCostInput {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .vue-input-tag-wrapper {
    border: 1px solid #e3e8ec !important;
  }

  /deep/ .integration-page-legend legend {
      padding-top: 0 !important;
      line-height: 18px;
  }

</style>
