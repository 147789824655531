<template>
<div>
    <attributes-updater></attributes-updater>
</div>
</template>

<script>

import AttributesUpdater from '@/components/catalog/AttributesUpdater'
export default {
  name: 'attributes-updater-view',
  components: {
    AttributesUpdater
  },
  mixins: [],
  methods: {
  },
  data () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
