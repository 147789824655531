<template>
<b-col class="p-0">
  <b-row>
    <b-col cols="6">
      <b-card :header="$t('Precios')" class="my-3" border-variant="primary" header-bg-variant="primary">
        <b-row>
          <b-col md="6" class="px-4 py-2">
            <b-form-group
              id="precioRegularInput"
              :label-cols="2"
              breakpoint="md"
              :invalid-feedback="validPrecioRegularInput"
              :state="validPrecioRegularInput === ''"
              :label="$t('Precio regular')"
              label-for="precioRegularFormatterHelp">
              <b-form-input
                id="precioRegularFormatterHelp"
                :disabled="pricesDisabled"
                v-model="priceData.listPrice"
                type="number">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" class="px-4 py-2">
            <b-form-group id="precioDescuentoInput"
              :label-cols="2"
              breakpoint="md"
              :label="$t('Precio con descuento')"
              :invalid-feedback="precioDescuentoInput"
              :state="precioDescuentoInput === ''"
              label-for="precioDescuentoFormatterHelp">
              <b-form-input
                id="precioDescuentoFormatterHelp"
                :disabled="pricesDisabled"
                v-model="priceData.price"
                type="number">
              </b-form-input>
            </b-form-group>
            <div v-show="priceOff !== '0'" class="priceOff">
              {{priceOff}}% {{$t('de descuento.')}}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" class="px-4 py-2">
            <b-form-group
              id="válidoHastaFromGroup"
              :label-cols="2"
              breakpoint="md"
              oncontextmenu="return false;"
              :label="$t('Válido hasta')"
              :invalid-feedback="!validPriceUntil? $t('Fecha seleccionada inválida') : ''"
              :state="validPriceUntil"
              label-for="válidoHastaFormatterHelp"
              :description="priceData.price > 0 && $t('Introduzca una fecha válida para el precio con descuento')">
              <date-picker v-on:contextmenu.native.stop.prevent v-on:keydown.native.stop.prevent id="válidoHasta"
                v-model="priceData.priceValidUntil" :disabled="pricesDisabled" :config="config"></date-picker>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col cols="6">
      <b-card :header="$t('Medidas con empaque')" class="my-3" border-variant="primary" header-bg-variant="primary">
        <b-row>
          <b-col md="6" class="px-4 py-2">
            <b-form-group
              id="width"
              :label-cols="2"
              oncontextmenu="return false;"
              breakpoint="md"
              :label="$t('Ancho cm')"
              :invalid-feedback="!isValidFloat(dimension.width, true, true) && !sizesDisabled? $t('No es un valor válido') : ''"
              :state="isValidFloat(dimension.width, true, true)"
              label-for="widthInput">
              <b-form-input
                id="widthInput"
                :disabled="sizesDisabled"
                v-model="dimension.width">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" class="px-4 py-2">
            <b-form-group
              id="height"
              :label-cols="2"
              oncontextmenu="return false;"
              breakpoint="md"
              :label="$t('Alto cm')"
              :invalid-feedback="!isValidFloat(dimension.height, true, true) && !sizesDisabled? $t('No es un valor válido') : ''"
              :state="isValidFloat(dimension.height, true, true)"
              label-for="heightInput">
              <b-form-input
                id="heightInput"
                :disabled="sizesDisabled"
                v-model="dimension.height">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" class="px-4 py-2">
            <b-form-group
              id="length"
              :label-cols="2"
              oncontextmenu="return false;"
              breakpoint="md"
              :label="$t('Largo cm')"
              :invalid-feedback="!isValidFloat(dimension.length, true, true) && !sizesDisabled? $t('No es un valor válido') : ''"
              :state="isValidFloat(dimension.length, true, true)"
              label-for="lengthInput">
              <b-form-input
                id="lengthInput"
                :disabled="sizesDisabled"
                v-model="dimension.length">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" class="px-4 py-2">
            <b-form-group
              id="weight"
              :label-cols="2"
              breakpoint="md"
              oncontextmenu="return false;"
              :label="$t('Peso gr')"
              :invalid-feedback="!isValidFloat(dimension.weight, true, true) && !sizesDisabled? $t('No es un valor válido') : ''"
              :state="isValidFloat(dimension.weight, true, true)"
              label-for="weightInput">
              <b-form-input
                id="weightInput"
                :disabled="sizesDisabled"
                v-model="dimension.weight">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" class="px-4 py-2">
            <b-form-group
              id="cubicWeight"
              :label-cols="2"
              oncontextmenu="return false;"
              breakpoint="md"
              :description="$t('Este valor es el volumen calculado del tamaño del empaque')"
              :label="$t('Volumen cm3 (Alto x Largo x Ancho)')"
              label-for="cubicWeightInput">
              <b-form-input
                type="number"
                id="cubicWeightInput"
                :disabled=true
                v-model="cubicWeight">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</b-col>
</template>
<script>

import Vue from 'vue'
import datePicker from 'vue-bootstrap-datetimepicker'
import { ProductServiceMixim } from '@/mixims/ProductServiceMixim.js'
import { ValidationsMixim } from '@/mixims/ValidationsMixim.js'
import Big from 'big.js'

Vue.use(datePicker)

export default {
  name: 'price-and-dimencion',
  mixins: [ProductServiceMixim, ValidationsMixim],
  component: { datePicker },
  props: ['priceData', 'dimension', 'pricesDisabled', 'sizesDisabled'],
  computed: {
    cubicWeight: {
      get: function () {
        if (this.isValidFloat(this.dimension.length, true) && this.isValidFloat(this.dimension.width, true) && this.isValidFloat(this.dimension.height, true)) {
          return new Big(this.dimension.length).times(this.dimension.width).times(this.dimension.height)
        }
        return ''
      },
      set: function (newValue) {
        this.dimension.cubicWeight = newValue
      }
    },
    validPrecioRegularInput: function () {
      if (this.pricesDisabled || !this.priceData) return ''
      if (!this.isValidFloat(this.priceData.listPrice, true, true) || parseFloat(this.priceData.listPrice) < 0) {
        return this.$t('El precio es inválido')
      }
      return ''
    },
    precioDescuentoInput: function () {
      if (this.pricesDisabled) return ''
      if (!this.isEmptyData(this.priceData.price)) {
        if (!this.isValidFloat(this.priceData.price, true) || parseFloat(this.priceData.price) < 0) {
          return this.$t('El precio es inválido')
        }
        if(parseFloat(this.priceData.price) <= 0) {
          return this.$t('No se admite precio de descuento igual a 0')
        }
        if (this.isValidFloat(this.priceData.listPrice, true, true)) {
          if (parseFloat(this.priceData.listPrice) < parseFloat(this.priceData.price)) {
            return this.$t('El precio de descuento debe ser menor o igual que el precio regular')
          }
        }
      }
      return ''
    },
    validPriceUntil: function () {
      if (!this.priceData.priceValidUntil || this.pricesDisabled) return true
      let dateSelected = new Date(this.priceData.priceValidUntil)
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      return today < dateSelected
    },
    priceOff: function () {
      if(
        this.priceData.price === '0' 
        || parseFloat(this.priceData.price) <= 0 
        || this.priceData.listPrice === '0' 
        || parseFloat(this.priceData.listPrice) <= 0 
      ) {
        return '0'
      }
      if (this.priceData.price && this.priceData.listPrice) {
        let discount = 0
        discount = 100 - ((this.priceData.price * 100) / this.priceData.listPrice)
        return discount > 0 ? discount.toFixed() : '0'
      } else {
        return '0'
      }
    }
  },
  data () {
    return {
      config: {
        format: 'YYYY-MM-DD',
        useCurrent: false,
        icons: {
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .priceOff {
    font-weight: 700;
    color: #4DBD74;
  }
</style>
