<template>
  <div>
    <change-prices-csv />
  </div>
</template>

<script>
import ChangePricesCsv from '@/components/catalog/ChangePricesCsv'
export default {
  name: 'change-prices-view',
  components: {
    ChangePricesCsv
  }
}
</script>
