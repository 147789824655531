<template>
  <div>
    <shipping-grates-list/>
  </div>
</template>

<script>
import ShippingGratesList from '@/components/carrier/ShippingGratesList'

export default {
  name: 'shipping-grates-view',
  components: {
    ShippingGratesList
  }
}
</script>
