<template>
  <b-container fluid>
    <div>

      <!-- Form Modal -->
      <b-modal
        :title="$t('Devolución')"
        header-bg-variant="primary"
        centered
        size="lg"
        ref="modalInstance"
        id="modalInstance"
        :ok-title="$t('Aceptar')"
        @ok="showConfirmModal"
        :ok-disabled="!_canSave"
        :cancel-title="$t('Cancelar')">
        <b-form>
          <b-form-group
                :label-cols="4"
                :label="$t('Elige el/los skus que quieres marcar como devueltos.')"
                label-class="mb-3">
              <b-form-checkbox-group
                v-model="selectedOptions"
                :options="options"
                :disabled="loading"
                stacked>
              </b-form-checkbox-group>
          </b-form-group>
        </b-form>
      </b-modal>

      <!-- Confirm Modal -->
      <b-modal
        centered
        ref="confirmModal"
        header-bg-variant="primary"
        id="confirmModal"
        size="lg"
        :title="$t('Confirmar devolución')"
        :ok-title="$t('Aceptar')"
        @ok="accept"
        :ok-disabled="loading"
        :cancel-title="$t('Cancelar')"
        @cancel="cancelConfirmation"
        :cancel-disabled="loading"
        o-close-on-backdrop="true" >
        <div class="mb-3">{{$t('¿Confirma que desea devolver el/los skus?')}}</div>
        <div
          v-for="(item, index) in selectedOptions"
          :key="index"
          >
          {{'- ' + item.id + ', ' + item.name}}
        </div>
        <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
      </b-modal>
    </div>
  </b-container>
</template>

<script>

import appConfig from '../../config/applicationConfig.js'
import {OrdersServiceMixim} from '../../mixims/OrdersServiceMixim'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import { ValidationsMixim } from '@/mixims/ValidationsMixim'

export default {
  name: 'return-order-modal',
  mixins: [OrdersServiceMixim, ValidationsMixim],
  props: ['order'],
  components: {
    MoonLoader
  },
  data () {
    return {
      errorText: '',
      loading: false,
      options: [],
      selectedOptions: [],
      tagsMapping: appConfig.TAGS_MAPPING
    }
  },
  methods: {
    show () {
      this.loading = false
      this.errorText = ''
      this.selectedOptions = []
      this.options = []
      if (this.order && this.order.items) {
        this.options = this.order.items
        .filter(item => !item.tags || !item.tags.includes(this.tagsMapping.R.key))
        .map(item => {
          return {text: item.id + ', ' + item.name, value: item}
        })
      }

      this.$refs.modalInstance.show()
    },
    showConfirmModal () {
      this.$refs.modalInstance.hide()
      this.$refs.confirmModal.show()
    },
    cancelConfirmation () {
      this.$refs.confirmModal.hide()
      this.$refs.modalInstance.show()
    },
    accept (e) {
      e.preventDefault()
      if (!this.selectedOptions || this.selectedOptions.length == 0)
        return

      this.loading = true
      this.setAsReturn(this.order.id, this.selectedOptions).then(
        respose => {
          this.$refs.confirmModal.hide()
          this.loading = false
          this.$emit('onSuccessReturn')
        }, () => {
          this.cancelConfirmation()
          this.$emit('onErrorReturn')
          this.loading = false
        }).catch(error => {
          this.cancelConfirmation()
          this.$emit('onErrorReturn')
          this.loading = false
        }
      )
    }
  },
  computed: {
    _canSave () {
      return this.selectedOptions && this.selectedOptions.length && !this.loading
    }
  }
}
</script>

<style lang="scss" scoped>
  p {
    margin-bottom: 2px;
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
    }
  }

  span.loading {
    display: inline-block;
    width: 100%;
    text-align: center;

    .loading-icon {
      animation:spin 2s linear infinite;
      font-size: 44px;
      color: #000;
     }
  }

 .fa-exclamation-circle {
   margin-top: 3px;
   margin-right: 3px;
 }

 h5 {
   border-bottom: 1px solid #CCCCCC;
   padding-bottom: 5px;
 }

</style>
