<template>
  <div>
    <integrations/>
  </div>
</template>

<script>
import Integrations from '@/components/integrations/Integrations'

export default {
  name: 'integrations-view',
  components: {
    Integrations
  }
}
</script>