<template>
<div>
   <b-alert :show="errorText !== ''" fade dismissible variant="danger">
     <h4 class="alert-heading">{{$t('¡Error!')}}</h4>
     <hr>
     <p v-html="errorText">{{errorText}}</p>
   </b-alert>  
   <b-alert :show="success" variant="success" dismissible>{{$t('La acción fue completada con éxito')}}</b-alert>

   <b-modal header-bg-variant="primary" centered ref="processCSVModal" id="processCSVModal" size="lg" :title="$t('Guardar los cambios de precio')" o-close-on-backdrop="true" @ok="processAltaPrecios" :cancel-title="$t('Cancelar')">
    {{$t('¿Desea guardar los cambios de precios para los productos seleccionados?')}}
  </b-modal>
  <h4>{{$t('Actualización masiva de precios')}}</h4>
  <b-row class="my-3">
    <b-col md="12">
      <b-button
        :disabled="!Boolean(csvPricesFile) || errorText !== ''"
        v-on:click="showProcessConfirm"
        variant="primary"
        class="float-right mr-2">{{$t('Procesar')}}</b-button>
      <b-button
        @click="downloadPriceFile"
        variant="primary"
        class="float-right mr-2">{{$t('Obtener listado actual de precios CSV')}}</b-button>
    </b-col>
  </b-row>
  <moon-loader :loading="inProgress" color="#000" size="30px"></moon-loader>
  <b-tabs v-model="tabIndex">
    <b-tab :title="$t('Seleccione un archivo')" active>
      <h5>{{$t('Subir archivo CSV a procesar')}}</h5>
      <p v-show="!Boolean(csvPricesFile)" class="alert alert-warning"><i class="fa fa-exclamation-triangle"></i>{{$t('Se debe seleccionar un CSV para continuar.')}}</p>
      <p v-show="Boolean(csvPricesFile)">{{$t('Archivo CSV seleccionado:')}} <strong>{{csvPricesFile && csvPricesFile.name}}</strong></p>
      <b-row class="mb-2">
        <b-col md="6">
          <b-form-file id="csvPricesFile"
            ref="filePricestInput"
            :no-drop="true"
            @click.native="$refs.filePricestInput.reset()"
            accept=".csv"
            v-on:change="onFileChanged"
            v-model="csvPricesFile"
            :class="$i18n.locale"
            :placeholder="$t('Lista Precios CSV')"></b-form-file>
        </b-col>
      </b-row>
    </b-tab>
    <b-tab :title="$t('Previsualización de datos')" :disabled="!Boolean(csvPricesFile)">
      <b-row class="mt-3">
        <b-col class="col-md-auto">
          <b-pagination :total-rows="productsCount" :per-page="perPage" v-model="currentPage" class="mb-3" />
        </b-col>
        <b-col class="col-md-auto per-page">
          <b-form-group id="perPage" horizontal :label="$t('Mostrar por página')">
            <b-form-select :options="pageOptions" v-model="perPage" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Previsualización')">
            <h5>{{$t('Previsualización de precios a actualizar')}}</h5>
            <p>{{$t('Cantidad de Productos:')}} <strong>{{productsCount}}</strong></p>
            <b-table
              ref="productsTable"
              :items="itemProvider"
              :current-page="currentPage"
              :per-page="perPage"
              hover
              :fields="csvFields"
              :small="true"
              show-empty
              striped
              :empty-text="$t('No hay productos para mostrar')"
              stacked="lg"
              foot-clone>
            <template slot="sku" slot-scope="sku">
              {{sku.value === '' ? '(new)' : sku.value}}
            </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-tab>
  </b-tabs>
</div>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import {ProductServiceMixim} from '@/mixims/ProductServiceMixim.js'
import Papa from 'papaparse'

export default {
  name: 'change-prices-csv',
  components: {
    MoonLoader
  },
  mixins: [ProductServiceMixim],
  methods: {
    resetCSVPricesFile () {
      this.$refs.filePricestInput.reset()
    },
    itemProvider (tableComponentInfo) {
      let start = (tableComponentInfo.currentPage - 1) * tableComponentInfo.perPage
      let end = tableComponentInfo.perPage * tableComponentInfo.currentPage
      return this.csvElements.slice(start, end)
    },
    showProcessConfirm () {
      this.$refs.processCSVModal.show()
    },
    processAltaPrecios () {
      this.uploadPricesFile()
    },
    downloadPriceFile () {
      this.errorText = ''
      this.getPriceList().then(
        (csvFile) => {
          let headers = csvFile.headers
          var blob = new Blob([csvFile.body], {
            type: { type: headers.map['content-type'] }
          })
          const blobURL = window.URL.createObjectURL(blob)
          const tempLink = document.createElement('a')
          tempLink.style.display = 'none'
          tempLink.href = blobURL
          tempLink.setAttribute('download', 'priceList.csv')
          document.body.appendChild(tempLink)
          tempLink.click()
          document.body.removeChild(tempLink)
        },
        () => {
          this.errorText = this.$t('Error procesando la descarga')
        }
      )
    },
    uploadPricesFile () {
      this.inProgress = true
      this.success = false
      this.errorText = ''
      let formData = new FormData()
      formData.append('file', this.csvPricesFile)
      this.uploadCsvPriceList(formData, this.delimiter).then(
        () => {
          this.inProgress = false
          this.success = true
        },
        (error) => {
          this.inProgress = false
          this.errorText = error.body.message || `${this.$t('Error procesando el archivo CSV -')} ${this.csvPricesFile.name}`
          /* Mark every row as error */
          this.csvElements.forEach((elem, index) => {
            elem._rowVariant = 'danger'
          })
          this.$refs.productsTable.refresh()
        }
      )
    },
    onFileAttrChanged (evt) {
      this.errorText = ''
      this.success = false
    },
    /* On price csv file changed */
    onFileChanged (evt) {
      const self = this
      this.errorText = ''
      this.success = false
      this.csvElements = []
      self.$refs.productsTable.refresh()
      var file = evt.target.files[0]
      if (!file) {
        return
      }

      var reader = new FileReader()

      /* Parse the file */
      reader.onload = (evt) => {
        try {
          self.tabIndex = 1
          let rowError = false
          let parsedData = Papa.parse(
            evt.target.result.trim(),
            {
              delimiter: '',
              skipEmptyLines: true,
              encoding: 'ISO-8859-1'
            }
          )

          if (parsedData.errors.length > 0) {
            self.errorText = this.$t('El archivo tiene un formato que no es soportado')
            self.$refs.fileProductInput.reset()
            return
          }

          self.delimiter = parsedData.meta.delimiter

          let header = parsedData.data.shift()
          if (header.length !== 6) {
            self.errorText += this.$t('La cabecera del archivo CSV debe tener 6 columnas.')
            self.errorText += '<ul>'
            self.errorText += '<li>' + this.$t("Verificar que la cabecera es 'Nombre del producto','SKU','UPC','Precio regular','Precio con descuento','Fecha de validez del descuento'") + '</li>'
            self.errorText += '</ul>'
            return
          }

          this.productsCount = parsedData.data.length
          parsedData.data.forEach((elem, index) => {
            let newRowElem = {
              'productName': elem[0],
              'sku': elem[1],
              'upc': elem[2],
              'listPrice': elem[3],
              'price': elem[4],
              'priceValidUntil': elem[5],
              '_cellVariants': null
            }
            if (elem.length !== 6) {
              rowError = true
              newRowElem = {'_cellVariants': null}
              newRowElem.productName = `${this.$t('Faltan')} ${6 - elem.length} ${this.$t('campos')}`
            } else {
              newRowElem._cellVariants = {}
              rowError = this.testPrices(elem, newRowElem) || this.testDates(elem, newRowElem)
            }
            if (rowError) {
              self.errorText = this.$t('Hay filas con errores en el archivo CSV')
              self.errorText += '<ul>'
              self.errorText += '<li>' + this.$t('Verifique que las filas tengan 6 items igual que la cabecera.') + '</li>'
              self.errorText += '<li>' + this.$t('Verifique que las fechas tengan formato YYYY-MM-DD.') + '</li>'
              self.errorText += '<li>' + this.$t('Verifique que los precios sean números válidos.') + '</li>'
              self.errorText += '<li>' + this.$t('Verifique que el precio con descuento va acompañado por una fecha valida') + '</li>'
              self.errorText += '</ul>'

              if (newRowElem._cellVariants === null) {
                newRowElem._rowVariant = 'danger'
              }
            } else {
              newRowElem._rowVariant = 'success'
            }
            self.csvElements.push(newRowElem)
          })
          this.$refs.productsTable.refresh()
        } catch (err) {
          console.log(err)
          this.errorText = this.$t('El archivo CSV de precios tiene fallas')
        }
      }
      reader.readAsText(file, 'ISO-8859-1')
    },
    testDates (elements, newRowElem) {
      let date = elements[5]
      let price = elements[4]
      let listPrice = elements[3]

      if (isNaN(price) || Number(price) === 0) {
        return false
      }

      if (Number(listPrice) <= Number(price)) {
        return false
      }

      let myRegExp = /^\d{4}-\d{2}-\d{2}$/
      if (!date.match(myRegExp)) {
        newRowElem._cellVariants.priceValidUntil = 'danger'
        return true
      }

      let d = new Date(date)
      if (Number.isNaN(d.getTime())) {
        newRowElem._cellVariants.priceValidUntil = 'danger'
        return true
      }

      if (new Date(date) <= new Date()) {
        newRowElem._cellVariants.priceValidUntil = 'danger'
        return true
      }
      return false
    },
    testPrices (elements, newRowElem) {
      let listPrice = elements[3]
      let price = elements[4]
      /* Precio de lista (Obligatorio) */
      if (isNaN(listPrice) || Number(listPrice) === 0) {
        newRowElem._cellVariants.listPrice = 'danger'
        return true
      }

      /* Precio con descuento, si existe el precio de lista tiene que existir */
      if (price) {
        if (isNaN(price) || Number(price) === 0) {
          newRowElem._cellVariants.price = 'danger'
          return true
        }
      }

      if (Number(listPrice) < Number(price)) {
        newRowElem._cellVariants.price = 'danger'
        newRowElem._cellVariants.listPrice = 'danger'
        return true
      }

      return false
    }
  },
  data () {
    return {
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 50, 70, 100, 1000],
      tabIndex: 0,
      success: false,
      inProgress: false,
      productsCount: 0,
      errorText: '',
      debugFileExtencion: '',
      delimiter: null,
      csvPricesFile: null,
      csvElements: [],
      csvFields: [
        { key: 'productName', label: this.$t('Nombre') },
        { key: 'sku', label: this.$t('SKU') },
        { key: 'upc', label: this.$t('UPC') },
        { key: 'listPrice', label: this.$t('Precio regular') },
        { key: 'price', label: this.$t('Precio con descuento') },
        { key: 'priceValidUntil', label: this.$t('Vencimiento descuento') }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>

  .b-form-file.custom-file {
    .custom-file-control[data-choose] {
      &::before {
          content: "Seleccionar" !important;
      }
    }
  }

  .fa-exclamation-triangle {
    color: #f8cb00;
    font-size: 20px;
    float: left;
    margin-right: 5px;
    display: inline-block;
  }

</style>