<template>
  <footer class="app-footer">
    <span><a target="blank" href="http://www.summasolutions.net/">Summa Solutions</a> &copy; {{currentDate.getFullYear()}}.</span>
  </footer>
</template>
<script>
export default {
  name: 'footer-sc',
  data () {
    return {
      currentDate: new Date()
    }
  }
}
</script>
