<template>
  <div class="animated fadeIn">
    <b-modal header-bg-variant="primary" size="lg" ref="confirmationCloseIncidentModal" id="confirmationCloseIncidentModal" centered :title="$t('Cerrar incidencia')"
        @ok="solveIncident(incidentToSolve)"
        :cancel-title="$t('Cancelar')">
        <span>{{$t('¿Confirma que desea marcar la incidencia como CERRADA?')}}</span>
    </b-modal>
    <b-container fluid>
      <div v-show="errorText !== ''" class="alert alert-danger" role="alert">
        {{errorText}}
      </div>
      <b-alert :show="successText !== ''" variant="success" dismissible>
        {{successText}}
       </b-alert>
      <b-row>
        <b-col>
          <b-button class="mb-2 mx-1 float-right" variant="primary" @click="openModal(true)">{{ $t('Crear incidencia') }}</b-button>
        </b-col>
      </b-row>
      <incidentsModal
        ref="incidentModal"
        :show.sync="showModal"
        :incident="incidentToEdit"
        :references="references"
        :priorities="priorities"
        @saved="newIncidentSaved"
        @markAsSolved="markAsSolved"/>
      <hr>
      <b-row class="filter-row">
        <b-col lg="4">
          <b-form-group
            :label="$t('Buscar')"
            :invalid-feedback="!isValidText(filters.text, false)? $t('No es un valor válido') : ''"
            :state="isValidText(filters.text, false)"
            class="mb-0"
            :description="$t('Ingrese el texto a buscar')">
            <b-input-group>
              <b-form-input v-model="filters.text" :placeholder="$t('Texto a buscar')" />
              <b-input-group-button>
                <b-btn @click="filterTable">{{$t('Buscar')}}</b-btn>
              </b-input-group-button>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col md="3">
          <b-form-group
            id="dateFilterFromGroup"
            :label="$t('Rango de fechas')"
            :description="$t('Se filtrara los reportes por el rango de fechas seleccionado')"
            label-for="filterRangeDate">
            <date-picker
              v-on:contextmenu.native.stop.prevent
              v-on:keydown.native.stop.prevent
              id="filterRangeDate"
              name="filterRangeDate"
              ref="filterBeginDate"
              v-model="rangeDate"
              format="YYYY-MM-DD"
              valueType="YYYY-MM-DD"
              :confirm="true"
              :lang="lang"
              :confirm-text="$t('Seleccione rango')"
              :clearable="true"
              range>
            </date-picker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" sm="6" md="3" lg="3">
          <b-form-group :label="$t('Referencia')" class="mb-3">
            <b-form-select :options="references" v-model="filters.incidenceType">
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6" md="3" lg="3" v-if="isAdmin">
          <b-form-group :label="$t('Seller')" class="mb-3">
            <b-form-select :options="organizations" v-model="filters.accountName">
              <option slot="first" :value="null">{{$t('Todos')}}</option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6" md="3" lg="3">
          <b-form-group :label="$t('Prioridad')" class="mb-3">
            <b-form-select :options="priorities" v-model="filters.priority">
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6" md="3" lg="3">
          <b-form-group :label="$t('Estado')" class="mb-3">
            <b-form-select :options="status" v-model="filters.status">
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col>
          <b-button class="mb-2 mx-1 float-right" variant="primary" @click.stop="filterApply">{{$t('Filtrar')}}</b-button>
          <b-button class="mb-2 mx-1 float-right" variant="primary" :disabled="!filterHasBeenUsed" @click.stop="filterShowAll">{{ $t('Borrar filtros') }}</b-button>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col>
            <incidents-table
              ref="indicentsTable"
              :filters="filters"
              :addReferenceLink="true"
              @error="showError"
              @selected="selectIncident">
            </incidents-table>
        </b-col>
      </b-row>
   </b-container>
  </div>
</template>

<script>
import { AdminServiceMixin } from '@/mixims/AdminServiceMixin'
import { ValidationsMixim } from '@/mixims/ValidationsMixim.js'
import { IncidentsServices } from '@/mixims/IncidentsServices'

import incidentsTable from '@/components/incidents/IncidentsTable'
import incidentsModal from '@/components/incidents/incidentsModal'
import 'vue2-datepicker/index.css'
import DatePicker from 'vue2-datepicker'

export default {
  name: 'incidents-view',
  mixins: [AdminServiceMixin, IncidentsServices, ValidationsMixim],
  components: {
    incidentsTable,
    DatePicker,
    incidentsModal
  },
  data () {
    return {
      loading: false,
      incidentToEdit: null,
      showModal: false,
      isAdmin: this.$store.getters.isAdmin,
      errorText: '',
      successText: '',
      references: [
        { value: null, text: this.$t('Todas') },
        { value: 'PRODUCT', text: this.$t('Producto') },
        { value: 'SKU', text: this.$t('sku') },
        { value: 'ORDER', text: this.$t('Pedido') }
      ],
      priorities: [
        { value: null, text: this.$t('Todas') },
        { value: 'HIGH', text: this.$t('Alta') },
        { value: 'HALF', text: this.$t('Media') },
        { value: 'LOW', text: this.$t('Baja') }
      ],
      status: [
        { value: null, text: this.$t('Todos') },
        { value: 'OPEN', text: this.$t('Abierta') },
        { value: 'CLOSE', text: this.$t('Cerrada') }
      ],
      lang: {
        formatLocale: this.$i18n.locale === 'en-gr' ? {} : {
          // MMMM
          months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          // MMM
          monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          // dddd
          weekdays: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
          // ddd
          weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vier', 'Sab'],
          // dd
          weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']
        }
      },
      tabIndex: 0,
      rangeDate: null,
      filters: {
        fromDate: null,
        toDate: null,
        incidenceType: null,
        accountName: null,
        priority: null,
        status: null
      },
      filterHasBeenUsed: false,
      incidentToSolve: null
    }
  },

  watch: {
    rangeDate () {
      this.filters.fromDate = this.rangeDate[0]
      this.filters.toDate = this.rangeDate[1]
    }
  },
  computed: {
    organizations () {
      return this.$store.getters.organizations
    }
  },
  methods: {
    filterShowAll () {
      this.filters.fromDate = null
      this.filters.toDate = null
      this.filters.incidenceType = null
      this.filters.accountName = null
      this.filters.priority = null
      this.filters.status = null
      this.filters.text = null

      this.filterHasBeenUsed = false
      this.$refs.indicentsTable.refresh()
      if (this.$route.query.ref) this.$router.push(this.$route.path).catch(() => {})
    },
    filterApply () {
      this.$refs.indicentsTable.refresh()
      this.filterHasBeenUsed = true
    },
    filterTable () {
      this.$refs.indicentsTable.refresh()
      this.filterHasBeenUsed = true
    },
    selectIncident (item) {
      this.incidentToEdit = item
      this.openModal(false)
    },
    tabChange () {
      this.errorText = ''
    },
    showError (errorText) {
      this.errorText = errorText
    },
    openModal (resetIncident) {
      this.successText = ''
      if (resetIncident) {
        this.incidentToEdit = null
        this.$refs.incidentModal.resetModal()
      }
      this.showModal = true
    },
    newIncidentSaved (incidentId) {
      this.successText = `${this.$t('La incidencia')} ${incidentId} ${this.$t('se ha registrado correctamente')}`
      this.showModal = false
      this.$refs.indicentsTable.refresh()
    },
    markAsSolved (incident) {
      this.incidentToSolve = incident
      this.$refs.confirmationCloseIncidentModal.show()
    },
    solveIncident (incident) {
      incident.status = 'CLOSE'
      this.updateIncident(incident.id, incident).then(
        response => {
          this.$refs.indicentsTable.refresh()
        }
      )
    }
  },
  mounted () {
    if (this.$route.query.sku || this.$route.query.order || this.$route.query.product) {
      let reference = ''
      let incidentType = ''
      if (this.$route.query.sku) {
        reference = this.$route.query.sku
        incidentType = 'SKU'
      }
      if (this.$route.query.order) {
        reference = this.$route.query.order
        incidentType = 'ORDER'
      }
      if (this.$route.query.product) {
        reference = this.$route.query.product
        incidentType = 'PRODUCT'
      }
      let incident = {
        creationDate: '',
        id: '',
        incidenceType: '',
        priority: '',
        reference: '',
        title: '',
        status: '',
        updateDate: '',
        populate: true
      }
      // SKU, ORDER, PRODUCT
      incident.incidenceType = incidentType
      incident.reference = reference
      this.incidentToEdit = incident
      this.openModal()
    }
  },
  created () {
    if (this.$route.query.ref) {
      this.filters.text = this.$route.query.ref
      this.filterHasBeenUsed = true
    }
  }
}
</script>
