<template>
  <div class="app">
    <AppHeader/>
    <div class="app-body">
      <Sidebar :navItems="nav"/>
      <main class="main" :class="isAdmin ? 'admin' : ''">
        <breadcrumb :list="list"/>
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside/>
    </div>
    <AppFooter/>
  </div>
</template>

<script>
import nav from '../_nav'
import { Header as AppHeader, Sidebar, Aside as AppAside, Footer as AppFooter, Breadcrumb } from '../components/'
import {RoleService} from '@/mixims/AdminServiceMixin'

export default {
  name: 'full',
  mixins: [RoleService],
  components: {
    AppHeader,
    Sidebar,
    AppAside,
    AppFooter,
    Breadcrumb
  },
  data () {
    return {
      nav: nav.items(this.$store.getters.signedRoles, this.$store.getters.isAdmin)
    }
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched
    },
    isAdmin () {
      return this.$store.getters.isAdmin
    }
  }
}
</script>

<style>
  .main.admin {
    background: #EEE;
  }
</style>
