<template>
  <div class="noticesList">
    <b-alert :show="showAlert" :variant="alertVariant" role="alert" dismissible>
      {{alertText}}
    </b-alert>

    <b-row class="mb-4">
      <b-col cols="12">
        <button v-show="isAdminWrite" class="btn btn-primary" @click="handleCreateNotice">Crear notificación</button>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col class="col-md-auto d-flex align-items-center">
        <b-pagination :total-rows="totalRows" :per-page="displayTableOptions.size" v-model="displayTableOptions.from" class="mb-3" />
      </b-col>
      <b-col class="col-md-auto d-flex flex-row mb-3">
        <div class="d-flex align-items-center mr-2">
          Mostrar por página
        </div>
        <div class="d-flex align-items-center">
          <b-form-select :options="pageOptions" v-model="displayTableOptions.size" />
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card header-bg-variant="primary" border-variant="primary" header="Lista de Notificaciones">
          <moon-loader :loading="loading" class="mb-2" color="#000" size="30px"></moon-loader>
          <b-table id="mainTable"
            ref="mainTable"
            show-empty
            striped
            hover
            :sort-by.sync="displayTableOptions.sortBy"
            :sort-desc.sync="displayTableOptions.sortDesc"
            :per-page="displayTableOptions.size"
            :current-page="displayTableOptions.from"
            :items="itemProvider"
            :fields="fields"
            :fixed=true
            responsive=true
            @row-clicked="openNoticeModal"
            stacked="xl"
            empty-text="No hay notificaciones para mostrar"
            empty-filtered-text="No hay notificaciones para mostrar">
            <template slot="text" slot-scope="row">
              <span>{{row.item.text.length > 50 ? row.item.text.substring(0,50) + '...' : row.item.text}}</span>
            </template>
            <template slot="notifyDate" slot-scope="row">
              <span v-show="row.item.notifyDate">
                {{new Date(row.item.notifyDate).toLocaleDateString(locale)}}
                {{new Date(row.item.notifyDate).toLocaleTimeString(locale)}}
              </span>
            </template>
            <template slot="status" slot-scope="row">
              <h5><b-badge class="badgeState" :variant="row.item.status === 'sent' ? 'success' : 'warning'">{{row.item.status === 'sent' ? 'Enviado' : 'Pendiente'}}</b-badge></h5>
            </template>
            <template slot="actions" slot-scope="row">
              <b-button v-show="isAdminWrite" variant="link" style="color: black" @click.stop="openConfirmationModal(row.item)"><h5><i class="far fa-trash-alt"></i></h5></b-button>
              <b-button v-show="isAdminWrite" variant="link" style="color: black" @click.stop="handleCopyAction(row.item)"><h5><i class="far fa-copy"></i></h5></b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import appConfig from '@/config/applicationConfig.js'
import { NotificationServiceMixim } from '@/mixims/NotificationServiceMixim.js'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
export default {
  components: { MoonLoader },
  mixins: [NotificationServiceMixim],
  data () {
    return {
      fields: [
        { key: 'text', label: 'Mensaje', 'class': 'text-left', sortable: false },
        { key: 'notifyDate', label: 'Fecha/Hora', 'class': 'text-left', sortable: true },
        { key: 'status', label: 'Estado', 'class': 'text-left', sortable: true },
        { key: 'actions', label: 'Acciones', 'class': 'text-left', sortable: false }
      ],
      displayTableOptions: {
        sortDesc: true,
        sortBy: 'notifyDate',
        size: 10,
        from: 1
      },
      filters: {
        type: 'MESSAGE_NOTIFICATION',
        mode: 'INBOX'
      },
      totalRows: 0,
      pageOptions: [ 10, 15, 25, 50, 100 ],
      loading: false,
      showAlert: false,
      alertVariant: 'success',
      alertText: '',
      locale: appConfig.LOCALE,
      isAdminWrite: this.$store.getters.signedRoles.includes('ADMIN_WRITE'),
    }
  },
  methods: {
    handleCreateNotice () {
      this.showAlert = false
      this.$emit('showNoticeModal', {notice: {mode: 'INBOX', type: 'MESSAGE_NOTIFICATION'}, action: 'create'})
    },
    openNoticeModal (notice) {
      if (!this.isAdminWrite) {
        return
      }
      this.showAlert = false
      this.$emit('showNoticeModal', {notice: { ...notice }, action: 'edit'})
    },
    openConfirmationModal (notice) {
      this.showAlert = false
      this.$emit('showDeleteNoticeModal', notice)
    },
    handleCopyAction (notice) {
      this.showAlert = false
      this.$emit('showNoticeModal', {notice: { ...notice }, action: 'copy'})
    },
    itemProvider (tableFilterInfo) {
      this.loading = true
      return this.getNotificationsNotices(tableFilterInfo, this.filters).then(
        (response) => {
          this.loading = false
          this.totalRows = response.body.length > 0 ? parseInt(response.headers.get('X-Total-Count')) : 0
          return response.body
        },
        (error) => {
          this.loading = false
          let errorText = error.body.message || 'Error obteniendo las notificaciones'
          this.$emit('error', errorText)
          return []
        }
      )
    },
    showAlertNoticesList (text, variant) {
      this.$refs.mainTable.refresh()
      this.showAlert = true
      this.alertVariant = variant
      this.alertText = text
    },
    refreshTable () {
      this.$refs.mainTable.refresh()
    }
  }
}
</script>
<style lang="scss" scoped>
.badgeState {
  padding: 10px;
  border-radius: 5px;
  width: 80px;
}
</style>