<template>
  <div class="animated fadeIn">
    <b-container fluid class="product-list">
      <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Filtros')">
        <b-row>
          <b-col lg="4" class="px-4 py-2">
            <b-form-group id="upcHastaInput" horizontal :label-cols="4" breakpoint="md"
              :invalid-feedback="isValidText(filter.text) ? '' : ''" :state="!isValidText(filter.text)"
              :label="$t('Filtro de texto')"
              :description="$t('Este campo de filtro realizará la busqueda en los nombres, id, UPC y SKU de los productos')"
              label-for="upcHastaFormatter">
              <b-input-group>
                <b-form-input :state="isValidText(filter.text)" :placeholder="$t('Ingrese texto a buscar')"
                  id="upcHastaInputFormatter" v-model="filter.text"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col lg="4" class="px-4 py-2">
            <b-form-group id="estadoInput" horizontal :label-cols="4" breakpoint="md" :label="$t('Estado')"
              label-for="estadoFormatter">
              <b-form-select id="categoryFormatter" v-model="filter.estado">
                <option slot="first" :value="null">{{ $t('Todos') }}</option>
                <option v-for="item in estados" :key="item.value" :value="item.value">{{ $t(item.text) }}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" class="px-4 py-2">
            <b-form-group id="categoryInput" horizontal :label-cols="4" breakpoint="md" :label="$t('Categoría')"
              label-for="categoryFormatter">
              <category-tree id='selectCategory' :categorySelected="filter.category" @onCategorySelected="selectCategory">
              </category-tree>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-button class="float-right mr-2" :disabled="loading || disableFilter" variant="primary"
              @click.stop="filterTable">{{ $t('Filtrar') }}</b-button>
            <b-button class="float-right mr-2" :disabled="loading" variant="primary" @click.stop="cleanFilters">{{
              $t('Mostrar Todos') }}</b-button>
            <b-button class="float-right mr-2" :disabled="loading || disableFilter" variant="primary"
              @click.stop="exportCSV">{{ $t('Exportar CSV') }}</b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-row class="mt-3">
        <b-col class="col-md-auto">
          <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="mb-3" />
        </b-col>
        <b-col class="col-md-auto per-page">
          <b-form-group id="perPage" horizontal :label="$t('Mostrar por página')">
            <b-form-select :options="pageOptions" v-model="perPage" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col md="12">
          <b>{{ totalRows }}</b> {{ $t('productos encontrados') }}
        </b-col>
      </b-row>
      <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Lista de productos')">
        <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
        <b-table v-show="!loading" id="mainTable" ref="mainTable" show-empty striped hover :items="itemProvider"
          foot-clone :fields="fieldsProduct" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc" responsive=true @row-clicked="rowClick" stacked="xl"
          :empty-text="$t('No hay productos para mostrar')" :empty-filtered-text="$t('No hay productos para mostrar')">
          <template slot="category" slot-scope="row">
            {{ row.item.category.name }}
          </template>
          <template slot="brand" slot-scope="row">
            {{ row.item.brand.name }}
          </template>
          <template slot="action" slot-scope="row">
            <b-button v-if="row.item.skus.length > 0" size="sm" variant="link" @click.stop="row.toggleDetails">
              <span v-show="!row.detailsShowing"><i class="fa fa-plus"></i></span>
              <span v-show="row.detailsShowing"><i class="fa fa-minus"></i></span>
              {{ row.detailsShowing ? $t('Ocultar') : $t('Ver') }} {{ $t('Variantes') }} ({{ row.item.skus.length }})
            </b-button>
            <span v-else><b>{{ $t('No contiene variantes') }}</b></span>
          </template>
          <template slot="row-details" slot-scope="row">
            <b-card>
              <b-table v-show="!loading" id="productTable" ref="variantTable" show-empty hover :items="row.item.skus"
                :fields="fieldsVariant" responsive=true stacked="xl" :empty-text="$t('No hay variantes para mostrar')"
                :empty-filtered-text="$t('No hay variantes para mostrar')">
                <template slot="listPrice" slot-scope="row">
                  {{ currency }} {{ row.item.price.listPrice }}
                </template>
                <template slot="inventory" slot-scope="row">
                  <div class="inventoryContainer" v-b-tooltip.hover
                    :title="$t('La cantidad entre parentesis es stock reservado')">
                    <span class="inventoryTotal">{{ row.item.inventory.totalQuantity }}</span>
                    <span class="inventoryReserved">({{ row.item.inventory.reservedQuantity ?
                      row.item.inventory.reservedQuantity : 0 }})</span>
                  </div>
                </template>
                <template slot="price" slot-scope="row">
                  {{ currency }} {{ row.item.price.listPrice }}<br>
                  <span v-b-tooltip.hover :title="$t('Precio con descuento')" class="discount">({{ currency }}
                    {{ row.item.price.price }})</span><br>
                  {{ row.item.price.priceValidUntil }}
                </template>
                <template slot="status" slot-scope="data">
                  <div v-for="smks in data" :key="smks.id">
                    <div v-for="smk in smks.marketplaces" :key="smk.id" style="display: flex; flex-direction: column; margin-bottom: 6px;">
                      <b class="text-center" style="font-size: 12px;">{{ smk.name }}</b>
                      <b v-if="smk.status === 'APPROVED'" class="text-success text-center" style="margin-top: -4px;">
                        {{ $t(estados.find(p => p.value === smk.status).text) }}
                      </b>
                      <b v-if="smk.status === 'REJECTED'" class="text-danger text-center" style="margin-top: -4px;">
                        {{ $t(estados.find(p => p.value === smk.status).text) }}
                      </b>
                      <b v-if="smk.status === 'PENDING_APPROVAL'" class="text-warning text-center" style="margin-top: -4px;">
                        {{ $t(estados.find(p => p.value === smk.status).text) }}
                      </b>
                    </div>
                  </div>
                </template>
              </b-table>
            </b-card>
          </template>
        </b-table>
      </b-card>
      <b-row>
        <b-col md="6" class="my-1">
          <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { ProductServiceMixim, PRODUCT_STATES } from '@/mixims/ProductServiceMixim.js'
import CategoryTree from '@/components/catalog/CategoryTree'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import { CsvReportExportMixim } from './CsvReportExportMixim.js'
import appConfig from '@/config/applicationConfig.js'
import { ValidationsMixim } from '@/mixims/ValidationsMixim.js'

export default {
  name: 'orders-report',
  components: { MoonLoader, CategoryTree },
  props: ['rangeDate', 'account', 'marketplace'],
  mixins: [ProductServiceMixim, CsvReportExportMixim, ValidationsMixim],
  watch: {
    rangeDate: function (val, oldVal) {
      this.filter.fecha.desde = val[0]
      this.filter.fecha.hasta = val[1]
      this.$refs.mainTable.refresh()
    },
    account: function (val, oldVal) {
      this.filter.seller = val
      this.$refs.mainTable.refresh()
    },
    marketplace: function (val, oldVal) {
      this.filter.marketplaces = val
      this.$refs.mainTable.refresh()
    }
  },
  data() {
    return {
      fieldsVariant: [
        { key: 'skuName', label: this.$t('Nombre'), 'class': 'text-left' },
        { key: 'upc', label: this.$t('UPC'), 'class': 'text-left' },
        { key: 'id', label: this.$t('SKU'), 'class': 'text-left' },
        { key: 'refId', label: this.$t('Identificador'), 'class': 'text-left' },
        { key: 'inventory', label: this.$t('Inventario'), 'class': 'text-left' },
        { key: 'price', label: this.$t('Precio'), class: 'priceCol' },
        { key: 'status', label: this.$t('Estado (por sitio)'), 'class': 'text-left' }
      ],
      fieldsProduct: [
        { key: 'id', label: 'Id', 'class': 'text-left', sortable: true },
        { key: 'name', label: this.$t('Nombre'), 'class': 'text-left', sortable: true },
        { key: 'category', label: this.$t('Categoría'), 'class': 'text-left', sortable: true },
        { key: 'brand', label: this.$t('Marca'), 'class': 'text-left', sortable: true },
        { key: 'action', label: ' ', 'class': 'text-right', sortable: false }
      ],
      estados: PRODUCT_STATES,
      isAdmin: this.$store.getters.isAdmin,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      pageOptions: [10, 15, 25, 50, 100],
      sortBy: null,
      email: '',
      sortDesc: false,
      locale: appConfig.LOCALE,
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : '$',
      filter: {
        seller: null,
        name: null,
        estado: null,
        fecha: { desde: null, hasta: null },
        category: { id: null, text: null },
        text: null,
        useFilters: true,
        marketplaces: null
      },
      showFilters: false,
      tableComponentInfo: null,
      currentPageElement: [],
      loading: false,
      config: {
        format: 'YYYY-MM-DD',
        useCurrent: false
      }
    }
  },
  computed: {
    sortOptions() {
      /* Create an options list from our fields */
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    },
    disableFilter() {
      if (
        !this.isValidText(this.filter.name, false) ||
        !this.isValidText(this.filter.text, false)) {
        return true
      }
      return false
    }
  },
  methods: {
    cleanFilters() {
      this.filter = {
        cuenta: null,
        seller: null,
        estado: null,
        text: null,
        fecha: { desde: null, hasta: null },
        category: { id: null, text: null }
      }
      this.$refs.mainTable.refresh()
    },
    selectCategory(res) {
      this.filter.category = res
    },
    rowClick(item) {
      this.$router.push(`/catalog/details/${item.id}`)
    },
    filterTable() {
      this.$refs.mainTable.refresh()
    },
    exportCSV() {
      this.loading = true
      this.$emit('error', '')
      return this.productsReport(null, this.filter, true).then(
        (csvFile) => {
          this.loading = false
          this._exportCSV(csvFile, 'productsReportCSV.csv')
        },
        (error) => {
          this.loading = false
          let errorText = error.body.message || this.$t('Error obteniendo el reporte de pedidos')
          this.$emit('error', errorText)
        }
      )
    },
    itemProvider(tableFilterInfo) {
      this.errorText = ''
      this.tableFilterInfo = tableFilterInfo
      this.loading = true
      return this.productsReport(tableFilterInfo, this.filter).then(
        (response) => {
          this.loading = false
          this.totalRows = parseInt(response.headers.get('X-Total-Count'))
          return response.body
        },
        (error) => {
          this.loading = false
          let errorText = error.body.message || this.$t('Error obteniendo el inventario')
          this.$emit('error', errorText)
          return []
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.product-list {

  .btn-link {
    color: #666666;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .filters {
    border: 1px solid #e3e8ec;
    background-color: #FFF
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
  }

  tr:hover {
    cursor: pointer
  }

  .inventoryContainer {
    display: inline-block;

    .inventoryTotal {
      font-weight: bolder;
      font-size: 16px;
    }

    .inventoryReserved {
      color: #777;
    }
  }

  .priceCol {
    .discount {
      font-size: 12px;
      font-weight: bolder;
    }
  }
}
</style>
