<template>
  <b-col md="12" class="p-0">
    <div v-show="errorText !== ''" class="alert alert-danger" role="alert">
      {{errorText}}
    </div>
    <div v-show="success" class="alert alert-success" role="alert">
      {{$t('La imagen fue eliminada con éxito')}}
    </div>
    <moon-loader class="my-3" :loading="loading" color="#000" size="30px"></moon-loader>
    <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Imágenes y Videos')">
      <b-row class="mb-5 search-row">
        <b-col md="8">
          <b-col v-show="!isAdmin">
            <b-form-group
              id="imageToUpload"
              :label-cols="2"
              breakpoint="md"
              :label="$t('Imagen')"
              :invalid-feedback="fileNotSupported ? $t('Tipo de archivo no soportado') : ''"
              :state="!fileNotSupported"
              label-for="categoryFormatterHelp">
              <b-form-file
                :disabled="disabled"
                :class="$i18n.locale"
                ref="fileinput"
                :no-drop="true"
                accept="image/*"
                v-on:change="previewImage"
                @click.native="fileNotSupported = null"
                :placeholder="$t('Seleccione una imagen...')">
              </b-form-file>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-radio-group
              class="mb-4"
              v-model="imageSelected"
              @change="radioChange"
              id="image-radios"
              name="radioSubComponent">
                <b-form-radio value="0">{{$t('Imagen 1')}}</b-form-radio>
                <b-form-radio value="1">{{$t('Imagen 2')}}</b-form-radio>
                <b-form-radio value="2">{{$t('Imagen 3')}}</b-form-radio>
                <b-form-radio value="3">{{$t('Imagen 4')}}</b-form-radio>
                <b-form-radio value="4">{{$t('Imagen 5')}}</b-form-radio>
                <b-form-radio value="5">{{$t('Imagen 6')}}</b-form-radio>
                <b-form-radio value="6">{{$t('Imagen 7')}}</b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col md="12">
            <button v-show="!isAdmin" :disabled="disabled" type="button" v-on:click="deleteImage" class="btn btn-primary float-left mt-3">{{$t('Eliminar imagen')}} {{parseInt(imageSelected) + 1}}</button>
          </b-col>
        </b-col>
        <b-col md="4"  :data-count="images.length" class="imgCount">
          <div @click="zoomImage" class="image-preview" v-if="imageData">
            <img v-b-tooltip.hover :title="$t('Click para zoom de la imagen')" class="preview" :src="imageData" @error="imageError" @load="imageSuccess" v-show="!errorImg">
          </div>
          <div v-else>
            <p>{{$t('No hay imagen cargada')}}</p>
          </div>
          <div v-if="errorImg && imageData">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#485b65" width="54px" height="54px"><path d="M0 0h24v24H0zm0 0h24v24H0zm21 19c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2" fill="none"/><path d="M0 0h24v24H0z" fill="none"/><path d="M21 5v6.59l-3-3.01-4 4.01-4-4-4 4-3-3.01V5c0-1.1.9-2 2-2h14c1.1 0 2 .9 2 2zm-3 6.42l3 3.01V19c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-6.58l3 2.99 4-4 4 4 4-3.99z"/></svg>
            <p class="text-danger pl-2">{{$t('Error con la URL asociada a la imagen')}}</p>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-3 search-row">
        <b-col md="8">
          <b-col md="12" v-show="!isAdmin">
            <b-form-group id="videoUrl"
              breakpoint="md"
              :label="$t('Video')">
              <b-form-input
                :disabled="disabled"
                v-model="videoTextInput" 
                @input="videoTextChange"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-radio-group
              class="mb-4"
              v-model="videoSelected"
              @change="videoRadioChange"
              id="image-radios"
              name="radioSubComponent">
                <b-form-radio value="0">{{$t('Video 1')}}</b-form-radio>
                <b-form-radio value="1">{{$t('Video 2')}}</b-form-radio>
                <b-form-radio value="2">{{$t('Video 3')}}</b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col md="12">
            <button v-show="!isAdmin" :disabled="disabled" type="button" v-on:click="deleteVideo" class="btn btn-primary float-left mt-3">{{$t('Eliminar video')}} {{parseInt(videoSelected) + 1}}</button>
          </b-col>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>
<script>

import Vue from 'vue'
import appConfig from '@/config/applicationConfig.js'
import 'vue-image-zoom/dist/vue-image-zoom.css'
import zoom from 'vue-image-zoom'
import { ProductServiceMixim } from '@/mixims/ProductServiceMixim.js'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
Vue.use(zoom)

export default {
  name: 'image-loader',
  mixins: [ProductServiceMixim],
  props: ['productImages', 'productVideos', 'disabled', 'skuId'],
  components: {MoonLoader},
  watch: {
    productImages: function (newVal, oldVal) {
      this.images = []
      this.success = false
      newVal.forEach((elem) => {
        this.images.push({imageUrl: elem.imageUrl})
      })
      this.radioChange(0)
    },
    productVideos: function (newVal, oldVal) {
      this.videos = []
      if (newVal && newVal.length > 0) {
          newVal.forEach((elem) => {
          this.videos.push(elem)
        })
      }
      this.videoRadioChange(0)
    }
  },
  data () {
    return {
      isAdmin: this.$store.getters.isAdmin,
      fileNotSupported: null,
      imageData: null,
      imageSelected: 0,
      images: [],
      videoTextInput: '',
      videoSelected: 0,
      videos: [null, null, null],
      errorText: '',
      success: false,
      loading: false,
      errorImg: false
    }
  },
  methods: {
    zoomImage: function () {
      this.$zoom(this.imageData, {
        allowZoom: true,
        autoScale: true,
        closeOnClickModal: true
      })
    },
    deleteImage: function () {
      if (this.skuId && this.imageCount() <= 3) {
        this.errorText = this.$t('No se puede eliminar, el mínimo de imágenes debe ser 3')
        return  
      }
      this.$refs.fileinput.reset()
      this.success = false
      this.errorText = ''
      this.loading = true
      if (this.images[this.imageSelected] && this.images[this.imageSelected].imageUrl) {
        let image1 = ''
        let image2 = ''
        let image3 = ''
        let image4 = ''
        let image5 = ''
        let image6 = ''
        let image7 = ''
        if (this.imageSelected === 0 || this.imageSelected === '0') {
          image2 = this.images[1] ? this.images[1].imageUrl : ''
          image3 = this.images[2] ? this.images[2].imageUrl : ''
          image4 = this.images[3] ? this.images[3].imageUrl : ''
          image5 = this.images[4] ? this.images[4].imageUrl : ''
          image6 = this.images[5] ? this.images[5].imageUrl : ''
          image7 = this.images[6] ? this.images[6].imageUrl : ''
        } else if (this.imageSelected === 1 || this.imageSelected === '1') {
          image1 = this.images[0] ? this.images[0].imageUrl : ''
          image3 = this.images[2] ? this.images[2].imageUrl : ''
          image4 = this.images[3] ? this.images[3].imageUrl : ''
          image5 = this.images[4] ? this.images[4].imageUrl : ''
          image6 = this.images[5] ? this.images[5].imageUrl : ''
          image7 = this.images[6] ? this.images[6].imageUrl : ''
        } else if (this.imageSelected === 2 || this.imageSelected === '2') {
          image1 = this.images[0] ? this.images[0].imageUrl : ''
          image2 = this.images[1] ? this.images[1].imageUrl : ''
          image4 = this.images[3] ? this.images[3].imageUrl : ''
          image5 = this.images[4] ? this.images[4].imageUrl : ''
          image6 = this.images[5] ? this.images[5].imageUrl : ''
          image7 = this.images[6] ? this.images[6].imageUrl : ''
        } else if (this.imageSelected === 3 || this.imageSelected === '3') {
          image1 = this.images[0] ? this.images[0].imageUrl : ''
          image2 = this.images[1] ? this.images[1].imageUrl : ''
          image3 = this.images[2] ? this.images[2].imageUrl : ''
          image5 = this.images[4] ? this.images[4].imageUrl : ''
          image6 = this.images[5] ? this.images[5].imageUrl : ''
          image7 = this.images[6] ? this.images[6].imageUrl : ''
        } else if (this.imageSelected === 4 || this.imageSelected === '4') {
          image1 = this.images[0] ? this.images[0].imageUrl : ''
          image2 = this.images[1] ? this.images[1].imageUrl : ''
          image3 = this.images[2] ? this.images[2].imageUrl : ''
          image4 = this.images[3] ? this.images[3].imageUrl : ''
          image6 = this.images[5] ? this.images[5].imageUrl : ''
          image7 = this.images[6] ? this.images[6].imageUrl : ''
        } else if (this.imageSelected === 5 || this.imageSelected === '5') {
          image1 = this.images[0] ? this.images[0].imageUrl : ''
          image2 = this.images[1] ? this.images[1].imageUrl : ''
          image3 = this.images[2] ? this.images[2].imageUrl : ''
          image4 = this.images[3] ? this.images[3].imageUrl : ''
          image5 = this.images[4] ? this.images[4].imageUrl : ''
          image7 = this.images[6] ? this.images[6].imageUrl : ''
        } else if (this.imageSelected === 6 || this.imageSelected === '6') {
          image1 = this.images[0] ? this.images[0].imageUrl : ''
          image2 = this.images[1] ? this.images[1].imageUrl : ''
          image3 = this.images[2] ? this.images[2].imageUrl : ''
          image4 = this.images[3] ? this.images[3].imageUrl : ''
          image5 = this.images[4] ? this.images[4].imageUrl : ''
          image6 = this.images[5] ? this.images[5].imageUrl : ''
        }
        this.deleteImageExec(this.skuId, image1, image2, image3, image4, image5, image6, image7).then(
          () => {
            this.images[this.imageSelected] = null
            this.imageData = null
            this.success = true
            this.loading = false
            this.$emit('imageDeleted')
          },
          (error) => {
            this.errorText = error.body.message || this.$t('Error eliminando la imagen')
            this.loading = false
          })
      } else {
        this.images[this.imageSelected] = null
        this.imageData = null
        this.loading = false
      }
    },
    deleteVideo: function() {
      this.videoTextChange()
      this.videoTextInput = null
    },
    radioChange: function (selected) {
      this.$refs.fileinput.reset()
      this.imageSelected = selected
      this.fileNotSupported = null
      let image = this.images[this.imageSelected]
      if (image) {
        if (image.imageUrl && image.imageUrl !== undefined) {
          /* From service */
          this.imageData = image.imageUrl
        } else {
          /* New file */
          if (this.images[this.imageSelected].name) {
            this.readFile(this.images[this.imageSelected])
          } else {
            this.imageData = null
          }
        }
      } else {
        this.imageData = null
      }
    },
    videoTextChange: function () {
      const regex = /^(http|https):\/\/[^ "]+$/ // Expresión regular para verificar URLs
      if (this.videoTextInput && regex.test(this.videoTextInput)) 
        this.videos[this.videoSelected] = this.videoTextInput
      else 
        this.videos[this.videoSelected] = null
    },
    videoRadioChange: function (selected) {
      this.videoSelected = selected
      this.videoTextInput = this.videos[this.videoSelected]
      this.videoTextChange()
    },
    readFile: function (file) {
      let self = this
      var reader = new FileReader()
      reader.onload = (e) => {
        let img = new Image()
        img.src = e.target.result
        img.onload = () => {
          if (img.width > appConfig.IMAGE_MAX_SIZE || img.width < appConfig.IMAGE_MIN_SIZE || img.height > appConfig.IMAGE_MAX_SIZE || img.height < appConfig.IMAGE_MIN_SIZE) {
            self.success = false
            self.errorText = this.$t('Tamaño de imagen incorrecto, el alto y el ancho deben ser mayores a 1000px y menores a 2000px')
            self.images[self.imageSelected] = null
            self.imageData = null
            self.loading = false
          } else {
            self.imageData = e.target.result
            self.$emit('imageJustLoaded')
          }
        }
      }
      reader.readAsDataURL(file)
    },
    previewImage: function (event) {
      var input = event.target
      let regexp = /^[a-zA-Z0-9-_]+$/
      this.success = false
      this.errorText = ''

      if (input.files && input.files[0]) {
        let imageSrc = input.files[0].name
        let extencion = (/[.]/.exec(imageSrc)) ? /[^.]+$/.exec(imageSrc)[0] : undefined
        if (extencion !== undefined) {
          if (appConfig.IMAGE_FILES_SUPPORTED.indexOf(extencion.toUpperCase()) === -1) {
            this.fileNotSupported = true
          } else {
            if (regexp.test(imageSrc.split(".")[0])) {
              if (input.files[0].size*0.001 <= appConfig.IMAGE_MAX_KB) {
                this.images[this.imageSelected] = input.files[0]
                this.readFile(input.files[0])
              } else {
                this.success = false
                this.errorText = this.$t('El tamaño de la imágen no debe superar los 300KB.')
                this.images[this.imageSelected] = null
                this.imageData = null
                this.loading = false
              }
            } else {
              this.success = false
              this.errorText = this.$t('Error en el nombre de la imagen. Este debe ser alfanumerico y no debe llevar espacios. Puede contener guiones (- ó _) para separar palabras.')
              this.images[this.imageSelected] = null
              this.imageData = null
              this.loading = false
            }
          }
        }
      }
    },
    _filterImages: function () {
      return this.images.map(
        (e) => {
          return e ? {imageUrl: e.imageUrl} : null
        }
      ).filter(
        (e) => {
          return e !== null
        }
      )
    },
    uploadImages: function (id) {
      this.fileNotSupported = null
      let formData = new FormData()
      let endPoint = `${appConfig.API_END_POINT}/sku/${id}/images`

      if (!this.hasImagesToUpload()) {
        return Promise.resolve(this._filterImages())
      }

      this.images
        .filter(im => im !== null && im.name)
        .forEach(im => {
          formData.append('file', im)
        })
      return this.$http.post(endPoint, formData, {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }).then(
        (newImages) => {
          let _this = this
          newImages.body.forEach(
            (newImage) => {
              if (_this.images['0'] && _this.images['0'].name) {
                _this.images['0'] = newImage
              } else if (_this.images['1'] && _this.images['1'].name) {
                _this.images['1'] = newImage
              } else if (_this.images['2'] && _this.images['2'].name) {
                _this.images['2'] = newImage
              } else if (_this.images['3'] && _this.images['3'].name) {
                _this.images['3'] = newImage
              } else if (_this.images['4'] && _this.images['4'].name) {
                _this.images['4'] = newImage
              } else if (_this.images['5'] && _this.images['5'].name) {
                _this.images['5'] = newImage
              } else if (_this.images['6'] && _this.images['6'].name) {
                _this.images['6'] = newImage
              }
            }
          )
          return this._filterImages()
        },
        (error) => {
          return Promise.reject(new Error(error.body.message || this.$t('Error asociando las imagenes al producto')))
        }
      )
    },
    hasImagesToUpload: function () {
      this.success = false
      this.errorText = ''
      return this.images.some(
        (image) => {
          return image !== null && image.name
        }
      )
    },
    hasImages: function () {
      this.success = false
      this.errorText = ''
      if (this.images.length !== 0) {
        if (!this.images.some((elem) => elem !== null)) {
          return false
        }
        return true
      }
      return false
    },
    imageCount: function () {
      return this.images ? this.images.filter((elem) => elem instanceof File || (elem !== undefined && elem !== null && elem.imageUrl)).length : 0
    },
    imageError () {
      this.errorImg = true
    },
    imageSuccess () {
      this.errorImg = false
    },
    getVideos: function () {
      return this.videos
    }
  }
}
</script>

<style lang="scss">

  img.preview {
    width: 180px;
    background-color: white;
    border: 1px solid #DDDDDD;
    padding: 5px;
    cursor: pointer;
  }

</style>
