<template>
<div>
    <category-and-brand-updater></category-and-brand-updater>
</div>
</template>

<script>

import CategoryAndBrandUpdater from '@/components/catalog/CategoryAndBrandUpdater'
export default {
  name: 'category-and-brand-updater-view',
  components: {
    CategoryAndBrandUpdater
  },
  mixins: [],
  methods: {
  },
  data () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
