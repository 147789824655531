<template>
<b-row>
  <b-col>
    <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Lista de puntos de retiro')">
      <moon-loader :loading="loading" color="#000" size="30px"></moon-loader>
      <b-table
        v-show="!loading"
        id="mainTable"
        ref="mainTable"
        responsive=true
        stacked="xl"
        :empty-text="$t('No hay puntos de retiro para mostrar')"
        :empty-filtered-text="$t('No hay puntos de retiro para mostrar')"
        show-empty
        striped
        hover
        foot-clone
        :items="itemProvider"
        :fields="fields"
        :filter="filter"
        @row-dblclicked="goDetailPickupPoint"
        >
          <template slot="isActive" slot-scope="row">
            <b-badge class="p-2" pill :variant="row.item.isActive ? 'success' : 'danger'">
              {{row.item.isActive ? $t('Activo') : $t('Inactivo')}}
            </b-badge>
          </template>
          <template slot="actions" slot-scope="row">
            <b-button variant="primary" size="sm" @click.stop="showConfirmModal(row.item)">{{$t('Eliminar')}}</b-button>
          </template>
      </b-table>
    </b-card>
  </b-col>
</b-row>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import { PickupPointServiceMixin } from '@/mixims/CarrierServiceMixin.js'
export default {
  mixins: [PickupPointServiceMixin],
  components: {
    MoonLoader
  },
  props: {
    filters: {
      type: Object,
      default: null
    },
    refreshTable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: true,
      fields: [
        { key: 'name', label: this.$t('Nombre'), 'class': 'text-left', sortable: false },
        { key: 'formattedAddress', label: this.$t('Dirección'), 'class': 'text-left', sortable: false },
        { key: 'isActive', label: this.$t('Estado'), 'class': 'text-left', sortable: false },
        { key: 'actions', label: this.$t('Acciones'), 'class': 'text-left', sortable: false }
      ]
    }
  },
  methods: {
    itemProvider (ctx) {
      this.loading = true
      return this.getPickupPoints(ctx.filter()).then(
        response => {
          this.loading = false
          return response.body
        },
        error => {
          console.log(error)
          this.loading = false
          return []
        }
      )
    },
    goDetailPickupPoint (item) {
      this.$router.push({name: 'Detalle punto de retiro', params: { pickupPointId: item.id }})
    },
    showConfirmModal (item) {
      this.$emit('showConfirmDeleteModal', item)
    },
    filter () {
      return this.filters
    }
  },
  watch: {
    filters () {
      this.$refs.mainTable.refresh()
    },
    refreshTable (newValue) {
      if (newValue) this.$refs.mainTable.refresh()
    }
  }
}
</script>

<style>

</style>