<template>
<b-card>
  <b-row>
    <b-col cols="6">
        <b-form-group
          id="idInput"
          :label-cols="2"
          breakpoint="md"
          :label="$t('ID')"
          label-for="idInput">
          <b-form-input
            id="idInṕut"
            v-model="product.id"
            :disabled="true">
        </b-form-input>
      </b-form-group>
    </b-col>
    <b-col cols="6">
      <b-form-group
        id="nombreInput"
        :label-cols="2"
        breakpoint="md"
        :label="$t('Nombre')"
        :description="$t('Máximo 100 caracteres')"
        :invalid-feedback="!state.name ? $t('El nombre no es válido') : ''"
        :state="state.name"
        label-for="nombreFormatterHelp">
        <b-form-input
          id="nombreFormatterHelp"
          v-model="product.name"
          :disabled="editionDisabled"
          maxlength="100">
        </b-form-input>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="6">
      <b-form-group
        id="categoriaInput"
        :label-cols="2"
        breakpoint="md"
        :label="$t('Categoría')"
        :invalid-feedback="!state.category ? $t('Seleccione una categoría') : ''"
        :state="state.category"
        label-for="categoryFormatterHelp">
        <category-tree
          :editable="!editionDisabled"
          id='categoryFormatterHelp'
          :categorySelected="product.category"
          @onCategorySelected="selectCategory">
        </category-tree>
      </b-form-group>
    </b-col>
    <b-col cols="6">
      <b-form-group id="marcaInput"
        :label-cols="2"
        breakpoint="md"
        :label="$t('Marca')"
        :invalid-feedback="!state.brand ? $t('Seleccione una marca') : ''"
        :state="product.brand.length > 0"
        label-for="marcaFormatterHelp">
        <autocomplete
          :v-if="product.brand"
          id="inputBrand"
          class="input_class optional"
          name="inputBrand"
          :maxElements="1"
          :placeholder="$t('Ingrese una marca...')"
          :provider="getSearchBrands"
          :disabled="editionDisabled"
          v-model="product.brand">
        </autocomplete>
      </b-form-group>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-form-group
        id="textLinkInput"
        :label-cols="2"
        breakpoint="md"
        :label="$t('Descripción')"
        :invalid-feedback="!state.description ? $t('La descripción no es un texto / html válido') : ''"
        :state="state.description"
        label-for="textlinkFormatterHelp">
        <vue-editor :editorToolbar="customToolbar" :disabled="editionDisabled" name="Description" v-model="product.description" />
      </b-form-group>
    </b-col>
  </b-row>

  <b-row>
    <b-col>
      <b-form-group
        :label-cols="2"
        breakpoint="md"
        :label="$t('Sitios')"
        :invalid-feedback="!selectedSites ? $t('Seleccione al menos un sitio') : ''"
        :state="selectedSites">
        <b-form-checkbox-group
          v-model="sites"
          :options="marketplacesOptions"
          @change="siteChange"
          :disabled="editionDisabled"
          class="mb-3"
        ></b-form-checkbox-group>
      </b-form-group>
    </b-col>
  </b-row>

</b-card>
</template>

<script>
import CategoryTree from './CategoryTree'
import autocomplete from '@/components/common/vue-autocomplete'
import { VueEditor } from 'vue2-editor'

import appConfig from '@/config/applicationConfig.js'
import { ProductServiceMixim, PRODUCT_STATES } from '@/mixims/ProductServiceMixim'
import {ValidationsMixim} from '@/mixims/ValidationsMixim.js'

export default {
  mixins: [ProductServiceMixim, ValidationsMixim],
   components: {
    CategoryTree,
    autocomplete,
    VueEditor
  },
  props: {
    product: Object,
    state: Object
  },
  data () {
    return {
      isAdmin: this.$store.getters.isAdmin,
      marketplacesOptions: [...this.$store.getters.marketplacesCombo],
      sites: [],
      selectedSites: false,
      specifications: [],
      customToolbar: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        [
          { list: 'ordered' },
          { list: 'bullet' }
        ],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        ['link'],
        [{ color: [] }, { background: [] }],
        [{ header: 1 }, { header: 2 }],
        ['clean']
      ],
      locale: appConfig.LOCALE,
      currency: appConfig.CURRENCY !== "" ? appConfig.CURRENCY : '$'
    }
  },
  computed: {
    editionDisabled: function () {
      if (this.isAdmin) {
        return true
      }

      return this.product.skus.some((sku) => {
        return sku.status !== 'PENDING_APPROVAL'
      })
    }
  },
  methods: {
    selectCategory (category) {
      this.loading = true
      this.errorText = ''
      this.product.category = category
      return this.getProductAtributes(category.id).then(
        (res) => {
          this.product.productSpecifications.splice(0, this.product.productSpecifications.length)
          this.specifications = res.body
          this.$emit('categorySelected', res.body)
          this.loading = false
        },
        (error) => {
          this.loading = false
          this.errorText = error.body.message || this.$t('Error obteniendo los atributos del producto')
        }
      )
    },
    siteChange (val) {
      let marketplaces = this.$store.getters.marketplaces
      this.product.marketplaces = []

      marketplaces.forEach(marketplace => {
        let isMarketplace = val.some(site => { return site === marketplace.id })
        if (isMarketplace) {
          this.product.marketplaces.push(marketplace)
        }
      })
      if (this.product.marketplaces.length > 0) {
        this.selectedSites = true
      } else {
        this.selectedSites = false
      }
    }
  }
}
</script>

<style>

</style>