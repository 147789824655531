<template>
  <b-form-group horizontal
    :label-cols="2"
    :label="$t('Sellers')"
    :invalid-feedback="$t(invalidFeedback)"
    :state="state">
    <b-form-select
      v-model="organizations"
      :state="state"
      :options="organizationOptions"
      multiple
      :select-size="4"
      :disabled="!($store.getters.signedRoles.includes('ADMIN_USER'))"
      class="mb-3">
    </b-form-select>
  </b-form-group>
</template>
<script>

export default {
  props: ['value', 'options'],
  methods: {
    convertToOptions (list) {
      var self = this
      return list.map(i => {
        self.user.organizations.push(i)
        return {text: i.name, value: i}
      })
    }
  },
  computed: {
    organizationOptions () {
      return this.convertToOptions(this.options)
    },
    organizations: {
      set (newVal) {
        if (!newVal) this.value.organizations = []
        else if (!Array.isArray(newVal)) this.value.organizations = [newVal]
        else this.value.organizations = newVal
      },
      get () {
        return this.value.organizations
      }
    },
    invalidFeedback () {
      if (this.organizations && this.organizations.length > 0) return this.$t('Ocurrio un error al obtener los sellers')
      else return this.$t('Campo obligatorio')
    },
    state () {
      return Boolean(this.organizations && this.organizations.length > 0)
    }
  }
}
</script>
