<template>
  <div>
    <activable-products-list/>
  </div>
</template>

<script>
import ActivableProductsList from '@/components/catalog/ActivableProductList'

export default {
  name: 'activable-products-view',
  components: {
    ActivableProductsList
  }
}
</script>
